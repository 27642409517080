import * as React from 'react';
import Button from '@mui/material/Button';
import Box from "@mui/material/Box";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { IProps } from "./IProps";
import { getAxios } from '../../utils/endpoints';
import { react } from '@babel/types';
import { LeadInfo } from '../../types/LeadInfo';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

type InfoProps = {
    displayColor?: boolean;
    title: string;
    text: string;
}

const Info: React.FunctionComponent<InfoProps> = (props): JSX.Element => {
    return (
        <Box width="100%" p={1} bgcolor={props.displayColor ? "#1BA0E9" : ""}>
            <Typography fontSize={20} fontWeight="bold">{props.title}</Typography>
            <Typography mt={2}>
                { props.text ? props.text : "n/a" }
            </Typography>
        </Box>
    )
}

export const LeadInfoDialog: React.FunctionComponent<IProps> = (props): JSX.Element => {
    const [leadInfo, setLeadInfo] = React.useState<LeadInfo | null>(null)

    const onFetchDealerInfo = async (dealerId: string): Promise<void> => {
        const response = await getAxios(`/dealers/${dealerId}/info`, "GET");

        const data: LeadInfo = await response.data;

        setLeadInfo(data);
    }

    React.useEffect(() => {
        if (props.open) {
            onFetchDealerInfo(props.id);
        }
    }, [props.open])


    if (!leadInfo) {
        return <p>loading...</p>
    }

    return (
        <BootstrapDialog
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    width: 600,
                    height: 831,
                    borderRadius: 8
                }
            }}
        >
            <BootstrapDialogTitle id="customized-dialog-title" onClose={props.onClose}>
                Bath Planet of Billings
            </BootstrapDialogTitle>
            <DialogContent dividers>
                <Info displayColor title="Business Name" text={leadInfo.businessName} />
                <Info title="Warm Transfer To" text={leadInfo.transferLabel} />
                <Info displayColor title="Dealer Time Zone" text={leadInfo.dealerTimeZone} />
                <Info title="Call Center Hours" text={leadInfo.callCenterHours} />
                <Info displayColor title="Promotion" text={leadInfo.promotion} />
                <Info title='Demo Info' text={leadInfo.demoInfo1} />
                <Info displayColor title='Warranty Info' text="" />
                <Info title='Installer Info' text={leadInfo.installerInfo} />
                <Info displayColor title='Showroom Address' text={leadInfo.showroomAddress} />
                <Info title='Dealer Phone' text={leadInfo.dealerPhone} />
                <Info displayColor title="Website comments" text={leadInfo.website} />
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={props.onClose}>
                    Close
                </Button>
            </DialogActions>
        </BootstrapDialog>
    );
}
