import { FunctionComponent } from 'react';
import TextField from '@mui/material/TextField';
import Box from "@mui/material/Box"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { IProps } from "./IProps";

export const CustomTimePicker: FunctionComponent<IProps> = ({ value, onChange, ...props }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <TimePicker
        disabled={props.isDisabled}
        value={value}
        onChange={onChange}
        InputProps={{
            sx: {
                width: props.width ? props.width : 227,
                height: 35,
                fontSize: 14,
                flexDirection: "row-reverse"
            },
        }}
        renderInput={(params) => {
          return (
            <Box width="100%" >
              <TextField sx={{ width: "100%" }} {...params} />
            </Box>
          )
        }}
      />
    </LocalizationProvider>
  );
}
