import { FunctionComponent, useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, Stack, SvgIcon, Alert, Snackbar, SnackbarContent, Typography } from "@mui/material"
import { SearchBar } from "../SearchBar";
import { UserProfileNav } from "../UserProfileNav";
import { mdiBackburger } from "@mdi/js";
import { Icon } from "@mdi/react"
import { IProps } from "./IProps";
import { HubConnection, HubConnectionBuilder, ILogger } from '@microsoft/signalr';
import { LPLHttpNotificationClient } from "../../signalR/lpl-notification-http"
import axios from "axios";
import { getAxios } from '../../utils/endpoints';

export const NavBar: FunctionComponent<IProps> = ({ onToggleSidebar }): JSX.Element => {

    /*const logger: ILogger = {
        log: (logLevel, message) => {
            console.log(`log Level: ${logLevel} and message: ${message}`);
        }
    }

    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [notifications, setNotifications] = useState<string[]>([])

    let url: string = "https://lpltestapis.azure-api.net/signalr"
    

    useEffect(() => {
        const connect = new HubConnectionBuilder()
            .withUrl(url, { httpClient: new LPLHttpNotificationClient(logger) })
            .withAutomaticReconnect()
            .build();

        setConnection(connect);
    }, []);

    useEffect(() => {
        connection
            ?.start()
            .then(() => {
                console.log("connected");
                
                connection?.on("processedReport", (data) => {
                    setNotifications([data.Message, ...notifications]);

                    if (data.FileUrl !== null) {
                        window.open(data.FileUrl, "_blank")
                    }
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }, [connection])*/


    return (
        <AppBar 
            position="fixed"
            sx={{
                boxShadow: "0px 2px 5px rgba(40, 72, 154, 0.18), 0px 0px 2px rgba(40, 72, 154, 0.12), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                backgroundColor: "#FFFFFF",
                width: { md: `calc(100% - ${270}px)` },
                ml: { md: `${270}px` }
            }}
        >
            <Toolbar sx={{ width: { xs: "50%", sm: "65%", md: "35%", lg: "100%", xl: "100%" }, display: "flex", alignItems: "center", justifyContent: "center" }} >
                <Stack width="100%" direction="row" alignItems="center" spacing={2} ml={2} mr={2}>
                    <Box display={{ sm: "flex", md: "none" }}>
                        <SvgIcon htmlColor="#1BA0E9" onClick={onToggleSidebar} >
                            <Icon path={mdiBackburger} />
                        </SvgIcon>
                    </Box>
                    <SearchBar />
                </Stack>
                <Box sx={{ flexGrow: 1 }} />
                <Box sx={{ mx: 2, display: 'flex', alignItems: "center" }}>
                    <UserProfileNav />
                </Box>
            </Toolbar>
        </AppBar>
    );
}