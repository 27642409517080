import { useCallback, useEffect, useState } from "react";
import { getAxios } from "../../utils/endpoints";
import { IProfileRow, IUserRow } from "../../types/UserAccess";

interface UserAccessResponse { 
    items: IUserRow[];
    totalRecordCount: number;
}

export const useUserAccessDataGrid = () => {
    const [rows, setRows] = useState<IUserRow[]>([]);
    const [profiles, setProfiles] = useState<IProfileRow[]>([]);

    const [isDealerAccessOpen, setIsDealerAccessOpen] = useState<boolean>(false);
    const [isAssignOpen, setIsAssignOpen] = useState<boolean>(false);
    
    const [username, setUsername] = useState<string>("");
    const [rowId, setRowId] = useState<number>(0);
    const [value, setValue] = useState<string>("");
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const [startIndex, setStartIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onPageChange = (newPage: number) => {
        setStartIndex(newPage);
    }

    const onPageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    }

    const onFetchProfiles = async (): Promise<void> => {
        const response = await getAxios("/profiles", "GET", undefined, {
            ProfileMode: true,
        });        

        const data = await response.data;

        const items: IProfileRow[] = data.items;
        
        setProfiles(items);
    }

    const onFetchUserAccess = useCallback(async (userSearch?: string) => {
        const response = await getAxios("/profiles", "GET", undefined, {
            ProfileMode: false,
            search: userSearch,
            startindex: (pageSize * startIndex),
            pagesize: pageSize,
        });

        const data: UserAccessResponse = await response.data;

        if (typeof data === "string") {
            setRows([]);
            return;
        }

        setRows(data.items);
        setTotalRecordCount(data.totalRecordCount);
    }, [startIndex, pageSize]);


    const onSave = async (selectedUsername: string, profileUsername: string): Promise<void> => {
        const response = await getAxios("/access/assign-profile", "PUT", {
            selectedUsername: selectedUsername,
            profileUsername: profileUsername
        });

        const data: string = await response.data;


        setSnackbarMessage(data);
    }

    useEffect(() => {
        onFetchUserAccess();
    }, [onFetchUserAccess]);

    useEffect(() => {
        onFetchProfiles()
    }, []);

    return {
        rows,
        profiles,
        isDealerAccessOpen,
        isAssignOpen,
        username,
        rowId,
        value,
        snackbarMessage,
        search,
        startIndex,
        pageSize,
        totalRecordCount,
        setIsAssignOpen,
        setIsDealerAccessOpen,
        setUsername,
        setRowId,
        setValue,
        setSnackbarMessage,
        onPageChange,
        onPageSizeChange,
        onChange,
        onSave,
        onFetchUserAccess
    }
}