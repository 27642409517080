import { useState, useEffect, useCallback } from "react";
import { getAxios } from "../../utils/endpoints";
import { DealerAccess } from "../../types/DealerAccess";

export const useBranchAccessDialog = (username: string, onCancel: () => void) => {
    const [ids, setIds] = useState<DealerAccess[]>([]);
    const [checkedIds, setCheckedIds] = useState<string[]>([]);

    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarType, setSnackbarType] = useState<"info" | "error">("info");


    const getUserDealerAccess = useCallback(async () => {
        const response = await getAxios("/access/dealers", "GET", undefined, {
            active: true,
            selectedUsername: username
        });

        const data: DealerAccess[] = await response.data;

        data.map((val) => {
            if (val.isAssigned) {
                setCheckedIds((prevState) => [...prevState, val.username]);
            }
        })

        setIds(data);
    }, [username])


    const assignAll = (isAssigned: boolean) => {
        const allAssigned = ids.map((val) => {
            val.isAssigned = isAssigned;

            return val;
        });

        return allAssigned;
    }

    const onChangeCheckedParent = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setIds(assignAll(true));
            setCheckedIds(assignAll(true).map((val) => val.username))
        } else {
            setIds(assignAll(false));
            setCheckedIds([]);
        }
    }

    const onChangeChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;

        const items = ids;
        const objIndex = items.findIndex((val) => val.username === value);

        if (event.target.checked) {
            setCheckedIds((prevState) => [...prevState, value]);
            items[objIndex].isAssigned = true;
        } else {
            const filtered = checkedIds.filter((val) => val !== value);

            setCheckedIds(filtered);
            items[objIndex].isAssigned = false;
        }

        setIds(items);
    }

    const onSave = async () => {
        if (checkedIds.length === 0) {
            setSnackbarMessage("Select at least one id")
            setSnackbarType("error")
            return;
        }


        const response = await getAxios("/access/dealers", "PUT", {
            optionsIds: checkedIds,
            selectedUsername: username
        });

        const data = await response.data;

        setSnackbarMessage("Saved Successfully");
        setSnackbarType("info");
        onCancel();
    }

    useEffect(() => {
        setIds([]);
        setCheckedIds([]);
        getUserDealerAccess();
    }, [getUserDealerAccess]);

    return {
        ids,
        checkedIds,
        snackbarMessage,
        snackbarType,
        setSnackbarMessage,
        setSnackbarType,
        onChangeChecked,
        onChangeCheckedParent,
        onSave
    }
}