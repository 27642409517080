import React, { FunctionComponent } from "react";
import { Card, CardContent, Box, Stack } from "@mui/material";
import { Button } from "../../../Button"
import { IProps } from "./IProps";
import { Action } from "../../IState";
import { useExportModuleFormContext } from "../../context/"
import { useLocation } from "react-router-dom";

export const ActionButtons: FunctionComponent<IProps> = (props): JSX.Element => {
    const location = useLocation();
    const context = useExportModuleFormContext();

    const DiscardButton: FunctionComponent = () => {
        return (
            <Button width={120} height={40} label="Discard" onClick={props.handleDiscard} />
        );
    }

    const NextButton: FunctionComponent = () => {
        return (
            <Button
                width={120}
                height={40}
                label={(props.activeStep === 4) ? "Save" : "Next"}
                onClick={() => props.activeStep !== 4 ? (props.handleStepper(Action.Increment)) : () => {
                    context.onHandleProcess("save")
                }}
            />
        )

    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", borderTop: 1, backgroundColor: "#FAFAFA", borderColor: "#00000026", p: 2 }}>
            <Stack direction="row" spacing={2}>
                {(props.activeStep !== 0 && props.mode !== "M") && <Button variant="outlined" width={120} height={40} label='Back' onClick={() => props.mode === "M" ? null : props.handleStepper(Action.Decrement)} />}
                { context.isDisposable ? <DiscardButton /> : <NextButton /> }
            </Stack>
        </Box>
    )
}