//function to shorten a string

export const truncate = (str: string, maxLength: number, useWordBoundary: boolean): string => {
    if (str.length < maxLength) return str;

    const subString = str.slice(0, (maxLength- 1));

    return ( useWordBoundary  
        ? subString.slice(0, subString.lastIndexOf(" "))
        : subString) + "...";
}

export const validateParenthesis = (str: string): boolean => {
    let count = 0; 
    for (let char of str) { 
        if (char === '(') { 
            count++; 
        } else if (char === ')') { 
            if (count === 0) { 
                return false; 
            } 
            
            count--; 
        } 
    } 
    
    return count === 0;
}