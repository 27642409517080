import { Typography, Box } from "@mui/material"
import { EditProfileTabs } from "../../components/EditProfileTabPanel";

export const EditProfile = () => {
    return (
        <>
            <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600 }} >
                Edit Profile
            </Typography>
            <Box mt={4} >
                <EditProfileTabs  />
            </Box>
        </>
    )

}