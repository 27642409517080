import React, { useState, FunctionComponent, useEffect } from "react";
import {
    TagOutlined,
    EmailOutlined,
    PersonOutlineOutlined,
    LocalPhoneOutlined,
    PhoneOutlined,
    EditOutlined
} from "@mui/icons-material";
import { Stack, MenuItem, SelectChangeEvent } from "@mui/material"
import { CustomSelect, CustomInput, Button } from "../../../components"
import Grid from '@mui/material/Unstable_Grid2';


interface IState {
    leadCall: string;
    leadEmail: string;
    leadName: string;
    leadPhone: string;
    saleCall: string;
    saleName: string;
    salePhone: string;
}

interface IProps {
    applyFilter: (searchFor: string, seachMode: string) => Promise<void>;
    page: number;
    pageSize: number;
    id?: string;
}

const initialState: IState = {
    leadCall: "",
    leadEmail: "",
    leadName: "",
    leadPhone: "",
    saleCall: "",
    saleName: "",
    salePhone: ""
}

export const SearchFor: FunctionComponent<IProps> = ({ applyFilter, page, pageSize, id }): JSX.Element => {
    const [search, setSearch] = useState<string>(id ? "5" : "1");
    const [text, setText] = useState<string>(id ? id : "");
    const [state, setState] = useState<IState>(initialState);

    const renderSearchInputPropsBySearch = () => {
        let inputRenderedProps = {
            text: "",
            name: "",
            value: "",
            placeholder: "",
            StartIcon: <></>
        };

        switch (search) {
            case "1": {
                inputRenderedProps.text = "Phone";
                inputRenderedProps.name = "leadPhone";
                inputRenderedProps.value = state.leadPhone;
                inputRenderedProps.placeholder = "0000000";
                inputRenderedProps.StartIcon = <PhoneOutlined />;
                break;
            }
            case "2": {
                inputRenderedProps.text = "Name";
                inputRenderedProps.name = "leadName";
                inputRenderedProps.value = state.leadName;
                inputRenderedProps.placeholder = "...";
                inputRenderedProps.StartIcon = <PersonOutlineOutlined />;
                break;
            }
            case "5": {
                inputRenderedProps.text = "Call ID";
                inputRenderedProps.name = "leadCall";
                inputRenderedProps.value = state.leadCall;
                inputRenderedProps.placeholder = "000000";
                inputRenderedProps.StartIcon = <TagOutlined />;
                break;
            }
            case "3": {
                inputRenderedProps.text = "Phone";
                inputRenderedProps.name = "salePhone";
                inputRenderedProps.value = state.salePhone;
                inputRenderedProps.placeholder = "(000)-000-000";
                inputRenderedProps.StartIcon = <LocalPhoneOutlined />;
                break;
            }
            case "4": {
                inputRenderedProps.text = "Name";
                inputRenderedProps.name = "saleName";
                inputRenderedProps.value = state.saleName;
                inputRenderedProps.placeholder = "...";
                inputRenderedProps.StartIcon = <PersonOutlineOutlined />;
                break;
            }
            case "6": {
                inputRenderedProps.text = "Call ID";
                inputRenderedProps.name = "000000";
                inputRenderedProps.value = state.saleCall;
                inputRenderedProps.placeholder = "000000";
                inputRenderedProps.StartIcon = <TagOutlined />;
                break;
            }
            case "7": {
                inputRenderedProps.text = "Email";
                inputRenderedProps.name = "leadEmail";
                inputRenderedProps.value = state.leadEmail;
                inputRenderedProps.placeholder = "name@email.com";
                inputRenderedProps.StartIcon = <EmailOutlined />;
                break;
            }
        }

        return inputRenderedProps;
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>): void => {
        const { value } = event.target;

        setSearch(value as string);
        setState(initialState);
    }

    const onFetch = async (): Promise<void> => {
        await applyFilter(text, search);
    }

    useEffect(() => {
        if (id) {
            onFetch();
        }
    }, [])

    const inputProps = renderSearchInputPropsBySearch();
    const { StartIcon } = inputProps;

    return (
        <Grid container sx={{ mb: 1 }} columns={{ xs: 12, sm: 12, lg: 12 }} columnSpacing={{ lg: 2 }} >
            <Grid container sm={12} xs={12} lg={10} columnSpacing={{ xs: 0, sm: 2 }} rowSpacing={{ xs: 2 }}>
                <Grid sx={{ width: "100%" }} xs={12} sm={6} lg={4}>
                    <CustomSelect width={"100%"} placeholder="Leads with Phone #s Like" label="Search for" name="search" onChange={onChangeSelect} value={search}>
                        <MenuItem value="1">Leads with Phone #s Like</MenuItem>
                        <MenuItem value="2">Leads with Names Like</MenuItem>
                        <MenuItem value="5">Leads with Lead ID#s Like</MenuItem>
                        <MenuItem value="3">Sales with Phone #s Like</MenuItem>
                        <MenuItem value="4">Sales with Names Like</MenuItem>
                        <MenuItem value="6">Sales with Lead ID#s Like</MenuItem>
                        <MenuItem value="7">Lead with emails like</MenuItem>
                    </CustomSelect>
                </Grid>
                <Grid sx={{ width: "100%" }} xs={12} sm={6} lg={4}>
                    <CustomInput width={"100%"} height={35} label={{ text: inputProps.text }} type="text" name={inputProps.name} placeholder={inputProps.placeholder} value={text} onChange={(e) => setText(e.target.value)} startAdornment={StartIcon} />
                </Grid>
                <Grid xs={12} sm={12} lg={2} display="flex" alignItems="end">
                    <Button width={120} height={40} label='Apply' onClick={onFetch} />
                </Grid>
            </Grid>
        </Grid>
    )
} 