//function to manage dates

export const formatDate = (date: string): string => {

    if (!date || date === null) {
        return "n/a"
    }

    const dt = new Date(date);
    const month = dt.toLocaleDateString("en-US", { month: "long" });
    
    return `${month} ${dt.getDate()}, ${dt.getFullYear()}`;
}

export const getHour = (date: Date, showTimezoneCode?: boolean): string => {

    if (!date) {
        return "n/a";
    }

    let hours = date.getHours();
    let minutes: number | string = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : 'am';
    
    const timezoneCode = date.toLocaleTimeString("en-US", { timeZoneName: "short" }).split(' ')[2];

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = ('0' + minutes).slice(-2);
    
    const hour = hours + ':' + minutes + ' ' + ampm;

    return `${hour} ${showTimezoneCode ? timezoneCode : ""}`;
}

export const timeFormat = (n: number): string => {
    let seconds = n < 0 ? 0 : n;
    let hours = (seconds / 3600);
    let rootHours = Math.floor(hours);

    let secondsMinusHours = seconds - (rootHours * 3600)
    let hhssFormat = new Date(seconds * 1000).toISOString().substr(14, 5);

    if (rootHours === 0) {
        return `${hhssFormat} min`;
    }

    else if (secondsMinusHours === 0) {
        return `${rootHours} hrs`
    }

    return `${rootHours} hrs\n${hhssFormat} min`
}

export const formatUSNumber = (phoneNumber: string): string => {
    let USNumber : any = phoneNumber.match(/(\d{3})(\d{3})(\d{4})/);
    
    USNumber = `(${USNumber[1]}) ${USNumber[2]}-${USNumber[3]}`;

    return USNumber;
}

const padToTwoDigits = (num: Number): string => {
    return num.toString().padStart(2, '0')
}

export const formatPadDate = (date: Date, union: string) => {
    return [
        padToTwoDigits(date.getMonth() + 1),
        padToTwoDigits(date.getDate()),
        padToTwoDigits(date.getFullYear())
    ].join(union);
}

export const formatDateRange = (start: Date, end: Date): string[] => {
    const startMonth = ('0' + (start.getMonth() + 1)).slice(-2);
    const startDay = ('0' + (start.getDate())).slice(-2);
    
    const endMonth = ('0' + (end.getMonth() + 1)).slice(-2);
    const endDay = ('0' + (end.getDate())).slice(-2);

    const startDate = `${start.getUTCFullYear()}-${startMonth}-${startDay}`;
    const endDate = `${end.getUTCFullYear()}-${endMonth}-${endDay}`;
    
    

    return [startDate, endDate];
}

export const listOfHours = (initialHour: number, endHour: number, ampm?: "am" | "pm"): string[] => {
    const hours: string[] = [];

    for (let i = initialHour; i < endHour; i++) {
        hours.push(`${i}:00 ${ampm && ampm}`);

        if (i !== endHour) {
            hours.push(`${i}:30 ${ampm && ampm}`);
        }
    }

    return hours;
}

export const get24HourTime = (twelveHourTime: string) => {
    const d = new Date("1/1/2013 " + twelveHourTime);
    let hours: number | string = d.getHours();
    let minutes: number | string = d.getMinutes();

    console.log(minutes);

    if (hours === 0) {
        hours = "00";
    }

    if (minutes === 0) {
        minutes = "00";
    }

    return `${hours}:${minutes}`;
}

export const list12Hours = (ampm: "am" | "pm") => {
    const hours: number[] = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const formatHours: string[] = [];

    for (let i = 0; i < hours.length; i++) {
        formatHours.push(`${hours[i]}:00 ${ampm && ampm}`);

        if (i !== hours.length ) {
            formatHours.push(`${hours[i]}:30 ${ampm && ampm}`);
        }
    }
    
    return formatHours;
}

export const isValidDate = (date: string): boolean => {
    return new Date(date).toString() !== "Invalid Date";  
}