import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ExportData } from "../../types/Export"
import { getAxios, route } from "../../utils/endpoints";
import { User } from "../../model/User";

type ExportMode = "V" | "T" | "P" | "M";

export const useExport = () => {
    const [rows, setRows] = useState<ExportData[]>([]);
    
    const user = new User();
    const disableExport = user.HasForbiddenAccess("Can Read Exports Home");
    const disableAddEditExport = user.HasForbiddenAccess("Can Add or Edit Exports");

    const navigate = useNavigate();

    const onFetchExportData = useCallback(async () => {
        const response = await getAxios(route.apiExport.exports, "GET", undefined, {
            mode: "V"
        });

        const rows: ExportData[] = await response.data;

        setRows(rows)
    }, [])
    

    const onRedirectExportModuleMode = (mode: ExportMode) => {
        navigate(`/export-module?mode=${mode}`)
    }

    useEffect(() => {
        if(!disableExport) onFetchExportData(); 
    }, [onFetchExportData]);
    
    return {
        rows,
        onRedirectExportModuleMode,
        disableExport,
        disableAddEditExport
    }
}