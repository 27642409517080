import { FunctionComponent } from "react";
import { Box, SvgIcon, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import Icon from "@mdi/react";
import { IProps } from "./IProps";

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#1BA0E9',
        fontWeight: 600,
        maxWidth: 300,
        borderRadius: 10,
        textTransform: "none",
        fontSize: theme.typography.pxToRem(12),
        padding: "10px 18px 10px 22px",
    },
}));

export const BackgroundIcon: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <LightTooltip title={<Typography>{props.label!}</Typography>} placement="top-start" >
            <Box onClick={props.onClick} sx={{
                width: props.width ? props.width : 45,
                height: props.height ? props.height : 45,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "center",
                padding: 1,
                borderRadius: "100%",
                backgroundColor: props.bgColor
            }} >
                <SvgIcon
                    sx={{
                        color: props.color,
                        width: props.iconWidth ? props.iconWidth : 23.99,
                        height: props.iconHeight ? props.iconHeight : 23.99,
                    }} >
                    <Icon path={props.icon} />
                </SvgIcon>
            </Box>
        </LightTooltip>
    )
}