import React, { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { EditProfileForm } from "../EditProfileForm";
import { CustomTabs } from "../CustomTabs";
import { QueriesPanel } from "../QueriesPanel";
import { DashboardPanel } from "../DashboardPanel";
import { User } from "../../model/User";

export const EditProfileTabs: FunctionComponent = (props) => {
  const user = new User();
  const disableQueries = user.HasForbiddenAccess("Can Read Queries Tab");
  const disableDashboard = user.HasForbiddenAccess("Can Read Dashboard Tables Tab");
  const { username } = useParams();


  return (
    <CustomTabs
      tabLabels={[
        { title: "Edit Profile", disabled: false }, 
        { title: "Queries", disabled: disableQueries }, 
        { title: "Dashboard", disabled: disableDashboard },
        { title: "Integrations", disabled: false }]}
      tabComponents={[<EditProfileForm username={username} />, <QueriesPanel />, <DashboardPanel />, <EditProfileForm isIntegrationsEnabled username={username} />]}
    />
  )
}