import { useState, useEffect } from "react";
import { SourceColumn } from "../../../types/SourceColumn";
import { getAxios, route } from "../../../utils/endpoints"
import { IMode } from "../interfaces/IMode";

export const useStepFour = (mode: IMode, source: string) => {
    const [sourceItems, setSourceItems] = useState<string[]>([]);

    const onFetchSourceTables = async (): Promise<void> => {
        const sourceName = localStorage.getItem("source");

        const response = await getAxios(route.apiExport.columns, "GET", undefined, {
            mode: mode,
            source: source,
            showmode: false
        });

        const data: SourceColumn[] = await response.data;

        setSourceItems(data.map((source) => source.name));
    }
    
    useEffect(() => {
        onFetchSourceTables();
    }, []);

    return {
        sourceItems,
    }
}