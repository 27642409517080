import React, { useState, useEffect, useCallback } from "react"
import { useParams } from "react-router-dom";
import { SelectChangeEvent } from "@mui/material";
import { CallLead } from "../../types/CallLead";
import { route, getAxios } from "../../utils/endpoints"
import { User } from "../../model/User";
import { IState, IPaginationState } from "./IState";
import { GridSortModel } from "@mui/x-data-grid";
import { formatDateRange } from "../../utils/time";

export const useViewLeads = () => {
    const user = new User();
    const disableUserFunctions = user.HasForbiddenAccess("Can Read Datagrid");
    const { leadId } = useParams<{ leadId: string }>();

    const [state, setState] = useState<IState>({
        callLeads: [],
        filter: leadId ? "7" : "1",
        errorMessage: ""
    });

    const [paginationState, setPaginationState] = useState<IPaginationState>({
        isLoading: false,
        orderBy: "",
        page: 0,
        pageSize: 25,
        total: 0,
        sortModel: [{ field: "leadDate", sort: "asc" }]
    });

    const onChangeOrder = (newSortModel: GridSortModel) => {
        const currentField = newSortModel[0].field === "caller" ? "LastName" : newSortModel[0].field;

        setPaginationState((prevState) => ({
            ...prevState,
            sortModel: newSortModel,
            orderBy: `${currentField} ${newSortModel[0].sort}`
        }));
    }

    const onPageChange = (newPage: number) => {
        setPaginationState((prevState) => ({
            ...prevState,
            page: newPage
        }));
    }

    const onPageSizeChange = (newPageSize: number) => {
        setPaginationState((prevState) => ({
            ...prevState,
            pageSize: newPageSize
        }))
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>): void => {
        const { name, value } = event.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value as string,
            errorMessage: ""
        }))
    };

    const compareDates = (start: Date, end: Date): boolean => {
        let startDate = new Date(start).getTime();
        let endDate = new Date(end).getTime();

        if (startDate === endDate) {
            return true;
        }

        if (!(startDate < endDate) || !(endDate > startDate)) {
            setState({
                ...state,
                errorMessage: "Invalid date range"
            });

            return false;
        } else {
            setState({
                ...state,
                errorMessage: ""
            })
            return true
        }

        return true;
    }

    const fetchLeadsByDateAndStatus = async (start: Date | null, end: Date | null, status?: string) => {
        if (start === null || end === null) {
            setState({
                ...state,
                errorMessage: "Field cannot be empty"
            });

            return;
        }

        if (!compareDates(start, end)) {
            return;
        }

        const dates = formatDateRange(start, end)

        await fetchLead((status ? route.apiViewLeads.leadsBetweenDateAndStatus : route.apiViewLeads.salesBetween), {
            startDate: dates[0],
            endDate: dates[1],
            status: status
        });
    }

    const fetchLead = useCallback(async (endpoint: string, params?: object) => {
        setPaginationState((prevState) => ({
            ...prevState,
            isLoading: true
        }));

        try {
            const paginationParams = {
                orderBy: paginationState.orderBy,
                startIndex: paginationState.pageSize * paginationState.page,
                pageSize: paginationState.pageSize,
                userAccount: user.UserAccount
            };

            const response = await getAxios(endpoint, "GET", undefined, {
                ...params,
                ...paginationParams
            });

            const callLeads: CallLead[] = await response.data.items;
            const totalLength = await response.data.totalRecordCount;

            if (!callLeads) {
                setPaginationState((prevState) => ({
                    ...prevState,
                    isLoading: false
                }));

                setState((prevState) => ({
                    ...prevState,
                    callLeads: []
                }));

                return;
            }

            setState((prevState) => ({
                ...prevState,
                callLeads: callLeads
            }));

            setPaginationState((prevState) => ({
                ...prevState,
                total: totalLength,
                isLoading: false
            }));
        } catch (error) {
            console.error("Lead Error: ", error);

            setPaginationState((prevState) => ({
                ...prevState,
                isLoading: false
            }));
        }

    }, [state.filter, paginationState.page, paginationState.pageSize, paginationState.orderBy]);

    const fetchLeadsByFilter = async (): Promise<void> => {
        switch (state.filter) {
            case "1": {
                await fetchLead(route.apiViewLeads.unresulted);
                break;
            }

            case "5": {
                await fetchLead(route.apiViewLeads.openSales)
                break;
            }

            case "4": {
                await fetchLead(route.apiViewLeads.unresultedAppointments);
                break;
            }

            case "3": {
                await fetchLead(route.apiViewLeads.openInquiries);
                break;
            }
        }
    }

    const fetchLeadBySearch = async (searchFor: string, searchMode: string): Promise<void> => {
        try {
            await fetchLead(route.apiViewLeads.searchBy, {
                searchFor: searchFor,
                searchMode: searchMode
            });
        }

        catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        fetchLeadsByFilter();
    }, [fetchLead]);


    return {
        state,
        leadId,
        paginationState,
        disableUserFunctions,
        onChangeSelect,
        fetchLeadsByDateAndStatus,
        fetchLeadBySearch,
        onPageChange,
        onPageSizeChange,
        onChangeOrder
    };
}