import { FunctionComponent } from "react";
import { Grid, Typography, Box } from "@mui/material";
import WestIcon from '@mui/icons-material/West';
import { Button } from "../Button";
import { IProps } from "./IProps";
import { useNavigate } from "react-router-dom";


export const NotFound: FunctionComponent<IProps> = (props): JSX.Element => {
    const navigate = useNavigate();
    
    return (
        <>
            <Box sx={{ width: "100%", height: "80vh" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <img style={{ width: 300, height: 100 }} src={"/images/LP_Central_CyanOnly.png"} alt="" />
                </Grid>
                <Grid item sx={{ mt: 6 }} >
                    <Typography variant="h3" sx={{ color: "#1BA0E9", fontWeight: 600, mb: 2 }}>
                        404 { props.message }
                    </Typography>
                </Grid>
               { props.url && <Button sx={{ mt: 4 }} label={props.url.label} startIcon={<WestIcon />} onClick={() => navigate(props.url!.url)} /> } 
            </Box>
        </>
    )
}