import { HttpRequest, HttpResponse } from "@microsoft/signalr";
import { DefaultHttpClient } from "@microsoft/signalr"
import { OCP_APIM_SUBSCRIPTION_KEY } from "../utils/endpoints";

export class LPLHttpNotificationClient extends DefaultHttpClient {
    private readonly token: string | null = sessionStorage.getItem("token");

//"Ocp-Apim-Subscription-Key": "82f9a7e80b47433eb8a83460424a0bc0",

    send(request: HttpRequest): Promise<HttpResponse> {
        request.headers = {
            ...request.headers,
            "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY,
            "LPL-Authorization": `Bearer ${this.token}`
        }

        return super.send(request);
    }
}