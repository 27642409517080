import React, { useState, useEffect, useCallback } from "react";
import { IUserRow } from "../../types/UserAccess";
import { getAxios } from "../../utils/endpoints";

type AccountsResponse = {
    items: IUserRow[];
    totalRecordCount: number;
}

export const useAccountDatagrid = (isAdminMode?: boolean) => {
    const [rows, setRows] = useState<IUserRow[]>([]);
    const [rowCount, setRowCount] = useState<number>(0);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const onFetchAccounts = React.useCallback(async (profileSearch?: string) => {
        const response = await getAxios("/profiles", "GET", undefined, {
            ProfileMode: false,
            search: profileSearch,
            adminMode: isAdminMode,
            startindex: (pageSize * startIndex),
            pageSize: pageSize,
        });

        const data: AccountsResponse = await response.data;

        const userRows = data.items;

        setRows(userRows);
        setRowCount(data.totalRecordCount);
    }, [startIndex, pageSize])

    const onKeyEnterEvent = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === "Enter") {
            onFetchAccounts(event.target.value);
        }
    }

    const onPageChange = (newPage: number) => {
        setStartIndex(newPage);
    }

    const onPageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    }

    useEffect(() => {
        onFetchAccounts()
    }, [onFetchAccounts]);

    return {
        rows,
        rowCount,
        startIndex,
        pageSize,
        onFetchAccounts,
        onPageChange,
        onPageSizeChange,
        onKeyEnterEvent
    }
}