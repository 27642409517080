import React, { FunctionComponent } from "react";
import {
    Drawer,
    Box,
    Typography,
    Stack,
    SvgIcon,
    MenuItem,
    Popper,
    Autocomplete,
    TextField,
    Checkbox,
    autocompleteClasses
} from "@mui/material";
import { Icon } from "@mdi/react";
import { mdiFilterOutline } from "@mdi/js";
import { CustomDatePicker } from "../CustomDatePicker";
import { CustomSelect } from "../CustomSelect";
import { Button } from "../Button";
import { IProps } from "./IProps";
import { CustomDateRangePicker } from "../../components/CustomDateRangePicker";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from "@mui/material/styles"
import { Label } from "../../components/component-utils/Label";

const StyledPopper = styled(Popper)({
    [`&. ${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        '& ul': {
            padding: 0,
            margin: 0,
           
        }
    }
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const ReportSidebar: FunctionComponent<IProps> = (props): JSX.Element => {
    

    return (
        <Drawer open={props.open} anchor="right" PaperProps={{ sx: { width: 290, borderTopLeftRadius: 20, borderBottomLeftRadius: 20, p: 4, display: "flex", justifyContent: "space-between", flexDirection: "column" } }} >
            <Box borderBottom="1px solid rgba(0, 0, 0, 0.12)" width="100%" pb={4} >
                <Stack alignItems="center" direction="row" spacing={1}>
                    <SvgIcon>
                        <Icon path={mdiFilterOutline} color="#1BA0E9" />
                    </SvgIcon>
                    <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 20, mt: 2 }} >
                        Filters
                    </Typography>
                </Stack>
            </Box>
            <Stack alignItems="center" justifyContent="center" spacing={2} >
                {
                    props.filters.map((filter, index) => {
                        const lowerDescription = filter.description.toLocaleLowerCase().replaceAll(" ", "_");
                        const value = props.state[lowerDescription as keyof typeof props.state]

                        return (
                            <Autocomplete
                            sx={{ width: "100%" }}
                            multiple
                            limitTags={2}
                            disableListWrap
                            disableCloseOnSelect
                            PopperComponent={StyledPopper}
                            options={filter.selectedItems}
                            getOptionLabel={(option) => option.description}
                            value={value.selectedItems}
                            onChange={(event, newValue) => props.onChangeSelect(event, newValue, lowerDescription)}
                            renderInput={(params) => {
                                params.size = "small";


                                return (
                                    <>
                                        <Label text={filter.description} color="#000000" />
                                        <TextField {...params} />
                                    </>
                                )
                            }}
                            renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                    <Checkbox
                                        icon={icon}
                                        checkedIcon={checkedIcon}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.description}
                                </li>
                            )}
                        />
                        )
                    })
                }
            </Stack>
            <Stack width="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={2} mt={34} >
                <Button onClick={() => {
                    props.onToggleSidebar();
                    props.onClear();
                }} width={128} height={35} label="Clear" variant="outlined" />
                <Button width={128} height={35} label="Apply" onClick={() => {
                    props.onApply();
                    props.onToggleSidebar()
                }} />
            </Stack>
        </Drawer>
    )
}