import { combineReducers } from "redux";
import alertReducer from "./alertReducer";
import menuReducer from "./menuReducer";

const reducers = combineReducers({
    alert: alertReducer,
    menu: menuReducer
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;