import React, { FunctionComponent,  } from "react";
import { CustomDataGrid } from "../CustomDataGrid";
import { IProps } from "./IProps";

export const ReportGrid: FunctionComponent<IProps> = (props) => {
    return (
        <>
            <CustomDataGrid
                columns={props.columns}
                rows={props.rows}
                initialState={{
                    sorting: {
                        sortModel: props.sortModel
                    }
                }}
                sortingOrder={["asc", "desc"]}
                renderRefresh={props.renderRefresh}
                renderToggle={props.renderToggle}
                onRefresh={props.onRefresh}
                page={props.page}
                pageSize={props.pageSize}
                sortModel={props.sortModel}
                onPageChange={props.onPageChange}
                onPageSizeChange={props.onPageSizeChange}
                paginationMode="server"
                sortingMode="server"
                loading={props.loading}
                rowCount={props.total}
                onSortModelChange={props.onSortModelChange}
            />
        </>
    )
}