import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "../Button";
import {
    Typography,
    MenuItem,
    Divider,
    SvgIcon,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    Snackbar,
    Alert,
    Grid
} from "@mui/material/";
import { mdiClose, mdiAccountCogOutline, mdiAccountDetailsOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { CheckBox } from "@mui/icons-material";
import { CustomTabs } from "../CustomTabs";
import { IProps } from "./IProps";
import { CustomSelect } from "../CustomSelect";
import { getAxios } from "../../utils/endpoints";
import { useAssignDialog } from "./hook";
import { PageLevelSecurity } from "../../types/PageLevelSecurity";

type SelectionListItem = {
    id: number;
    name: string;
    checked: boolean;
    searchVal: string;
}

type TabName = "pageLevel" | "functionLevel"

interface ISelectionListProps {
    name: TabName;
    items: SelectionListItem[];
    selectedItems: number;
    onChangeParent: (to: TabName, event: React.ChangeEvent<HTMLInputElement>) => void;
    onChange: (to: TabName, event: React.ChangeEvent<HTMLInputElement>) => void;
    onSelectAllItemsByFilter: (to: TabName, filterName: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectionList: FunctionComponent<ISelectionListProps> = (props): JSX.Element => {
    const menuItems = props.items.map((val) => val.searchVal)

    const filtered = menuItems.filter((val, index) => {
        return menuItems.indexOf(val) === index;
    });

    const [search, setSearch] = useState<string>("");

    const allItemsAreSelected: boolean = (props.items.length > 0) && (props.items.every((val) => (val.checked === true)));

    return (
        <>
            <Box display="flex" alignItems="center" justifyContent="space-between" borderBottom="2px solid #0000001F" paddingBottom={2}>
                <CustomSelect
                    value={search}
                    name="search"
                    sx={{ width: "100%", height: 35 }}
                    label=""
                    onChange={(event) => {
                        const value = event.target.value as unknown as string;

                        setSearch(value);
                    }}
                >
                    <MenuItem value="" >
                        All
                    </MenuItem>
                    {
                        filtered.map((item) => {
                            return (
                                <MenuItem value={item} >
                                    {item}
                                </MenuItem>
                            )
                        })
                    }
                </CustomSelect>
            </Box>
            <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={allItemsAreSelected === true} checked={props.selectedItems === props.items.length} onChange={(event) => search ? props.onSelectAllItemsByFilter(props.name, search, event) : props.onChangeParent(props.name, event)} />} label="Select All" />
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    {
                        props.items.filter((val) => {
                            if (!search) {
                                return val;
                            }

                            else if (val.searchVal.includes(search)) {
                                return val;
                            }
                        }).map((val, index) => {
                            return (
                                <Grid item xs={6} >
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox defaultChecked={val.checked} checked={val.checked} value={val.id} onChange={(event) => props.onChange(props.name, event)} />} label={val.name} />
                                    </FormGroup>
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </FormGroup>
        </>
    )
}

export const AssignDialog: FunctionComponent<IProps> = (props): JSX.Element => {

    const {
        functionLevelSecurityItems,
        pageLevelSecurityItems,
        functionLevelIds,
        pageLevelIds,
        snackbarMessage,
        snackbarType,
        setSnackbarMessage,
        setSnackbarType,
        onChange,
        onChangeParent,
        onSave,
        onSelectAllItemsByFilter
    } = useAssignDialog(props.title, props.onCancel!);

    const pageLevelItems: SelectionListItem[] = pageLevelSecurityItems.map((val) => {
        return {
            checked: val.hasAccess,
            name: val.description,
            id: val.menuId,
            searchVal: val.sectionDescription
        }
    });

    const functionLevelItems: SelectionListItem[] = functionLevelSecurityItems.map((val) => {
        return {
            checked: val.hasAccess,
            id: val.accessId,
            name: val.itemName,
            searchVal: val.pageName
        }
    });



    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    width: 600,
                    height: 831,
                    borderRadius: 8
                }
            }}
        >
            <DialogTitle
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                id="alert-dialog-title"
            >
                <Stack direction="row" alignItems="center" spacing={2} >
                    <SvgIcon>
                        <Icon path={mdiAccountDetailsOutline} style={{ fontSize: 23, color: "#1BA0E9" }} />
                    </SvgIcon>
                    <Typography fontSize={22} fontWeight={700} sx={{ color: "#000000CC" }} >
                        {props.title}
                    </Typography>
                </Stack>
                <IconButton onClick={props.onCancel} >
                    <SvgIcon>
                        <Icon path={mdiClose} style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.5)" }} />
                    </SvgIcon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <CustomTabs
                    tabLabels={[{ title: "Page Level Security" }, { title: "Function Level Security" }]}
                    tabComponents={
                        [
                            <SelectionList
                                name="pageLevel"

                                onChangeParent={onChangeParent}
                                onChange={onChange}
                                items={pageLevelItems}
                                selectedItems={pageLevelIds.length}
                                onSelectAllItemsByFilter={onSelectAllItemsByFilter}
                            />,
                            <SelectionList
                                name="functionLevel"

                                onChangeParent={onChangeParent}
                                onChange={onChange}
                                items={functionLevelItems}
                                selectedItems={functionLevelIds.length}
                                onSelectAllItemsByFilter={onSelectAllItemsByFilter}
                            />
                        ]
                    }
                />
            </DialogContent>
            <DialogActions sx={{ borderTop: "2px solid #0000001F" }}>
                <Button width={70} height={35} label="Save" title="Save" onClick={() => onSave()} />
                <Button width={80} height={35} variant="outlined" label="Cancel" title="Cancel" onClick={props.onCancel} />
            </DialogActions>
            <Snackbar
                key={9}
                open={Boolean(snackbarMessage.length)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={() => {
                    setSnackbarMessage("");
                    setSnackbarType("info");
                }} autoHideDuration={3000}>
                <Alert severity={snackbarType} >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

