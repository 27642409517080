import { useNavigate } from "react-router-dom"
import { EditLeadForm } from "../../components"

export const EditLead = (): JSX.Element => {
    const navigate = useNavigate();

    return (
        <>
            <button onClick={() => navigate("/edit-sunesta-lead")} >go to edit sunesta edit lead</button>
            <EditLeadForm />
        </>
    )
}