import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { LoginForm, GridImage } from "../../components/";
import { useIsAuthenticated } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react"
import { User } from "../../model/User";

export const Login = (): JSX.Element => {
    const isAuthenticated = useIsAuthenticated();
    const user = new User();
    const token = sessionStorage.getItem("token");
    const hasToken = token ? true : false;
    const navigate = useNavigate();
    const { instance } = useMsal(); 

    const Logout = (): void => {
        instance.logout().then(() => {
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("roles");
            navigate("/");
        });
    }
    
    useEffect(() => {
        if (isAuthenticated && user.Exists && hasToken) {
            navigate("/home");
        }
    }, [])

    return (
        <>
            <Grid height="100vh" container item xs={12}>
                <GridImage src={process.env.PUBLIC_URL + "/images/login_img.jpg"} />
                <LoginForm />
            </Grid>
        </>
    )
}