import React, { FunctionComponent } from "react";
import {
    Typography,
    Box,
    MenuItem,
    MenuProps,
    Checkbox,
    ListItemText,
    SelectChangeEvent
} from "@mui/material";
import { CustomMultipleAutocomplete } from "../../CustomMultipleAutocomplete";
import { StepTitle } from "../StepTitle";
import { FilterDropdown, IStepThreeProps } from "../IProps";
import { IStepThreeState } from "../IState";
import Grid from '@mui/material/Unstable_Grid2';
import { CustomDateRangePicker } from "../../CustomDateRangePicker";
import { getAxios, route } from "../../../utils/endpoints"
import { DropdownValue } from "../../../types/DropdownValue";
import { CustomSelect } from "../../CustomSelect";

const ITEM_HEIGHT: number = 48;
const ITEM_PADDING_TOP: number = 8;

const PartialMenuProps: Partial<MenuProps> = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}

export const StepThree: FunctionComponent<IStepThreeProps<IStepThreeState>> = ({ reportType, onChangeMultipleSelect, onChangeDateRange, onChangeSelect, filters, state, ...props }): JSX.Element => {
    const [groupByValues, setGroupByValues] = React.useState<{ id: number, description: string, sortOrder: number }[]>([]);
    const [subGroupValues, setSubGroupValues] = React.useState<{ id: number, description: string, sortOrder: number }[]>([]);

    const fetchGroups = async () => {
        const groupByResponse = await getAxios(route.apiReport.filter.values, "GET", undefined, {
            Mode: 1
        });

        const subGroupResponse = await getAxios(route.apiReport.filter.values, "GET", undefined, {
            Mode: 2
        });

        const groupByData: {
            id: number;
            description: string;
            sortOrder: number
        }[] = await groupByResponse.data;

        const subGroupData: {
            id: number;
            description: string;
            sortOrder: number
        }[] = await subGroupResponse.data;

        setGroupByValues(groupByData);
        setSubGroupValues(subGroupData);
    }

    React.useEffect(() => {
        fetchGroups();
    }, []);

    const FilterForm = () => {
        return (
            <>
                {
                    filters.map((filter) => {
                        const lowerDescription = filter.description.toLowerCase().replaceAll(' ', '');
                        const sortedValues = filter.values?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1);

                        return (
                            <Grid width={"100%"} container xs={12} sm={6} md={6} rowSpacing={0} columnSpacing={0} sx={{ my: 2 }}>

                                {
                                    filter.filterType === "D" ? (
                                        <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                            <CustomMultipleAutocomplete
                                                label={{
                                                    text: filter.description
                                                }}
                                                name={lowerDescription}
                                                value={(state[lowerDescription as keyof typeof state] as FilterDropdown).values as DropdownValue[]}
                                                onChange={(event, value) => onChangeMultipleSelect(event, value, lowerDescription)}
                                                options={sortedValues === null ? [] : sortedValues}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid xs={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }} >
                                            <CustomDateRangePicker
                                                label={{
                                                    text: filter.description
                                                }}
                                                name={lowerDescription}
                                                value={lowerDescription === "inquirydate" ? state.inquirydate.values! : state.apptdate.values!}
                                                onChange={(newValue) => onChangeDateRange(newValue, lowerDescription)}
                                            />
                                        </Grid>
                                    )
                                }

                            </Grid>
                        )
                    })
                }
            </>
        )
    }

    console.log(filters);

    return (
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{ width: "100%" }} >
            <Grid xs={12} sm={12} lg={12} mt={2}>
                <StepTitle title="Detail the options inside the Filters" />
            </Grid>
            <Box mt={4} >
                <Grid width={"100%"} xs={12} sm={12} lg={12}>
                    <Typography sx={{ fontWeight: 700, color: "#1BA0E9", fontSize: 18 }} >
                        How would you want to view the data?
                    </Typography>
                </Grid>
                <Grid width={"100%"} xs={12} sm={12} lg={12}>
                    <Typography mt={1} sx={{ fontSize: 14, color: filters.length > 0 ? "inherit" : "red" }} >
                        {filters.length > 0 ? "Tick the info you want to view in each filter" : "*Please select at least one filter in step two"}
                    </Typography>
                </Grid>
            </Box>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} width={"100%"} display="flex" mt={1} sm={12} md={12}> {/*Main Container*/}
                <>
                    {(filters.length > 0) && (
                        <>
                            {
                                filters.map((filter) => {
                                    const lowerDescription = filter?.description.toLowerCase().replaceAll(' ', '') || '';
                                    const sortedValues = filter?.values?.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1) || [];

                                    return (
                                        <Grid width={"100%"} container xs={12} sm={6} md={6} rowSpacing={0} columnSpacing={0} sx={{ my: 2 }}>

                                            {
                                                filter?.filterType === "D" ? (
                                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                                        <CustomMultipleAutocomplete
                                                            label={{
                                                                text: filter.description
                                                            }}
                                                            name={lowerDescription}
                                                            value={(state[lowerDescription as keyof typeof state] as FilterDropdown).values as DropdownValue[]}
                                                            onChange={(event, value) => onChangeMultipleSelect(event, value, lowerDescription)}
                                                            options={sortedValues}
                                                        />
                                                    </Grid>
                                                ) : (
                                                    <Grid xs={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }} >
                                                        <CustomDateRangePicker
                                                            label={{
                                                                text: filter?.description
                                                            }}
                                                            name={lowerDescription}
                                                            value={lowerDescription === "inquirydate" ? state.inquirydate.values! : state.apptdate.values!}
                                                            onChange={(newValue) => onChangeDateRange(newValue, lowerDescription)}
                                                        />
                                                    </Grid>
                                                )
                                            }

                                        </Grid>
                                    )
                                })
                            }
                        </>
                    )}
                    {(filters.length > 0) && (
                        <Grid width={"100%"} container xs={12} sm={6} md={6} rowSpacing={0} columnSpacing={0}>
                            <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                {
                                    reportType === "2" && (
                                        <CustomSelect
                                            label="Group By"
                                            name="groupBy"
                                            defaultValue={state.groupBy}
                                            value={state.groupBy}
                                            onChange={(event, value) => onChangeSelect(event)}
                                            hint={(!state.isValid && !state.groupBy) ? { type: "error", message: "Group By cannot be empty" } : undefined}
                                        >
                                            {
                                                groupByValues.map((val) => {
                                                    return (
                                                        <MenuItem key={val.id} value={val.id}>
                                                            {val.description}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )}
                    {(filters.length > 0) && (
                        <Grid width={"100%"} container xs={12} sm={6} md={6} rowSpacing={0} columnSpacing={0}>
                            <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                {
                                    reportType === "2" && (
                                        <CustomSelect
                                            label="Sub Group"
                                            name="subGroup"
                                            defaultValue={state.subGroup}
                                            value={state.subGroup!}
                                            onChange={(event) => onChangeSelect(event)}
                                        >
                                            {
                                                subGroupValues.map((val) => {
                                                    return (
                                                        <MenuItem key={val.id} value={val.id}>
                                                            {val.description}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                    )
                                }
                            </Grid>
                        </Grid>
                    )}
                </>
            </Grid>
        </Grid>
    )
}