import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Warranty } from "../../types/Warranty";
import { getAxios } from "../../utils/endpoints";
import { SelectChangeEvent } from "@mui/material";
import { User } from "../../model/User";

export const useEditWarrantyForm = (id: string) => {
    const navigate = useNavigate();
    const user: User = new User();

    const [warranty, setWarranty] = React.useState<Warranty>({
        admin: 1,
        city: "",
        dateReceived: "",
        dealerId: 0,
        dealerName: "",
        firstName: "",
        id: 4,
        lastName: "",
        phone: "",
        print: "",
        state: "",
        street: "",
        streetNumber: "",
        zip: "",
        AdminNote: "",
        NewHomeOwnerName: ""
    });

    const dealers: { id: number, name: string }[] = user.Accounts.map((val) => {
        return {
            id: parseInt(val.dealerId),
            name: val.companyName
        }
    });

    const [adminNotes, setAdmiNotes] = useState<string>("");
    const [newHomeOwnerName, setNewHomeOwnerName] = useState<string>("");
    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);

    const hideSnackbar = () => {
        setSnackOpen(false);
    }

    const onFetchWarranty = async (): Promise<void> => {
        const response = await getAxios(`/warranties/${id}`, "GET");

        let data: Warranty = await response.data;

        if (!data) {
            setNotFound(true);

            return;
        }

        const date = new Date(data.dateReceived);

        const formatDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDay()}`;

        data.dateReceived = formatDate;

        setWarranty(data);
    }

    const onChangeSelect = async (event: SelectChangeEvent<unknown>): Promise<void> => {
        setWarranty((prevState) => ({
            ...prevState,
            dealerId: event.target.value as number
        }));
    }

    const onUpdate = async (): Promise<void> => {
        const response = await getAxios("/warranties", "PUT", {
            Id: parseInt(id),
            FirstName: warranty.firstName,
            LastName: warranty.lastName,
            StreetNumber: warranty.streetNumber,
            Street: warranty.street,
            City: warranty.city,
            State: warranty.state,
            Zip: warranty.zip,
            Phone: warranty.phone,
            NewHomeOwnerName: warranty.NewHomeOwnerName,
            DateOfTransfer: warranty.dateReceived,
            AdminNotes: warranty.AdminNote,
            NewDealer: warranty.dealerId
        });

        setSnackOpen(true);
        
        setTimeout(() => {
            navigate("/view-warranties");
        }, 1000)
    }

    const onClose = () => {
        navigate("/view-warranties");
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        if (name === "streetNumber" || name === "zip") {
            if ((/[^0-9\b]$/.test(value))) {
                return;
            }
        }

        setWarranty((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    useEffect(() => {
        onFetchWarranty();
    }, []);

    return {
        warranty,
        adminNotes,
        dealers,
        newHomeOwnerName,
        setAdmiNotes,
        setNewHomeOwnerName,
        onChange,
        onChangeSelect,
        onUpdate,
        snackOpen,
        notFound,
        hideSnackbar,
        onClose
    }
}