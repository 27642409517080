import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import GlobalStyles from "@mui/material/GlobalStyles";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { AppTheme } from "../theme/";
import { useApp } from "./app.hook";
import { routes } from "../routes";
import { PageLayout } from "../components";
//import { msalConfig } from "../config/msalConfig";
import { ProtectedRoute } from "../components/ProtectedRoute";
import { LicenseInfo } from "@mui/x-license-pro"
import { AlertDialog } from "../components/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { RootState } from "../state/reducers";

//instance of microsoft azure
//const clientApplication: PublicClientApplication = new PublicClientApplication(msalConfig);

LicenseInfo.setLicenseKey('b95df37960443de6901cc7d25138610fTz01MjA0NyxFPTE2OTY2OTI2Mzc4OTQsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

export const App = () => {
    const { tenantName, webAppId, alertDialogProps } = useApp();

    const msalConfig: Configuration = {
        auth: {
            clientId: webAppId,
            authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/B2C_1_LPLSignIn`,
            knownAuthorities: [`${tenantName}.b2clogin.com/user.read`],
            postLogoutRedirectUri: "/",
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: true
        }
    };

    const clientApplication: PublicClientApplication = new PublicClientApplication(msalConfig);

    const state = useSelector((state: RootState) => state.alert);
    const dispatch = useDispatch();

    const { SwitchAlertOff } = bindActionCreators(actionCreators, dispatch);

    return (
        <>
            <MsalProvider instance={clientApplication}>
                <ThemeProvider theme={AppTheme} >
                    <GlobalStyles styles={{
                        body: { backgroundColor: "#F9FCFE" }
                    }}
                    />
                    <BrowserRouter>
                        <Routes>
                            {
                                routes.map((route, index): JSX.Element => {
                                    return (
                                        <Route
                                            key={index}
                                            index={route.index}
                                            path={route.path!}
                                            element={
                                                route.renderWithLayout ? (
                                                    <ProtectedRoute>
                                                        <PageLayout>{route.element}</PageLayout>
                                                    </ProtectedRoute>
                                                ) : route.element
                                            }
                                        />
                                    )
                                })
                            }
                        </Routes>
                    </BrowserRouter>
                    <AlertDialog
                        type="error"
                        title="Access Denied"
                        description="You do not have permission to perform this action"
                        open={state}
                        onCancel={SwitchAlertOff}
                        onContinue={SwitchAlertOff}
                    />
                </ThemeProvider>
            </MsalProvider>
        </>
    )
}

/*
                <MsalProvider instance={clientApplication}>
                    <ThemeProvider theme={AppTheme} >
                        <GlobalStyles styles={{
                            body: { backgroundColor: "#F9FCFE" }
                        }}
                        />
                        <BrowserRouter>
                            <Routes>
                                {
                                    routes.map((route, index): JSX.Element => {
                                        return (
                                            <Route
                                                key={index}
                                                index={route.index}
                                                path={route.path!}
                                                element={
                                                    route.renderWithLayout ? (
                                                        <ProtectedRoute>
                                                            <PageLayout>{route.element}</PageLayout>
                                                        </ProtectedRoute>
                                                    ) : route.element
                                                }
                                            />
                                        )
                                    })
                                }
                            </Routes>
                        </BrowserRouter>
                        <AlertDialog
                            type="error"
                            title="Access Denied"
                            description="You do not have permission to perform this action"
                            open={false}
                            onCancel={() => { }}
                            onContinue={() => { }}
                        />
                    </ThemeProvider>
                </MsalProvider>*/