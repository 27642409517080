import React, { FunctionComponent, useState } from "react";
import { SvgIcon, Typography, Grid, IconButton, Stack, Box, Snackbar, Alert, styled, MenuItem } from "@mui/material";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { CustomDataGrid } from "../CustomDataGrid";
import { mdiSquareEditOutline, mdiTrashCan, mdiPencil, mdiContentSave, mdiClose, mdiMagnify } from "@mdi/js";
import { Icon } from "@mdi/react"
import { IProps } from "./IProps";
import { Button } from "../../components/Button";
import { BranchAccessModal } from "../../components/BranchAccessDialog";
import { AssignDialog } from "../../components/AssignDialog";
import { CustomInput } from "../CustomInput";
import { useUserAccessDataGrid } from "./hook";
import { CustomSelect } from "../CustomSelect";

const defaultColumnOptions: Partial<GridColDef> = {
    resizable: false,
    flex: 1,
    hideSortIcons: false,
    disableColumnMenu: false
}

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#1BA0E9"
}));

export const UserAccessDataGrid: FunctionComponent<IProps> = (props) => {
    const {
        isAssignOpen,
        isDealerAccessOpen,
        rowId,
        rows,
        profiles,
        username,
        value,
        snackbarMessage,
        search,
        pageSize,
        startIndex,
        totalRecordCount,
        onPageChange,
        onPageSizeChange,
        setSnackbarMessage,
        setIsAssignOpen,
        setIsDealerAccessOpen,
        setRowId,
        setUsername,
        setValue,
        onChange,
        onSave,
        onFetchUserAccess,
    } = useUserAccessDataGrid();

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={22}
                    >
                        {params.value}
                    </Typography>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "username",
            headerName: "Username",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={22}
                        sx={{ color: "#00000099" }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "dealerAccess",
            headerName: "Dealer Access",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const hasValue: boolean = Boolean(params.value);
                const ids: string[] = (params.value as string).split(",").splice(0, 3);
                const dealerAccessValues: string = ids.length > 1 ? (ids.join(",") + "...") : ids.join(",")

                const isAdmin: boolean = params.row.seclevel > 1;

                const onGetRow = () => {
                    setUsername(params.row.username)
                    setIsDealerAccessOpen(true);
                }

                return (
                    <Box sx={{ cursor: "pointer" }} onClick={isAdmin ? onGetRow : undefined} >
                        <Typography
                            fontWeight={700}
                            fontSize={14}
                            lineHeight={22}
                            sx={{ color: (hasValue && isAdmin) ? "#1BA0E9" : "#ED3E3E" }}
                        >
                            {(hasValue) ? dealerAccessValues : "None"}
                        </Typography>
                    </Box>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "assign",
            headerName: "Assign",
            align: "center",
            headerAlign: "center",

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <IconButton color="primary" sx={{ border: "2px solid", borderRadius: 1 }} onClick={() => {
                        setUsername(params.row.username)
                        setIsAssignOpen(true)
                    }}>
                        <SvgIcon>
                            <Icon path={mdiSquareEditOutline} />
                        </SvgIcon>
                    </IconButton>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "profileUsername",
            headerName: "Profile",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    (rowId === params.row.id) ? (
                        <CustomSelect label="" name="profile" value={value} onChange={(event) => setValue(event.target.value as unknown as string)}  width={150}>
                            { profiles.map((val) => {
                                return (
                                    <MenuItem value={val.companyName} >{val.companyName}</MenuItem>
                                )
                            }) }
                        </CustomSelect>
                    ) : (
                        <Typography
                            fontWeight={400}
                            fontSize={14}
                            lineHeight={22}
                            sx={{ color: "#00000099" }}
                        >
                            {params.value}
                        </Typography>
                    )
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "actions",
            headerName: "Actions",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                const EditActionButton = () => {
                    return (
                        <Stack direction="row" spacing={1}>
                            <IconButton color="default" onClick={() => {
                                setRowId(params.row.id);
                                setValue(params.row.profileUsername)
                            }} >
                                <SvgIcon>
                                    <Icon path={mdiPencil} />
                                </SvgIcon>
                            </IconButton>
                            <IconButton color="default" onClick={() => {
                                setRowId(0);
                                setValue("");
                            }} >
                                <SvgIcon>
                                    <Icon path={mdiTrashCan} />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    )
                }

                const SaveActionButton = () => {
                    return (
                        <Stack direction="row" spacing={1}>
                            <IconButton color="default" onClick={async () => {
                                await onSave(params.row.username, value);
                                setValue("")
                                setRowId(0)
                            }}>
                                <SvgIcon>
                                    <Icon path={mdiContentSave} />
                                </SvgIcon>
                            </IconButton>
                            <IconButton color="default" onClick={() => {
                                setRowId(0);
                                setValue("");
                            }} >
                                <SvgIcon>
                                    <Icon path={mdiClose} />
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    )
                }

                return (rowId === params.row.id) ? <SaveActionButton /> : <EditActionButton />
            },
            ...defaultColumnOptions
        },
    ]

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
                <CustomInput name="search" value={search} onChange={onChange} height={35} placeholder="Search for a record" onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        onFetchUserAccess(event.target.value)
                    }
                }} />
                <Button label="Search" height={35} onClick={() => onFetchUserAccess(search)} /> 
            </Stack>
            <CustomDataGrid
                sortingOrder={["asc", "desc"]}
                columns={columns}
                rows={rows}
                page={startIndex}
                rowsPerPageOptions={[5, 10, 15, 25]}
                rowCount={totalRecordCount}
                pagination
                initialState={{
                    pagination: {
                        page: startIndex,
                        pageSize: pageSize
                    }
                }}
                paginationMode="server"
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
            />
            <Snackbar
                key={9}
                open={Boolean(snackbarMessage.length)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={() => {
                    setSnackbarMessage("");
                }} autoHideDuration={3000}>
                <Alert severity="info" >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <BranchAccessModal open={isDealerAccessOpen} title={username} onCancel={() => {
                setUsername("");
                setIsDealerAccessOpen(false);
            }} />
            <AssignDialog
                ids={[]}
                open={isAssignOpen}
                title={username}
                onCancel={() => {
                    setUsername("");
                    setIsAssignOpen(false);
                }}
            />
        </>
    )
}