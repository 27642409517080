import React, { FunctionComponent } from "react";
import { Card, CardContent, Typography, Box, Stack, MenuItem, Snackbar, Alert } from "@mui/material";
import { Save } from "@mui/icons-material";

import { CustomInput, CustomSelect, Button, } from "..";
import { CustomTextArea } from "../CustomTextArea";;
import { useEditWarrantyForm } from "./hook";
import { useParams } from "react-router-dom";
import { NotFound } from "../NotFound";

export const EditWarrantyForm = (): JSX.Element => {
    const { warrantyId } = useParams();

    const {
        warranty,
        dealers,
        adminNotes,
        snackOpen,
        notFound,
        newHomeOwnerName,
        setAdmiNotes,
        setNewHomeOwnerName,
        onChange,
        onChangeSelect,
        onUpdate,
        hideSnackbar,
        onClose
    } = useEditWarrantyForm(warrantyId!);

    const SectionTitle: FunctionComponent<{ title: string }> = (props): JSX.Element => {
        return (
            <Box sx={{ p: 2, backgroundColor: "#F5F5F5", borderRadius: 4, mt: 6 }}>
                <Typography fontSize={18} fontWeight="600" color="#1BA0E9">
                    {props.title}
                </Typography>
            </Box>
        )
    }

    return !notFound ? (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Card sx={{ borderRadius: 4, width: "100%" }} >
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" position="relative">
                        <Typography fontSize={22} fontWeight="600" color="#1BA0E9" textAlign="center">
                            Editing | <span style={{ color: "#00000099" }}>Warranty ID# { warranty.id } ({warranty.firstName}, {warranty.lastName})</span>
                        </Typography>
                    </Box>
                    <SectionTitle title="Personal Info" />
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="firstName" label={{ text: "First Name" }} value={warranty.firstName} onChange={onChange} width={360} height={35} />
                        <CustomInput name="lastName" label={{ text: "Last Name" }} value={warranty.lastName} onChange={onChange} width={360} height={35} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="streetNumber" label={{ text: "Street #" }} value={warranty.streetNumber} onChange={onChange} width={360} height={35} />
                        <CustomInput name="street" label={{ text: "Street" }} value={warranty.street} width={360} onChange={onChange} height={35} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="city" label={{ text: "City" }} value={warranty.city} width={360} onChange={onChange} height={35} />
                        <CustomInput name="state" label={{ text: "State" }} value={warranty.state} width={360} onChange={onChange} height={35} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="zip" label={{ text: "Zip" }} value={warranty.zip} onChange={onChange} width={360} height={35} />
                    </Stack>
                    <SectionTitle title="Administrative Information" />
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomSelect name="dealer" label="Dealer" value={warranty.dealerId} onChange={onChangeSelect} width={360}>
                            {
                                dealers.map((item) => {
                                    return (
                                        <MenuItem value={item.id} >
                                            {item.name}
                                        </MenuItem>
                                    )
                                })
                            }
                        </CustomSelect>
                        <CustomInput name="newHomeOwner" label={{ text: "New Home Owner" }} value={warranty.NewHomeOwnerName} onChange={onChange} width={360} height={35} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="dateReceived" label={{ text: "Date of transfer" }} onChange={onChange} value={warranty.dateReceived} width={360} height={35} />
                    </Stack>
                    <Box mt={4}>
                        <CustomTextArea label={{ text: "Admin Notes" }} value={warranty.AdminNote} onChange={onChange} name="adminNotes" width="100%" maxLength={500} />
                    </Box>
                    <Box sx={{ width: "100%", borderTop: "1px solid rgba(0, 0, 0, 0.12)", mt: 4, p: 2 }}>
                        <Stack direction="row" justifyContent="flex-end">
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" label="Cancel" onClick={onClose} />
                                <Button startIcon={<Save />} label="Save" onClick={onUpdate} />
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
            {<Snackbar key={9} open={snackOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={hideSnackbar} autoHideDuration={2000}>
                <Alert sx={{ width: '100%', backgroundColor: "#1BA0E9", color: "white", fontSize: 18, fontWeight: 600 }}>
                    Warranty has been updated!
                </Alert>
            </Snackbar>}
        </Box>
    ) : <NotFound message="Warranty not found" url={{ url: "/view-warranties", label: "Go Back" }} />
}