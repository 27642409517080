import React, { FunctionComponent } from "react";
import { Card, CardContent, Box, Stack } from "@mui/material";
import { Button } from "../Button";
import { CustomTabs } from "../CustomTabs";
import { AccessDashboard } from "../AccessDashboard";
import { TabPanelInfo } from "../TabPanelInfo";
import { RenderIcon } from "../RenderIcon";
import { mdiAccountFilterOutline, mdiFinance, mdiCalendarCheckOutline, mdiFileCogOutline } from "@mdi/js";
import { useDashboardPanel } from "./hook"

const icons = {
    mdiAccountFilterOutline,
    mdiFinance,
    mdiCalendarCheckOutline,
    mdiFileCogOutline
}

export const DashboardPanel: FunctionComponent = () => {
    const { categories, items, selectedItems, isLoading, disableSaveButton, onChange, onUpdateSelectedTables, fetchTableAccessItems } = useDashboardPanel();

    const labels = categories.map((category) => {
        return {
            icon: RenderIcon(icons[category.icon as keyof typeof icons]),
            title: category.description
        }
    });

    return (
        <>
            <TabPanelInfo length={selectedItems.length} message="You may select up to 3 Graphs, Leader Boards, and Required Charts to include on your personalized dashboard on the Start page of LeadPerfection" />
            <Card sx={{ boxShadow: 2, mt: 2, borderRadius: 4 }}>
                <CardContent>
                    <CustomTabs
                        orientation="vertical"
                        tabLabels={labels}
                        tabComponents={labels.map((label) => {
                            const categoryItems = items.filter((item) => item.dashboardTableCategory === label.title)

                            return (
                                <AccessDashboard isLoading={isLoading} disabled={selectedItems.length === 3} items={categoryItems} onChange={onChange} />
                            )
                        })}
                    />
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", borderTop: 1, backgroundColor: "#FAFAFA", borderColor: "#00000026", p: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" width={120} height={40} label="Cancel" onClick={fetchTableAccessItems} />
                        <Button disabled={disableSaveButton} width={120} height={40} label='Save' onClick={onUpdateSelectedTables} />
                    </Stack>
                </Box>
            </Card>
        </>
    )
}
