import { FunctionComponent } from "react";
import { Grid, Stack, Typography, Radio, RadioGroup, FormControl, FormControlLabel, MenuItem } from "@mui/material";
import { CustomSelect } from "../../../CustomSelect";
import { IProps } from "./IProps";
import { useSourceOrder } from "./hook";

export const SourceOrder: FunctionComponent<IProps> = (props): JSX.Element => {
    //const { onChangeSelect, onChangeRadio, order, source } = useSourceOrder();

    return (
        <Grid container xs={12} sm={12} md={12} lg={12} columnSpacing={2}>
            <Grid xs={2} sm={1.2} md={1.2} display="flex" alignItems="center" mt={4}>
                <Stack display={"flex"} justifyContent={"center"} alignItems={"center"} minWidth={38} minHeight={38} width={38} height={38} border={1} borderColor={"#00000026"} borderRadius={"50%"}>
                    <Typography fontWeight={700} fontSize={14} color={"#1BA0E9"}>{props.order}</Typography>
                </Stack>
            </Grid>
            <Grid xs={4} sm={7} md={5} lg={6.5} display="flex" alignItems="center" mt={4}>
                <CustomSelect name="source" value={props.sourceValue} onChange={props.onChangeSelect} width={'100%'} label=''>
                    {
                        props.sourceItems.map((item, index) => {
                            return (
                                <MenuItem value={item} key={index}>{item}</MenuItem>
                            )
                        })
                    }
                </CustomSelect>
            </Grid>
            <Grid xs sm md lg display="flex" alignItems="center" mt={4}>
                <FormControl>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        name="radio-buttons-group"
                        value={props.orderValue}
                        onChange={props.onChangeOrder}
                    >
                        <Grid direction={'row'} ml={4}>
                            <FormControlLabel value="ASC" control={<Radio />} label="Ascending" />
                            <FormControlLabel value="DESC" control={<Radio />} label="Descending" />
                        </Grid>
                    </RadioGroup>
                </FormControl>
            </Grid>
        </Grid>
    )
}