import { FunctionComponent } from "react";
import { Grid, Stack, Box } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import { Button } from "../";
import { CustomInput } from "../CustomInput"
import { IProps } from "./IProps";
import { useLoginForm } from "./login-form.hook"

export const LoginForm: FunctionComponent<IProps> = (props) => {
    const { state,  onChange, onClear, onOpen } = useLoginForm();

    return (
        <Grid position="relative" display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="center" bgcolor="#12253A" item xs={12} md={6} lg={6} xl={6} p={0}>
            <Box position="absolute" top={0} right={0} >
                <img style={{ width: 204, height: 218 }} src={process.env.PUBLIC_URL + "images/LP_Top_Right_Corner.png"} alt="" />
            </Box>
            <Box mt={10} >
                <img style={{ width: 300, height: 100 }} src={process.env.PUBLIC_URL + "images/LP_Central_CyanOnly.png"} alt="" />
            </Box>
            <Stack spacing={6} >
                <CustomInput 
                    sx={{ width: 500, backgroundColor: "white", fontWeight: 600, py: 3, px: 2 }}
                    hint={{
                        type: state.error.type,
                        message: state.error.message
                    }}
                    startIcon={<PersonOutlineOutlinedIcon />} 
                    label={{ text: "User", color: "white" }} 
                    placeholder="User" 
                    type="text" 
                    name="email" 
                    value={state.email}
                    onChange={onChange} 
                    onClear={() => onClear("email")} 
                />
            </Stack>
            <Button label="Log In" endIcon={<ArrowForward />} onClick={onOpen} />
            <img src={process.env.PUBLIC_URL + "lpl_footer.svg"} alt="" />
        </Grid>
    )
}