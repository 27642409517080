import React, { FunctionComponent } from 'react';
import { Link } from "react-router-dom";
import {
    Typography,
    SvgIcon,
    IconButton,
    Accordion,
    AccordionSummary
} from "@mui/material";
import { mdiDotsVertical } from "@mdi/js";
import { Icon } from "@mdi/react";
import FolderIcon from '@mui/icons-material/Folder';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Unstable_Grid2';
import { FolderMenu } from "./FolderMenu";
import { IProps, IReportsFolders, IReportHeaderProps } from "./IProps";
import { useFolder } from "./hook";
import { FolderItem } from "./FolderItem";
import { AlertDialog } from '../AlertDialog';

const FolderHeader: FunctionComponent<IReportHeaderProps> = (props) => {
    const { anchorEl, onClick, onClose, open } = useFolder();

    return (
        <>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{'color': '#1BA0E9'}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{boxShadow: '0px 4px 8px rgba(40, 72, 154, 0.06)'}}
            >
                <Grid container display="flex" alignItems="center">
                    <Grid>
                        <FolderIcon sx={{ color: "#1BA0E9" }} />
                    </Grid>
                    <Grid>
                        <Typography sx={{ fontWeight: 700, fontSize: 22 }}>{props.title}</Typography>
                    </Grid>
                </Grid>
                <Grid xs display="flex" justifyContent="flex-end" alignItems="center">
                    <IconButton onClick={onClick} >
                        <SvgIcon>
                            <Icon path={mdiDotsVertical} color="rgba(0, 0, 0, 0.4)" />
                        </SvgIcon>
                    </IconButton>
                </Grid>
            </AccordionSummary>
            <FolderMenu
                open={open}
                anchorEl={anchorEl}
                hideDeleteFolder={props.hideDeleteFolder} 
                hideRenameFolder={props.hideRenameFolder} 
                onClose={onClose}
                onDelete={props.onDelete}
                onRename={() => {
                    onClose();
                    props.onRename();
                }}
            />
        </>
    )
}

export const Folder: FunctionComponent<IReportsFolders> = (props): JSX.Element => {
    const [open, setOpen] = React.useState<boolean>(false);
    const lengthFolders = props.reportsItem.length;
    return (
        <>
            <Accordion>
                <FolderHeader title={props.description} onDelete={props.onDelete} onRename={props.onRename} hideDeleteFolder={props.hideDeleteFolder} hideRenameFolder={props.hideRenameFolder} />
                {
                    props.reportsItem?.map((item, index) => {
                        return (
                            <FolderItem
                                key={index}
                                id={item.id}
                                reportFolderId={item.reportFolderId}
                                folders={props.folders!}
                                favorite={item.favorite}
                                lastItem={index === lengthFolders - 1}
                                onDeleteItem={() => props.onDeleteReport(item.id)}
                                onFavorite={() => props.onFavorite(item.id)}
                                onUnFavorite={() => props.onUnFavorite(item.id)}
                                reportTitle={item.reportTitle}
                                description={item.description}
                                onClone={() => props.onClone(item.id)}
                                onSelect={() => props.onSelectedItem(item.id)}
                                unSelect={() => props.unSelectedItem(item.id)}
                                onNewFolder={() => props.onNewFolder()}
                                hideEdit={props.hideEditItem}
                                hideClone={props.hideClone}
                                hideAddFavorites={props.hideAddFavorites}
                                hideDelete={props.hideDelete}
                                hideMove={props.hideMove}
                                hideLink={props.hideLink}
                            />
                        )
                    })
                }
            </Accordion>
        </>
    );
}