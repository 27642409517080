import { useState } from "react";

export const useCustomDatePicker = () => {
    const [color, setColor] = useState<string>("rgba(0, 0, 0, 0.8)");

    const onChangeLabelColor = (color: string) =>{
        setColor(color);
    }

    return {
        color,
        onChangeLabelColor
    }
}