import { FunctionComponent } from "react";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import { CustomMenu } from "../CustomMenu";
import { User } from "../../model/User";
import { IProps } from "./IProps";
import Icon from "@mdi/react";
import { 
    mdiPencil,
    mdiAccountKeyOutline
} 
from "@mdi/js";
import { mdiHomeVariantOutline, mdiAccountFilterOutline, mdiFolderOutline, mdiFilePlusOutline, mdiTestTube, mdiLinkBoxOutline } from "@mdi/js"
import { useUserNavMenu } from "./hook";
import { UserMenuLink } from "./type"
import { useSelector } from "react-redux";
import { RootState } from "../../state/reducers";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";

export const UserNavMenu: FunctionComponent<IProps> = (props): JSX.Element => {
    const { Logout, onChangePassword } = useUserNavMenu();

    const state = useSelector((state: RootState) => state.menu);

    const links: UserMenuLink[] = state.filter((val) => val.show !== "L").map((item) => {
        let icon: any;

        switch (item.title) {
            case "General": {
                icon = mdiHomeVariantOutline;
                break;
            }

            case "Lead Detail": {
                icon = mdiAccountFilterOutline;
                break;
            }

            case "Marketing": {
                icon = mdiFolderOutline;
                break;
            }

            case "Edit Profile": {
                icon = mdiPencil;
                break; 
            }

            default: {
                icon = mdiLinkBoxOutline;
                break;
            }
        }        

        return {
            href: item.slug,
            label: item.title,
            Icon: <Icon path={icon} />
        }
    })

    const funcLinks = [
        { Icon: <Icon path={mdiAccountKeyOutline} />, label: "Reset Password", onClick: onChangePassword },
        { Icon: <LogoutOutlinedIcon />, label: "Logout", onClick: Logout },
    ];



    return (
        <CustomMenu links={links} funcLinks={funcLinks} {...props} />
    )
}