import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { Card, CardContent, Typography, Box, SvgIcon, Stack, FormControlLabel, Checkbox, MenuItem, Snackbar, Alert } from "@mui/material";
import { CloseOutlined, PersonOutlined, Person, LanguageOutlined, LocationOnOutlined, Save } from "@mui/icons-material";
import { mdiMapMarkerRadiusOutline, mdiMapMarkerPath, mdiBriefcaseOutline, mdiReceiptOutline, mdiPound, mdiStoreOutline, mdiEmailOutline, mdiPhoneOutline, mdiClockOutline, mdiCurrencyUsd } from "@mdi/js";
import { Icon } from "@mdi/react";
import { CustomInput, CustomSelect, Button, } from "..";
import { CustomTextArea } from "../CustomTextArea";
import { useEditLeadForm } from "./hook";
import Link from '@mui/material/Link';
import { CustomMultipleAutocomplete } from "../CustomMultipleAutocomplete";
import Grid from '@mui/material/Unstable_Grid2';
import { DropdownValue } from "../../types/DropdownValue";
import { CustomDatePicker } from "..";
import { text } from "stream/consumers";
import { User } from "../../model/User";
import { listOfHours, list12Hours, get24HourTime } from "../../utils/time";
import { CustomAutocomplete } from "../../components/CustomAutocomplete"

export const EditLeadForm = (): JSX.Element => {
    const { leadId } = useParams();
    const user = new User();

    const {
        state,
        number,
        dropdownItems,
        snackOpen,
        dropdownProduct,
        dropDonwNonBillReason,
        dropDownDealerAccess,
        dropdownNumber,
        dropdownSale,
        onChange,
        onChangeCheckBox,
        onChangeSelect,
        onUpdate,
        onCancel,
        hideSnackbar,
        onChangeDate,
        onChangeAutocomplete
    } = useEditLeadForm(parseInt(leadId!));

    const SectionTitle: FunctionComponent<{ title: string }> = (props): JSX.Element => {
        return (
            <Box sx={{ p: 2, backgroundColor: "#F5F5F5", borderRadius: 4, mt: 6 }}>
                <Typography fontSize={18} fontWeight="600" color="#1BA0E9">
                    {props.title}
                </Typography>
            </Box>
        )
    }

    const ApptSection = (): JSX.Element => {
        return (
            <>
                <Grid container xs={12} sm={12} md={4}>
                    <Grid xs={12} width={"100%"}>
                        <CustomDatePicker disabled={state.formDisabled} width={"100%"} label={{ text: "Appt Date" }} onChange={(newValue) => onChangeDate(newValue, "Appt Date")}></CustomDatePicker>
                    </Grid>
                </Grid>
                <Grid container xs={12} sm={12} md={4}>
                    <Grid xs={12} width={"100%"}>
                        <CustomSelect disabled={state.formDisabled} MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }} width="100%" name="apptTime" value={state.apptTime} label="Time Interval" StartIcon={(
                            <SvgIcon>
                                <Icon path={mdiClockOutline} />
                            </SvgIcon>
                        )} onChange={onChangeSelect} >
                            {
                                [list12Hours("am"), list12Hours("pm")].flat(1).map((value, index) => {
                                    return (
                                        <MenuItem key={index} value={get24HourTime(value.toUpperCase())}>
                                            {value}
                                        </MenuItem>
                                    )
                                })
                            }
                        </CustomSelect>
                    </Grid>
                </Grid>
            </>
        )
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Card sx={{ borderRadius: 4, width: "100%" }} >
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" position="relative">
                        <Typography fontSize={22} fontWeight="600" color="#1BA0E9" textAlign="center">
                            Editing | <span style={{ color: "#00000099" }}>Lead ID# {leadId} ({state.actualLastName}, {state.actualFirstname})</span>
                        </Typography>
                    </Box>
                    <SectionTitle title="Personal Info" />
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput disabled={state.formDisabled} value={state.actualFirstname} name="actualFirstname" startIcon={<Person />} label={{ text: "First Name" }} width={360} height={35} onChange={onChange} />
                        <CustomInput disabled={state.formDisabled} value={state.actualLastName} name="actualLastName" startIcon={<PersonOutlined />} label={{ text: "Last Name" }} width={360} height={35} onChange={onChange} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput disabled={state.formDisabled} value={state.actualState} name="actualState" startIcon={<LanguageOutlined />} label={{ text: "State Name" }} width={360} height={35} onChange={onChange} />
                        <CustomInput disabled={state.formDisabled} value={state.actualCity} name="actualCity" startIcon={<Icon path={mdiMapMarkerRadiusOutline} />} label={{ text: "City Name" }} width={360} height={35} onChange={onChange} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput disabled={state.formDisabled} value={state.actualAddress} name="actualAddress" startIcon={<LocationOnOutlined />} label={{ text: "Address" }} width={360} height={35} onChange={onChange} />
                        <CustomInput disabled={state.formDisabled} value={state.actualZip} name="actualZip" startIcon={<Icon path={mdiMapMarkerPath} />} label={{ text: "Zip Code" }} width={360} height={35} onChange={onChange} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput disabled={state.formDisabled} value={state.actualPhone} name="actualPhone" startIcon={<Icon path={mdiPhoneOutline} />} label={{ text: "Phone 1" }} width={360} height={35} onChange={onChange} />
                        <CustomInput disabled={state.formDisabled} value={state.phone2} name="phone2" startIcon={<Icon path={mdiPhoneOutline} />} label={{ text: "Phone 2" }} width={360} height={35} onChange={onChange} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput disabled={state.formDisabled} value={state.actualEmail} name="actualEmail" startIcon={<Icon path={mdiEmailOutline} />} label={{ text: "Email 1" }} width={360} height={35} onChange={onChange} />
                        <CustomInput disabled={state.formDisabled} value={state.email2} name="email2" startIcon={<Icon path={mdiEmailOutline} />} label={{ text: "Email 2" }} width={360} height={35} onChange={onChange} />
                    </Stack>
                    <Box mt={2}>
                        <CustomTextArea disabled={state.formDisabled} value={state.otherInfo} name="otherInfo" width="100%" maxLength={500} onChange={onChange} />
                    </Box>
                    <SectionTitle title="Lead Detail" />
                    <Grid container columns={{ xs: 12, sm: 12, md: 12 }} columnSpacing={0} sx={{ mt: 2 }} rowSpacing={2}>

                        <Grid container xs={12} sm={12} md={6} >
                            <Grid xs={12} sm={12} md={6} width={{ xs: "100%", md: "97%" }}>
                                <CustomSelect disabled={state.formDisabled} MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }} name="callStatus" width={"100%"} label="Lead Outcome" value={state.callStatus} onChange={onChangeSelect} >
                                    {

                                        dropdownItems.map((item, index) => {

                                            return (
                                                <MenuItem key={index} value={item.id} >{item.description}</MenuItem>
                                            )
                                        })
                                    }
                                </CustomSelect>
                            </Grid>
                        </Grid>
                        <Grid xs={12} container sm={12} md={6}>
                            <Grid xs={12} sm={12} md={6} width={{ xs: "100%", md: "97%" }}>
                                <CustomSelect disabled={state.formDisabled} StartIcon={<Icon path={mdiBriefcaseOutline} />} name="product" width={"100%"} label="Product Interest" value={state.product} onChange={onChangeSelect}>
                                    {
                                        dropdownProduct.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.id} >{item.description}</MenuItem>
                                            )
                                        })
                                    }
                                </CustomSelect>
                            </Grid>
                        </Grid>

                        <Grid container md={12}> {/*SALES SECTION */}
                            {(state.callStatus === "8") && <>

                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomDatePicker disabled={state.formDisabled} name="apptDate" width={"100%"} label={{ text: "Appt Date" }} value={state.apptDate} onChange={(newValue) => onChangeDate(newValue, "apptDate")}></CustomDatePicker>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomSelect disabled={state.formDisabled} MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }} name="apptTime" value={state.apptTime} width="100%" label="Time Interval" StartIcon={(
                                            <SvgIcon>
                                                <Icon path={mdiClockOutline} />
                                            </SvgIcon>
                                        )} onChange={onChangeSelect} >
                                            {
                                                [list12Hours("am"), list12Hours("pm")].flat(1).map((value, index) => {
                                                    return (
                                                        <MenuItem key={index} value={get24HourTime(value.toUpperCase())}>
                                                            {value}
                                                        </MenuItem>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomInput disabled={state.formDisabled} value={state.saleAmount} name="saleAmount" startIcon={<Icon path={mdiCurrencyUsd} />} type="number" label={{ text: "Sale Amount", color: "rgba(0, 0, 0, 0.8)" }} width={"100%"} height={35} />
                                    </Grid>
                                </Grid>
                                <Grid xs={12} sm={12} md={12}>
                                    <Grid>
                                        <SectionTitle title="Sale Information" />
                                    </Grid>
                                </Grid>

                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomSelect disabled={state.formDisabled} name="saleStatus" width={"100%"} label="Sales Status" value={state.saleStatus} onChange={onChangeSelect}>
                                            {
                                                dropdownSale.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.id}>{item.description}</MenuItem>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomDatePicker disabled={state.formDisabled} name="saleDate" width={"100%"} label={{ text: "Sale Date" }} value={state.saleDate} onChange={(newValue) => onChangeDate(newValue, "saleDate")}></CustomDatePicker>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomDatePicker disabled={state.formDisabled} name="orderDate" width={"100%"} label={{ text: "Order Date" }} value={state.orderDate} onChange={(newValue) => onChangeDate(newValue, "orderDate")}></CustomDatePicker>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomDatePicker disabled={state.formDisabled} name="shipDate" width={"100%"} label={{ text: "Ship Date" }} value={state.shipDate} onChange={(newValue) => onChangeDate(newValue, "shipDate")}></CustomDatePicker>
                                    </Grid>
                                </Grid>
                                <Grid container xs={12} sm={6} md={4}>
                                    <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "95%" }}>
                                        <CustomDatePicker disabled={state.formDisabled} name="installDate" width={"100%"} label={{ text: "Install Date" }} value={state.installDate} onChange={(newValue) => onChangeDate(newValue, "installDate")}></CustomDatePicker>
                                    </Grid>
                                </Grid>

                            </>}

                        </Grid>
                        <Grid container xs={12} sm={12} md={12} >
                            {(state.callStatus === "4") && <ApptSection />}
                            {(state.callStatus === "5") && <ApptSection />}
                            {(state.callStatus === "6") && <ApptSection />}
                            {(state.callStatus === "7") && <ApptSection />}
                            {(state.callStatus === "I") && <ApptSection />}
                        </Grid>
                    </Grid>


                    <SectionTitle title="Administrative Information" />
                    <Grid columns={{ xs: 12 }} container columnSpacing={0}>
                        <Grid container xs={12} sm={6} md={4}>
                            <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "97%" }}>
                                <CustomSelect disabled={state.formDisabled} width={"100%"} StartIcon={<Icon path={mdiReceiptOutline} />} label="Non Bill Reason" name="nbrId" value={state.nbrId} onChange={onChangeSelect}>
                                    {
                                        dropDonwNonBillReason.map((item, index) => {
                                            return (

                                                <MenuItem key={index} value={item.id}>{item.description}</MenuItem>
                                            )
                                        })
                                    }
                                </CustomSelect>
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sm={6} md={4}>
                            <Grid xs={12} sm={12} md={12} width={{ xs: "100%", sm: "95%", md: "97%" }}>
                                <CustomAutocomplete
                                    disabled={state.formDisabled}
                                    options={dropdownNumber}
                                    name="dialedTollFree"
                                    label={{
                                        text: "Number"
                                    }}
                                    value={number}
                                    onChange={(event, val) => onChangeAutocomplete(event, val, "dialedTollFree")}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12} sm={6} md={4} >
                            <Grid xs={12} md={12} width={{ xs: "100%", sm: "95%", md: "97%" }}>
                                {
                                    (user.Roles[0].secLevel !== 1) && (
                                        <CustomSelect disabled={state.formDisabled} MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }} width={"100%"} StartIcon={<Icon path={mdiStoreOutline} />} label="Reassign to Dealer" placeholder="Select a product" value={state.newDealerId} name="newDealerId" onChange={onChangeSelect}>
                                            {
                                                dropDownDealerAccess.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.id}>{item.description}</MenuItem>
                                                    )
                                                })
                                            }
                                        </CustomSelect>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Stack direction="row" alignItems="center" justifyContent="flex-end" >
                        <FormControlLabel disabled={state.formDisabled} control={<Checkbox name="sendEmail" value={state.sendEmail} onChange={onChangeCheckBox} />} label="Email Dealer" />
                    </Stack>
                    <Box sx={{ width: "100%", borderTop: "1px solid rgba(0, 0, 0, 0.12)", mt: 4, p: 2 }}>
                        <Stack direction="row" justifyContent="flex-end">
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" label="Cancel" onClick={onCancel} />
                                <Button disabled={state.formDisabled} startIcon={<Save />} label="Save" onClick={onUpdate} />
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
            <Snackbar key={9} open={snackOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={hideSnackbar} autoHideDuration={2000}>
                <Alert sx={{ width: '100%', backgroundColor: "#1BA0E9", color: "white", fontSize: 18, fontWeight: 600 }}>
                    Lead has been updated!
                </Alert>
            </Snackbar>
        </Box>
    )
}