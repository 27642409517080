import { useState } from "react";
import {  
    Dialog,
    DialogTitle,
    DialogActions,
    DialogContent,
    DialogContentText,
    IconButton,
    styled,
} from "@mui/material/";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "../Button";
import { IDialogTitleProps, ICustomDialogProps } from "./IProps";

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(4),
    },
}));

const DialogHeader = (props: IDialogTitleProps): JSX.Element => {
    const { children, onClose, color, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, p: 2, color }} { ...other } >
            { children }
            { onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[600],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null }
        </DialogTitle>
    );
}

export const CustomDialog = (props: ICustomDialogProps): JSX.Element => {
    const [open, setOpen] = useState<boolean>(props.isOpen)

    const onClose = () => {
        setOpen(false);
    }

    const renderColorByVariant = (): string => {
        let color: string = "";

        if (props.variant === "default") {
            color = "rgba(0, 0, 0, 0.8)"
        } 
        
        else {
            color = "#1BA0E9"    
        }

        return color;
    }

    return (
        <StyledDialog open={open} onClose={onClose} aria-labelledby="custom-dialog" >
            <DialogHeader id="dialog-header-title" color={renderColorByVariant()} onClose={onClose} >
                { props.title }
            </DialogHeader>
            <DialogContent dividers >
                <DialogContentText>
                    { props.body }
                </DialogContentText>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }} disableSpacing >
                {props.variant === "default" ? (
                    <Button backgroundColor="transparent" label="Accept" width="100%" height={35} onClick={onClose} />
                    ) : (
                        <Button label="Accept" width={235} height={35} onClick={onClose} />
                    )
                }
            </DialogActions>
        </StyledDialog>
    )
}