import { CSSProperties, FunctionComponent, useState, memo } from "react";
import {
    Typography,
    IconButton,
    SvgIcon,
    Box,
    ListItem,
    ListItemButton,
    Popover,
    Stack,
    Badge
} from "@mui/material";
import { useNotificationPopOver } from "./hook";
import { Icon } from "@mdi/react"
import { mdiBellOutline, mdiClose, mdiCircle } from "@mdi/js";
import Grid from '@mui/material/Unstable_Grid2';
import { FixedSizeList, FixedSizeListProps, ListChildComponentProps, areEqual } from "react-window";
import { INotificationProps } from "./IProps";
import { LPLNotification } from "../../types/LPLNotification";
import { getHour, timeFormat } from "../../utils/time"
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime"
import moment from "moment";
import memoize from "memoize-one";

interface IRowProps {
    notifications: LPLNotification[];
    onAcknowledge: (id: number) => void;
}

const createItemData = memoize((notifications, onAcknowledge) => ({
    notifications,
    onAcknowledge
}));

const Row = memo((props: ListChildComponentProps<IRowProps>) => {
    const { index, style, data } = props;
    const { notifications, onAcknowledge } = data;

    const row = notifications[index];

    const cssStyle: Partial<CSSProperties> = {
        ...style,
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: !row.acknowledged ? "rgba(27, 160, 233, 0.2)" : "inherit"
    }

    const onClick = () => {
        if (row.url) {
            window.open(row.url, "_blank");
        }

        onAcknowledge(row.id);
    }

    const relativeTime = () => {
        let secondsAgo = row.secondsAgo // comes in the notification object
        let timeUnit;
        let amount;

        if (secondsAgo < 60) {
            // Less than a minute has passed:
            amount = secondsAgo
            timeUnit = "second";

        } else if (secondsAgo < 3600) {
            // Less than an hour has passed:
            amount = Math.floor(secondsAgo / 60)

            timeUnit = "minute";

        } 

        else if (secondsAgo < 86400) {
            // Less than a day has passed:
            amount = Math.floor(secondsAgo / 3600);
            timeUnit = "hour";

        } else if (secondsAgo < 2620800) {
            // Less than a month has passed:
            amount = Math.floor(secondsAgo / 86400);
            timeUnit = "day";
        } 
        
        else if (secondsAgo < 31449600) {
            // Less than a year has passed:
            amount = Math.floor(secondsAgo / 2620800);
            timeUnit = "month";
        } 
        
        else {
            // More than a year has passed:
            amount = Math.floor(secondsAgo / 31449600);
            timeUnit = "year";
        }

        // pluralize
        if (amount != 1) timeUnit += "s";

        return `${amount} ${timeUnit} ago`;
    }

    return (
        <ListItem style={cssStyle} key={index} component="div" disablePadding>
            <ListItemButton onClick={onClick}>
                <Grid md={12} >
                    <Stack spacing={0.4} px={1}>
                        <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" >
                            <Typography sx={{ fontSize: 16, fontWeight: 700, color: "#1BA0E9", fontFamily: "lato" }}>{row.messageTitle}</Typography>
                            <SvgIcon sx={{ fontSize: 10 }}>
                                <Icon path={mdiCircle} color="#1BA0E9" />
                            </SvgIcon>
                        </Box>
                        <Box pr={2} mb={1}>
                            <Typography sx={{ fontSize: 14, fontWeight: 400 }}>{row.messageDetails}</Typography>
                            <Typography sx={{ fontSize: 13, fontWeight: 400, color: "#00000080" }}>{relativeTime()}</Typography>
                        </Box>
                    </Stack>
                </Grid>
            </ListItemButton>
        </ListItem>
    )
}, areEqual)

export const NotificationPopOver: FunctionComponent<INotificationProps> = (props): JSX.Element => {
    const {
        anchorEl,
        open,
        onClick,
        onClose
    } = useNotificationPopOver();

    const itemData = createItemData(props.notifications, props.onAcknowledgeNotification)

    return (
        <>
            <IconButton onClick={onClick}>
                <Badge badgeContent={props.notifications.filter((notification) => !notification.acknowledged).length} color="primary">
                    <SvgIcon>
                        <Icon path={mdiBellOutline} />
                    </SvgIcon>
                </Badge>
            </IconButton>
            <Popover open={open} anchorEl={anchorEl} onClose={() => {
                props.onReset();
                onClose();
            }} sx={{ minWidth: 220, maxWidth: 420, minHeight: 273, maxHeight: 473, margin: 0 }}>
                <Box p={2} borderBottom="solid 1px rgba(0, 0, 0, 0.12)" position="sticky" overflow="hidden">
                    <Grid display="flex" alignItems="center" justifyContent="space-between">
                        <Typography justifyContent="start" sx={{ fontWeight: 700, fontSize: 20, color: "#000000CC" }}>Notifications</Typography>
                        <IconButton onClick={(event) => {
                            props.onReset()
                            onClick(event);
                        }}>
                            <SvgIcon>
                                <Icon path={mdiClose} />
                            </SvgIcon>
                        </IconButton>
                    </Grid>
                </Box>
                {
                    props.notifications.length ? (
                        <FixedSizeList
                            height={430}
                            width={420}
                            itemSize={90}
                            itemCount={props.notifications.length}
                            overscanCount={2}
                            itemData={itemData}
                        >
                            {Row}
                        </FixedSizeList>
                    ) : (
                        <Box width={420} height={420} display="flex" alignItems="center" justifyContent="center">
                            <Typography sx={{ fontSize: 14, fontWeight: 700, fontFamily: "lato" }}>You have zero notifications</Typography>
                        </Box>
                    )
                }
            </Popover>
        </>
    )
}