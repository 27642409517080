import { FunctionComponent, useEffect, useState } from "react";
import { MenuItem, Stack, Box, Typography, SelectChangeEvent } from "@mui/material";
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import { CustomSelect, CustomDatePicker, Button } from "../../../components";
import Grid from '@mui/material/Unstable_Grid2';
import { getAxios, route } from "../../../utils/endpoints";

type DropdownItem = {
    id: string;
    description: string;
}

interface IState {
    startDate: Date | null,
    endDate: Date | null;
    dropdownItems: DropdownItem[];
    status: string;
}

interface IProps {
    applyFilter: (start: Date, end: Date, status: string) => Promise<void>;
    page: number,
    pageSize: number
}

export const AllSalesBetweenASpecificDateAndStatus: FunctionComponent<IProps> = ({ applyFilter, page, pageSize }): JSX.Element => {
    const [state, setState] = useState<IState>({
        startDate: new Date(),
        endDate: new Date(),
        dropdownItems: [],
        status: "U"
    });

    const onChangeDate = (newDate: Date | null, name: string): void => {
        setState({
            ...state,
            [name]: newDate
        });
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;

        setState({
            ...state,
            status: value as string
        });

    }

    const getDropdownItems = async (): Promise<void> => {
        const response = await getAxios(route.apiOptions.leadStatus, "GET", undefined, {IncludeAll: true});

        const dropdowns: DropdownItem[] = await response.data;

        setState((prevState) => ({
            ...prevState,
            dropdownItems: dropdowns
        }));
    }

    const onFetch = async (): Promise<void> => {
        await applyFilter(state.startDate!, state.endDate!, state.status);
    }

    useEffect(() => {
        onFetch();
        getDropdownItems();
    }, [page, pageSize]);

    return (

        <Grid container columns={{ xs: 12, sm: 12, lg: 12 }} sx={{ width: "100%", mb: 2 }} columnSpacing={{ xs: 0, lg: 2 }} rowSpacing={{ xs: 2, lg: 0 }}>
            <Grid container xs={12} sm={12} lg={6} sx={{ width: "100%" }} rowSpacing={{ xs: 2 }} columnSpacing={{ sm: 2 }}>
                <Grid xs={12} sm={6} lg={6} sx={{ width: "100%" }}>
                    <CustomDatePicker width={"100%"} label={{ text: "Start Date" }} value={state.startDate} onChange={(newValue) => onChangeDate(newValue, "startDate")} />
                </Grid>
                <Grid xs={12} sm={6}>
                    <CustomDatePicker width={"100%"} label={{ text: "End Date" }} value={state.endDate} onChange={(newValue) => onChangeDate(newValue, "endDate")} />
                </Grid> 
            </Grid>
            <Grid container xs={12} sm={12} lg={6} display="flex" spacing={2} >
                <Grid xs={6} sm={6} lg={6}>
                    <CustomSelect
                        width={"100%"}
                        name="status"
                        placeholder="Leads with Phone is Like"
                        StartIcon={<FormatAlignLeftOutlinedIcon />}
                        label="Status" onChange={onChangeSelect} value={state.status}
                    >
                        {
                            state.dropdownItems.map((item, index) => {
                                return (
                                    <MenuItem key={index} value={item.id}>{item.description}</MenuItem>
                                )
                            })
                        }
                    </CustomSelect>
                </Grid>
                <Grid xs sm={6} lg={6} display="flex" alignItems="end">
                    <Button width={120} height={40} label='Apply' onClick={onFetch} />
                </Grid>
            </Grid>
        </Grid>


    )
} 