import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom"
import { SelectChangeEvent } from "@mui/material";
import { arrayMove } from "@dnd-kit/sortable";
import { IStepOneState, IStepTwoState, IStepThreeState, IStepFourState, Action } from "./IState";
import { FilterDropdown, IColumn, IFilter, ISelectedFilter, StepThree } from "./IProps"
import { getAxios, route } from "../../utils/endpoints";
import { Folder } from "../../types/Folder";
import { DateRange } from "@mui/x-date-pickers-pro";
import { Dayjs } from "dayjs";
import { DropdownValue } from "../../types/DropdownValue";
import { formatDate, formatDateRange, formatPadDate } from "../../utils/time";
import { StepFour } from "./StepFour";
import dayjs from "dayjs";
import { User } from "../../model/User";

interface IFilterDetail {
    filterId: number;
    selectedItems: any;
}

interface JSONBody {
    reportType: string;
    folderId: number;
    reportTitle: string;
    description: string;
    shortDescription: string;
    sendEmailNotification: boolean;
    emailsToNotify: string[];
    fileFormat: string;
    selectedColumnsIds: number[];
    selectedFiltersIds: number[];
    filterDetails: IFilterDetail[];
    groupBy: number[];
    subGroupBy: number[];
    isScheduled: boolean;
    timeInterval: string;
    timeZone: string;
    frequency: number;
    time: string;
    dayPreference: string;
    startingDate: Date | null;
    endingDate: Date | null;
    id?: number;
}

export const useNewReportForm = (
    httpAction: "POST" | "PUT",
    stepOne: IStepOneState,
    stepTwo: IStepTwoState,
    stepThree: IStepThreeState,
    stepFour: IStepFourState,
    id?: string
) => {
    const [activeStep, setActiveStep] = useState<number>(0);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [responseStatus, setResponseStatus] = useState({
        msg: "",
        severity: ""
    })

    const user = new User();
    const hideEditReport = user.HasForbiddenAccess("Can Edit Custom Report Specs");
    const hideAddReport = user.HasForbiddenAccess("Can Add Custom Report Specs");

    const navigate = useNavigate();

    const handleStepper = (action: Action) => {
        switch (action) {
            case Action.Increment: {
                if (activeStep >= 3) {
                    return;
                }

                setActiveStep(activeStep + 1);
                break;
            }

            case Action.Decrement: {
                if (activeStep <= 0) {
                    return;
                }

                setActiveStep(activeStep - 1);
                break;
            }
        }
    }

    const jumpToStep = (step: number) => {
        setActiveStep(step);
    }

    const resetStepper = () => setActiveStep(0);

    const onChange = <T extends object>(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>, setterState: React.Dispatch<React.SetStateAction<T>>) => {
        const { name, value } = event.target;

        setterState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const hideSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setSnackbarOpen(false);
    }

    const mockOnSave = () => {
        const filterDetails: IFilterDetail[] = [];

        for (const key in stepThree) {
            const prop = key as keyof typeof stepThree;

            if (
                (key !== "inquirydate") &&
                (key !== "apptdate") &&
                (key !== "isValid") &&
                (key !== "groupBy") &&
                (key !== "subGroup")
            ) {
                const filter = stepThree[prop] as unknown as any;
                const newFilter: FilterDropdown = stepThree[prop] as unknown as FilterDropdown;

                filterDetails.push({
                    filterId: filter.filterId,
                    selectedItems: httpAction === "POST" ? newFilter.values.map((val) => val.id) : filter.values.map((val: any) => val.value ? parseInt(val.value) : val.id)
                });

                console.log(`filter ${prop}: `, filter);
                console.log(`step three ${prop}: `, stepThree);
            }
        }

        if (stepThree.apptdate.values[0] !== null) {
            const date = formatDateRange(stepThree.apptdate.values[0]!.toDate(), stepThree.apptdate.values[1]!.toDate())

            filterDetails.push({
                filterId: stepThree.apptdate.filterId,
                selectedItems: [date[0], date[1]]
            })
        }

        console.log(filterDetails)

    }

    const onSave = async (): Promise<void> => {
            let route: string = httpAction === "POST" ? "reports/custom" : `reports/custom/${id}`;
            const emails: string[] = [];
            const filterDetails: IFilterDetail[] = [];

            if ((stepOne.folder === 0) && (!stepOne.title)) {
                setSnackbarOpen(true);
                setResponseStatus({
                    msg: "Fill the required fields in step one before continue",
                    severity: "error"
                });

                return;
            }

            if (stepOne.folder === 0) {
                setSnackbarOpen(true);
                setResponseStatus({
                    msg: "Select a folder in the folders dropdown in step one",
                    severity: "error"
                });

                return;
            }

            if (stepOne.title === "") {
                setSnackbarOpen(true);
                setResponseStatus({
                    msg: "Report title cannot be empty",
                    severity: "error"
                });

                return;
            }

            for (const key in stepFour.emails) {
                emails.push(stepFour.emails[key as keyof typeof stepFour.emails]);
            }

            if (stepFour.isReportScheduled) {
                let filteredEmails = emails.filter((email) => (email !== "" && typeof email !== "undefined"));

                if (filteredEmails.length === 0) {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: "Please fill at least one email input to continue",
                        severity: "error"
                    });

                    return;
                }

                const notValidEmails = filteredEmails.filter((email) => !email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/));

                if(notValidEmails.length > 0) {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: "One of the emails is not valid",
                        severity: "error"
                    });

                    return;
                }

                const duplicateResult = filteredEmails.some((value, index) => {
                    return filteredEmails.indexOf(value) !== index;
                });

                if (duplicateResult) {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: "There are duplicate emails",
                        severity: "error"
                    });

                    return;
                }

            }

            if (stepOne.type === "2") {
                if (!stepThree.groupBy) {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: "Group By is a required field",
                        severity: "error"
                    });

                    return;
                }
            }

            for (const key in stepThree) {
                const prop = key as keyof typeof stepThree;

                if (
                    (key !== "inquirydate") &&
                    (key !== "apptdate") &&
                    (key !== "isValid") &&
                    (key !== "groupBy") &&
                    (key !== "subGroup")
                ) {
                    const filter = stepThree[prop] as unknown as any;
                    const newFilter: FilterDropdown = stepThree[prop] as unknown as FilterDropdown;


                    filterDetails.push({
                        filterId: filter.filterId,
                        selectedItems: httpAction === "POST" ? newFilter.values.map((val) => val.id) : filter.values.map((val: any) => val.value ? parseInt(val.value) : val.id)
                    });

                    console.log(`filter ${prop}: `, filter);
                }
            }

            if (stepThree.apptdate.values[0] !== null) {
                const apptDate = formatDateRange(stepThree.apptdate.values[0]!.toDate(), stepThree.apptdate.values[1]!.toDate())
    
                filterDetails.push({
                    filterId: stepThree.apptdate.filterId,
                    selectedItems: [apptDate[0], apptDate[1]]
                })
            }

            if (stepThree.inquirydate.values[0] !== null) {
                const inquiryDate = formatDateRange(stepThree.inquirydate.values[0]!.toDate(), stepThree.inquirydate.values[1]!.toDate())
    
                filterDetails.push({
                    filterId: stepThree.inquirydate.filterId,
                    selectedItems: [inquiryDate[0], inquiryDate[1]]
                })
            }

            if (stepTwo.selectedOptions.length <= 0) {
                setSnackbarOpen(true);
                setResponseStatus({
                    msg: "Please select at least one of the columns in step two",
                    severity: "error"
                });

                return;
            }

            if (stepTwo.selectedFilters.length <= 0) {
                setSnackbarOpen(true);
                setResponseStatus({
                    msg: "Please select at least one of the filters in step two",
                    severity: "error"
                });

                return;
            }

            if (stepFour.isReportScheduled) {
                if (!stepFour.reportFile) {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: "Select a file format",
                        severity: "error"
                    });
    
                    return;
                }

                for (const key in stepFour) {
                    if (key === "on") {
                        continue;
                    }

                    if (
                        (stepFour[key as keyof typeof stepFour] === "") ||
                        (stepFour[key as keyof typeof stepFour] === null)
                    ) {
                        setSnackbarOpen(true);
                        setResponseStatus({
                            msg: `Error: Fill all fields in step 4 before continue`,
                            severity: "error"
                        });

                        return;
                    }
                }
            }

            const aux = filterDetails.filter((val) => (val.selectedItems.length !== 0 && typeof val !== "undefined"));

            stepTwo.selectedFilters.forEach(element => {
                const item = aux.find((val) => val.filterId === element);

                if (item === undefined || item === null) {
                    aux.push({ filterId: element, selectedItems: [null] })
                }
            });

            const JsonBody: JSONBody = {
                reportType: stepOne.type === "1" ? "D" : "S",
                folderId: stepOne.folder,
                reportTitle: stepOne.title ? stepOne.title : `Report ${formatPadDate(new Date(), "/")}`,
                description: stepOne.description,
                shortDescription: stepOne.shortDescription,
                sendEmailNotification: stepFour.isReportScheduled,
                emailsToNotify: (stepFour.isReportScheduled) ? emails.filter((val) => (val !== "" && typeof val !== "undefined")) : [],
                fileFormat: stepFour.reportFile,
                selectedColumnsIds: stepTwo.selectedOptions,
                selectedFiltersIds: stepTwo.selectedFilters,
                filterDetails: aux,
                groupBy: [parseInt(stepThree.groupBy)],
                subGroupBy: [parseInt(stepThree.subGroup)],
                isScheduled: stepFour.isReportScheduled,
                timeInterval: stepFour.isReportScheduled ? stepFour.timeInterval : "",
                timeZone: stepFour.isReportScheduled ? stepFour.timeZone : "",
                frequency: stepFour.isReportScheduled ? parseInt(stepFour.every) : 0,
                time: stepFour.isReportScheduled ? `${stepFour.hour?.hour()}:${stepFour.hour?.minute()}` : "",
                dayPreference: stepFour.isReportScheduled ? stepFour.on as string : "",
                startingDate: stepFour.isReportScheduled ? stepFour.startDate : new Date(),
                endingDate: stepFour.isReportScheduled ? stepFour.endDate : new Date()
            }

            

            await getAxios(route, httpAction, {
                JSONString: JSON.stringify(JsonBody)
            })
                .then((val) => {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: httpAction === "POST" ? `The report ${stepOne.title} has been created` : `${stepOne.title} has been updated`,
                        severity: "success"
                    });

                    setTimeout(() => {
                        navigate("/reports");
                    }, 2000);
                })
                .catch(function (error) {
                    setSnackbarOpen(true);
                    setResponseStatus({
                        msg: `Error: ${error.data[0].errorMessage}`,
                        severity: "error"
                    });
                });
    }

    return {
        activeStep,
        snackbarOpen,
        responseStatus,
        hideEditReport,
        hideAddReport,
        hideSnackbar,
        handleStepper,
        onChange,
        onSave,
        resetStepper,
        jumpToStep,
        mockOnSave
    }
}

export const useStepOne = (stepOnePreloadedData: Partial<IStepOneState>) => {
    const initialState = {
        type: stepOnePreloadedData.type === "D" ? "1" : "2",
        folder: stepOnePreloadedData.folder!,
        title: stepOnePreloadedData.title!,
        description: stepOnePreloadedData.description!,
        shortDescription: stepOnePreloadedData.shortDescription!
    }

    const [stepOneState, setStepOneState] = useState<IStepOneState>({
        ...initialState,
        folders: [],
        isValid: true
    });

    const validateField = () => {
        if ((stepOneState.type === "") || (stepOneState.folder <= 0) || (stepOneState.title === "")) {
            setStepOneState((prevState) => ({
                ...prevState,
                isValid: false
            }));

            return false
        }

        else {
            setStepOneState((prevState) => ({
                ...prevState,
                isValid: true
            }));

            return true;
        }
    }

    const onClear = () => {
        setStepOneState((prevState) => ({
            ...initialState,
            folders: prevState.folders,
            isValid: true
        }));
    }

    const fetchFolders = useCallback(async () => {
        const response = await getAxios(route.apiReports.reportsFolders, "GET", undefined, {
            Active: 1,
            SortBy: 0,
            IncludeAll: false
        });

        const folders: Folder[] = await response.data

        const filterFolders = folders.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : 1).filter((folder, index) => {
            return folder.description !== "ALL";
        })

        setStepOneState((prevState) => ({
            ...prevState,
            folders: filterFolders
        }));
    }, [])

    useEffect(() => {
        setStepOneState((prevState) => ({
            ...initialState,
            folders: prevState.folders,
            isValid: true
        }));
    }, [stepOnePreloadedData])

    useEffect(() => {
        fetchFolders();
    }, [fetchFolders])

    return {
        stepOneState,
        setStepOneState,
        validateField,
        fetchFolders,
        onClear
    };

}

export const useStepTwo = (reportType: number, stepTwoPreloadedData: Partial<IStepTwoState>) => {
    const initialState: IStepTwoState = {
        isLoading: true,
        columns: [],
        filters: [],
        selectedOptions: stepTwoPreloadedData.selectedOptions!,
        selectedFilters: stepTwoPreloadedData.selectedFilters!,
        isValid: true,
        isPreloadedData: stepTwoPreloadedData.isPreloadedData,
    }

    const [stepTwoState, setStepTwoState] = useState<IStepTwoState>(initialState);

    const fetchColumns = async () => {
        const response = await getAxios(route.apiReport.columns, "GET", undefined, {
            Active: -1,
            SortBy: 1,
            ReportType: reportType
        });

        const responseColumns: IColumn[] = await response.data;

        responseColumns.map((column, index) => {
            if (stepTwoState.selectedOptions.length) {
                column.checked = stepTwoPreloadedData.selectedOptions?.includes(column.id) ? true : false;
            }

            return column;
        })

        return responseColumns;
    }

    const onFetchFilter = async () => {
        try {
            const response = await getAxios(route.apiReport.filter.filters, "GET", undefined, {
                Active: -1,
                SortBy: 1
            });
    
            const responseFilters: IFilter[] = await response.data;
    
            const mappedFilters = responseFilters.map((filter, index) => {
                filter.checked = stepTwoPreloadedData.selectedFilters?.includes(filter.id) ? true : false;

                return filter;
            });
    
            return mappedFilters;
        } catch (error) {
            console.error("filter Error: ", error);
        }
    }

    const getDataForSecondStep = async () => {
        const filters = await onFetchFilter() || [];
        const columns = await fetchColumns() || [];
        setStepTwoState((prevState) => ({
            ...prevState,
            columns,
            filters: filters.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1),
            isLoading: false
        }));
    };

    const getNewColumns = async () => {
        const columns = await fetchColumns() || [];
        
        setStepTwoState((prevState) => ({
            ...prevState,
            columns,
            isLoading: false,
        }));
    };

    const onClearSelectedOptions = () => {
        setStepTwoState((prevState) => ({
            ...prevState,
            selectedOptions: []
        }));
    }

    const onSortEnd = (oldIndex: number, newIndex: number): void => {
        setStepTwoState((prevState) => {
            const currentOldIndex: number = prevState.columns.findIndex((item) => item.id === oldIndex);
            const currentNewIndex: number = prevState.columns.findIndex((item) => item.id === newIndex);

            return {
                ...prevState,
                columns: arrayMove(prevState.columns, currentOldIndex, currentNewIndex)
            }
        });
    }

    const onSelect = (filter: IFilter) => {
        const updatedFilters = stepTwoState.filters.map((value) => {
            if (filter.id === value.id) {
                if (value.checked) {
                    value.checked = false;

                    const filterSelection = stepTwoState.selectedFilters.filter((i) => {
                        return i !== value.id;
                    })

                    setStepTwoState((prevState) => ({
                        ...prevState,
                        selectedFilters: filterSelection
                    }));
                }

                else {
                    value.checked = true;

                    setStepTwoState((prevState) => ({
                        ...prevState,
                        selectedFilters: [...prevState.selectedFilters, value.id]
                    }));
                }
            }

            return value;
        });

        setStepTwoState((prevState) => ({
            ...prevState,
            filters: updatedFilters
        }));
    }

    const onChangeChecked = (event: React.ChangeEvent<HTMLInputElement>, isChecked: boolean) => {
        if (isChecked) {
            const filteredSelected = stepTwoState.selectedOptions.filter((value, index) => {
                return value !== parseInt(event.target.value)
            });

            setStepTwoState((prevState) => ({
                ...prevState,
                selectedOptions: filteredSelected
            }));

            const newState = stepTwoState.columns.map((i, index) => {
                if (i.id === parseInt(event.target.value)) {
                    return { ...i, checked: false }
                }

                return i;
            });

            setStepTwoState((prevState) => ({
                ...prevState,
                columns: newState
            }));

            return;
        }

        setStepTwoState((prevState) => ({
            ...prevState,
            selectedOptions: [...prevState.selectedOptions, parseInt(event.target.value)]
        }));

        const newState = stepTwoState.columns.map((value, index) => {
            if (value.id === parseInt(event.target.value)) {
                return { ...value, checked: true };
            }

            return value;
        });

        setStepTwoState((prevState) => ({
            ...prevState,
            columns: newState
        }));
    }

    const onClear = () => {
        setStepTwoState(initialState);
    }

    const validateColumnsAndFilters = (): boolean => {
        if (stepTwoState.selectedFilters.length < 1) {
            setStepTwoState((prevState) => ({
                ...prevState,
                isValid: false
            }));

            return false;
        }

        else if (stepTwoState.selectedOptions.length < 1) {
            setStepTwoState((prevState) => ({
                ...prevState,
                isValid: false
            }));

            return false;
        }

        else {
            setStepTwoState((prevState) => ({
                ...prevState,
                isValid: true
            }));

            return true
        }
    }

    useEffect(() => {
        setStepTwoState((prevState) => ({
            ...initialState,
            isValid: true
        }));
    }, [stepTwoPreloadedData])

    useEffect(() => {
        setStepTwoState((prevState) => ({
            ...prevState,
            isLoading: true
        }));

        getNewColumns();
    }, [reportType]);

    useEffect(() => {
        getDataForSecondStep();
    }, []);

    console.log(stepTwoState.selectedOptions)

    return {
        stepTwoState,
        setStepTwoState,
        onSortEnd,
        onSelect,
        onChangeChecked,
        onClear,
        onClearSelectedOptions,
        validateColumnsAndFilters,
    }
}

export const useStepThree = (preloadedStepThree: StepThree, stepTwoSelectedFilters: number[]) => {
    const initialFilterDropdown = {
        filterId: 0,
        values: []
    }

    const initialState: IStepThreeState = {
        campaign: initialFilterDropdown,
        dealer: initialFilterDropdown,
        leadstatus: initialFilterDropdown,
        leadtype: initialFilterDropdown,
        product: initialFilterDropdown,
        state: initialFilterDropdown,
        salesstatus: initialFilterDropdown,
        subcampaign: initialFilterDropdown,
        groupBy: preloadedStepThree.groupBy.value,
        subGroup: preloadedStepThree.subGroup.value,
        apptdate: {
            filterId: 0,
            values: [null, null]
        },
        inquirydate: {
            filterId: 0,
            values: [null, null]
        },
        isValid: true
    }

    const [stepThreeState, setStepThreeState] = useState<IStepThreeState>(initialState);
    const [groupByDropdownValues, setGroupByDropdownValues] = useState<DropdownValue[]>([])
    const [subGroupByDropdownValues, setSubgGroupByDropdownValues] = useState<DropdownValue[]>([])
    const [allFilters, setAllFilters] = useState<IFilter[]>([])
    const [filters, setFilters] = useState<IFilter[]>([]);

    const onFetchFilters = async () => {
        const response = await getAxios(route.apiReport.filter.filters, "GET");

        const fetchedFilters: IFilter[] = await response.data;


        const mappedCreateFilters = fetchedFilters.map((filter) => {
            const lowerDescription = filter.description.toLocaleLowerCase().replaceAll(' ', '');

            if (filter.filterType === "D") {
                setStepThreeState((prevState) => ({
                    ...prevState,
                    [lowerDescription]: {
                        filterId: filter.id,
                        values: []
                    }
                }));
            } else {
                setStepThreeState((prevState) => ({
                    ...prevState,
                    [lowerDescription]: {
                        filterId: filter.id,
                        values: [null, null]
                    }
                }));
            }

            return filter;
        })

        const mappedEditFilters = preloadedStepThree.selectedFilters.map((preloadedFilter) => {
            const match = fetchedFilters.find((filter) => preloadedFilter.filterId === filter.id);
            //const dropdownValues = preloadedStepThree.selectedFilters.find((item) => item.filterId === match!.id)!.selectedItems;

            const lowerDescription = match!.description.toLocaleLowerCase().replaceAll(' ', '');

            const dateValue = (preloadedFilter && !preloadedFilter.selectedItems.length) ? [null, null] : [dayjs(preloadedFilter.selectedItems[0].value), dayjs(preloadedFilter.selectedItems[1].value)] 

            if (match!.filterType === "D") {
                setStepThreeState((prevState) => ({
                    ...prevState,
                    [lowerDescription]: {
                        filterId: preloadedFilter.filterId,
                        values: preloadedFilter.selectedItems
                    }
                }))
            }

            else {
                setStepThreeState((prevState) => ({
                    ...prevState,
                    [lowerDescription]: {
                        filterId: preloadedFilter.filterId,
                        values: dateValue
                    }
                }))
            }

            return match!;
        })

        setAllFilters(fetchedFilters);
        setFilters(!preloadedStepThree.selectedFilters.length ? [] : mappedEditFilters);
    }

    const onFetchSummaryGroups = async () => {
        const groupResponse = await getAxios(route.apiReport.filter.values, "GET", undefined, {
            Mode: 1
        });

        const subGroupResponse = await getAxios(route.apiReport.filter.values, "GET", undefined, {
            Mode: 2
        });

        const groupByData: DropdownValue[] = await groupResponse.data;
        const subGroupData: DropdownValue[] = await subGroupResponse.data;

        setGroupByDropdownValues(groupByData);
        setSubgGroupByDropdownValues(subGroupData);
    }

    const displaySelectedFilters = () => {
        const foundFilters = stepTwoSelectedFilters.map((filterId) => {
            const match = allFilters.find((filter) => filter.id === filterId);

            return match!;
        });

        if (!foundFilters.length) {
            return;
        }

        setFilters((prevState) => [...foundFilters])
    }

    const onChangeDateRange = (newValue: DateRange<Dayjs>, name: string) => {
        const filterId = (stepThreeState[name as keyof typeof stepThreeState] as FilterDropdown).filterId

        setStepThreeState((prevState) => ({
            ...prevState,
            [name]: {
                filterId,
                values: newValue
            }
        }));
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target;

        setStepThreeState((prevState) => ({
            ...prevState,
            [name]: value as string
        }));
    }

    const onChangeMultipleSelect = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownValue[],
        name: string
    ) => {
        const filterId = (stepThreeState[name as keyof typeof stepThreeState] as FilterDropdown).filterId

        setStepThreeState((prevState) => ({
            ...prevState,
            [name]: {
                filterId,
                values: value
            }
        }));
    }

    const onClear = () => {
        setStepThreeState(initialState);
    }

    const validateGroupBy = () => {
        if (!stepThreeState.groupBy) {
            setStepThreeState((prevState) => ({
                ...prevState,
                isValid: false
            }));

            return false
        }

        else {
            setStepThreeState((prevState) => ({
                ...prevState,
                isValid: true
            }));

            return true;
        }
    }


    useEffect(() => {
        setStepThreeState((prevState) => ({
            ...initialState,
            isValid: true
        }));
    }, [preloadedStepThree]);

    useEffect(() => {
        onFetchSummaryGroups();
        onFetchFilters();
    }, [preloadedStepThree]);

    useEffect(() => {
        displaySelectedFilters();
    }, [stepTwoSelectedFilters])

    return {
        stepThreeState,
        setStepThreeState,
        filters,
        groupByDropdownValues,
        subGroupByDropdownValues,
        onClear,
        onChangeSelect,
        onChangeMultipleSelect,
        onChangeDateRange,
        validateGroupBy
    }
}

export const useStepFour = (stepFourPreloadedData: Partial<IStepFourState>) => {
    const initialState: IStepFourState = {
        isReportScheduled: stepFourPreloadedData.isReportScheduled!,
        timeInterval: stepFourPreloadedData.timeInterval!,
        timeZone: stepFourPreloadedData.timeZone!,
        every: stepFourPreloadedData.every!,
        on: stepFourPreloadedData.on!,
        hour: stepFourPreloadedData.hour!,
        startDate: stepFourPreloadedData.startDate!,
        endDate: stepFourPreloadedData.endDate!,
        reportFile: stepFourPreloadedData.reportFile!,
        emails: stepFourPreloadedData.emails!,
        emailInputs: [{ name: "email0" }],
        isEmailDisabled: true
    }

    const [stepFourState, setStepFourState] = useState<IStepFourState>(initialState);

    const onDisableReportScheduled = () => {
        setStepFourState({
            ...stepFourState,
            isReportScheduled: !stepFourState.isReportScheduled
        });
    }

    const onChangeDate = (newDate: Date, name: string) => {
        setStepFourState((prevState) => ({
            ...prevState,
            [name]: newDate
        }));
    }

    const onChangeHour = (newValue: Dayjs) => {
        setStepFourState((prevState) => ({
            ...prevState,
            hour: newValue
        }));
    }


    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepFourState({
            ...stepFourState,
            reportFile: (event.target as HTMLInputElement).value
        })
    };

    const generateEmailTextField = () => {
        if (stepFourState.emailInputs.length >= 4) {
            return;
        }

        const obj = {
            name: `email${stepFourState.emails[`${stepFourState.emailInputs.length}` as keyof typeof stepFourState.emails]}`,
            value: stepFourState.emails[`email${stepFourState.emailInputs.length}` as keyof typeof stepFourState.emails]
        }

        setStepFourState((prevState) => ({
            ...prevState,
            emailInputs: [...prevState.emailInputs, obj]
        }));
    }

    const onChangeEmails = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStepFourState((prevState) => ({
            ...prevState,
            emails: {
                ...prevState.emails,
                [event.target.name]: event.target.value
            }
        }));
    }

    const onClear = () => {
        setStepFourState(initialState);
    }

    const onDisableInput = () => {
        setStepFourState({
            ...stepFourState,
            isEmailDisabled: !stepFourState.isEmailDisabled
        });
    }

    useEffect(() => {
        let arr: any = []

        for (const key in stepFourState.emails) {
            if (stepFourState.emails[key as keyof typeof stepFourState.emails] !== undefined) {
                arr.push({ name: key });
            }
        }

        setStepFourState((prevState) => ({
            ...initialState,
            emailInputs: arr.length ? arr : prevState.emailInputs,
            isEmailDisabled: !prevState.isEmailDisabled
        }))
    }, [stepFourPreloadedData])

    return {
        stepFourState,
        setStepFourState,
        onClear,
        onChangeDate,
        onChangeHour,
        onDisableReportScheduled,
        onChangeRadio,
        onChangeEmails,
        generateEmailTextField,
        onDisableInput
    }
}