import React, { FunctionComponent, useState } from "react";
import { IconButton, SvgIcon, Typography } from "@mui/material";
import { CustomDataGrid } from "../CustomDataGrid";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import PrintIcon from '@mui/icons-material/Print';
import EditIcon from '@mui/icons-material/Edit';
import { Warranty } from "../../types/Warranty";
import { MoreVert } from "@mui/icons-material";
import { CustomMenu } from "../CustomMenu";

const defaultColumnOptions: Partial<GridColDef> = {
    resizable: false,
    flex: 1,
    hideSortIcons: false,
    disableColumnMenu: false,
}

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {params.value}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "dateReceived",
        headerName: "Date Received",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            const date = new Date(params.value);

            const formatDate = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDay()}`;

            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {formatDate}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "dealerName",
        headerName: "Dealer",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {params.value}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "lastName",
        headerName: "Last Name",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {params.value}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "street",
        headerName: "Street",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {params.value}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "city",
        headerName: "City",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {params.value}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "zip",
        headerName: "Zip",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    {params.value}
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "options",
        headerName: "Options",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <strong>
                    {params.colDef.headerName}
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            let links = [
                { Icon: <EditIcon />, href: `/edit-warranty/${params.row.id}`, label: "Edit Warranty" },
            ];

            const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
            const open = Boolean(anchorEl);
    
            const onClick = (event: React.MouseEvent<HTMLElement>): void => {
                setAnchorEl(event.currentTarget);
            }
    
            const onClose = () => {
                setAnchorEl(null);
            }

            return (
                <strong>
                    <span>
                        <IconButton onClick={onClick} >
                            <SvgIcon>
                                <MoreVert htmlColor="rgba(0, 0, 0, 0.4)" />
                            </SvgIcon>
                        </IconButton>
                    </span>
                    <CustomMenu open={open} anchorEl={anchorEl} onClose={onClose} links={links} funcLinks={[{ label: "Print", Icon: <PrintIcon />, onClick: () => alert("not available yet") }]} />
                </strong>
            )
        },
        ...defaultColumnOptions
    },
]

type IProps = {
    rows: Warranty[];
    total: number;
    pageSize: number;
    startIndex: number;
    onPageChange: (newPage: number) => void;
    onPageSizeChange: (newPageSize: number) => void;
}

export const WarrantyGrid: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <CustomDataGrid
            sortingOrder={["asc", "desc"]}
            columns={columns}
            rows={props.rows}
            page={props.startIndex}
            rowsPerPageOptions={[5, 10, 15, 25]}
            rowCount={props.total}
            pagination
            initialState={{
                pagination: {
                    page: props.startIndex,
                    pageSize: props.pageSize
                }
            }}
            paginationMode="server"
            onPageChange={props.onPageChange}
            onPageSizeChange={props.onPageSizeChange}
        />
    )
}