import React, { FunctionComponent, useState } from 'react';
import {
    Typography,
    IconButton,
    SvgIcon,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Stack
} from "@mui/material";
import { mdiStar, mdiChevronUp, mdiChevronDown, mdiStarOffOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import StarIcon from '@mui/icons-material/Star';
import { IProps } from './IProps';
import Grid from '@mui/material/Unstable_Grid2';
import { useReports } from '../../pages/Reports/hook';



export const ReportsMenuFavorite: FunctionComponent<IProps> = ({ ReportTittle, items, onUnFavorite }): JSX.Element => {
    const lengthReports = items.length;
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{'color': '#1BA0E9'}}/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
                sx={{boxShadow: '0px 4px 8px rgba(40, 72, 154, 0.06)'}}
            >
                <Grid container display="flex" alignItems="center" >
                    <Grid>
                        <StarIcon sx={{ color: "#1BA0E9", height: 37 }} />
                    </Grid>
                    <Grid>
                        <Typography sx={{ fontWeight: 700, fontSize: 22 }}>{ReportTittle}</Typography>
                    </Grid>
                </Grid>
            </AccordionSummary>
            <Stack>
                {
                    items?.map((item, index) => {
                        return (
                            <AccordionDetails sx={[
                                {   
                                    borderBottom: !(index === lengthReports - 1)  ? '1px solid rgba(0, 0, 0, 0.07)' : 'none',
                                    "&:hover":{
                                        backgroundColor: '#DDF1FC',
                                        borderBottom: !(index === lengthReports - 1) ? '1px solid #8fd0f3' : 'none'
                                    },
                                }
                            ]}>
                                {
                                    item.favorite &&
                                    <Grid container columns={{ xs: 12 }} >
                                        <Grid container xs={12} display="flex" justifyContent="space-between" alignItems="center">
                                            <Grid xs={1}>
                                                <Checkbox />
                                            </Grid>
                                            <Grid xs={8} width={"60%"}>
                                                <Typography fontWeight={700} fontSize={14} color="rgba(0, 0, 0, 0.8)">{item.reportTitle}</Typography>
                                                <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="rgba(0, 0, 0, 0.6)" fontWeight={400} fontSize={14}>{item.description}</Typography>
                                            </Grid>

                                            <Grid xs={1} display="flex" justifyContent="flex-end" alignItems="center">
                                                <IconButton onClick={() => {
                                                    onUnFavorite(item.id)
                                                }}>
                                                    <SvgIcon>
                                                        <Icon path={mdiStarOffOutline} color="rgba(0, 0, 0, 0.4)" />
                                                    </SvgIcon>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </AccordionDetails>
                        )
                    })
                }
            </Stack>
        </Accordion>
    );
}