import React, { useState } from "react";

export const useFolder = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const onClick = (event: React.MouseEvent<HTMLElement>): void => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
        
    }

    const onClose = () => {
        setAnchorEl(null);
    }

    return {
        anchorEl,
        open,
        onClick,
        onClose, 
        
    }
}