import React, { FunctionComponent } from "react";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { Typography, Stack, Box } from "@mui/material";
import { IProps } from "./IProps";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

export const CallerIdTooltip: FunctionComponent<IProps> = ({ htmlInjection, children }): JSX.Element => {

  const StyledCallerIdTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: 'white',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 300,
      borderRadius: 10,
      textTransform: "none",
      fontSize: theme.typography.pxToRem(12),
      padding: "10px 18px 10px 22px",
      boxShadow: "0px 2px 4px rgba(40, 72, 154, 0.06), 0px 5px 20px rgba(40, 72, 154, 0.04), 0px 2px 6px rgba(40, 72, 154, 0.04)"
    },
  }));

  return (
    <StyledCallerIdTooltip
      placement="top"
      arrow
      sx={{
        '& .MuiTooltip-arrow': {
          color: "#ECF1F3",
        },
      }}
      title={
        <React.Fragment>
          <div dangerouslySetInnerHTML={{ __html: htmlInjection }} />
        </React.Fragment>
      }>
      {children}
    </StyledCallerIdTooltip>
  );
}