import React, { useEffect } from "react"
import { useState } from "react";
import { IState } from "./IState";
import { mdiHomeVariantOutline, mdiAccountFilterOutline, mdiFolderOutline, mdiFilePlusOutline, mdiTestTube, mdiLinkBoxOutline } from "@mdi/js"
import { route, getAxios } from "../../utils/endpoints";
import { IProps as SidebarContentProps } from "../SidebarContent/IProps"
import { SideBarMenu } from "../../types/SidebarMenu"
import { useLocation, Link } from "react-router-dom";
import { User } from "../../model/User"
import { useSelector } from "react-redux";
import { RootState } from "../../state/reducers";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";

export const usePageLayout = () => {
    const [content, setContent] = useState<SidebarContentProps[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const user = new User();
    
    const state = useSelector((state: RootState) => state.menu);
    const dispatch = useDispatch();
    const { GetMenus } = bindActionCreators(actionCreators, dispatch); 

    const { pathname } = useLocation();

    const fetchSideBarGroups = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const response = await getAxios(route.apiSidebar.sideMenu, "GET")

            if (!response.data) {
                setIsLoading(false);
                return;
            }

            const groups: SideBarMenu[] | null = await response.data;

            if (groups === null) {
                setIsLoading(false);
                return;
            }

            GetMenus(groups);

        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    const mappedGroups = () => {
        const sortedGroupByGroupNum = state.filter((val) => val.show !== "U").sort((a, b) => {
            return a.groupNum > b.groupNum ? 1 : -1;
        })

        let names: string[] = [];

        sortedGroupByGroupNum.forEach((group, index) => {
            if (!names.includes(group.sectionName)) {
                names.push(group.sectionName);
            }
        });

        let objArr: SidebarContentProps[] = [];

        names.map((name, index) => {
            const obj: Record<string, any> = {};
            let icon: string = "";

            obj.title = name.toLocaleUpperCase();


            switch (obj.title) {
                case "GENERAL": {
                    icon = mdiHomeVariantOutline;
                    break;
                }

                case "LEAD DETAIL": {
                    icon = mdiAccountFilterOutline;
                    break;
                }

                case "REPORTS": {
                    icon = mdiFolderOutline;
                    break;
                }

                default: {
                    icon = mdiLinkBoxOutline;
                    break;
                }
            }

            const filteredArr = sortedGroupByGroupNum.filter((group, index) => {
                return group.sectionName === name;
            }).sort((a, b) => {
                return a.lineNum > b.lineNum ? 1 : -1
            });

            const links = filteredArr.map((item, index) => {
                return {
                    text: item.title,
                    href: item.slug,
                    icon: icon
                }
            });

            obj.links = links;

            objArr.push(obj as SidebarContentProps);
        });

        setContent(objArr);
    }

    const onMe = async () => {
        const response = await getAxios("/me", "GET");

        const data = await response.data;

        user.SaveSession(data)
    }

    useEffect(() => {
        fetchSideBarGroups();
    }, []);

    useEffect(() => {
        mappedGroups();
    }, [state]);

    useEffect(() => {
        onMe()
    }, [pathname])

    return {
        content,
        isLoading
    }
}