import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { IProps } from "./IProps";

const StyledTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: 14,
    fontWeight: "400",
}));

export const Label: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <StyledTypography color={props.color} mb={1} >
            { props.text }
        </StyledTypography>
    )
}