import { FunctionComponent } from "react";
import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import { Typography, Divider } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useStepOne } from "../hooks/useStepOne"
import { IProps } from "../interfaces/IStepOne"
import { StepTitle } from "../Components/StepTitle"
import { useExportModuleFormContext } from "../context";

export const StepOne: FunctionComponent<IProps> = (props) => {
  const { sourceList } = useStepOne(props.mode!);
  const context = useExportModuleFormContext();

  return (
    <>
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} ml={4}>
        <StepTitle title="Select the source" />
        <Grid sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', mt: 2.5 }}>
          <List sx={{ border: 1, borderColor: "#00000026", borderRadius: 2.5, overflow: 'auto', maxHeight: 391 }}>
            {
              sourceList.length ? (
                <>
                  {
                    sourceList.map((item, index) => {
                      return (
                        <ListItem disablePadding sx={{ backgroundColor: context.source === item.name ? "#DDF1FC" : "inherit" }} >
                          <ListItemButton 
                          onClick={() => {
                              context.setSource(item.name); 
                            }}
                          >
                            <ListItemText primary={item.name} />
                          </ListItemButton>
                        </ListItem>
                      )
                    })
                  }
                </>
              ) : (<p>No sources available</p>)
            }
          </List>

        </Grid>
      </Grid>
    </>
  )
}