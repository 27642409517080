import { useParams } from "react-router-dom"
import {
    Typography,
    Stack,
    MenuItem,
    Card,
    CardContent,
    Divider,
    SvgIcon,
    Box,
    Snackbar,
    Alert,
    AlertColor,
    Autocomplete,
    Checkbox,
    TextField,
    Popper,
    autocompleteClasses
} from "@mui/material/";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { RefreshOutlined } from "@mui/icons-material";
import { mdiFilterOutline, mdiFilePdfBox, mdiFileDelimitedOutline, mdiMicrosoftExcel, mdiCalendarClock } from "@mdi/js";
import { Icon } from "@mdi/react";
import { CustomSelect, Button, ReportGrid, ReportSidebar } from "../../components";
import { AlertDialog } from "../../components/AlertDialog";
import { CustomBreadCrumbs } from "../../components/CustomBreadcrumbs";
import { CustomMenu } from "../../components/CustomMenu";
import { ScheduleDialog } from "../../components/ScheduleDialog";
import { IFilter, IFilterDate, useReportDetail } from "./hook";
import Fade from '@mui/material/Fade';
import React, { useState } from "react";
import { CustomDateRangePicker } from "../../components/CustomDateRangePicker";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from "@mui/material/styles"
import { Label } from "../../components/component-utils/Label";
import { ForbiddenAccessMessage } from "../../components/ForbiddenAccessMessage";

const StyledPopper = styled(Popper)({
    [`&. ${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        '& ul': {
            padding: 0,
            margin: 0,
           
        }
    }
});

export const ReportDetail = (): JSX.Element => {
    const params = useParams();
    const {
        description,
        filters,
        gridColumns,
        isLoading,
        open,
        reportTitle,
        page,
        pageSize,
        rows,
        sortModel,
        total,
        scheduleOpen,
        snackbarOpen,
        responseStatus,
        emailInputs,
        filterState,
        scheduleState,
        warningModalOpen,
        permissions,
        setWarningModalOpen,
        onChange,
        setSnackbarOpen,
        toggleOpenDrawer,
        onChangeOrder,
        onChangeFilterSelect,
        onDownloadFile,
        onPageChange,
        setScheduleOpen,
        onPageSizeChange,
        onChangeRadio,
        onChangeDate,
        onChangeDateRange,
        generateEmailTextfield,
        onSchedule,
        onDisableReportScheduled,
        onChangeEmail,
        onGenerateReportData,
        onFetchReportData
    } = useReportDetail(params.id!);

    const fileDownloadLinks = [
        {
            Icon: <Icon path={mdiFilePdfBox} />,
            label: ".PDF",
            onClick: () => onDownloadFile("pdf")
        },
        {
            Icon: <Icon path={mdiFileDelimitedOutline} />,
            label: ".CSV",
            onClick: () => onDownloadFile("csv")
        },
        {
            Icon: <Icon path={mdiMicrosoftExcel} />,
            label: ".XLSX",
            onClick: () => onDownloadFile("xlsx")
        }
    ];

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const ReportControls = (): JSX.Element => {

        const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<null | HTMLElement>(null);
        const downloadOpen = Boolean(downloadMenuAnchorEl);

        const onClickDownloadButton = (event: React.MouseEvent<HTMLButtonElement>) => {
            setDownloadMenuAnchorEl(document.getElementById('demo-customized-button'));
        }

        return (
            <>
                <Stack direction="row" spacing={2} >
                    <Button width={120} height={35} startIcon={<RefreshOutlined />} label="Generate" variant="outlined" onClick={onGenerateReportData} />
                    <Button disabled={filters.length <= 4} onClick={toggleOpenDrawer} width={106} height={35} startIcon={<SvgIcon><Icon path={mdiFilterOutline} /></SvgIcon>} label="Filter" />
                    <Divider sx={{ height: 35 }} orientation="vertical" light flexItem />
                    {!permissions.hideExportReports &&
                        <Button
                            id="demo-customized-button"
                            aria-controls={downloadOpen ? 'demo-customized-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={downloadOpen ? 'true' : undefined}
                            variant="contained"
                            disableElevation
                            disableRipple
                            sx={{ backgroundColor: "#7EDE5D", "&:hover": { backgroundColor: "#6c9147" } }}
                            width={120}
                            height={35}
                            label="Download"
                            endIcon={<KeyboardArrowDownIcon />}
                            onClick={onClickDownloadButton}
                        />
                    }
                    
                </Stack>
                <CustomMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                        'aria-labelledby': 'demo-customized-button',
                    }}
                    open={downloadOpen}
                    anchorEl={downloadMenuAnchorEl}
                    funcLinks={fileDownloadLinks}
                    TransitionComponent={Fade}
                    onClose={() => setDownloadMenuAnchorEl(null)}
                />
            </>
        )
    }

    const dateFilters = filters.filter((filter) => {
        return filter.type !== "D"
    });

    return (
        <>
            <ScheduleDialog
                open={scheduleOpen}
                onClose={() => setScheduleOpen(false)}
                emails={scheduleState.emails}
                emailInputs={scheduleState.emailInputs}
                at={scheduleState.at}
                endDate={scheduleState.endDate}
                every={scheduleState.every}
                fileFormat={scheduleState.fileFormat}
                isScheduled={scheduleState.isScheduled}
                timeInterval={scheduleState.timeInterval}
                startDate={scheduleState.startDate}
                timeZone={scheduleState.timeZone}
                on={scheduleState.on}
                onChange={onChange}
                onChangeRadio={onChangeRadio}
                onChangeDate={onChangeDate}
                onGenerateEmailTextfield={generateEmailTextfield}
                onDisableReportScheduled={onDisableReportScheduled}
                onSchedule={onSchedule}
                onChangeEmail={onChangeEmail}
            />
            <CustomBreadCrumbs path={`Home/Reports`} />
            <Box display="flex" alignItems="center" justifyContent="space-between" >
                <Box>
                    <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25, mt: 2 }} >
                        {reportTitle}
                    </Typography>
                    <Typography sx={{ fontSize: 14, mt: 2 }} >
                        {description}
                    </Typography>
                </Box>
                {!permissions.hideEditSchedule &&
                    <Button
                        width={128}
                        height={35}
                        startIcon={<SvgIcon><Icon path={mdiCalendarClock} /></SvgIcon>}
                        label="Schedule it"
                        variant="outlined"
                        onClick={() => setScheduleOpen(true)}
                    />
                }
            </Box>

            {permissions.hideReadCustomReportData ? <ForbiddenAccessMessage/> :
                <> 
                    <Stack direction="row" display="flex" alignItems="center" justifyContent={dateFilters.length > 1 ? "space-between" : ""} spacing={4} mt={4}>
                        {
                            dateFilters.length > 1 ? dateFilters.map((filter) => {
                                const lowerDescription = filter.description.toLocaleLowerCase().replaceAll(" ", "_");
                                const value = filterState[lowerDescription as keyof typeof filterState] as IFilterDate;                 
        
                                return (
                                    <CustomDateRangePicker
                                        label={{
                                            text: filter.description
                                        }}
                                        name={lowerDescription}
                                        value={value.selectedItems}
                                        onChange={(newDate) => onChangeDateRange(newDate, lowerDescription)}
                                    />
                                )
                            }) : filters.slice(0, 4).map((filter, index) => {
                                const lowerDescription = filter.description.toLocaleLowerCase().replaceAll(" ", "_");
                                const value = filterState[lowerDescription as keyof typeof filterState]
        
                                return filter.type === "D" ? (
                                    <Autocomplete
                                        sx={{ width: "100%" }}
                                        multiple
                                        limitTags={2}
                                        disableListWrap
                                        disableCloseOnSelect
                                        PopperComponent={StyledPopper}
                                        options={filter.selectedItems}
                                        getOptionLabel={(option) => option.description}
                                        value={(value as IFilter).selectedItems}
                                        onChange={(event, newValue) => onChangeFilterSelect(event, newValue, lowerDescription)}
                                        renderInput={(params) => {
                                            params.size = "small";
        
        
                                            return (
                                                <>
                                                    <Label text={filter.description} color="#000000" />
                                                    <TextField {...params} />
                                                </>
                                            )
                                        }}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.description}
                                            </li>
                                        )}
                                    />
                                ) : (
                                    <CustomDateRangePicker
                                        label={{
                                            text: filter.description
                                        }}
                                        name={lowerDescription}
                                        value={(value as IFilterDate).selectedItems}
                                        onChange={(newDate) => onChangeDateRange(newDate, lowerDescription)}
                                    />
                                )
                            })
                        }
                    </Stack>
                    <Card sx={{ mt: 2, borderRadius: 4 }} >
                        <CardContent>
                            <ReportControls />
                            <ReportGrid
                                columns={gridColumns}
                                rows={rows}
                                loading={isLoading}
                                onPageChange={onPageChange}
                                onPageSizeChange={onPageSizeChange}
                                onSortModelChange={(newSortModel) => onChangeOrder(newSortModel)}
                                page={page}
                                pageSize={pageSize}
                                sortModel={sortModel}
                                total={total}
                            />
                        </CardContent>
                    </Card>
                </>
            }

            <ReportSidebar onClear={onFetchReportData} state={filterState} onChangeSelect={onChangeFilterSelect} onApply={onGenerateReportData} open={open} onToggleSidebar={toggleOpenDrawer} filters={
                dateFilters.length > 1 ? filters.filter((filter) => filter.type !== "T") : filters.slice(4, filters.length)
            } />
            <Snackbar key={9} open={snackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => setSnackbarOpen(false)} autoHideDuration={2000}>
                <Alert severity={responseStatus.severity as AlertColor} >
                    {responseStatus.message}
                </Alert>
            </Snackbar>
            <AlertDialog 
                open={warningModalOpen} 
                type="warning" 
                title="Schedule should not be empty" 
                description="You can't open a schedule with empty data. Please fill in the form or set the schedule to off." 
                onContinue={() => setWarningModalOpen(false)} 
            />
        </>
    )
}