import React, { useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { IState } from "./IState";
import { getAxios } from "../../utils/endpoints";

const defaultState: IState = {
    companyName: "",
    email: "",
    type: 0,
    username: "",
    password: ""
}

export const useAddUserDialog = () => {
    const [state, setState] = useState<IState>(defaultState);
    const [message, setMessage] = useState<string>("");
    const [alertType, setAlertType] = useState<string>("");

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setState((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target;

        const val = parseInt(value as string);

        setState({
            ...state,
            type: val
        });
    }

    const onClear = () => setState(defaultState);

    const onSubmit = async (): Promise<void> => {
        try {
            const response = await getAxios("/user", "POST", {
                newUsername: state.username,
                CompanyName: state.companyName,
                SecLevel: state.type,
                Email: state.email,
                Password: state.password
            });
    
            const data = await response.data;
        
            console.log(typeof data);

            setAlertType("success");
            setMessage(data.message);
        } catch (error: any) {
            console.log("Error user post: ", error);
            
            setAlertType("error");
            setMessage(error.data.message);
        }
    }

    return {
        state,
        message,
        setMessage,
        alertType,
        onChange,
        onChangeSelect,
        onSubmit,
        onClear
    }
}