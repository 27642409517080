import { mdiAlert } from '@mdi/js'
import { Typography } from '@mui/material'
import { Icon } from "@mdi/react"

export const ForbiddenAccessMessage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row', margin: 20 }}>
        <Icon path={mdiAlert} size={1} color="#F3D745" />
        <Typography sx={{ fontSize: 14, ml: 2 }} >
            You don't have access this module.
        </Typography>
    </div>
  )
}
