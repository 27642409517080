import { FunctionComponent } from "react";
import { Box, Stack, Typography, SvgIcon } from "@mui/material"
import { Icon } from "@mdi/react";
import { IProps } from "./IProps";
import { useLocation, Link } from "react-router-dom";

export const SidebarContent: FunctionComponent<IProps> = ({ title, links }): JSX.Element => {
    const { pathname } = useLocation();

    const SectionTitle = (): JSX.Element => {
        return (
            <Box sx={{ padding: "0px 0px 0px 20px", color: "#1BA0E9", fontSize: 14, width:270, height:60 }} display="flex" alignItems="center" >
                <Typography
                    variant="subtitle2"
                    noWrap
                    component="div"
                    sx={{ color: "#1BA0E9", fontWeight: "700", fontSize: 13, letterSpacing: 1.6 }}
                >
                    {title}
                </Typography>
            </Box>
        )
    }

    return (
        <Box>
            <SectionTitle />
            <Box sx={{
                color: "white",
                fontWeight: "500"

            }}>
                {
                    links.map((link, index): JSX.Element => {
                        return (
                            <Link key={index} to={link.href} style={{ textDecoration: "none", color: "white" }} >
                                <Stack sx={{ backgroundColor: link.href === pathname ? "#1BA0E9" : "" }} width={270} key={index} height={60} p={2} spacing={2} direction="row" alignItems="center" >
                                    <SvgIcon htmlColor="white" width={22} height={22} sx={{margin:"0px 4px 0px 20px"}} >
                                        <Icon path={link.icon}  />
                                    </SvgIcon>
                                    <Typography
                                        variant="subtitle1"
                                        noWrap
                                        component="div"
                                        margin={0}
                                        marginLeft={0}
                                        letterSpacing={0.032}
                                        sx={{ fontWeight: "600", fontSize: 14, marginLeft:0 }}
                                    >
                                        {link.text}
                                    </Typography>
                                </Stack>
                            </Link>
                        )
                    })
                }
            </Box>
        </Box>
    )
}
