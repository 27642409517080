import React, { FunctionComponent } from "react";
import { Button } from "../Button";
import {
    Typography,
    MenuItem,
    Divider,
    SvgIcon,
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    DialogActions,
    FormGroup,
    FormControlLabel,
    Checkbox,
    IconButton,
    Snackbar,
    Alert
} from "@mui/material/";
import { mdiClose, mdiAccountCogOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { IProps } from "./IProps";
import { CheckBox } from "@mui/icons-material";
import { useBranchAccessDialog } from "./hook";

export const BranchAccessModal: FunctionComponent<IProps> = (props): JSX.Element => {

    const { ids, checkedIds, snackbarMessage, snackbarType, setSnackbarMessage, setSnackbarType, onChangeChecked, onChangeCheckedParent, onSave } = useBranchAccessDialog(props.title, props.onCancel!);

    const allSelected: boolean = (ids.length > 0) && ids.every((val) => val.isAssigned);

    return (
        <Dialog
            open={props.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    width: 600,
                    height: 438,
                    borderRadius: 8
                }
            }}
        >
            <DialogTitle
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                borderBottom="2px solid #0000001F"
                id="alert-dialog-title"
            >
                <Stack direction="row" alignItems="center" spacing={2} >
                    <SvgIcon>
                        <Icon path={mdiAccountCogOutline} style={{ fontSize: 23, color: "#1BA0E9" }} />
                    </SvgIcon>
                    <Typography fontSize={22} fontWeight={700} sx={{ color: "#000000CC" }} >
                        {props.title}
                    </Typography>
                </Stack>
                <IconButton onClick={props.onCancel} >
                    <SvgIcon>
                        <Icon path={mdiClose} style={{ fontSize: 23, color: "rgba(0, 0, 0, 0.5)" }} />
                    </SvgIcon>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FormControlLabel
                    label="Select All"
                    control={
                        <Checkbox
                            defaultChecked={allSelected}
                            checked={(checkedIds.length === ids.length)}
                            onChange={onChangeCheckedParent}
                        />
                    }
                />
                {
                    ids.length ? (
                        ids.map((val) => {
                            return (
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox defaultChecked={val.isAssigned} checked={val.isAssigned} onChange={onChangeChecked} value={val.username} />} label={val.username} />
                                </FormGroup>
                            )
                        })
                    ) : <></>
                }
            </DialogContent>
            <DialogActions sx={{ borderTop: "2px solid #0000001F" }}>
                <Button width={70} height={35} label="Save" title="Save" onClick={onSave} />
                <Button width={80} height={35} variant="outlined" label="Cancel" title="Cancel" onClick={props.onCancel} />
            </DialogActions>
            <Snackbar 
                key={9} 
                open={Boolean(snackbarMessage.length)} 
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
                onClose={() => {
                    setSnackbarMessage("");
                    setSnackbarType("info");
            }} autoHideDuration={3000}>
                <Alert severity={snackbarType} >
                    { snackbarMessage }
                </Alert>
            </Snackbar>
        </Dialog>
    )
}

