import { FunctionComponent, useState, useEffect } from "react";
import { Stack, Box } from "@mui/material";
import { CustomDatePicker, Button } from "../../../components/";
import Grid from '@mui/material/Unstable_Grid2';

interface IState {
    startDate: Date | null;
    endDate: Date | null;
}

interface IProps {
    applyFilter: (start: Date, end: Date) => Promise<void>;
    page: number,
    pageSize: number
}

export const AllSalesBetweenASpecificDate: FunctionComponent<IProps> = ({ applyFilter, page, pageSize }): JSX.Element => {
    const [state, setState] = useState<IState>({
        startDate: new Date(),
        endDate: new Date()
    });

    const onChange = (newDate: Date | null, name: string) => {
        setState({
            ...state,
            [name]: newDate
        });
    }

    const onFetch = async (): Promise<void> => {
        await applyFilter(state.startDate!, state.endDate!);
    }

    useEffect(() => {
        onFetch();
    }, [page, pageSize])

    return (
        <Grid container columns={{ xs: 12, sm: 12, md:12 }} sx={{width:"100%", mb:2}} columnSpacing={{xs:0, md:2}} rowSpacing={{xs:2, md:0}}>
            <Grid xs={12} md={4}>
                <CustomDatePicker
                    label={{ text: "Start Date" }}
                    value={state.startDate}
                    onChange={(newValue, keyInputValue) => onChange(newValue, "startDate")}
                    width={"100%"}
                />
            </Grid>
            <Grid xs={12} md={4}>
                <CustomDatePicker
                    label={{ text: "End Date" }}
                    value={state.endDate}
                    onChange={(newValue, keyInputValue) => onChange(newValue, "endDate")}
                    width={"100%"}
                />
            </Grid>
            <Grid xs={12} md={4} display="flex" alignItems="end">
                <Button width={120} height={40} label='Apply' onClick={onFetch} />
            </Grid>
            {/*state.errorMessage && <Typography color="red" fontSize={12}>{state.errorMessage}</Typography>*/}
        </Grid>
    )
}