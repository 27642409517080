import React, { FunctionComponent } from "react";
import { Card, CardContent, Box, Stack } from "@mui/material";
import { Button } from "../Button";
import { CustomTabs } from "../CustomTabs";
import { RenderIcon } from "../RenderIcon";
import { AccessDashboard } from "../AccessDashboard";
import { TabPanelInfo } from "../TabPanelInfo";
import { mdiAccountFilterOutline, mdiFinance, mdiCalendarCheckOutline, mdiFileCogOutline } from "@mdi/js";
import { useQueriesPanel } from "./hook";
import { title } from "process";

const icons = {
  mdiAccountFilterOutline,
  mdiFinance,
  mdiCalendarCheckOutline,
  mdiFileCogOutline
}

export const QueriesPanel: FunctionComponent = () => {
  const { categories, items, selectedItems, isLoading, disableSaveButton, onChange, fetchQueriesAccessItems, onUpdateSelectedQueries } = useQueriesPanel();

  const labels = categories.map((category) => {

    return {
      icon: RenderIcon(icons[category.icon as keyof typeof icons]),
      title: category.description,
    }
  });

  return (
    <>
      <TabPanelInfo length={selectedItems.length} message="You may select up to 12 Queries to include on your personalized dashboard on the Start page of LeadPerfection" />
      <Card sx={{ boxShadow: 2, mt: 2, borderRadius: 4 }}>
        <CardContent>
          <CustomTabs
            orientation="vertical"
            tabLabels={labels}
            tabComponents={labels.map((label) => {
              const categoryItems = items.filter((item) => item.queryCategory === label.title)

              return (
                <AccessDashboard isLoading={isLoading} items={categoryItems} onChange={onChange} disabled={selectedItems.length === 12} />
              )
            })}
          />
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", borderTop: 1, backgroundColor: "#FAFAFA", borderColor: "#00000026", p: 2 }}>
          <Stack direction="row" spacing={2}>
            <Button variant="outlined" width={120} height={40} label="Cancel" onClick={fetchQueriesAccessItems} />
            <Button disabled={disableSaveButton} width={120} height={40} label='Save' onClick={onUpdateSelectedQueries} />
          </Stack>
        </Box>
      </Card>
    </>
  )
}