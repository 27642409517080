import { useEffect, useState, useCallback } from "react";
import { Warranty } from "../../types/Warranty";
import { SelectChangeEvent } from "@mui/material";
import { getAxios } from "../../utils/endpoints";
import { formatDateRange } from "../../utils/time";

type IWarrantyResponse = {
    items: Warranty[];
    totalRecordCount: number;
}

export const useViewWarranties = () => {
    const [filter, setFilter] = useState<string>("0");
    const [mode, setMode] = useState<string>("1");
    const [search, setSearch] = useState<string>("");
    const [rows, setRows] = useState<Warranty[]>([]);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    const [startIndex, setStartIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);

    const onPageChange = (newPage: number) => setStartIndex(newPage);
    const onPageSizeChange = (newPageSize: number) => setPageSize(newPageSize);

    const onChangeSelect = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target;

        setMode(value as string);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setSearch(value);
    }

    const onFetchWarranties = useCallback(async () => {
        const response = await getAxios("/warranties", "GET", undefined, {
            Mode: "0",
            StartIndex: startIndex,
            PageSize: pageSize,
        });

        const data: IWarrantyResponse = await response.data;

        if (data.items) {
            setRows(data.items);
        }

        setTotalRecordCount(data.totalRecordCount);
    }, [startIndex, pageSize])

    const onFetchWarrantiesByDateAndDealer = async (startDate: Date, endDate: Date, dealer: string) => {
        const dates = formatDateRange(startDate, endDate);

        const response = await getAxios("/warranties", "GET", undefined, {
            Mode: "C",
            StartIndex: 0,
            PageSize: 15,
            StartingDate: dates[0],
            EndingDate: dates[1],
            Dealer: dealer
        });

        const data: IWarrantyResponse = await response.data;

        if (data.items) {
            setRows(data.items);
        } else setRows([]);

        setTotalRecordCount(data.totalRecordCount);
    }

    const onFetchWarrantiesBySearch = async (mode: string, search: string): Promise<void> => {
        const response = await getAxios("/warranties", "GET", undefined, {
            Mode: mode,
            SearchFor: search,
            StartIndex: startIndex,
            PageSize: pageSize,
        });

        const data: IWarrantyResponse = await response.data;

        if (data.items) {
            setRows(data.items);
        } else setRows([]);

        setTotalRecordCount(data.totalRecordCount)
    }

    useEffect(() => {
        if (filter === "0") {
            onFetchWarranties();
        }
    }, [onFetchWarranties])

    return {
        filter,
        mode,
        search,
        startIndex,
        pageSize,
        rows,
        totalRecordCount,
        onFetchWarranties,
        onFetchWarrantiesByDateAndDealer,
        onFetchWarrantiesBySearch,
        onChange,
        onChangeSelect,
        onPageChange,
        onPageSizeChange,
        setFilter
    }
}