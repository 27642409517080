import React, { useState, useEffect, useCallback } from "react";
import { SvgIcon } from "@mui/material";
import { route, getAxios } from "../../utils/endpoints";
import { Folder } from "../../types/Folder";
import { Report } from "../../types/Report";
import { ReportFolder } from "../../types/ReportFolder";
import { mdiFolderOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useFolderItem } from "../../components/Folder/FolderItem/hook";
import { User } from "../../model/User";

export interface IPermissions {
    hideReportsHome: boolean,
    hideEditCurrentFolder: boolean,
    hideEditFavorite: boolean,
    hideEditBulkFavorite: boolean,
    hideDeleteBulkFavorite: boolean,
    hideFolders: boolean,
    hideEditFolders: boolean,
    hideAddFolders: boolean,
    hideDeleteFolders: boolean,
    hideEditCustomReportSpecs: boolean,
    hideAddCustomReportSpecs: boolean,
    hideDeleteCustomReportSpecs: boolean,
    hideReadCustomReportData: boolean,
    hideAddClonedReport: boolean,
    hideEditSchedule: boolean,
    hideDeleteBulkReports: boolean,
    hideExportReports: boolean,
}

export const useReports = () => {
    const [httpAction, setHttpAction] = useState<"POST" | "PUT">("POST");
    const [folderId, setFolderId] = useState<number | undefined>(undefined);
    const [folderName, setFolderName] = useState<string>("");
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [folders, setFolders] = useState<Folder[]>([]);
    const [favoriteReports, setFavoriteReports] = useState<ReportFolder[]>([]);
    const [linkFolders, setLinkFolders] = useState<any[]>([]);

    //permissions
    const user = new User();
    const permissions: IPermissions = {
        hideReportsHome: user.HasForbiddenAccess("Can Read Reports Home"),
        hideEditCurrentFolder: user.HasForbiddenAccess("Can Edit Current Folder"),
        hideEditFavorite: user.HasForbiddenAccess("Can Edit Favorite"),
        hideEditBulkFavorite: user.HasForbiddenAccess("Can Edit Bulk Favorite"),
        hideDeleteBulkFavorite: user.HasForbiddenAccess("Can Delete Bulk Favorite"),
        hideFolders: user.HasForbiddenAccess("Can Read Folders"),
        hideEditFolders: user.HasForbiddenAccess("Can Edit Folders"),
        hideAddFolders: user.HasForbiddenAccess("Can Add Folders"),
        hideDeleteFolders: user.HasForbiddenAccess("Can Delete Folders"),
        hideEditCustomReportSpecs: user.HasForbiddenAccess("Can Edit Custom Report Specs"),
        hideAddCustomReportSpecs: user.HasForbiddenAccess("Can Add Custom Report Specs"),
        hideDeleteCustomReportSpecs: user.HasForbiddenAccess("Can Delete Custom Report Specs"),
        hideReadCustomReportData: user.HasForbiddenAccess("Can Read Custom Report Data"),
        hideAddClonedReport: user.HasForbiddenAccess("Can Add Cloned Report"),
        hideEditSchedule: user.HasForbiddenAccess("Can Edit Schedule"),
        hideDeleteBulkReports: user.HasForbiddenAccess("Can Delete Bulk Reports"),
        hideExportReports: user.HasForbiddenAccess("Can Export Reports"),
    };
    
    const { onClose } = useFolderItem()

    const toggleOpenDialog = () => {
        setOpenDialog(!openDialog);
    }

    const setPOSTDialogForm = () => {
        setHttpAction("POST");
        toggleOpenDialog();
        onClose()
    }

    const setPUTDialogForm = (id: number, name: string) => {
        setHttpAction("PUT");
        setFolderId(id);
        setFolderName(name);
        toggleOpenDialog();
    }

    const setPUTNewFolder = (id: number) => {
        console.log(id, 111111)
    }

    const onMoveFolder = async (reportFolderId: number, idReport: number) => {
        
        try {
           
            await getAxios(route.apiReports.reporstCustom + idReport + "/folder", "PUT", {
                destinationFolderId: reportFolderId
            });
            await fetchReportsFolders()
           
           
        } catch (error) {
            console.error(error)
        }
        onClose()
    }

    const fetchReportsFolders = useCallback(async () => {
        try {
            const folderResponse = await getAxios(route.apiReports.reportsFolders, "GET", undefined, { Active: -1, SortBy: 0, IncludeAll: false });
            const reportResponse = await getAxios(route.apiReports.reporstCustom, "GET");

            const fetchedFolders: Folder[] = await folderResponse.data;
            let fetchedReports: ReportFolder[] = await reportResponse.data;
           
            console.log(fetchedFolders,);
            console.log(fetchedReports);

            if (!fetchedReports) {
                fetchedReports = [];
            }

            const mappedFolders = fetchedFolders.map((folder, index) => {
                folder.reports = fetchedReports.filter((report) => report.reportFolderId === folder.reportFolderId);

                if (!folder.reports.length) {
                    folder.reports = [];
                }

                return folder;
            });
            console.log(mappedFolders, 5454)
            const favorites: ReportFolder[] = fetchedReports.filter((report) => report.favorite !== false);

            const linkFolders = mappedFolders.map((folder) => {
                return {
                    Icon: <Icon path={mdiFolderOutline} />,
                    label: folder.description,
                    onClick: (idReport: number) =>{
                        
                        onMoveFolder(folder.reportFolderId, idReport)
                        onClose()
                    },
                        
                }
            });

            setFolders(mappedFolders);
            setFavoriteReports(favorites);
            setLinkFolders(linkFolders);
            onClose()
        }

        catch (error) {
            console.error(error)
        }
    }, []);

    const onFetchFavorites = useCallback(async () => {
        const response = await getAxios(route.apiReports.reporstCustom, "GET", undefined, {
            Active: -1,
            sortBy: 0,
            IncludeAll: true
        });

        const fetchedReports: ReportFolder[] = await response.data;

        const favorites: ReportFolder[] = fetchedReports.filter((report) => report.favorite !== false);

        setFavoriteReports(favorites);
    }, [])

    const onDeleteFolder = async (folderId: number) => {
        try {
            await getAxios(route.apiReports.reportsFolders + folderId, "DELETE");
            await fetchReportsFolders();
        }

        catch (error) {
            console.error(error);
        }
    }

    const onDeleteReport = async (reportId: number) => {
        try {
            await getAxios(route.apiReports.reporstCustom + reportId, "DELETE");

            await fetchReportsFolders();
        }

        catch (error) {
            console.error(error)
        }
    }

    const onRenameFolder = async (id: number, newName: string) => {
        await getAxios(route.apiReports.reportsFolders, "PUT", {
            FolderId: id,
            FolderName: newName
        });

        await fetchReportsFolders();

    }

    const onFavorite = async (id: number) => {
        try {
            await getAxios(route.apiReports.reporstCustom + id + "/favorite", "PUT", {
                isFavorite: true
            })
                .then(async () => {
                    await onFetchFavorites();
                    await fetchReportsFolders()
                });

        } catch (error) {
            console.log(error)
        }
    }

    const onUnFavorite = async (id: number) => {
        try {
            await getAxios(route.apiReports.reporstCustom + id + "/favorite", "PUT", {
                isFavorite: false
            })
                .then(async () => {
                    await onFetchFavorites();
                    await fetchReportsFolders()
                })
        } catch (error) {
            console.log(error)
        }

    }

    const onClone = async (id: number) => {
        try {
            await getAxios(route.apiReports.reporstCustom + "clone", "PUT", {
                id: id
            })
            await fetchReportsFolders();
        } catch (error) {
            console.error(error)
        }

    }

    useEffect(() => {
        fetchReportsFolders()
    }, [fetchReportsFolders]);

    useEffect(() => {
        onFetchFavorites()
    }, [onFetchFavorites]);

    return {
        folders,
        favoriteReports,
        openDialog,
        folderId,
        folderName,
        httpAction,
        linkFolders,
        permissions,
        fetchReportsFolders,
        onDeleteFolder,
        onRenameFolder,
        toggleOpenDialog,
        setPOSTDialogForm,
        setPUTDialogForm,
        onDeleteReport,
        onFavorite,
        setPUTNewFolder,
        onUnFavorite,
        onClone,

    }
}