import React, { useState, useEffect } from "react";
import { useAccount, useMsal } from "@azure/msal-react";
import { IState } from "./IState";
import { useNavigate } from "react-router-dom";
import { User } from "../../model/User";
import { getAxios, route } from "../../utils/endpoints";
import { userFlow, IdTokenClaims, B2C_TENANT_NAME, } from "../../config/msalConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import Cookies from "universal-cookie";

export const useLoginForm = () => {
    const [state, setState] = useState<IState>({
        email: "",
        error: {
            type: "default",
            message: ""
        }
    });

    const { instance } = useMsal();
    const navigate = useNavigate();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;

        setState((prevState) => {
            return {
                ...prevState,
                [name]: value
            }
        });
    }

    const onClear = (name: string): void => {
        setState({
            ...state,
            [name]: ""
        });
    }

    const onOpen = async (): Promise<void> => {
        if (!state.email.match(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/) && state.email) {
            setState({
                ...state,
                error: {
                    type: "error",
                    message: "Please type a valid email"
                }
            });

            return;
        }

        if (!state.email) {
            setState({
                ...state,
                error: {
                    type: "error",
                    message: "Please fill the email field to proceed"
                }
            })
            return;
        }

        setState({
            ...state,
            error: {
                type: "default",
                message: ""
            }
        });

        await onLogin();
    }

    const onLogin = async (): Promise<void> => {
        const tenantName: string = localStorage.getItem("tenantName") as string;

        let msalInstance: PublicClientApplication = instance as PublicClientApplication;

        

        try {
            const user = new User();
            const azureResponse = await instance.loginPopup({ scopes: [], loginHint: state.email, authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${userFlow.SignIn}` });

            const idTokenClaims = azureResponse.idTokenClaims as IdTokenClaims;

            if (idTokenClaims["tfp"] === userFlow.SignIn && idTokenClaims.isForgotPassword) {
                alert("Your password has been successfully changed");
                return;
            }

            const token = azureResponse.idToken;

            sessionStorage.setItem("token", token);

            const axiosResponse = await getAxios(route.apiUsers.me, "GET")

            if (!axiosResponse.data.roles.length) {
                alert("This user dont have any roles");

                instance.logout({
                    onRedirectNavigate: () => {
                        sessionStorage.removeItem("user");
                        sessionStorage.removeItem("token");
                    },
                    postLogoutRedirectUri: "/"
                });

                return;
            }

            if (axiosResponse.data.roles[0].secLevel === 0) {
                alert("At this moment you are unable to sign in");

                instance.logout({
                    onRedirectNavigate: () => {
                        sessionStorage.removeItem("user");
                        sessionStorage.removeItem("token");
                    },
                    postLogoutRedirectUri: "/"
                });

                return;
            }

            user.SaveSession(axiosResponse.data);

            navigate("/dealer-home");
        } catch (error: any) {
            if (error.data) {
                instance.logout({
                    onRedirectNavigate: () => {
                        sessionStorage.removeItem("user");
                        sessionStorage.removeItem("token");
                    },
                    postLogoutRedirectUri: "/"
                });
            }

            console.log("login error: ", error);
        }
    }

    const keyDownHandler = async (event: KeyboardEvent) => {
        if ((event.key === "Enter")) {
            await onOpen();
        }
    }

    useEffect(() => {           
        const itemKey: string = "msal.interaction.status";
         
        const cookies: Cookies = new Cookies();

        if (sessionStorage.getItem(itemKey)) {
            sessionStorage.removeItem(itemKey);
            
            cookies.remove(itemKey, { path: "/" });
        }

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    })

    return {
        state,
        onChange,
        onClear,
        onOpen,
        onLogin,
    }
}