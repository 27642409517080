import React, { FunctionComponent, useState } from "react";
import { Box, IconButton, SvgIcon, Typography, Tooltip } from "@mui/material";
import { MoreVert, SwapVert } from "@mui/icons-material";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { CustomDataGrid } from "../CustomDataGrid";
import { BackgroundIcon } from "../BackgroundIcon"
import { CallerIdTooltip } from "../CallerIdTooltip"
import { formatDate, getHour, timeFormat } from "../../utils/time";
import { truncate } from "../../utils/text";
import { getLeadTypeColors } from "../../utils/get-lead-type-colors";
import { getSaleStatusDescription } from "../../utils/get-sale-status-description";
import { ItemMenu } from "../ItemMenu";
import { IProps } from "./IProps";

const defaultColumnOptions = {
    resizable: false,
    flex: 1,
    hideSortIcons: false,
    disableColumnMenu: false,
}

const columns: GridColDef[] = [
    {
        field: "leadDate",
        headerName: "Lead Date",
        align: "left",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {

            return (
                <Box sx={{ mt: 1, mb: 1 }} >
                    <Typography sx={{ fontWeight: 400, fontSize: 14 }} >
                        {formatDate(params.value)}
                    </Typography>
                    <Typography sx={{ fontWeight: 600, fontSize: 14 }} >
                        {getHour(new Date(params.value))}
                    </Typography>
                </Box>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "duration",
        headerName: "Duration",
        headerAlign: "center",
        align: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
            if (params.value < 1) {
                return (
                    <strong>n/a</strong>
                )
            }

            return (
                <strong>{timeFormat(params.value)}</strong>
            );
        },
        ...defaultColumnOptions
    },
    {
        field: "type",
        headerName: "Type",
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
            const { bg, icon, lead_type } = getLeadTypeColors(parseInt(params.value));

            const redirectTo = () => {
                window.open(params.row.callRecordingUrl);
            }

            return (
                <BackgroundIcon color="rgba(0, 0, 0, 0.4)" bgColor={bg} icon={icon} label={lead_type} onClick={lead_type.toLowerCase() === "call" ? redirectTo : undefined} />
            );
        },
        ...defaultColumnOptions
    },
    {
        field: " Lead Outcome",
        headerName: "Lead Outcome",
        headerAlign: "center",
        align: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
            const callerName = truncate(`${params.row.caller.firstName} ${params.row.caller.lastName}`, 15, true);
            const saleStatusDescription = getSaleStatusDescription(params.row.saleStatus);

            return (
                <strong >
                    <Typography gutterBottom fontSize={14} >
                        {params.row.callStatus}
                    </Typography>
                </strong>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "callerPopup",
        headerName: "Name/Caller ID",
        headerAlign: "center",
        align: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
            const callerName = truncate(`${params.row.caller.firstName} ${params.row.caller.lastName}`, 15, true);
            const saleStatusDescription = getSaleStatusDescription(params.row.saleStatus);

            if (!params.value) {
                return (
                    <Typography sx={{ fontWeight: 600, fontSize: 14 }}>n/a</Typography>
                )
            }

            return (
                <CallerIdTooltip
                    htmlInjection={params.value}
                >
                    <Box sx={{ cursor: "pointer" }} >
                        <Typography gutterBottom fontSize={14} >
                            {callerName}
                        </Typography>
                        <Typography sx={{ fontWeight: 600, fontSize: 14 }} >
                            {params.row.phoneNumber}
                        </Typography>
                    </Box>
                </CallerIdTooltip>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "appointmentDate",
        headerName: "Appt Dt",
        headerAlign: "center",
        align: "center",
        sortable: false,
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
            if (!params.value) {
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    n/a
                </Typography>
            }

            return (
                <strong>
                    {formatDate(params.value)}
                </strong>
            )
        },
        ...defaultColumnOptions
    },
];

const optionColumns: GridColDef = {
    field: "",
    headerAlign: "right",
    align: "right",
    sortable: false,
    renderHeader: (params: GridColumnHeaderParams) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);

        const onClick = (event: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(event.currentTarget);
        }

        const onClose = () => {
            setAnchorEl(null);
        }
        return (
            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                <span>
                    Options
                </span>
            </Typography>
        );
    },
    renderCell: (params: GridRenderCellParams) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);

        const onClick = (event: React.MouseEvent<HTMLElement>): void => {
            setAnchorEl(event.currentTarget);
        }

        const onClose = () => {
            setAnchorEl(null);
        }

        return (
            <strong>
                <span>
                    <IconButton onClick={onClick} >
                        <SvgIcon>
                            <MoreVert htmlColor="rgba(0, 0, 0, 0.4)" />
                        </SvgIcon>
                    </IconButton>
                </span>
                <ItemMenu itemId={params.row.id} open={open} anchorEl={anchorEl} onClose={onClose} />
            </strong>
        );
    },
    ...defaultColumnOptions
}



export const LeadGrid: FunctionComponent<IProps> = (props) => {
    const customColumnsHeaders: string[] = props.partialColumns.slice(12, props.partialColumns.length - 1).filter((val) => {
        if (val.startsWith("customColumnHeader")
            && (props.callLeads[0][val] !== "") 
            && (props.callLeads[0][val] !== null)) {
            return val;
        }
    });

    
    const customColumns = [];
    
    // columns gets plit in half because each column takes up two properties: Data and Header
    for (let i = 0; i < (customColumnsHeaders.length); i++) {
        const headerName: string = customColumnsHeaders[i];
        
        const customColumnIndex: number = parseInt(headerName.replace("customColumnHeader", ""));
        const heading: string = props.callLeads[0][`customColumnHeader${customColumnIndex}`];

        const newColumn: GridColDef = {
            field: `customColumnData${customColumnIndex}`,
            headerName: heading,
            headerAlign: "center",
            align: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                        {params.colDef.headerName!}
                    </Typography>
                );
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <strong>{params.value}</strong>
                )
            },
            ...defaultColumnOptions
        }

        customColumns.push(newColumn);
    }
    
    const gridColumns: GridColDef[] = columns.concat(customColumns);

    gridColumns.push(optionColumns);

    return (
        <>
            <CustomDataGrid
                initialState={{
                    sorting: {
                        sortModel: props.sortModel
                    }
                }}
                sortingOrder={["asc", "desc"]}
                columns={gridColumns}
                rows={props.callLeads}
                renderRefresh={props.renderRefresh}
                renderToggle={props.renderToggle}
                onRefresh={props.onRefresh}
                page={props.page}
                pageSize={props.pageSize}
                sortModel={props.sortModel}
                onPageChange={props.onPageChange}
                onPageSizeChange={props.onPageSizeChange}
                paginationMode="server"
                sortingMode="server"
                loading={props.loading}
                rowCount={props.total}
                onSortModelChange={props.onSortModelChange}
            />
        </>
    )
}