import { SelectChangeEvent } from "@mui/material";
import React, { useState, useEffect } from "react";
import { getAxios, route } from "../../utils/endpoints";
import { IState } from "./IState";
import { useNavigate } from "react-router-dom";
import { DropdownValue } from "../../types/DropdownValue"
import { User } from "../../model/User";

const initialState: IState = {
    actualFirstname: "",
    actualLastName: "",
    actualAddress: "",
    actualCity: "",
    actualState: "",
    actualZip: "",
    actualPhone: "",
    actualEmail: "",
    otherInfo: "",
    callStatus: "",
    apptDate: new Date(),
    apptTime: "",
    product: "",
    updVersion: 0,
    newDealerId: 0,
    sendEmail: false,
    saleAmount: 0,
    phone2: "",
    phone3: "",
    email2: "",
    saleStatus: "",
    saleDate: new Date(),
    orderDate: new Date(),
    shipDate: new Date(),
    installDate: new Date(),
    nbrId: 0,
    dialedTollfree: "",
    leadOutcome: "",
    status: "U",
    formDisabled: true,
}

export const useEditLeadForm = (leadId: number) => {
    const user = new User();
    const navigate = useNavigate();

    const [snackOpen, setSnackOpen] = useState<boolean>(false);
    const [number, setNumber] = useState<DropdownValue | null>({
        id: 0,
        description: "",
        sortOrder:0,
    })

    const [state, setState] = useState<IState>(initialState);
    const [dropdownItems, setDropdownItems] = useState<DropdownValue[]>([]);
    const [dropdownProduct, setDropdownProduct] = useState<DropdownValue[]>([])
    const [dropDonwNonBillReason, setDropdonwNonBillReason] = useState<DropdownValue[]>([])
    const [dropDownDealerAccess, setDropdownDealerAcces] = useState<DropdownValue[]>([])
    const [dropdownNumber, setDropDownNumber] = useState<DropdownValue[]>([])
    const [dropdownSale, setDropdownSale] = useState<DropdownValue[]>([])

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    }

    const hideSnackbar = () => {
        setSnackOpen(false);
    }

    const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            sendEmail: event.target.checked
        });
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value as string
        });
    }

    const onUpdate = async (): Promise<void> => {
        try {
            await getAxios(`${route.apiCallDetail.callDetail}`, "PUT", {...state, id: leadId, dialedTollFree: number?.id.toString()});
            setSnackOpen(true)
        } catch (error) {
            console.error(error);
        }
    }

    const onCancel = () => {
        navigate("/view-leads");
    }

    const onChangeDate = (newDate: Date | null, name: string): void => {
        setState({
            ...state,
            [name]: newDate
        });
    }

    const fetchLead = async (): Promise<void> => {
        try {
            const response = await getAxios(`${route.apiCallDetail.callDetail}?id=${leadId}`, "GET");
            const dropdownResponse = await getAxios(route.apiOptions.leadStatus, "GET");
            const dropdonwProductResponse = await getAxios(route.apiOptions.product, "GET");
            const nonBillReasonResponse = await getAxios(route.apiOptions.nonBillReason, "GET");
            const dealerAccessResponse = await getAxios(route.apiOptions.dealerAccess, "GET");
            const numberResponse = await getAxios(route.apiOptions.subcampaign, "GET");
            const saleResponse = await getAxios(route.apiOptions.saleStatus, "GET");
            
            const leadData = await response.data;
            const dropdowns: DropdownValue[] = await dropdownResponse.data;
            const productItem: DropdownValue[] = await dropdonwProductResponse.data;
            const nonBillReason: DropdownValue[] = await nonBillReasonResponse.data;
            const dealerAccess: DropdownValue[] = await dealerAccessResponse.data;
            const number: DropdownValue[] = await numberResponse.data;
            const sale: DropdownValue[] = await saleResponse.data;
            const dialedTollFree = await response.data; 
            
            setState((prevState) => ({
                ...leadData,
                formDisabled: user.HasForbiddenAccess("Can Edit Lead")
            }));
            
            setDropdownItems(dropdowns);
            setDropdownProduct(productItem)
            setDropdonwNonBillReason(nonBillReason)
            setDropdownDealerAcces(dealerAccess)
            setDropDownNumber(number)
            setDropdownSale(sale)
            setNumber({
                id: parseInt(dialedTollFree),
                description: "",
                sortOrder: 0
            })

           
        } catch (error) {
            console.error(error);
        }
    }

    const onChangeAutocomplete = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownValue | null,
        name: string,
    ) => {
        setNumber({
            id: parseInt(value!.id as unknown as string),
            description: value!.description,
            sortOrder: value!.sortOrder
        });

        console.log(number);
    }

    useEffect(() => {
        fetchLead();
    }, []);

    return { 
        state,
        number,
        snackOpen, 
        onChange, 
        onChangeCheckBox,
        onChangeSelect,
        onChangeAutocomplete,
        onUpdate, 
        fetchLead, 
        hideSnackbar,
        onCancel, 
        onChangeDate,
        dropdownItems,
        dropdownProduct,
        dropDonwNonBillReason,
        dropDownDealerAccess,
        dropdownNumber,
        dropdownSale
    };
}