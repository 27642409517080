const apiApp = {
    login: "/login"
}

const apiLeads = {
    search: "/search",
}

const apiUsers = {
    me: "/me",
    edit: "/profile/edit",
    menu: "/user-menu"
}

const apiCallDetail = {
    callDetail: "/call-detail"
}

const apiViewLeads = {
    unresulted: "/view-leads/unresulted",
    salesBetween: "/view-leads/sales-between",
    leadsBetweenDateAndStatus: "/view-leads/calls-between",
    openSales: "/view-leads/open-sales",
    openInquiries: "/view-leads/open-inquiries",
    unresultedAppointments: "/view-leads/unresulted-appointments",
    searchBy: "/view-leads/search-by"
};

const apiDashboard = {
    leads: "/dashboard/leads",
    metrics: "/dashboard/metrics",
    dealer: "/dashboard/dealer"
}

const apiOptions = {
    campaign: "/options/campaign",
    subcampaign: "/options/sub-campaign",
    product: "/options/product",
    saleStatus: "/options/sale-status",
    leadStatus: "options/lead-status",
    nonBillReason: "/options/non-bill-reason",
    dealerAccess: "/options/dealer-access",

}

const apiSidebar = {
    sideMenu: "/side-menu"
}

const apiReports = {
    reporstCustom: "/reports/custom/",
    reportsFolders: "/reports/folders/"
    
}

const apiReport = {
    filter: {
        filters: "/reports/filters",
        values: "/reports/filters/values"
    },
    columns: "/reports/columns"
}

const apiExport = {
    exports: "/exports",
    sources: "/exports/sources",
    columns: "/exports/columns",
    generate: "/exports/generate"
}

const apiAccessDashBoard = {
    queries: "/access/dashboard/queries",
    queryCategories: "/access/dashboard/queries/categories",
    tables: "/access/dashboard/tables",
    tableCategories: "/access/dashboard/tables/categories"
}

const apiAccount = {
    territory: "/territory"
}

const apiTable = "/table/"

export default {
    apiApp, 
    apiUsers, 
    apiDashboard, 
    apiLeads, 
    apiViewLeads, 
    apiCallDetail,
    apiOptions,
    apiSidebar,
    apiReport,
    apiReports,
    apiExport,
    apiTable,
    apiAccessDashBoard,
    apiAccount
}