import React, { useState } from "react";

export const useDownloadButton = () => {
    const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<null | HTMLElement>(null);
    const downloadOpen = Boolean(downloadMenuAnchorEl);

    const onClickDownloadButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDownloadMenuAnchorEl(document.getElementById('download-button'));
    };

    return {
        downloadMenuAnchorEl,
        setDownloadMenuAnchorEl,
        downloadOpen,
        onClickDownloadButton
    }
}