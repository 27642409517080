import React, { useState, useEffect, useCallback } from "react"
import { getAxios, route } from "../../../utils/endpoints";
import { IMode } from "../interfaces/IMode";
import { ISource } from "../interfaces/IStepOne"

const sourceName = localStorage.getItem("source");

export const useStepOne = (mode: IMode) => {
    const [sourceList, setSourceList] = useState<ISource[]>([]);

    const fetchSource = useCallback(async () => {
        try {
            const sourceResponse = await getAxios(route.apiExport.sources, "GET", undefined, {
                mode
            });

            const data: ISource[] | string = await sourceResponse.data;

            if (typeof data === "string") {
                return;
            }

            setSourceList(data)


        } catch (error) {
            console.error(error)
        }
    }, [mode])

    useEffect(() => {
        fetchSource();
    }, [fetchSource]);

    return {
        sourceList,
    }
}
