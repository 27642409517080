import { ItemProps, IColumn, IFilter } from "./IProps";
import { Folder } from "../../types/Folder";
import { Dayjs } from "dayjs"
import { DateRange } from "@mui/x-date-pickers-pro";
import { DropdownValue } from "../../types/DropdownValue";
import { FilterDropdown } from "./IProps"

export enum Action {
    Increment = 1,
    Decrement = 0
}

export interface IStepOneState  {
    type: string;
    folder: number;
    folders: Folder[];
    title: string;
    description: string;
    shortDescription: string;
    isValid: boolean | undefined;
}

export interface IStepTwoState {
    selectedFilters: number[];
    selectedOptions: number[];
    filters: IFilter[];
    columns: IColumn[];
    isValid: boolean;
    isLoading?: boolean;
    isPreloadedData?: boolean;
}


export interface IStepThreeState {
    inquirydate: {
        filterId: number;
        values: DateRange<Dayjs>;
    };
    apptdate: {
        filterId: number;
        values: DateRange<Dayjs>;
    };
    campaign: FilterDropdown; 
    subcampaign: FilterDropdown;
    leadtype: FilterDropdown;
    dealer: FilterDropdown; 
    leadstatus: FilterDropdown; 
    salesstatus: FilterDropdown; 
    state: FilterDropdown; 
    product: FilterDropdown; 
    groupBy: string; 
    subGroup: string;
    isValid: boolean;
}

export interface IStepFourState {
    isReportScheduled: boolean;
    timeInterval: string;
    timeZone: string;
    every: string;
    on: string | number;
    hour: Dayjs | null;
    startDate: Date;
    endDate: Date;
    emailInputs: { name: string }[];
    reportFile: string;
    emails: {
        email0: string;
        email1: string;
        email2: string;
        email3: string;
    };
    isEmailDisabled: boolean;
}