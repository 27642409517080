import React, { FunctionComponent, useState } from 'react';
import {
    Stack,
    MenuItem,
    Box,
    Typography,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Dialog,
    SvgIcon,
    Button as MuiButton
} from '@mui/material';
import { CustomSelect } from "../../CustomSelect";
import { CustomInput } from "../../CustomInput";
import { CustomTextArea } from "../../CustomTextArea";
import { CustomSwitch } from "../../CustomSwitch";
import { Button } from "../../Button";
import { Icon } from "@mdi/react";
import { mdiFolderOutline, mdiFolder, mdiFormatTitle, mdiEmailOutline, mdiFileDocumentOutline, mdiPlusThick } from "@mdi/js";
import { StepTitle } from "../StepTitle";
import { IStepOneProps } from "../IProps";
import { IStepOneState } from "../IState";
import Grid from '@mui/material/Unstable_Grid2';
import { formatPadDate } from "../../../utils/time"
import { NewFolderDialog } from "../../NewFolderDialog";
import { IProps as InputObj } from "../../CustomInput/IProps";

export const StepOne: FunctionComponent<IStepOneProps<IStepOneState>> = ({ state, setter, onChange, onRefreshFolder, onClearSelectedOptions }): JSX.Element => {
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    return (
        <Box  >
            <Grid>
                <StepTitle title="Identify your report" />
            </Grid>
            <Grid columns={{ sm: 12, md: 12 }} container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mt={4} > {/* MAIN CONTAINER*/}

                <Grid sm={12} md={6} container sx={{ width: "100%" }}>  {/*Report, Folder and Title Container */}
                    <Grid sm={12} md={12} sx={{ width: "100%" }}>{ /* Report Type Select */}
                        <CustomSelect
                            width={"100%"}
                            name="type"
                            value={state.type}
                            label="Report Type"
                            StartIcon={<Icon path={mdiFileDocumentOutline} />}
                            onChange={(event) => {
                                onChange(event, setter);
                                onClearSelectedOptions();
                            }}
                            hint={(!state.isValid && state.type === "") ? { type: "error", message: "Choose one of the reports below to start working on it" } : undefined}
                        >
                            <MenuItem value="1">
                                Detail
                            </MenuItem>
                            <MenuItem value="2">
                                Summary
                            </MenuItem>
                        </CustomSelect>
                    </Grid>

                    <Grid sm={12} md={12} sx={{ width: "100%" }}> { /* Folder Select */}
                        <CustomSelect
                            sx={{ width: "100%", height: 35 }}
                            name="folder"
                            defaultValue={state.folder}
                            value={state.folder}
                            label="Folder"
                            StartIcon={<Icon path={mdiFolderOutline} />}
                            onChange={(event) => onChange(event, setter)}
                            hint={(!state.isValid && state.folder <= 0) ? { type: "error", message: "Choose one of the folders below to start working on it" } : undefined}
                        >
                            <MenuItem onClick={() => setOpenDialog(true)} >
                                <Box sx={{ width: "100%" }}>
                                    <Stack direction="row" alignItems="center" spacing={1} >
                                        <Icon path={mdiPlusThick} color="#1BA0E9" size="18px" />
                                        <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 14 }}>New Folder</Typography>
                                    </Stack>
                                </Box>
                            </MenuItem>
                            {state.folders.map((folder, index) => {
                                return (
                                    <MenuItem key={index} value={folder.reportFolderId} >{folder.description}</MenuItem>
                                )
                            })}
                        </CustomSelect>
                    </Grid>

                    <Grid sx={{ width: "100%" }} sm={12} md={12}> { /* Report Title input */}
                        <CustomInput
                            label={{ text: "Report Title" }}
                            startIcon={<Icon path={mdiFormatTitle} />}
                            name="title"
                            placeholder={`Report ${formatPadDate(new Date(), "/")}`}
                            value={state.title}
                            onChange={(event) => onChange(event, setter)}
                            height={35}
                            width={"100%"}
                            hint={(!state.isValid && state.title === "") ? { type: "error", message: "This is a required field and cannot be empty" } : undefined}
                        />
                    </Grid>
                </Grid>
                <Grid sm={12} md={6} sx={{ width: "100%" }} container > {/*Description Container */}
                    <Grid sm={12} md={12} sx={{ width: "100%" }}>
                        <CustomTextArea width={"100%"} name="description" label={{ text: "Description" }} value={state.description} onChange={(event) => onChange(event, setter)} maxLength={300} />
                    </Grid>
                    <Grid sx={{ width: "100%" }} sm={12} md={12}>
                        <CustomTextArea width={"100%"} name="shortDescription" label={{ text: "Short Description" }} value={state.shortDescription} onChange={(event) => onChange(event, setter)} maxLength={70} />
                    </Grid>
                </Grid>

                
            </Grid>
            <NewFolderDialog 
                openDialog={openDialog}
                httpAction="POST" 
                onClose={() => setOpenDialog(false)} 
                onRefresh={onRefreshFolder} />
        </Box>
    )

}

