import { FunctionComponent, useState } from "react";
import { Dialog, Box, Button as MuiButton, Stack, Typography, SvgIcon, Backdrop, CircularProgress } from "@mui/material";
import { Button } from "../Button";
import { CustomInput } from "../CustomInput";
import { CustomSelect } from "../CustomSelect";
import { mdiFolder, mdiFolderOutline } from "@mdi/js"
import { Icon } from "@mdi/react";
import { IProps } from "./IProps";
import { useNewFolderDialog } from "./hook";

export const NewFolderDialog: FunctionComponent<IProps> = ({ openDialog, httpAction, id, name, onClose, onRefresh }): JSX.Element => {
    const { error, 
        isLoading, 
        newFolder, 
        onChange, 
        onClick 
    } = useNewFolderDialog(httpAction, id!, name!, onClose, onRefresh);

    return (
        <Dialog open={openDialog}>
            <Box position="relative" display="flex" flexDirection="column" alignItems="start" justifyContent="space-between" width={450} height={227} borderRadius="15px" bgcolor="white" padding={4}>
                {isLoading && (
                    <Box
                        sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", zIndex: 1, position: "absolute", top: 0, left: 0 }}
                    >
                        <CircularProgress color="primary" />
                    </Box>
                )}
                <Stack direction="row" alignItems="center" spacing={2}>
                    <SvgIcon htmlColor="#1BA0E9" fontSize="large" >
                        <Icon path={mdiFolder} />
                    </SvgIcon>
                    <Typography sx={{ color: "rgba(0, 0, 0, 0.8)", fontWeight: 600, fontSize: 22 }}>
                        { httpAction === "POST" ? "New Folder" : "Rename Folder" }
                    </Typography>
                </Stack>
                <Box width="100%" mt={2} >
                    <CustomInput
                        width="100%"
                        height={35}
                        name="newFolder"
                        value={newFolder}
                        onChange={onChange}
                        placeholder="Folder Name"
                        startIcon={<Icon path={mdiFolderOutline} />}
                        hint={error ? { type: "error", message: error } : undefined}
                    />
                </Box>
                <Stack width="100%" direction="row" display="flex" alignItems="center" justifyContent="flex-end" spacing={4} mt={1}>
                    <MuiButton variant="text" sx={{ fontWeight: 700, fontFamily: "Lato", fontSize: 16, color: "#1BA0E9" }} onClick={onClose} >Cancel</MuiButton>
                    <Button 
                        label={ httpAction === "POST" ? "Create" : "Rename"} 
                        width={101} 
                        height={46} 
                        onClick={onClick} 
                    />
                </Stack>
            </Box>
        </Dialog>
    )
}