import { Dispatch } from "redux";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { SideBarMenu } from "../../types/SidebarMenu";

export const SwitchAlertOn = () => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.ON,
            payload: true
        });
    }
}

export const SwitchAlertOff = () => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.OFF,
            payload: false
        });
    }
}

export const GetMenus = (items: SideBarMenu[]) => {
    return (dispatch: Dispatch<Action>) => {
        dispatch({
            type: ActionType.GET_MENU,
            payload: items
        });
    }
}