import React, { FunctionComponent, useState } from "react";
import { KeyboardArrowDown, RefreshOutlined } from "@mui/icons-material";
import { TableContainer, Box, Paper, Typography, Stack, Divider, LabelDisplayedRowsArgs, TablePagination } from "@mui/material";
import { DataGrid, GridRowIdGetter } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { IProps } from "./IProps";
import { Button } from "../Button";
import { CustomSwitch } from "../CustomSwitch";
import Grid from '@mui/material/Unstable_Grid2';


const StyledDataGrid = styled(DataGrid)<IProps>(({ theme }) => ({
    "& .MuiDataGrid-columnHeader": {
        backgroundColor: "#1BA0E9",
        color: "#FFFFFF",
        fontSize: 14,
        paddingLeft: '20px'
    },
    "& .MuiDataGrid-columnSeparator": {
        visibility: "hidden"
    },
    "&. MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "transparent"
    },
    "& .MuiDataGrid-row": {
        fontSize: 14
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
        "& .MuiDataGrid-row": {
            "&:nth-child(even)": {
                backgroundColor: "#FAFAFA"
            },
            "&:hover": {
                backgroundColor: '#DDF1FC',
                borderBottom: "2px solid #8fd0f3"
            }
        }
    },
    ".MuiDataGrid-cell": {
        paddingLeft: '20px'
    },
    "& .MuiDataGrid-main": {
        borderRadius: '10px'
    },
}));


export const CustomDataGrid: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <>
            <StyledDataGrid
                initialState={props.initialState}
                sortingOrder={props.sortingOrder}
                TopLeftElement={props.TopLeftElement}
                sx={{ width: "100%", mt: 2, height: "100vh", borderRadius: '10px' }}
                pagination
                disableSelectionOnClick
                loading={props.loading}
                rowHeight={65}
                getRowId={(row) => Math.random().toString()}
                columns={props.columns}
                rows={props.rows}
                paginationMode={props.paginationMode}
                sortingMode={props.sortingMode}
                page={props.page}
                pageSize={props.pageSize}
                onPageChange={props.onPageChange}
                onPageSizeChange={props.onPageSizeChange}
                rowsPerPageOptions={[5, 10, 15, 25]}
                rowCount={props.rowCount}
                onColumnHeaderClick={props.onColumnHeaderClick}
                onSortModelChange={props.onSortModelChange}
                localeText={props.localeText}
            />
        </>
    )
}