import { Typography } from "@mui/material"
import { CustomBreadCrumbs } from "../../components"
import Grid from '@mui/material/Unstable_Grid2';
import { ExportModuleForm } from "../../components";

export const ExportModule = () => {
    return (
        <>
           <ExportModuleForm />
        </>
    )

}