import React, { FunctionComponent, useState } from "react";
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    DragEndEvent
} from "@dnd-kit/core";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy,
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
    Stack,
    Typography,
    Checkbox,
    SvgIcon,
    Grid
} from "@mui/material";
import { mdiDragHorizontalVariant } from "@mdi/js";
import { Icon } from "@mdi/react";
import { ISortableItem } from "./interfaces"
import { IColumn } from "../../types/Column"
import { IProps } from "./IProps";

const SortableItem: FunctionComponent<ISortableItem> = (props): JSX.Element => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition
    } = useSortable({
        id: props.id,
        transition: {
            duration: 150,
            easing: "cubic-bezier(0.25, 1, 0.5, 1)"
        }
    });

    const styles: React.CSSProperties = {
        width: "100%",
        transform: CSS.Transform.toString(transform),
        transition: transition
    }

    return (
        <Grid ref={setNodeRef} style={styles} xs={12} sm={6} md={4} xl={2} columnSpacing={0} rowSpacing={0}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" width={"100%"} height={35} borderRadius="8px" border={1} marginTop={1} marginBottom={1} borderColor="rgba(0, 0, 0, 0.12)" >
                <Stack direction="row" alignItems="center" >
                    <Checkbox value={props.id} checked={props.isChecked} onChange={(event) => props.onChange!(event, props.isChecked!)} />
                    <Typography sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 14 }}>{props.title}</Typography>
                </Stack>
                <SvgIcon {...attributes} {...listeners} className="drag-handler" htmlColor="rgba(0, 0, 0, 0.4)" sx={{ marginRight: 1, cursor: "grab", outline: "none" }}>
                    <Icon path={mdiDragHorizontalVariant} />
                </SvgIcon>
            </Stack>
        </Grid>
    );
}

export const SortableContainer: FunctionComponent<IProps> = (props): JSX.Element => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={props.onDragEnd}
        >
            <SortableContext
                items={props.items}
                strategy={rectSortingStrategy}
            >
                <Grid  columnSpacing={{ xs: 0, sm: 2, md: 2 }} rowSpacing={0} container sx={{ width: "100%" }} mt={2} display="flex" wrap="wrap" justifyContent={{ xs: "center", sm: "flex-start" }} >
                    {
                        props.items.map((item, index) => {
                            return (
                                <SortableItem
                                    key={index}
                                    id={item.id}
                                    title={item.column}
                                    isChecked={item.checked}
                                    onChange={(event, isChecked) => props.onChangeChecked(event, isChecked)}
                                />
                            )
                        })
                    }
                </Grid>
            </SortableContext>
        </DndContext>
    )
}