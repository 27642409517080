import { FunctionComponent } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { CustomInput } from "../CustomInput";
import { IProps } from "./IProps";

export const InputGroup: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <Box position="relative" width={210} height={398} sx={{ border: "1px solid #1BA0E9", borderRadius: 4 }}>
            <Typography sx={{ top: -15, left: 10, px: 2, position: "absolute", color: "#1BA0E9", backgroundColor: "white", fontSize: 16, fontWeight: 800 }} >
                { props.title }
            </Typography>
            <Stack direction="column" alignItems="center" justifyContent="center" mt={2} spacing={1} >
                {
                    props.textboxes?.map((input): JSX.Element => {
                        return (
                            <CustomInput { ...input } width={150} height={35} />
                        )
                    })
                }
            </Stack>
        </Box>
    )
}