import { createTheme, Theme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { Components } from "@mui/material/styles/components";
import {  } from "@mui/material/styles/cssUtils"

const typography: TypographyOptions = {
    fontFamily: `"Lato", "Roboto", "Helvetica", "Arial"`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightMedium: 400,
    fontWeightBold: 500
};

const components: Components = {
    MuiCard: {
        styleOverrides: {
            root: {
                backgroundColor: "rgba(255, 255, 255, 0.8)",
                boxShadow: "0px 2px 4px rgba(40, 72, 154, 0.06), 0px 5px 20px rgba(40, 72, 154, 0.04), 0px 2px 6px rgba(40, 72, 154, 0.04)"   
            }
        }
    }
}

export const AppTheme: Theme = createTheme({
   typography,
   components
})