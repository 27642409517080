import * as React from 'react';
import SvgIcon from '@mui/icons-material/Feedback';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import ReportIcon from '@mui/icons-material/Report';
import WarningIcon from '@mui/icons-material/Warning';
import FeedbackIcon from '@mui/icons-material/Feedback';
import { isHTML } from "../../utils/is-html";

interface IProps {
    readonly type: "success" | "warning" | "error";
    readonly content: string
}


export const HomeMessageCard: React.FunctionComponent<IProps> = ({ type, content }): JSX.Element => {
    let severity: string;
    let MessageIcon: React.ReactNode;

    switch (type) {
        case "warning": {
            severity = "#ffeb3b";
            MessageIcon = <WarningIcon />;

            break;
        }

        case "error": {
            severity = "#d50000";
            MessageIcon = <ReportIcon />;

            break;
        }

        default: {
            severity = "#1BA0E9";
            MessageIcon = <FeedbackIcon />;
        
            break;
        }
    }

    return (
        <Box display="flex" width="100%" position="relative" sx={{ border: 4, borderColor: severity }}>
            <Box position="sticky" display="flex" alignItems="center" justifyContent="center" top={0} left={0} bottom={0} px={4} sx={{ backgroundColor: severity, color: "white" }}  >
                { MessageIcon }
            </Box>
            <Box px={2} >
                {isHTML(content) ? <div dangerouslySetInnerHTML={{ __html: content }} /> : <Typography>
                    { content }
                </Typography>}
            </Box>
        </Box>
    )
}