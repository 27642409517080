import { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom"
import { styled, alpha } from "@mui/material/styles";
import { Menu, MenuItem, ListItemIcon, Typography, Divider, SvgIcon } from "@mui/material";
import { MenuProps } from "@mui/material/Menu";
import { IProps } from "./IProps";
import { NestedMenuItem } from 'mui-nested-menu';
import { mdiPlusThick } from "@mdi/js";
import AddIcon from '@mui/icons-material/Add';
import AddSharpIcon from '@mui/icons-material/AddSharp';



//


const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    width: 260,
    color: "#00000099",
    fontWeight: 600,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));


export const CustomMenu: FunctionComponent<IProps> = (props): JSX.Element => {
  return (
    <StyledMenu {...props} >
      {
        props.links?.map((link, i) => {
          const { Icon, label, href } = link;

          return (
            <Link key={i} to={href!} style={{ textDecoration: "none", color: "inherit" }} >
              <MenuItem key={i} sx={{ my: 1 }} >
                {Icon && (
                  <ListItemIcon>
                    <SvgIcon>
                      {Icon}
                    </SvgIcon>
                  </ListItemIcon>
                )}
                <Typography sx={{ fontWeight: 600 }} >
                  {label}
                </Typography>
              </MenuItem>
            </Link>
          )
        })
      }
      { props.links?.length && <Divider sx={{ my: 0.5 }} /> }
      {
        props.funcLinks?.map((link, i) => {
          const { Icon, label, onClick } = link;

          return (
            <MenuItem key={i} sx={{ my: 1 }} onClick={onClick} >
              {Icon && (
                <ListItemIcon>
                  <SvgIcon>
                    {Icon}
                  </SvgIcon>
                </ListItemIcon>
              )}
              <Typography sx={{ fontWeight: 600 }} >
                {label}
              </Typography>
            </MenuItem>
          )
        })
      }

      {
        props.nestedLinks?.map((link, i) => {
          const { Icon, label, onClick } = link;
          const [anchorEl, setAnchorEl] = useState(null);
          const open = Boolean(anchorEl);
          const handleClose = () => setAnchorEl(null);

          return (
            <NestedMenuItem
              parentMenuOpen={!open}
              label={label}
              nonce
              leftIcon={Icon}
              key={i} 
              sx={{ my: 1, ml: 1.7, fontWeight:1000 }}
              onClick={handleClose}
            >
              {
                link.sublinks.map((subLink, index) => {
                  
                  return (
                    <MenuItem onClick={() => subLink.onClick!(props.reportId)} key={index} >
                      <ListItemIcon>
                        <SvgIcon>
                          {subLink.Icon}
                        </SvgIcon>
                      </ListItemIcon>
                      <Typography sx={{ fontWeight: 600 }} >
                        {subLink.label}
                      </Typography>
                    </MenuItem>
                  )
                })
              }
              <Divider sx={{ my: 0.5 }} />
              {
                link.subFuncLinks.map((item, index) => {
                  return (
                    <MenuItem onClick={onClick} key={index}>
                      <ListItemIcon>
                        <SvgIcon>
                          {item.Icon}
                        </SvgIcon>
                      </ListItemIcon>
                      <Typography sx={{ fontWeight: 600 }} >
                        {item.label}
                      </Typography>
                    </MenuItem>
                  )
                })
              }
            </NestedMenuItem>

          )
        })
      }


    </StyledMenu>
  )
}