import React, { useEffect, useState } from "react";
import { HubConnection, HubConnectionBuilder, ILogger } from "@microsoft/signalr"
import { LPLHttpNotificationClient } from "../../signalR/lpl-notification-http"
import { LPLNotification } from "../../types/LPLNotification";
import { BASE_URL, getAxios } from "../../utils/endpoints";


export const useUserProfileNav = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [connection, setConnection] = useState<null | HubConnection>(null);
    const [notifications, setNotifications] = useState<LPLNotification[]>([])

    const open = Boolean(anchorEl);
   
    const logger: ILogger = {
        log: (logLevel, message) => {
            console.log(`log level: ${logLevel} and message: ${message}`);
        }
    }

    const url: string = `${BASE_URL}/signalr`

    const onClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    }

    const onClose = () => {
        setAnchorEl(null);
    }

    const onReset = () => {
        const readNotifications = notifications.map((notification) => {
            
            return notification;
        });
        setNotifications(readNotifications);
    }

    const onGetNotificationsHistory = async (): Promise<void> => {
        const response = await getAxios("/notifications/history", "GET");
        const notifications: LPLNotification[] = await response.data;

        console.log(notifications);

        setNotifications(notifications);
    }

    const onAcknowledgeNotification = async (id: number) => {
        await getAxios(`/notifications/acknowledge/${id}`, "POST")
        .then(async () => {
            await onGetNotificationsHistory();
        })
    }

    useEffect(() => {
        const connect = new HubConnectionBuilder()
        .withUrl(url, { httpClient: new LPLHttpNotificationClient(logger) })
        .withAutomaticReconnect()
        .build();

        setConnection(connect);
    }, []);

    useEffect(() => {
        connection
            ?.start()
            .then(() => {
                connection?.on("notification", (newNotification) => {
                    console.log("New Notification: ", newNotification);
                
                    setNotifications((prevState) => [JSON.parse(newNotification as string), ...prevState]);
                })
            })
            .catch((error) => {
                console.log(error);
            })
    }, [connection]);

    useEffect(() => {
        onGetNotificationsHistory();
    }, [])


    return {
        open,
        anchorEl,
        notifications,
        onGetNotificationsHistory,
        onAcknowledgeNotification,
        onClick,
        onClose,
        onReset
    }
}