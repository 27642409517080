import { Account } from "../types/Account";
import { Role } from "../types/Role";
import { PageAccess } from "../types/PageAccess";
import { User as IUser } from "../types/User";
import { getAxios, route } from "../utils/endpoints";
import { HomeMessage } from "../types/HomeMessage";

export class User {
    private readonly currentUser: IUser = JSON.parse(sessionStorage.getItem("user")!);
    private userAccount: string | null = sessionStorage.getItem("user-account");

    public get Id(): number {
        return this.currentUser.id;
    }

    public get Accounts(): Account[] {
        return this.currentUser.accounts;
    }

    public get CompanyName(): string {
        return this.currentUser.companyName;
    }

    public get ContactPhone(): string {
        return this.currentUser.contactPhone;
    }

    public get Email(): string {
        return this.currentUser.email;
    }

    public get FirstName(): string {
        return this.currentUser.firstName;
    };

    public get LastName(): string {
        return this.currentUser.lastName;
    }

    public get LpDatabase(): string {
        return this.currentUser.lpDatabase;
    }

    public get LpProductID(): number | null {
        return this.currentUser.lpProductID;
    }

    public get LpServer(): string {
        return this.currentUser.lpServer;
    }

    public get LpSource(): string {
        return this.currentUser.lpSource;
    }

    public get LpUrl(): string {
        return this.currentUser.lpUrl;
    }

    public get PrimaryContact(): string {
        return this.currentUser.primaryContact;
    }

    public get ProductID(): number | null {
        return this.currentUser.productID;
    }

    public get SecLevel(): number {
        return this.currentUser.secLevel;
    }

    public get ServerPath(): string {
        return this.currentUser.serverPath;
    }

    public get UserName(): string {
        return this.currentUser.userName;
    }

    public get Roles(): Role[] {
        return this.currentUser.roles;
    }

    public get HomeMessages(): HomeMessage[] {
        return this.currentUser.homeMessages !== null ? this.currentUser.homeMessages : [];
    }

    public get Exists(): boolean {
        return sessionStorage.getItem("user") ? true : false;
    }

    public get UserAccount(): string {
        return this.userAccount!;
    }

    public get DisplayUserName(): string {
        if (this.Email === "") {
            return this.PrimaryContact;
        }

        return this.Email;
    }

    public set UserAccount(account: string) {
        sessionStorage.setItem("user-account", account);

        this.userAccount = sessionStorage.getItem("user-account");
    }

    public async Refresh(): Promise<void> {
        const response = await getAxios(route.apiUsers.me, "GET");

        const data: IUser = await response.data;

        this.SaveSession(data);

        console.log("===== EMAIL =====")
        console.log(this.currentUser.email);
    }

    public SaveSession(user: IUser): void {
        sessionStorage.setItem("user", JSON.stringify(user));
    }

    public removeUserAccount() {
        sessionStorage.removeItem("user-account");
    }

    public RemoveSession(): void {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("token");
    }

    public HasForbiddenAccess(itemName: string): boolean {
        const access = this.currentUser.pageAccesses.find((access: PageAccess) => access.itemName == itemName);
        if (access) {
            return !access.hasAccess;
        }

        // If no page access is found, it has access by default
        return false;
    }
}