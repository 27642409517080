import {
    Typography,
    CircularProgress,
    MenuItem,
    Box,
    Card,
    CardContent,
    SvgIcon,
    Stack
} from "@mui/material";
import { LeadBox, CustomSelect, LeadGrid, CustomDatePicker, Button } from "../../components";
import { useHome } from "./hook";
import { User } from "../../model/User";
import Grid from '@mui/material/Unstable_Grid2';
import FormatAlignLeftOutlinedIcon from "@mui/icons-material/FormatAlignLeftOutlined";
import { useNavigate } from "react-router-dom";
import { HomeMessageCard } from "../../components/HomeMessageCard";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { isHTML } from "../../utils/is-html";

export const Home = () => {
    const {
        callLeads,
        dashboardValue,
        dealerId,
        metrics,
        orderBy,
        selectedDashboard,
        page,
        pageSize,
        sortModel,
        total,
        dashboards,
        isLeadsLoading,
        isMetricsLoading,
        hideMetrics,
        hideTables,
        moveToDashboard,
        onChange,
        onChangeOrder,
        onPageChange,
        onPageSizeChange
    } = useHome();

    const user = new User();
    const navigate = useNavigate();

    const DealerSelect = () => {
        return (
            <Box mt={4} >
                <CustomSelect sx={{ width: "100%", height: 35, mb: 2, maxWidth: { md: 300, lg: 300, xl: 300 } }} label="Accounts" value={dealerId} onChange={onChange} >
                    <MenuItem value={"Select an account"}>
                        Select an account
                    </MenuItem>
                    {
                        user.Accounts.map((account) => {
                            return (
                                <MenuItem key={account.dealerId} value={account.dealerId}>
                                    {account.companyName}
                                </MenuItem>
                            )
                        })
                    }
                </CustomSelect>
            </Box>
        )
    }

    const HomeMessageList = () => {
        return <Stack mt={2} direction="column" spacing={2}>
            {
                user.HomeMessages.map((message, index) => {
                    return (
                        <HomeMessageCard key={index} type={message.type} content={message.message} />
                    )
                })
            }
        </Stack>
    }

    return (
        <>
            <Grid container sx={{ marginBottom: 2 }}>
                <Grid xs={12} lg={12}>
                    <Typography textAlign={{ sm: "center", md: "start", lg: "start" }} variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25 }} >
                        Hello {user.DisplayUserName}!
                    </Typography>
                    { !!user.HomeMessages.length && <HomeMessageList /> }
                </Grid>
                {user.Roles[0].secLevel !== 1 && !(hideMetrics && hideTables) &&
                    <Typography textAlign={{ sm: "center", md: "start", lg: "start" }} variant="body1" sx={{ color: "#12253A", mt: 2, fontSize: 14 }} >
                        You have been granted access to multiple accounts. To work with one specific account at a time, please choose one from the list below:
                    </Typography>
                }
            </Grid>
            {!(hideMetrics && hideTables) &&
                <Grid xs={12} sm={12} md={4} sx={{ width: "100%" }} textAlign={{ sm: "center", md: "start", lg: "start" }}>
                    {(user.Roles[0].secLevel !== 1) && <DealerSelect />}
                </Grid>
            }

            {isMetricsLoading && !(hideMetrics && hideTables) &&
                <Grid sx={{ marginBottom: 3, width: "100%", height: 165, display: "flex", alignItems: "center", justifyContent: "center", alignSelf: "center", justifySelf: "center" }} container spacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 15 }} >
                    <Grid xs={4} sm={4} md={4} lg={3} xl={3} >
                        <CircularProgress />
                    </Grid>
                </Grid>
            }

            {!isMetricsLoading && !hideMetrics &&
                <>
                    <Grid sx={{ marginBottom: 3, width: "100%" }} container spacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} columns={{ xs: 4, sm: 8, md: 12, lg: 12, xl: 15 }}>
                        {metrics.map((metric, index): JSX.Element => {
                            return (
                                <Grid xs={4} sm={4} md={4} lg={3} xl={3} >
                                    <LeadBox index={index} key={metric.id} label={metric.description} icon={metric.icon} data={metric.value} />
                                </Grid>
                            )
                        })}
                    </Grid>
                </>
            }

            {!hideTables &&
                <Grid container sx={{ width: "100%" }} columns={{ xs: 12, sm: 8, md: 12, lg: 12, xl: 15 }}>
                    <Grid xs={12} sm={8} md={12} lg={12} xl={15}>
                        <Card sx={{ borderRadius: 4 }}>
                            <CardContent>
                                <Grid container direction="row" justifyContent="flex-start">
                                    {
                                        dashboards.map((val, index) => {
                                            return (
                                                <Grid md={3} lg={2} xl={2} onClick={() => moveToDashboard(val.id)}>
                                                    <Typography
                                                        sx={{
                                                            color: "#1BA0E9",
                                                            fontSize: 14,
                                                            fontWeight: 600,
                                                            borderRadius: (dashboardValue === val.id) ? 2 : 0,
                                                            py: 1,
                                                            backgroundColor: (dashboardValue === val.id) ? "rgba(0, 0, 0, 0.07)" : "inherit",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {val.description}
                                                    </Typography>
                                                </Grid>
                                            )
                                        })
                                    }
                                </Grid>
                                <Grid>
                                    <LeadGrid
                                        partialColumns={callLeads.length ? Object.keys(callLeads[0]) : []}
                                        renderRefresh
                                        sortModel={sortModel}
                                        loading={isLeadsLoading}
                                        callLeads={selectedDashboard ? selectedDashboard.items : []}
                                        page={page}
                                        pageSize={pageSize}
                                        total={selectedDashboard ? selectedDashboard.quantity : 0}
                                        onPageChange={onPageChange}
                                        onPageSizeChange={onPageSizeChange}
                                        onSortModelChange={(newSortModel) => onChangeOrder(newSortModel)}
                                    />
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            }

        </>
    )
}