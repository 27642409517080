import { SelectChangeEvent } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { Metrics } from "../../types/Metrics";
import { CallLead } from "../../types/CallLead";
import { route, getAxios } from "../../utils/endpoints"
import { User } from "../../model/User"
import { IState, IPaginationState } from "./IState";
import { GridSortModel } from "@mui/x-data-grid";
import { formatDateRange } from "../../utils/time";

type DropdownItem = {
    id: string;
    description: string;
}

interface IDashboardTable {
    id: number;
    description: string;
    items: any[];
    quantity: number;
    sortOrder: number;
}

export const useHome = () => {
    const user = new User();
    const hideMetrics = user.HasForbiddenAccess("Can Read Metrics");
    const hideTables = user.HasForbiddenAccess("Can Read Dashboard Tables");

    const [dashboards, setDashboards] = useState<IDashboardTable[]>([]);
    const [selectedDashboard, setSelectedDashboard] = useState<IDashboardTable | undefined>();
    const [dashboardValue, setDashboardValue] = useState<number>(0);
    const [dealerId, setDealerId] = useState<string>("Select an account");
    const [metrics, setMetrics] = useState<Metrics[]>([]);
    const [callLeads, setCallLeads] = useState<CallLead[]>([]);
    const [isLeadsLoading, setIsLeadsLoading] = useState<boolean>(true);
    const [isMetricsLoading, setIsMetricsLoading] = useState<boolean>(true);

    const [pageSize, setPageSize] = useState<number>(25);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [orderBy, setOrderBy] = useState<string>("");
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: "leadDate", sort: "asc" }]);


    const onPageChange = (newPage: number) => {
        setPage(newPage);
    }

    const onPageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    }


    const onChangeOrder = (newSortModel: GridSortModel) => {
        const currentField = newSortModel[0].field === "caller" ? "LastName" : newSortModel[0].field;

        setSortModel(newSortModel);
        setOrderBy(`${currentField} ${newSortModel[0].sort}`);
    }

    const onChange = (event: SelectChangeEvent<unknown>) => {
        const { value } = event.target

        if ((value as string) === "Select an account") {
            user.removeUserAccount();

            setDealerId("Select an account");

            return;
        }

        user.UserAccount = value as string;

        setDealerId(value as string);
    }

    const fetchMetrics = useCallback(async () => {
        setIsMetricsLoading(true);

        try {
            const response = await getAxios(route.apiDashboard.metrics, "GET", undefined, {
                userAccount: user.UserAccount
            });

            const resultedMetrics: Metrics[] | string = response.data;

            if (typeof resultedMetrics === "string") {
                return;
            }

            setMetrics(resultedMetrics);
        } catch (error) {
            console.error(error);
        }

        setIsMetricsLoading(false);
    }, [user.UserAccount])

    const fetchDashboardTables = async () => {
        setIsLeadsLoading(true);

        try {
            const response = await getAxios("/dashboard-tables", "GET", undefined, {
                titlesOnly: false,
                pageSize: pageSize
            });

            const dashboardTitles: IDashboardTable[] = await response.data;
            setDashboards(dashboardTitles);


            setDashboardValue(dashboardTitles[0].id);
            setSelectedDashboard({
                description: dashboardTitles[0].description,
                id: dashboardTitles[0].id,
                items: dashboardTitles[0].items,
                quantity: dashboardTitles[0].quantity,
                sortOrder: dashboardTitles[0].sortOrder
            });


        } catch (error) {
            console.error(error);
        }

        setIsLeadsLoading(false);
    }

    const moveToDashboard = (value: number) => {
        setDashboardValue(value);

        const dashboard = dashboards.find((val) => val.id === value);

        if (dashboard) {
            setSelectedDashboard(dashboard);
        }
    }

    useEffect(() => {
        if(!hideMetrics) fetchMetrics();
        if(!hideTables) fetchDashboardTables();
    }, [fetchMetrics, page, pageSize, orderBy]);

    return {
        dealerId,
        metrics,
        total,
        sortModel,
        callLeads,
        dashboardValue,
        dashboards,
        selectedDashboard,
        page,
        pageSize,
        orderBy,
        isLeadsLoading,
        isMetricsLoading,
        hideMetrics,
        hideTables,
        onPageChange,
        onPageSizeChange,
        onChange,
        onChangeOrder,
        moveToDashboard,
    };
}