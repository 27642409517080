import React, { useState, useEffect, ElementRef } from "react";
import { useNavigate } from "react-router-dom";
import { GridSortModel, GridColumns, GridRowsProp, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid"
import { FilterDetail, ItemDetail, ReportDetail } from "./interfaces"
import { Box, SelectChangeEvent, Typography } from "@mui/material";
import {  formatDateRange, getHour } from "../../utils/time";
import { route, getAxios, BASE_URL, OCP_APIM_SUBSCRIPTION_KEY } from "../../utils/endpoints";
import { toLowerCaseObject } from "../../utils/to-lowercase-object";
import dayjs, { Dayjs } from "dayjs"
import { DateRange } from "@mui/x-date-pickers-pro";
import { StepThree } from "../../components/NewReportForm/StepThree";
import { User } from "../../model/User";

interface JSONBody {
    reportId?: number;
    reportType: string;
    folderId: number;
    reportTitle: string;
    description: string;
    shortDescription: string;
    sendEmailNotification: boolean;
    emailsToNotify: string[];
    fileFormat: string;
    selectedColumnsIds: number[];
    selectedFiltersIds: number[];
    filterDetails: any;
    groupBy: number[];
    subGroupBy: number[];
    isScheduled: boolean;
    timeInterval: string;
    timeZone: string;
    frequency: number;
    time: string;
    dayPreference: string;
    startingDate: Date | null;
    endingDate: Date | null;
    id?: number;
}

export interface IFilter {
    filterId: number,
    selectedItems: {
        value: string;
        description: string
    }[];
}

export interface IFilterDate {
    filterId: number,
    selectedItems: DateRange<Dayjs>
}

interface IFilterState {
    inquiry_date: IFilterDate,
    appt_date: IFilterDate,
    campaign: IFilter,
    sub_campaign: IFilter,
    lead_type: IFilter,
    dealer: IFilter,
    lead_status: IFilter,
    sales_status: IFilter,
    state: IFilter,
    product: IFilter,
}

export interface IPermissions {
    hideReadReportsHome: boolean,
    hideEditCurrentFolder: boolean,
    hideEditFavorite: boolean,
    hideEditBulkFavorite: boolean,
    hideDeleteBulkFavorite: boolean,
    hideReadFolders: boolean,
    hideEditFolders: boolean,
    hideAddFolders: boolean,
    hideDeleteFolders: boolean,
    hideReadCustomReportSpecs: boolean,
    hideEditCustomReportSpecs: boolean,
    hideAddCustomReportSpecs: boolean,
    hideDeleteCustomReportSpecs: boolean,
    hideReadCustomReportData: boolean,
    hideAddClonedReport: boolean,
    hideEditSchedule: boolean,
    hideDeleteBulkReports: boolean,
    hideExportReports: boolean,
}

export const useReportDetail = (id: string) => {
    const navigate = useNavigate();

    //permissions
    const user = new User();
    const permissions: IPermissions = {
        hideReadReportsHome: user.HasForbiddenAccess("Can Read Reports Home"),
        hideEditCurrentFolder: user.HasForbiddenAccess("Can Edit Current Folder"),
        hideEditFavorite: user.HasForbiddenAccess("Can Edit Favorite"),
        hideEditBulkFavorite: user.HasForbiddenAccess("Can Edit Bulk Favorite"),
        hideDeleteBulkFavorite: user.HasForbiddenAccess("Can Delete Bulk Favorite"),
        hideReadFolders: user.HasForbiddenAccess("Can Read Folders"),
        hideEditFolders: user.HasForbiddenAccess("Can Edit Folders"),
        hideAddFolders: user.HasForbiddenAccess("Can Add Folders"),
        hideDeleteFolders: user.HasForbiddenAccess("Can Delete Folders"),
        hideReadCustomReportSpecs: user.HasForbiddenAccess("Can Read Custom Report Specs"),
        hideEditCustomReportSpecs: user.HasForbiddenAccess("Can Edit Custom Report Specs"),
        hideAddCustomReportSpecs: user.HasForbiddenAccess("Can Add Custom Report Specs"),
        hideDeleteCustomReportSpecs: user.HasForbiddenAccess("Can Delete Custom Report Specs"),
        hideReadCustomReportData: user.HasForbiddenAccess("Can Read Custom Report Data"),
        hideAddClonedReport: user.HasForbiddenAccess("Can Add Cloned Report"),
        hideEditSchedule: user.HasForbiddenAccess("Can Edit Schedule"),
        hideDeleteBulkReports: user.HasForbiddenAccess("Can Delete Bulk Reports"),
        hideExportReports: user.HasForbiddenAccess("Can Export Reports"),
    };

    //dialogs and snackbars
    const [open, setOpen] = useState<boolean>(false);
    const [scheduleOpen, setScheduleOpen] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    //Datagrid data
    const [reports, setReports] = useState<any>([]);
    const [downloadMenuAnchorEl, setDownloadMenuAnchorEl] = useState<null | HTMLElement>(null);
    const downloadOpen = Boolean(downloadMenuAnchorEl);

    const [columns, setColumns] = useState<ItemDetail[]>([]);
    const [gridColumns, setGridColumns] = useState<GridColumns>([]);
    const [rows, setRows] = useState<GridRowsProp>([]);

    const [pageSize, setPageSize] = useState<number>(5);
    const [page, setPage] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [orderBy, setOrderBy] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //page data
    const [filters, setFilters] = useState<FilterDetail[]>([]);

    const [description, setDescription] = useState<string>("");
    const [reportTitle, setReportTitle] = useState<string>("");
    const [reportType, setReportType] = useState<string>("")
    const [folderId, setFolderId] = useState<number>(0);
    const [groupBy, setGroupBy] = useState<number[]>([]);
    const [subGroupBy, setSubGroupBy] = useState<number[]>([]);

    //schedule information
    const [time, setTime] = useState<string>("");
    const [timeInterval, setTimeInterval] = useState<string>("");
    const [timeZone, setTimeZone] = useState<string>("");
    const [isReportScheduled, setIsReportScheduled] = useState<boolean>(false);
    const [sendEmailNotification, setSendEmailNotification] = useState<boolean>(false);
    const [frequency, setFrequency] = useState<number>(0);
    const [dayPreference, setDayPreference] = useState<string>("");
    const [fileFormat, setFileFormat] = useState<string>("");
    const [emailInputs, setEmailInputs] = useState<{ name: string }[]>([{ name: "email0" }])

    const [scheduleState, setScheduleState] = useState<any>({
        at: null,
        emails: {
            email0: "",
            email1: "",
            email2: "",
            email3: ""
        },
        endDate: new Date(),
        every: "",
        fileFormat: "",
        isScheduled: false,
        startDate: new Date(),
        timeInterval: "",
        timeZone: "",
        on: "",
        emailInputs: [{ name: "email0" }]
    });

    //Filters State 
    const [filterState, setFilterState] = useState<IFilterState>({
        inquiry_date: {
            filterId: 0,
            selectedItems: [null, null]
        },
        appt_date: {
            filterId: 0,
            selectedItems: [null, null]
        },
        campaign: {
            filterId: 0,
            selectedItems: []
        },
        sub_campaign: {
            filterId: 0,
            selectedItems: []
        },
        lead_type: {
            filterId: 0,
            selectedItems: []
        },
        dealer: {
            filterId: 0,
            selectedItems: []
        },
        lead_status: {
            filterId: 0,
            selectedItems: []
        },
        sales_status: {
            filterId: 0,
            selectedItems: []
        },
        state: {
            filterId: 0,
            selectedItems: []
        },
        product: {
            filterId: 0,
            selectedItems: []
        },
    })

    const [responseStatus, setResponseStatus] = useState({
        severity: "",
        message: ""
    });

    const [warningModalOpen, setWarningModalOpen] = useState<boolean>(false);

    const onChange = (event: SelectChangeEvent<unknown>) => {
        setScheduleState((prevState: any) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const onChangeFilterSelect = (event: React.SyntheticEvent<Element, Event>, value: ItemDetail[], name: string) => {
        setFilterState((prevState) => ({
            ...prevState,
            [name]: {
                filterId: (filterState[name as keyof typeof filterState] as IFilter).filterId,
                selectedItems: value 
            }
        }));
    }

    const generateEmailTextfield = () => {
        if (scheduleState.emailInputs.length >= 4) {
            return;
        }

        console.log(`email${scheduleState.emails[`${emailInputs.length}` as keyof typeof scheduleState.emails]}`)

        setEmailInputs((prevState) => [...prevState, { name: `email${scheduleState.emails[`${emailInputs.length}` as keyof typeof scheduleState.emails]}` }])
        setScheduleState((prevState: any) => ({
            ...prevState,
            emailInputs: [
                ...prevState.emailInputs,
                { name: `email${scheduleState.emails[`${emailInputs.length}` as keyof typeof scheduleState.emails]}` }
            ]
        }))
    }

    const onChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScheduleState((prevState: any) => ({
            ...prevState,
            fileFormat: (event.target as HTMLInputElement).value
        }))
    }

    const onChangeDate = (newDate: Date, name: string) => {
        setScheduleState((prevState: any) => ({
            ...prevState,
            [name]: newDate
        }));
    }

    const onChangeDateRange = (newDate: DateRange<Dayjs>, name: string) => {
        setFilterState((prevState: any) => ({
            ...prevState,
            [name]: {
                filterId: (filterState[name as keyof typeof filterState] as IFilterDate).filterId,
                selectedItems: newDate
            }
        }));
    }

    const mappedGridColumns = (columns: ItemDetail[]): void => {
        const newColumns: GridColumns = columns.map((column) => {
            return {
                field: column.description.toLowerCase().replaceAll(" ", "_"),
                headerName: column.description,
                type: "text",
                align: "center",
                headerAlign: "center",
                minWidth: 75,
                editable: true,
                sortable: true,
                disableReorder: true,
                flex: 1,
                renderHeader: (params: GridColumnHeaderParams) => {
                    return (
                        <Typography>
                            {params.colDef.headerName}
                        </Typography>
                    )
                },
                renderCell: (params: GridRenderCellParams) => {
                    const date = new Date(params.value);

                    if (!params.value) {
                        return (
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 14, fontWeight: 600 }}>
                                n/a
                            </Typography>
                        )
                    }


                    return (
                        <Typography sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 14, fontWeight: 600 }}>
                            {params.value}
                        </Typography>
                    )
                }
            }
        });

        setGridColumns(newColumns);
    }

    const onChangeOrder = (newSortModel: GridSortModel): void => {
        setOrderBy(`${newSortModel[0].field} ${newSortModel[0].sort}`);
    }

    const onPageChange = (newPage: number): void => {
        setPage(newPage);
    }

    const onPageSizeChange = (newPageSize: number): void => {
        setPageSize(newPageSize);
    }

    const onGenerateReportData = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const emails: string[] = []

            for (const key in scheduleState.emails) {
                if (
                    scheduleState.emails[key as keyof typeof scheduleState.emails] !== ""
                ) {
                    emails.push(scheduleState.emails[key as keyof typeof scheduleState.emails]);
                }
            }

            const valArr = [];

            if (filterState.inquiry_date.selectedItems.length) {
                if (filterState.inquiry_date.selectedItems[0] !== null) {
                    const dates = formatDateRange(filterState.inquiry_date.selectedItems[0].toDate(), filterState.inquiry_date.selectedItems[1]!.toDate());
                
                    valArr.push({ filterId: filterState.inquiry_date.filterId, selectedItems: [dates[0], dates[1]] })
                }
            }

            if (filterState.appt_date.selectedItems.length) {
                if (filterState.appt_date.selectedItems[0] !== null) {
                    const dates = formatDateRange(filterState.appt_date.selectedItems[0].toDate(), filterState.appt_date.selectedItems[1]!.toDate());
                
                    valArr.push({ filterId: filterState.appt_date.filterId, selectedItems: [dates[0], dates[1]] })
                }
                
            }

            for (const key in filterState) {
                if (
                    (key !== "inquiry_date") &&
                    (key !== "appt_date")
                ) {
                    if ((filterState[key as keyof typeof filterState] as IFilter).selectedItems.length) {
                        const values = (filterState[key as keyof typeof filterState] as IFilter).selectedItems.map((val) => val.value);
                        valArr.push({ filterId: (filterState[key as keyof typeof filterState] as IFilter).filterId, selectedItems: values })
                    }
                }
            }


            const JsonBody: JSONBody = {
                reportId: parseInt(id),
                reportType: reportType,
                folderId: folderId,
                reportTitle: reportTitle,
                description: description,
                shortDescription: "",
                sendEmailNotification: scheduleState.isScheduled,
                emailsToNotify: scheduleState.isScheduled ? emails.filter((val) => (val !== "" && typeof val !== "undefined")) : [],
                fileFormat: scheduleState.fileFormat,
                selectedColumnsIds: columns.map((column) => parseInt(column.value)),
                selectedFiltersIds: filters.map((filter) => parseInt(filter.filterId)),
                filterDetails: valArr,
                groupBy: groupBy,
                subGroupBy: subGroupBy,
                isScheduled: scheduleState.isScheduled,
                timeInterval: scheduleState.isScheduled ? scheduleState.timeInterval : "",
                timeZone: scheduleState.isScheduled ? scheduleState.timeZone : "",
                frequency: scheduleState.isScheduled ? parseInt(scheduleState.every) : 0,
                time: scheduleState.isScheduled ? `${scheduleState.at.$H}:${scheduleState.at.$m}` : "",
                dayPreference: scheduleState.isScheduled ? scheduleState.on as string : "",
                startingDate: scheduleState.isScheduled ? scheduleState.startDate : new Date(),
                endingDate: scheduleState.isScheduled ? scheduleState.endDate : new Date()
            }

            const response = await fetch(`${BASE_URL}/reports/custom/${id}/data`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY
                },
                body: JSON.stringify({
                    ReportJSON: JSON.stringify(JsonBody),
                    startindex: pageSize * page,
                    pagesize: pageSize,
                    sortorder: orderBy
                })
            })

            
            const data = await response.json();

            const rows = await data.map((val: any) => {
                return toLowerCaseObject(val)
            });

            console.log(rows);

            setRows(rows);
            setTotal(rows[0].total_record_count);

            
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    const onFetchAllFiltersValues = async () => {
        const response = await getAxios(route.apiReport.filter.filters, "GET");

        const fetchedFilter = await response.data;

        const mappedFilters: IFilter[] = fetchedFilter.map((val: any) => {
            let selectedItems = [];

            if (val.values !== null) {
                selectedItems = val.values.map((item: any) => {
    
                    return { value: item.id, description: item.description }
                });                
            }

            return {
                filterId: val.id,
                selectedItems: selectedItems
            }
        });

        return mappedFilters;
    }

    const onFetchReportData = async (): Promise<void> => {
        try {
            const response: any = await getAxios(`/reports/custom/${id}`, "GET");

            const data = await response.data as ReportDetail;

            if (!data) {
                navigate("/resource_not_found");
            }

            const allFilters = await onFetchAllFiltersValues();

            const fetchedColumns: ItemDetail[] = data.columnDetails;
            const filters: FilterDetail[] = data.filterDetails.map((filter) => {
                filter.selectedItems = filter.selectedItems === null ? [] : filter.selectedItems;

                return filter;
            });

            const inquiryDates = filters.find((val) => val.description === "Inquiry Date");
            const apptDates = filters.find((val) => val.description === "Appt Date");

            const groupByValues = data.groupBy.map((i) => parseInt(i.value))
            const subGroupByValues = data.subGroupBy.map((i) => parseInt(i.value));

            setDescription(data.description);
            setReportTitle(data.reportTitle);
            setReportType(data.reportType);
            setFolderId(data.folderId);
            setGroupBy(groupByValues)
            setSubGroupBy(subGroupByValues)


            const time = {
                hour: data.time.split(":")[0],
                minute: data.time.split(":")[1]
            }

            setScheduleState((prevState: any) => ({
                ...prevState,
                every: data.frequency.toString(),
                emails: {
                    email0: data.emailsToNotify[0],
                    email1: data.emailsToNotify[1],
                    email2: data.emailsToNotify[2],
                    email3: data.emailsToNotify[3]
                },
                fileFormat: data.fileFormat,
                isScheduled: data.isScheduled,
                timeInterval: data.timeInterval,
                timeZone: data.timeZone,
                on: data.dayPreference,
                startDate: data.startingDate,
                endDate: data.endingDate,
                at: dayjs('2018-01-01').set("hour", parseInt(time.hour)).set("minute", parseInt(time.minute)),
            }));

            console.log("A: ", apptDates);
            console.log("B: ", inquiryDates);

            setFilterState((prevState: any) => ({
                ...prevState,
                appt_date: apptDates === undefined ? { filterId: 0, selectedItems: [null, null] } : {
                    filterId: apptDates!.filterId,
                    selectedItems: apptDates.selectedItems.length ? [dayjs(apptDates!.selectedItems[0].value), dayjs(apptDates!.selectedItems[1].value)] : [null, null]
                },
                inquiry_date: inquiryDates === undefined ? { filterId: 0, selectedItems: [null, null] } : {
                    filterId: inquiryDates!.filterId,
                    selectedItems: inquiryDates.selectedItems.length ? [dayjs(inquiryDates!.selectedItems[0].value), dayjs(inquiryDates!.selectedItems[1].value)] : [null, null]
                }
            }))

            setColumns(fetchedColumns);
            setFilters(filters);

            filters.map((filter, index) => {
                const lowerDescription: string = filter.description.toLocaleLowerCase().replaceAll(" ", "_");

                const matchFilter = allFilters.find((i) => i.filterId === parseInt(filter.filterId));

                if (filter.type === "D") {
                    setFilterState((prevState) => ({
                        ...prevState,
                        [lowerDescription]: {
                            filterId: filter.filterId,
                            selectedItems: filter.selectedItems ? filter.selectedItems : matchFilter!.selectedItems
                        }
                    }));
                }
            })

            mappedGridColumns(columns);
        }

        catch (error) {
            console.error(error);
        }
    }

    const onFetchRows = async (): Promise<void> => {
        setIsLoading(true);

        try {

            const emails: string[] = []

            for (const key in scheduleState.emails) {
                if (
                    scheduleState.emails[key as keyof typeof scheduleState.emails] !== ""
                ) {
                    emails.push(scheduleState.emails[key as keyof typeof scheduleState.emails]);
                }
            }

            const aux = filters.map((val) => val.selectedItems.map((i) => i.value));

            console.log(aux)

            const JsonBody: JSONBody = {
                reportId: parseInt(id),
                reportType: reportType,
                folderId: folderId,
                reportTitle: reportTitle,
                description: description,
                shortDescription: "",
                sendEmailNotification: scheduleState.isScheduled,
                emailsToNotify: scheduleState.isScheduled ? emails.filter((val) => (val !== "" && typeof val !== "undefined")) : [],
                fileFormat: scheduleState.fileFormat,
                selectedColumnsIds: columns.map((column) => parseInt(column.value)),
                selectedFiltersIds: filters.map((filter) => parseInt(filter.filterId)),
                filterDetails: aux,
                groupBy: groupBy,
                subGroupBy: subGroupBy,
                isScheduled: scheduleState.isScheduled,
                timeInterval: scheduleState.isScheduled ? scheduleState.timeInterval : "",
                timeZone: scheduleState.isScheduled ? scheduleState.timeZone : "",
                frequency: scheduleState.isScheduled ? parseInt(scheduleState.every) : 0,
                time: scheduleState.isScheduled ? `${scheduleState.at.$H}:${scheduleState.at.$m}` : "",
                dayPreference: scheduleState.isScheduled ? scheduleState.on as string : "",
                startingDate: scheduleState.isScheduled ? scheduleState.startDate : new Date(),
                endingDate: scheduleState.isScheduled ? scheduleState.endDate : new Date()
            }

            const response = await fetch(`${BASE_URL}/reports/custom/${id}/data`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY
                },
                body: JSON.stringify({
                    ReportJSON: JSON.stringify(JsonBody),
                    startindex: page,
                    pagesize: pageSize,
                    sortorder: orderBy
                })
            })

            const data = await response.json();

            const rows = await data.map((val: any) => {
                return toLowerCaseObject(val)
            });

            console.log(rows);

            setRows(rows);
            setTotal(rows[0].total_record_count);
        }
        catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    const onClickDownloadButton = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDownloadMenuAnchorEl(event.currentTarget)
    }

    const onDownloadFile = async (format: "pdf" | "xlsx" | "csv"): Promise<void> => {
        const response = await getAxios(`/reports/export/${id}?fileFormat=${format}&pageSize=${pageSize}&startIndex=${page}`, "GET");

        const data = await response.data;

        setSnackbarOpen(true);

        setResponseStatus({
            severity: "info",
            message: data.messageDetails
        });
    }

    const onSchedule = async (): Promise<void> => {
        const emails: string[] = []

        if (scheduleState.isScheduled) {
            for (const key in scheduleState) {
                if (typeof scheduleState[key] === "string") {
                    if (scheduleState[key] === "") {
                        setWarningModalOpen(true);
                        return;
                    }
                }
            }
        }

        for (const key in scheduleState.emails) {
            if (
                scheduleState.emails[key as keyof typeof scheduleState.emails] !== ""
            ) {
                emails.push(scheduleState.emails[key as keyof typeof scheduleState.emails]);
            }
        }

        const JsonBody: JSONBody = {
            reportType: reportType,
            folderId: folderId,
            reportTitle: reportTitle,
            description: description,
            shortDescription: "",
            sendEmailNotification: scheduleState.isScheduled,
            emailsToNotify: scheduleState.isScheduled ? emails.filter((val) => (val !== "" && typeof val !== "undefined")) : [],
            fileFormat: scheduleState.fileFormat,
            selectedColumnsIds: columns.map((column) => parseInt(column.value)),
            selectedFiltersIds: filters.map((filter) => parseInt(filter.filterId)),
            filterDetails: filters.filter((val) => (val.selectedItems.length !== 0 && typeof val !== "undefined")),
            groupBy: groupBy,
            subGroupBy: subGroupBy,
            isScheduled: scheduleState.isScheduled,
            timeInterval: scheduleState.isScheduled ? scheduleState.timeInterval : "",
            timeZone: scheduleState.isScheduled ? scheduleState.timeZone : "",
            frequency: scheduleState.isScheduled ? parseInt(scheduleState.every) : 0,
            time: scheduleState.isScheduled ? `${scheduleState.at.$H}:${scheduleState.at.$m}` : "",
            dayPreference: scheduleState.isScheduled ? scheduleState.on as string : "",
            startingDate: scheduleState.isScheduled ? scheduleState.startDate : new Date(),
            endingDate: scheduleState.isScheduled ? scheduleState.endDate : new Date()
        }

        const response = await getAxios(`/reports/custom/schedule/${id}`, "PUT", {
            JSONString: JSON.stringify(JsonBody)
        });

        setScheduleOpen(false);

        setSnackbarOpen(true);
        setResponseStatus({ severity: "info", message: "Report has been scheduled" })
    }

    const onDisableReportScheduled = () => {
        setScheduleState((prevState: any) => ({
            ...prevState,
            isScheduled: !prevState.isScheduled
        }))
    }

    const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
        setScheduleState((prevState: any) => ({
            ...prevState,
            emails: {
                ...prevState.emails,
                [event.target.name]: event.target.value
            }
        }));
    }

    const toggleOpenDrawer = () => {
        setOpen(!open);
    }

    useEffect(() => {
        onFetchReportData();
    }, []);

    useEffect(() => {
        if (folderId !== 0) {
            onGenerateReportData();
        }
    }, [folderId, page, pageSize, orderBy]);

    useEffect(() => {
        mappedGridColumns(columns);
    }, [columns]);

    useEffect(() => {
        let arr: any = [];

        if (scheduleState.emails.email0) {
            for (const key in scheduleState.emails) {
                if (
                    scheduleState.emails[key as keyof typeof scheduleState.emails] !== undefined) {
                    arr.push({ name: key });
                }
            }
        }

        setScheduleState((prevState: any) => ({
            ...prevState,
            emailInputs: arr.length ? arr : prevState.emailInputs
        }));

    }, [scheduleState.emails])

    return {
        scheduleState,
        reports,
        open,
        columns,
        description,
        downloadMenuAnchorEl,
        downloadOpen,
        filters,
        gridColumns,
        rows,
        total,
        sortModel,
        isLoading,
        orderBy,
        page,
        pageSize,
        reportTitle,
        scheduleOpen,
        responseStatus,
        snackbarOpen,
        time,
        timeInterval,
        timeZone,
        dayPreference,
        isReportScheduled,
        sendEmailNotification,
        frequency,
        fileFormat,
        emailInputs,
        filterState,
        warningModalOpen,
        permissions,
        setWarningModalOpen,
        onChangeDate,
        setSnackbarOpen,
        mappedGridColumns,
        onFetchReportData,
        onFetchRows,
        onClickDownloadButton,
        onDownloadFile,
        onPageChange,
        onPageSizeChange,
        onChangeOrder,
        onChange,
        onChangeFilterSelect,
        onChangeRadio,
        toggleOpenDrawer,
        setDownloadMenuAnchorEl,
        setScheduleOpen,
        generateEmailTextfield,
        onSchedule,
        onDisableReportScheduled,
        onChangeEmail,
        onGenerateReportData,
        onChangeDateRange,
    }
}