import { PublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosRequestConfig, AxiosPromise } from "axios";
import { msalConfig } from "../../config/msalConfig"
import { User } from "../../model/User";
import { store } from "../../state";
import { ActionType } from "../../state/action-types";
import { Menu } from "../../model/Menu";

type HTTPMethod = "GET" | "POST" | "PUT" | "DELETE";
type contentType = "applicacion/json" | "multipart/form-data" 

const { Service } = require("axios-middleware");

const BASE_URL: string = process.env.REACT_APP_API_URL as string;
const OCP_APIM_SUBSCRIPTION_KEY: string = process.env.REACT_APP_API_KEY as string;

const msalInstance = new PublicClientApplication(msalConfig);


const getAxios = async (endpoint: string, method: HTTPMethod, data: any = undefined, params: any = undefined, contentType: contentType = "applicacion/json"): Promise<AxiosPromise> => {
    const options: AxiosRequestConfig = {
        method: method,
        baseURL: BASE_URL,
        params: {
            ...params
        },
        headers: {
            "Authorization": `Bearer ${sessionStorage.getItem("token")}`,
            "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY,
            "Content-Type": contentType
        },
        url: endpoint,
        data: data,
        timeout: 1000 * 40
    }

    console.log(`[HTTP] ${method} Request Sent at ${BASE_URL + endpoint}`);

    if (params) {
        console.log("Endpoint has been requested with the following params: ");
        console.log(params);
    }

    return axios(options);
}

axios.defaults.validateStatus = (status: number): boolean => {
    console.log(`[HTTP] STATUS-CODE: ${status}`);

    return status >= 200 && status < 400;
}

axios.interceptors.response.use(response => response, error => {
    const user = new User();
    const appMenu = new Menu();
    console.log(`[HTTP] axios.interceptors.response call`);

    if (error.response.status) {
        const { status, data } = error.response;

        switch (status) {
            case 200:
                console.log("200 OK NUMBER");
                user.Refresh();
                appMenu.onRefreshPageMenu()
                .then((val) => {
                    const menu = store.getState().menu;

                    if (JSON.stringify(val) === JSON.stringify(menu)) {
                        console.log("are the same")
                    }

                    store.dispatch({
                        type: ActionType.GET_MENU,
                        payload: val
                    });
                });

                break;
            case 400:
                console.log("[HTTP] BAD REQUEST");
                if (data === "Jwt is not valid.") {
                    user.RemoveSession();
                    user.removeUserAccount();
                    msalInstance.logoutRedirect({
                        postLogoutRedirectUri: "/"
                    });
                }
            case 401:
                console.log("[HTTP] UNAUTHORIZED");
                break;
            case 403:
                console.log("[HTTP] FORBIDDEN");

                store.dispatch({
                    payload: true,
                    type: ActionType.ON
                });
                break;
            case 404:
                console.log("[HTTP] NOT FOUND");
                break;
            case 502:
                console.log("[HTTP] BAD GATEAWAY")
                break;
        }

        return Promise.reject(error.response)
    }
}, {});



export {
    BASE_URL,
    OCP_APIM_SUBSCRIPTION_KEY,
    getAxios,
};
