import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Stack, Card, CardContent, Box, Grid, Snackbar, Alert, AlertColor } from '@mui/material';
import { Button } from '../Button';
import { FormStepper } from "./FormStepper";
import { StepFour } from './StepFour';
import { StepThree } from './StepThree';
import { StepTwo } from './StepTwo';
import { StepOne } from './StepOne';
import { Action } from "./IState";
import { useStepOne, useStepTwo, useStepThree, useStepFour, useNewReportForm } from "./hook";
import { INewReportFormProps } from "./IProps";
import { ForbiddenAccessMessage } from '../ForbiddenAccessMessage';

export const NewReportForm: React.FunctionComponent<INewReportFormProps> = (props): JSX.Element => {
    const { stepOneState, setStepOneState, fetchFolders, validateField, ...stepOne } = useStepOne(props.stepOne);
    const { stepTwoState, setStepTwoState, onSortEnd, onSelect, onChangeChecked, onClearSelectedOptions, ...stepTwo } = useStepTwo(parseInt(stepOneState.type), props.stepTwo);
    const { stepThreeState, setStepThreeState, onChangeSelect, onChangeMultipleSelect, ...stepThree } = useStepThree(props.stepThree, stepTwoState.selectedFilters);
    const { stepFourState, setStepFourState, onDisableReportScheduled, onChangeDate, onChangeHour, generateEmailTextField, onChangeEmails, onChangeRadio, onDisableInput, ...stepFour } = useStepFour(props.stepFour);
    const { activeStep, snackbarOpen, responseStatus, hideSnackbar, handleStepper, onChange, onSave, resetStepper, jumpToStep, mockOnSave, hideEditReport, hideAddReport, } = useNewReportForm(
        props.httpAction,
        stepOneState,
        stepTwoState,
        stepThreeState,
        stepFourState,
        props.id
    );

    const discardReport = () => {
        stepOne.onClear();
        stepTwo.onClear();
        stepThree.onClear();
        stepFour.onClear();

        resetStepper();
    }

    const validateInputByStep = () => {
        if (activeStep === 0) {
            return validateField();
        }

        else if (activeStep === 1) {
            return stepTwo.validateColumnsAndFilters();
        }

        else if (activeStep === 2 && stepOneState.type === "2") {
            return stepThree.validateGroupBy();
        }

        return true;
    }

    const BtnLabel = props.id ? "Update" : "Create";

    const forbiddenAddReport = props.httpAction == "POST" && hideAddReport;
    const forbiddenEditReport = props.httpAction == "PUT" && hideEditReport;

    return (
        <>
            {
                (activeStep === 3) && (
                    <Grid container columns={{ xs: 12 }} display="flex" justifyContent="end" spacing={{ xs: 1 }}>
                        <Stack direction="row" spacing={2}>
                            <Grid>
                                <Button sx={{ fontSize: 14 }} variant="outlined" width={75} height={35} label='Discard' onClick={discardReport} />
                            </Grid>
                        </Stack>
                    </Grid>
                )
            }
            <Card sx={{ width: "100%", boxShadow: 2, mt: 2, borderRadius: 4 }}>
                {forbiddenAddReport && <ForbiddenAccessMessage />}
                {forbiddenEditReport && <ForbiddenAccessMessage />}
                {!forbiddenAddReport && !forbiddenEditReport &&
                    <>
                        <CardContent>
                            <Box >
                                <FormStepper activeStep={activeStep} onJumpStep={jumpToStep} />
                            </Box>
                            {(activeStep === 0) &&
                                <StepOne
                                    state={stepOneState}
                                    onChange={onChange}
                                    setter={setStepOneState}
                                    onRefreshFolder={fetchFolders}
                                    onClearSelectedOptions={onClearSelectedOptions}
                                />
                            }
                            {(activeStep === 1) && <StepTwo
                                state={stepTwoState}
                                setter={setStepTwoState}
                                onSortEnd={onSortEnd}
                                onSelect={onSelect}
                                onChangeChecked={onChangeChecked}
                            />
                            }
                            {(activeStep === 2) &&
                                <StepThree
                                    state={stepThreeState}
                                    setter={setStepThreeState}
                                    filters={stepThree.filters}
                                    groupByValues={stepThree.groupByDropdownValues}
                                    subGroupValues={stepThree.subGroupByDropdownValues}
                                    onChangeSelect={onChangeSelect}
                                    onChangeMultipleSelect={onChangeMultipleSelect}
                                    onChangeDateRange={stepThree.onChangeDateRange}
                                    reportType={stepOneState.type}
                                />
                            }
                            {(activeStep === 3) &&
                                <StepFour
                                    onChange={onChange}
                                    state={stepFourState}
                                    setter={setStepFourState}
                                    onDisableReportScheduled={onDisableReportScheduled}
                                    onChangeDate={onChangeDate}
                                    onChangeHour={onChangeHour}
                                    onChangeEmails={onChangeEmails}
                                    onChangeRadio={onChangeRadio}
                                    generateTextfield={generateEmailTextField}
                                    onDisableInput={onDisableInput}
                                />
                            }
                        </CardContent>
                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", borderTop: 1, backgroundColor: "#FAFAFA", borderColor: "#00000026", p: 2 }}>
                            <Stack direction="row" spacing={2}>
                                {(activeStep !== 0) && <Button variant="outlined" width={120} height={40} label='Back' onClick={() => handleStepper(Action.Decrement)} />}
                                <Button width={120} height={40} label={activeStep === 3 ? BtnLabel : "Next"} onClick={() => activeStep !== 3 ? (validateInputByStep() ? handleStepper(Action.Increment) : undefined) : onSave()} />
                            </Stack>
                        </Box>
                        <Snackbar key={9} open={snackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={hideSnackbar} autoHideDuration={2000}>
                            <Alert severity={responseStatus.severity as AlertColor} >
                                {responseStatus.msg}
                            </Alert>
                        </Snackbar>
                    </>
                }
            </Card>
        </>
    );
}