import React, { FunctionComponent } from "react";
import { Card, CardContent, Box, Stack, Typography, SvgIcon } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import icons, { 
    mdiAccountFilter,
    mdiFilterRemove,
    mdiFilterCheck,
    mdiCalendarFilter,
    mdiFolderClock
 } from "@mdi/js";
import { BackgroundIcon } from "../BackgroundIcon";
import { IProps } from "./IProps";
import Icon from "@mdi/react";
import { formatDate, getHour, isValidDate } from "../../utils/time";
import moment from "moment";

const getIconByName = (name: string) => {
    let icon: string = "";

    switch (name) {
        case "mdiAccountFilter":
            icon = mdiAccountFilter
            break;
    
        case "mdiFilterCheck":
            icon = mdiFilterCheck;
            break;
        
        case "mdiCalendarFilter":
            icon = mdiCalendarFilter;
            break;
        
        case "mdiFilterRemove":
            icon = mdiFilterRemove;
            break;
            
        case "mdiFolderClock":
            icon = mdiFolderClock;
            break;
    }

    return icon;
}

export const LeadBox: FunctionComponent<IProps> = ({ label, index, data, icon }): JSX.Element => {
    const colors = { bg: "", color: "" };
    let LeadIcon: string;

    switch (index) {
        case 0:
            colors.bg = "#1BA0E933";
            colors.color = "#1BA0E9";
            LeadIcon = getIconByName(icon);
            break;
        case 1: 
            colors.bg = "#ED3E3E33";
            colors.color = "#ED3E3E";
            LeadIcon = getIconByName(icon);
            break;
        case 2:
            colors.bg = "#2BAD6F33";
            colors.color = "#2BAD6F";
            LeadIcon = getIconByName(icon);
            break;
        case 3:
            colors.bg = "#7B61FF33";
            colors.color = "#7B61FF";
            LeadIcon = getIconByName(icon);
            break;
        case 4: 
            colors.bg = "#ECBA0A33";
            colors.color = "#ECBA0A";
            LeadIcon = getIconByName(icon);
            break;
    }

    const Header = () => {
        return (
            <Box marginLeft={2} >
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography sx={{ fontSize: 14 }}>
                        { label }
                    </Typography>
                    <ArrowForwardIosIcon sx={{ fontSize: 12 }} />
                </Stack>
            </Box>
        )        
    }

    const Content = () => {
        const value = moment(data, "MM/DD/YYYY HH:mm:ss").isValid() ? moment(data, "MM/DD/YYYY HH:mm:ss") : data

        return (
            <Stack direction="row" alignItems="center" sx={{ mt: 4, ml: 1 }} spacing={2} >
                <BackgroundIcon color={colors.color} bgColor={colors.bg} icon={LeadIcon} />
                <Box>
                    <Typography sx={{ fontSize: 18, fontWeight: 900, color: colors.color }}>
                        { moment(data, "MM/DD/YYYY HH:mm:ss").isValid() ? formatDate(data) : data }
                    </Typography>
                    {
                        moment(data, "MM/DD/YYYY HH:mm:ss").isValid() && (
                            <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                                {getHour(new Date(data), true)}
                            </Typography>
                        )
                    }
                </Box>
            </Stack>
        )
    }

    return (
        <Card sx={{width: "100%", maxWidth: 700, minWidth: 140, height: 165, borderRadius: 2 }}>
            <CardContent>
                <Header />
                <Content />
            </CardContent>
        </Card>
    )  
}