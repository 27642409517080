import { useState, useEffect, useCallback } from "react";

export const useFormStepper = (mode: string) => {
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    const disableFormStepper = useCallback(() => {
        if (mode === "M") {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [isDisabled]);

    useEffect(() => {
        disableFormStepper();
    }, [disableFormStepper]);

    return {
        isDisabled
    }
}