import React, { useEffect, useState } from "react";
import { IState } from "./IState";
import { route, getAxios } from "../../utils/endpoints";
import { User } from "../../model/User";

export interface ICompanyInfoState {
    companyName: string;
    contactPhone: string;
    phoneLeadCCEmail: string;
    internetLeadCCEmail: string;
    primaryContact: string;
    phoneLeadEmailAddress: string;
    internetLeadEmail: string;
}

export interface ILeadPerfectionData {
    lplurl: string;
    lpProduct: string;
    lpSource: string;
    lpSubsourceID: number;
}

export interface IMarketSharp {
    coy: string;
    formKey: string;
    sourceKey: string;
    captureName: string;
    defaultSource: string;
}

export interface IProfileInfo {
    companyName: string;
    contactPhone: string;
    htcApiAddress: string;
    htcClientId: string;
    htcClientToken: string;
    internetLeadCCEmail: string;
    internetLeadEmail: string;
    ipcApiAddress: string;
    ipcInterest: string;
    ipcSource: string;
    ipcSourceType: string;
    lpProduct: string;
    lpSource: string;
    lpSubsourceID: number;
    lpurl: string;
    mscCaptureName: string;
    mscCoy: string;
    mscFormKey: string;
    mscSource: string;
    mscSourceKey: string;
    phoneLeadCCEmail: string;
    phoneLeadEmailAddress: string;
    primaryContact: string;
    sendEmail: boolean;
}

export const useEditProfileForm = (username?: string) => {
    const user = new User();
    const selectedUsername: string = username === undefined ? user.UserName : username;

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const [state, setState] = useState<IState>({
        companyInfo: {
            companyName: "",
            contactPhone: "",
            internetLeadCCEmail: "",
            internetLeadEmail: "", 
            phoneLeadCCEmail: "",
            phoneLeadEmailAddress: "",
            primaryContact: "",
            sendEmail: false,
        },
        leadPerfectionData: {
            lpProduct: "",
            lpSource: "",
            lpSubsourceID: 0,
            lpurl: ""
        },
        marketSharp: {
            captureName: "",
            coy: "",
            defaultSource: "",
            formKey: "",
            sourceKey: ""
        },
        i360: {
            defaultInterest: "",
            defaultSource: "",
            defaultSourceType: "",
            webhookAddress: "",
        },
        hatch: {
            clientId: "",
            clientToken: "",
            webhookAddress: ""
        }
    });

    const onClose = () => {
        setSnackbarOpen(false);
    }

    const fetchUserData = async (): Promise<void> => {

        try {
            const response = await getAxios(`${route.apiUsers.edit}/${selectedUsername}`, "GET")
            
            const data: IProfileInfo = await response.data;

            setState((prevState) => ({
                ...prevState,
                companyInfo: {
                    companyName: data.companyName,
                    contactPhone: data.contactPhone,
                    internetLeadCCEmail: data.internetLeadCCEmail,
                    internetLeadEmail: data.internetLeadEmail,
                    phoneLeadCCEmail: data.phoneLeadCCEmail,
                    phoneLeadEmailAddress: data.phoneLeadEmailAddress,
                    primaryContact: data.primaryContact,
                    sendEmail: data.sendEmail,
                },
                marketSharp: {
                    captureName: data.mscCaptureName,
                    coy: data.mscCoy,
                    defaultSource: data.mscSource,
                    formKey: data.mscFormKey,
                    sourceKey: data.mscSourceKey
                },
                leadPerfectionData: {
                    lpProduct: data.lpProduct,
                    lpSource: data.lpSource,
                    lpSubsourceID: data.lpSubsourceID,
                    lpurl: data.lpurl
                },
                hatch: {
                    clientId: data.htcClientId,
                    clientToken: data.htcClientToken,
                    webhookAddress: data.htcApiAddress
                },
                i360: {
                    defaultInterest: data.ipcInterest,
                    defaultSource: data.ipcSource,
                    defaultSourceType: data.ipcSourceType,
                    webhookAddress: data.ipcApiAddress
                }
            }))
        }

        catch (error) {
            console.log(error);
        }
    }

    const onUpdate = async (): Promise<void> => {
        const responseBody: IProfileInfo = {
            companyName: state.companyInfo.companyName,
            contactPhone: state.companyInfo.contactPhone,
            htcApiAddress: state.hatch.webhookAddress,
            htcClientId: state.hatch.clientId,
            htcClientToken: state.hatch.clientToken,
            internetLeadCCEmail: state.companyInfo.internetLeadCCEmail,
            internetLeadEmail: state.companyInfo.internetLeadEmail,
            ipcApiAddress: state.i360.webhookAddress,
            ipcInterest: state.i360.defaultInterest,
            ipcSource: state.i360.defaultSource,
            ipcSourceType: state.i360.defaultSourceType,
            lpProduct: state.leadPerfectionData.lpProduct,
            lpSource: state.leadPerfectionData.lpSource,
            lpSubsourceID: state.leadPerfectionData.lpSubsourceID,
            lpurl: state.leadPerfectionData.lpurl,
            mscCaptureName: state.marketSharp.captureName,
            mscCoy: state.marketSharp.coy,
            mscFormKey: state.marketSharp.formKey,
            mscSource: state.marketSharp.defaultSource,
            mscSourceKey: state.marketSharp.sourceKey,
            phoneLeadCCEmail: state.companyInfo.phoneLeadCCEmail,
            phoneLeadEmailAddress: state.companyInfo.phoneLeadEmailAddress,
            primaryContact: state.companyInfo.primaryContact,
            sendEmail: state.companyInfo.sendEmail,
        }
        
        try {
            const response = await getAxios(`${route.apiUsers.edit}/${selectedUsername}`, "PUT", responseBody);

            setSnackbarOpen(true);

        } catch (error) {
            console.error(error)
        }
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, sectionName: string): void => {
        const { name, value } = event.target;

        setState((prevState) => ({
            ...prevState,
            [sectionName]: {
                ...prevState[sectionName as keyof typeof state],
                [name]: value
            }
        }));
    }

    const onChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { checked } = event.target;

        setState((prevState) => ({
            ...prevState,
            companyInfo: {
                ...prevState.companyInfo,
                sendEmail: checked
            }
        }));
    }

    const fetchQueries = async () => {
        const response = await getAxios("/access/dashboard/queries", "GET");

        const data = await response.data;

        console.log(data);
    }

    useEffect(() => {
        fetchUserData();
        fetchQueries();
    }, []);

    return {
        state,
        snackbarOpen,
        onClose, 
        onChange, 
        onChangeCheckBox, 
        onUpdate, 
        fetchUserData,  
    };
}
