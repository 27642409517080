import { FunctionComponent, useState } from "react";
import {
    SvgIcon
} from "@mui/material";
import { mdiTrashCanOutline, mdiPencilOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { CustomMenu } from "../../CustomMenu";
import { IFolderMenuProps, IProps } from "./IProps";


export const FolderMenu: FunctionComponent<IFolderMenuProps> = (props): JSX.Element => {
    const funcLinks = []
    if(!props.hideRenameFolder)
    {
        funcLinks.push({ Icon: <SvgIcon><Icon path={mdiPencilOutline} /></SvgIcon>, label: "Rename", onClick: props.onRename });
    }
    if(!props.hideDeleteFolder)
    {
        funcLinks.push({ Icon: <SvgIcon><Icon path={mdiTrashCanOutline} /></SvgIcon>, label: "Delete", onClick: props.onDelete });
    }
    return (
        // <CustomMenu links={[]} funcLinks={funcLinks} {...props} />
        <CustomMenu funcLinks={funcLinks} {...props} />
    )
}