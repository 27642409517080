import React, { FunctionComponent, useEffect } from "react";
import { Grid, Box } from "@mui/material"
import { mdiAccount, mdiAccountOutline, mdiMagnify } from "@mdi/js";
import { CustomBreadCrumbs } from "../../components/CustomBreadcrumbs";
import { UserAccessTabPanel } from "../../components/UserAccessTabPanel";
import { CustomInput, PageHeader } from "../../components";
import { getAxios } from "../../utils/endpoints";
import { BranchAccessModal } from "../../components/BranchAccessDialog";
import Icon from "@mdi/react";

export const UserAccess: FunctionComponent = (): JSX.Element => {

    return (
        <>
            <Grid xs={12} sx={{ mb: 2 }}>
                <CustomBreadCrumbs icon={mdiAccount} path="Account/User Access" />
            </Grid>
            <PageHeader title="User Access" />
            <Box mt={4}>
                <UserAccessTabPanel />
            </Box>

        </>
    )
}