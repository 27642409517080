import React, { FunctionComponent } from "react";
import { Box, Typography } from "@mui/material";
import { IProps } from "./IProps";

export const TabPanelInfo: FunctionComponent<IProps> = (props) => {
    return (
        <Box display="flex" alignItems="center" mb={2} mt={2} >
            <Box sx={{ width: 30, height: 30, borderRadius: "100%", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center", backgroundColor: "#1BA0E9" }} >
                <Typography sx={{ color: "white" }} fontWeight="600">{props.length}</Typography>
            </Box>
            <Box sx={{ pl: 2, marginLeft: 2, borderLeft: "1px solid #0000001F" }} >
                <Typography>
                    { props.message }
                </Typography>
            </Box>
        </Box>
    )
}