import React, { FunctionComponent } from "react";
import {
    AccordionDetails,
    Grid,
    Checkbox,
    Typography,
    IconButton,
    SvgIcon,
    colors,
} from "@mui/material";
import { mdiDotsVertical } from "@mdi/js";
import { Icon } from "@mdi/react";
import { FolderItemMenu } from "../FolderItemMenu"
import { useFolderItem } from "./hook";
import { IProps } from "./IProps";
import { Link } from "react-router-dom";
import { AlertDialog } from "../../AlertDialog";

export const FolderItem: FunctionComponent<IProps> = (props): JSX.Element => {
    const { anchorEl, onClick, onClose, open, openWarningModal, setOpenWarningModal } = useFolderItem();
    return (
        <>
            <AccordionDetails sx={[
                {
                    borderBottom: !props.lastItem ? '1px solid rgba(0, 0, 0, 0.07)' : 'none',
                    "&:hover": {
                        backgroundColor: '#DDF1FC',
                        borderBottom: !props.lastItem ? '1px solid #8fd0f3' : 'none',

                    },
                }
            ]}>
                <Grid container columns={{ xs: 12 }} sx={{ padding: 'calc(var(--Grid-nested-rowSpacing) / 2) calc(var(--Grid-nested-columnSpacing) / 2)' }}>
                    <Grid container xs={12} display="flex" alignItems="center" justifyContent="space-between">
                        <Grid xs={1}>
                            <Checkbox name="checkboxTwo" onChange={(event) => event.target.checked ? props.onSelect() : props.unSelect()} />
                        </Grid>
                        <Grid marginLeft={3} xs={8} width={"100%"} maxWidth={150}>
                            {props.hideLink ?
                                <Typography fontWeight={700} fontSize={14} color="rgba(0, 0, 0, 0.4)">{props.reportTitle}</Typography> :
                                <Link style={{ textDecoration: "none", color: "rgba(0, 0, 0, 0.8)" }} to={`/report-detail/${props.id!}`} >
                                    <Typography fontWeight={700} fontSize={14} color="color: rgba(0, 0, 0, 0.8)">{props.reportTitle}</Typography>
                                </Link>
                            }
                            <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" color="rgba(0, 0, 0, 0.6)" fontWeight={400} fontSize={14}>{props.description}</Typography>
                        </Grid>

                        <Grid xs={1} display="flex" justifyContent="flex-end" alignItems="center">
                            <IconButton onClick={onClick}>
                                <SvgIcon>
                                    <Icon path={mdiDotsVertical} color="rgba(0, 0, 0, 0.4)" />
                                </SvgIcon>
                            </IconButton>
                            <FolderItemMenu
                                reportId={props.id}
                                favorite={props.favorite}
                                folders={props.folders}
                                hideEdit={props.hideEdit}
                                hideClone={props.hideClone}
                                hideAddFavorites={props.hideAddFavorites}
                                hideDelete={props.hideDelete}
                                hideMove={props.hideMove}
                                onDeleteItem={() => {
                                    onClose();
                                    setOpenWarningModal(true);
                                }}
                                onFavorite={() => {
                                    onClose();
                                    props.onFavorite()
                                }}

                                onUnFavorite={() => {
                                    onClose();
                                    props.onUnFavorite();
                                }}

                                onClone={() => {
                                    onClose();
                                    props.onClone();
                                }}

                                onNewFolder={() => {
                                    onClose();
                                    props.onNewFolder()
                                }}
                                open={open} anchorEl={anchorEl} onClose={onClose} />
                        </Grid>
                    </Grid>

                </Grid>
            </AccordionDetails>
            <AlertDialog
                type="warning"
                title={`Delete Report ${props.reportTitle}?`}
                description="Are you sure you want to delete this report?"
                open={openWarningModal}
                onCancel={() => setOpenWarningModal(false)}
                onContinue={() => {
                    props.onDeleteItem();
                    setOpenWarningModal(false);
                    onClose();
                }}
            />
        </>
    )
}