import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { userFlow, IdTokenClaims, B2C_TENANT_NAME } from "../../config/msalConfig";
import { route, getAxios } from "../../utils/endpoints";
import { SideBarMenu } from "../../types/SidebarMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../state/reducers";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";

export const useUserNavMenu = () => {
    const { instance, accounts } = useMsal();

    const state = useSelector((state: RootState) => state.menu);
    const dispatch = useDispatch();
    const { GetMenus } = bindActionCreators(actionCreators, dispatch);

    const Logout = async (): Promise<void> => {
        await instance.logoutRedirect({
            account: instance.getAccountByHomeId(accounts[0].homeAccountId),
            onRedirectNavigate: () => {
                sessionStorage.removeItem("user");
                sessionStorage.removeItem("user-account");
                sessionStorage.removeItem("token");
            },
            postLogoutRedirectUri: "/"
        });
    }

    const onChangePassword = async (): Promise<void> => {
        const response = await instance.loginPopup({ 
            scopes: [],
            authority: `https://${B2C_TENANT_NAME}.b2clogin.com/${B2C_TENANT_NAME}.onmicrosoft.com/${userFlow.PasswordReset}`,
            redirectUri: "/"
         });

         const idTokenClaims = response.idTokenClaims as IdTokenClaims;

         if (idTokenClaims["tfp"] === userFlow.PasswordReset) {
             alert("Your password has been successfully changed. Please, sign in with your new credentials");
             await Logout();
         }
    }

    const getMenuItems = async (): Promise<void> => {
        const response = await getAxios(route.apiSidebar.sideMenu, "GET");

        const items: SideBarMenu[] | null = await response.data;

        if (items === null) {
            return
        }

        GetMenus(items);

        //setMenuItems(userMenuItems);
    }

    useEffect(() => {
        getMenuItems();
    }, [])
    
    return { Logout, onChangePassword };
}