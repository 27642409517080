import React, { useState } from "react";
import { getAxios, route } from "../../../utils/endpoints";
import { User } from "../../../model/User";

export const useStepFive = () => {
    const [displayActions, setDisplayActions] = useState<boolean>(false);
    const [openTitleDialog, setOpenTitleDialog] = useState<boolean>(false);
    const [openNotificationQueue, setOpenNotificationQueue] = useState<boolean>(false);
    const [sqlError, setSqlError] = useState("");
    
    const user = new User();
    const disableAddEditExport = user.HasForbiddenAccess("Can Add or Edit Exports");
    const disableGenerateExport = user.HasForbiddenAccess("Can Generate Export");

    const isValidSQL = (isValid: boolean) => {
        if (isValid) {
            setDisplayActions(true);
            setSqlError("");
            return;
        }

        setSqlError("Invalid SQL syntax");
    }

    return {
        displayActions,
        openTitleDialog,
        sqlError,
        openNotificationQueue,
        disableAddEditExport,
        disableGenerateExport,
        setOpenNotificationQueue,
        setDisplayActions,
        setOpenTitleDialog,
        setSqlError,
        isValidSQL,
    }
 }