import { FunctionComponent } from "react";
import { Typography, Grid } from "@mui/material"
import { IProps } from "./IProps"

export const PageHeader: FunctionComponent<IProps> = (props) => {
    return (
        <Grid xs={12} sm={5} md={5} lg={8}>
            <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25, mt: 2 }} >
                { props.title }
            </Typography>
            <Typography sx={{ fontSize: 14, mt: 2 }} >
                { props.subTitle }
            </Typography>
        </Grid>
    )
}