import { ReactNode } from "react";
import InfoIcon from '@mui/icons-material/Info';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const useHint = (type: string) => {
    let Icon: ReactNode;
    let color = "";

    switch (type) {
        case "default":
            color = "rgba(0, 0, 0, 0.7)"
            break;
        case "primary":
            color = "#2F79E9";
            Icon = <InfoIcon fontSize="small" color="primary" />;
            break;
        case "error":
            color = "#ED3E3E";
            Icon = <CancelIcon fontSize="small" color="error" />
            break;
        case "success":
            color = "#25C176"
            Icon = <CheckCircleIcon fontSize="small" color="success" />
            break;
    }

    return {
        Icon,
        color
    }
}   