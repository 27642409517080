import { useState } from "react";
import { getAxios, route } from "../../utils/endpoints";
import { LPLNotification } from "../../types/LPLNotification";

export const useAccounts = () => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState<boolean>(false);
    const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [fileMessage, setFileMessage] = useState<string>("");
    const [isSnackbarOpen, setIsSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarType, setSnackbarType] = useState<string>("");

    const openSelectionFile = () => {
        const fileBtn = document.getElementById("btn-file-upload");

        if (fileBtn !== null) {
            fileBtn.click();
        }
    }

    const onOpenFileUploadDialog = () => setIsUploadFileDialogOpen(true);
    const onOpenAddUserUploadDialog = () => setIsAddUserDialogOpen(true);

    const onOpenSnackbar = () => setIsSnackbarOpen(true);
    const onCloseSnackbar = () => setIsSnackbarOpen(false);

    const onCloseAddUser = () => setIsAddUserDialogOpen(false);

    const onCloseUploadFileDialog = () => {
        setSelectedFile(null);
        setIsError(false);
        setFileMessage("");
        setIsUploadFileDialogOpen(false);
    }

    const onSaveFile = async () => {
        const response = await getAxios(route.apiAccount.territory, "POST", {
           territoryFile: selectedFile! 
        }, undefined, "multipart/form-data");

        const data: Partial<LPLNotification> = await response.data;

        setSnackbarMessage(data.messageDetails!);
        onOpenSnackbar();

        setTimeout(() => {
            onCloseUploadFileDialog();
        }, 2000)
    }

    const onHandleSelectionFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;

        if (!files) {
            alert("operation cancelled");
            return;
        }

        const allowedExtensions = /(\.xls|\.xlsx|\.csv)$/i;

        if (!allowedExtensions.exec(files[0].name)) {
            setIsError(true);
            setFileMessage(`The ${files[0].type} extension is not valid`);
            return;
        }

        setSelectedFile(files[0]);
        setIsError(false);
    }

    return {
        selectedFile,
        isError,
        isAddUserDialogOpen,
        isUploadFileDialogOpen,
        fileMessage,
        onHandleSelectionFile,
        openSelectionFile,
        onCloseUploadFileDialog,
        onOpenAddUserUploadDialog,
        onOpenFileUploadDialog,
        onCloseSnackbar,
        onCloseAddUser,
        onOpenSnackbar,
        snackbarMessage,
        onSaveFile,
        isSnackbarOpen
    }
}