import React, { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import { Box, Stack, SvgIcon } from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { Label, Hint } from "../component-utils";
import { IProps } from "./IProps";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useCustomInput } from "./input.hook"

const StyledInput = styled(InputBase)<IProps>((props) => ({
    width: props.width ? props.width : 370,
    height: props.height ? props.height : 46,
    borderRadius: 5,
    fontWeight: 400,
    color: "rgba(0, 0, 0, 0.8)",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    paddingLeft: 2,
    backgroundColor: props.readOnly ? "rgba(0, 0, 0, 0.07)" : "white"
}));

export const CustomInput: FunctionComponent<IProps> = (props): JSX.Element => {
    const { state, onFocus, onBlur } = useCustomInput();
    const { color } = state;

    const StartAdornment = () => {
        return (
            <SvgIcon sx={{ color: "rgba(0, 0, 0, 0.4)", mr: 1 }} >
                {props.startIcon}
            </SvgIcon>
        )
    }

    const EndAdorment = () => {
        return (
            <Stack direction="row" alignItems="center" spacing={1} mx={1} >
                <SvgIcon sx={{ color: "rgba(0, 0, 0, 0.4)", mr: 1 }} >
                    {props.endIcon}
                </SvgIcon>
                {props.renderOnClear && <CloseOutlinedIcon onClick={props.onClear} sx={{ color: "rgba(0, 0, 0, 0.4)", cursor: "pointer" }} />}
                {props.renderEdit && <CreateOutlinedIcon sx={{ color: "rgba(0, 0, 0, 0.4)" }} />}

            </Stack>
        )
    }

    return (
        <Box >
            { props.label && <Label text={props.label.text} color={props.label.color ? props.label.color : state.color} />}
            <StyledInput
                sx={{ px: 1 }}
                startAdornment={props.startIcon ? <StartAdornment /> : null}
                onFocus={onFocus}
                onBlur={onBlur}
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                placeholder={props.placeholder}
                readOnly={props.readOnly}
                endAdornment={props.endIcon ? <EndAdorment /> : null}
                {...props}
            />
            {props.hint && <Hint type={props.hint.type} message={props.hint.message} />}
        </Box>
    )
}