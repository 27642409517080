import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from "../../../model/User";

export enum Action {
    Increment = 1,
    Decrement = 0
}

export const useExportModule = (hasId?: boolean) => {
    const initialStep: number = hasId ? 4 : 0;

    const [activeStep, setActiveStep] = useState<number>(initialStep);
    const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);
    
    const user = new User();
    const disableAddEditExport = user.HasForbiddenAccess("Can Add or Edit Exports");

    const navigate = useNavigate();

    const onDiscard = () => {
        navigate("/export");
    }

    const onOpenDiscardDialog = () => {
        setOpenDiscardDialog(!openDiscardDialog);
    }

    const handleStepper = (action: Action) => {

        switch (action) {
            case Action.Increment: {
                if (activeStep >= 4) {
                    return;
                }

                setActiveStep(activeStep + 1);
                break;
            }

            case Action.Decrement: {
                if (activeStep <= 0) {
                    return;
                }

                setActiveStep(activeStep - 1);
                break;
            }
        }
    }
    const jumpToStep = (step: number) => {
        setActiveStep(step);
    }


    return {
        activeStep,
        disableAddEditExport,
        openDiscardDialog,
        onOpenDiscardDialog,
        handleStepper,
        jumpToStep,
        onDiscard
    }
}