import { FunctionComponent } from "react";
import {
  Stack,
  MenuItem,
  SvgIcon
} from "@mui/material";
import { CustomSelect } from "../../../../components/CustomSelect";
import { IProps as ICustomSelectProps } from "../../../../components/CustomSelect/IProps";
import { CustomInput } from "../../../../components/CustomInput";
import { mdiCommentTextOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { useCriteriaForm } from "./hook";
import { IProps } from "./IProps";

const andOr: string[] = [
  "AND",
  "OR"
];

const operators: string[] = [
  "=",
  "<",
  ">",
  "<=",
  ">=",
  "<>",
  "IS NULL",
  "IS NOT NULL",
  "LIKE",
  "IN"
];

const leftParentehesis = [
  "",
  "("
];

const rightParenthesis = [
  "",
  ")"
];

interface CriteriaSelectProps extends ICustomSelectProps {
  items: string[];
}

const CriteriaSelect: FunctionComponent<CriteriaSelectProps> = (props) => {
  return (
    <CustomSelect {...props}>
      {
        props.items.map((item, index) => {
          return (
            <MenuItem value={item}>
              {item}
            </MenuItem>
          )
        })
      }
    </CustomSelect>
  )
}

export const CriteriaForm: FunctionComponent<IProps> = (props): JSX.Element => {
  
  return (
    <Stack width="100%" direction="row" alignItems="center" spacing={4}>
      <CriteriaSelect
        readOnly={props.isReadOnly}
        label="" 
        name="statement" 
        width={100} 
        items={andOr}
        value={props.statement}
        onChange={props.onChangeSelect}
      />
      <CriteriaSelect 
        label="" 
        name="leftParenthesis"
        value={props.leftParenthesis} 
        width={70} 
        items={leftParentehesis} 
        onChange={props.onChangeSelect}
      />
      <CriteriaSelect 
        label="" 
        name="source" 
        width={265} 
        value={props.source}
        items={["", ...props.sourceItems]} 
        onChange={props.onChangeSelect}
      />
      <CriteriaSelect 
        label="" 
        name="operator" 
        value={props.operator}
        width={160} 
        items={operators} 
        onChange={(event) => {
          props.onChangeSelect(event);
          
          if (((event.target.value as string).toLowerCase() === "is null") || ((event.target.value as string).toLowerCase() === "is not null")) {
            props.onResetNote!();
          }
        }}
      />
      <CustomInput readOnly={(props.operator.toLowerCase() === "is null" || props.operator.toLowerCase() === "is not null")} name="note" value={props.note} onChange={props.onChange} width={265} height={35} startIcon={(
        <SvgIcon>
          <Icon path={mdiCommentTextOutline} style={{ fontSize: 4, marginLeft: 1 }} />
        </SvgIcon>
      )} />
      <CriteriaSelect 
        label="" 
        name="rightParenthesis"
        value={props.rightParenthesis}
        width={70} 
        items={rightParenthesis} 
        onChange={props.onChangeSelect}
      />
    </Stack>
  )
}