import { Grid, Typography, Box } from "@mui/material";

export const NotFoundPage = (): JSX.Element => {
    return (
        <>
            <Box sx={{ width: "100%", height: "80vh" }} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                <Grid item>
                    <img style={{ width: 300, height: 100 }} src={process.env.PUBLIC_URL + "images/LP_Central_CyanOnly.png"} alt="" />
                </Grid>
                <Grid item sx={{ mt: 6 }} >
                    <Typography variant="h3" sx={{ color: "#1BA0E9", fontWeight: 600 }}>
                        404 Resource Not Found
                    </Typography>
                </Grid>
            </Box>
        </>
    )
}