import { FunctionComponent } from "react";
import { UserAccessDataGrid } from "../UserAccessDataGrid"
import { CustomTabs } from "../CustomTabs";
import { ProfileAccessDataGrid } from "../ProfileAccessDataGrid";

export const UserAccessTabPanel: FunctionComponent = (): JSX.Element => {

    return (
        <CustomTabs
            tabLabels={[{ title: "Users" }, { title: "Profiles" }]}
            tabComponents={[
                <UserAccessDataGrid  />, 
                <ProfileAccessDataGrid />
            ]}
        />
    )
}