import React, { FunctionComponent } from "react";
import { SvgIcon } from "@mui/material"
import { CustomMenu } from "../../CustomMenu";
import { FolderItemMenuProps as IProps } from "./IProps";
import {
    mdiPencilOutline,
    mdiContentDuplicate,
    mdiFolderArrowRight,
    mdiMinusThick,
    mdiPlusThick,
    mdiTrashCanOutline,
    mdiStar
} from "@mdi/js";
import { Icon } from "@mdi/react";

export const FolderItemMenu: FunctionComponent<IProps> = (props) => {
    let links = []
    if(!props.hideEdit)
    {
        links.push({ Icon: <SvgIcon><Icon path={mdiPencilOutline} /></SvgIcon>, label: "Edit", href: `/new-report/${props.reportId}` });
    }

    let funcLinks = []
    if(!props.hideClone)
    {
        funcLinks.push({ Icon: <SvgIcon><Icon path={mdiContentDuplicate} /></SvgIcon>, label: "Clone", onClick: () => props.onClone() });
    }
    if(!props.hideAddFavorites)
    {
        funcLinks.push({
            Icon: props.favorite ? <SvgIcon><Icon path={mdiMinusThick} /></SvgIcon> : <SvgIcon><Icon path={mdiPlusThick} /></SvgIcon>, label: props.favorite ? "Remove from Favorites" : "Add to Favorites",
            onClick: () => props.favorite ? props.onUnFavorite() : props.onFavorite()
        });
    }
    if(!props.hideDelete)
    {
        funcLinks.push({ Icon: <SvgIcon><Icon path={mdiTrashCanOutline} /></SvgIcon>, label: "Delete", onClick: () => props.onDeleteItem() });
    }

    let nestedLinks = []
    if(!props.hideMove)
    {
        nestedLinks.push({
            Icon: <SvgIcon><Icon path={mdiFolderArrowRight} /></SvgIcon>, label: "Move", onClick: () => props.onNewFolder(), sublinks: props.folders,
            subFuncLinks: [{ Icon: <SvgIcon> <Icon path={mdiPlusThick} /> </SvgIcon>, label: "New Folder", onClick: () => console.log("adding new folder") }]
        });
    }

    return (
        <CustomMenu links={links} funcLinks={funcLinks} nestedLinks={nestedLinks} reportId={props.reportId} {...props} />
    )
}