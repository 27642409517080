import { FunctionComponent } from "react";
import { Box, Typography, Select, MenuItem, SvgIcon } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useCustomSelect } from "./hook";
import { Label, Hint } from "../component-utils";
import { IProps } from "./IProps";

export const CustomSelect: FunctionComponent<IProps> = ({ label, StartIcon, value, onChange, children, ...props }): JSX.Element => {
    const { isFocused, onChangeFocus } = useCustomSelect();

    return (
        <Box>
            <Typography sx={{ color: `${isFocused ? "#1BA0E9" : "rgba(0, 0, 0, 0.8)" }`, fontSize: 14, mb: 1 }} >
                { label }
            </Typography>
            <Select 
                disabled={props.disabled}
                sx={{ width: props.width ? props.width: 370, height: 35, backgroundColor: props.readOnly ? "rgba(0, 0, 0, 0.07)" : "white", borderColor: "rgba(0, 0, 0, 0.15)" }} 
                IconComponent={KeyboardArrowDownIcon} 
                onOpen={onChangeFocus}
                onClose={onChangeFocus}
                value={value}
                onChange={onChange}
                placeholder={props.placeholder}
                startAdornment={StartIcon ? <SvgIcon sx={{ mr: 1, color: `${isFocused ? "#1BA0E9" : "rgba(0, 0, 0, 0.4)"}` }}>{StartIcon}</SvgIcon> : null}
                defaultValue={props.defaultValue}
                { ...props }
            >
                { children }
            </Select>
            {props.hint && <Hint type={props.hint.type} message={props.hint.message} />}
        </Box>
    )
}