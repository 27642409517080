import { useEffect, useState } from "react";
import { initialize, pageview } from "react-ga" //Google Analytics
import { getAxios } from "../utils/endpoints";
import { TenantCredentials } from "../types/TenantCredentials"
import { IProps } from "../components/AlertDialog/IProps";

export const useApp = () => {
    const [tenantName, setTenantName] = useState<string>("")
    const [webAppId, setWebAppId] = useState<string>("");
    const [alertDialogProps, setAlertDialogProps] = useState<IProps | null>(null);

    const getTenantInformation = async (): Promise<void> => {
        const response = await getAxios("/azure/tenant", "GET");

        const credentials: TenantCredentials = await response.data;

        //localStorage.setItem("tenantName", credentials.tenantName);
        //localStorage.setItem("webAppID", credentials.webAppID);

        setTenantName(credentials.tenantName);
        setWebAppId(credentials.webAppID);

        localStorage.setItem("tenantName", credentials.tenantName);
    }

    const setGoogleAnalytics = (): void => {
        initialize("UA-233108187-1");
        pageview("Init page view");
    }

    useEffect(() => {
        setGoogleAnalytics();
        getTenantInformation();
    }, []);


    const handleCancel = () => {
        // Handle cancel action
        setAlertDialogProps(null); // Close the dialog
    };

    const handleContinue = () => {
        // Handle continue action
        setAlertDialogProps(null); // Close the dialog
    };

    return {
        tenantName,
        webAppId,
        alertDialogProps
    }
}

