import {
    Typography,
    Stack,
    MenuItem,
} from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { CustomSelect, CustomInput, LeadGrid, CustomBreadCrumbs } from '../../components';
import { AllSalesBetweenASpecificDate } from "./AllSalesBetweenASpecificDate"
import { AllSalesBetweenASpecificDateAndStatus } from "./AllSalesBetweenDateAndStatus";
import { SearchFor } from "./SearchFor";
import { useViewLeads } from "./hook";
import Grid from '@mui/material/Unstable_Grid2';

export const ViewLeads = (): JSX.Element => {
    const {
        state,
        leadId,
        paginationState,
        disableUserFunctions,
        onChangeSelect,
        fetchLeadsByDateAndStatus,
        fetchLeadBySearch,
        onPageChange,
        onPageSizeChange,
        onChangeOrder
    } = useViewLeads();

    const gridColumns = state.callLeads[0];

    return (
        <>
            <Grid xs={12} sx={{ mb: 2 }}>
                <CustomBreadCrumbs path="Home/View Leads" />
            </Grid>
            <Grid container columns={{ xs: 12, sm: 10, md: 10, lg: 12 }} justifyContent="space-between" sx={{ mb: 3 }}>
                <Grid xs={12} sm={5} md={5} lg={8}>
                    <Typography textAlign={{ xs: "center", sm: "start" }} variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25 }} >
                        View Leads
                    </Typography>
                    <Typography textAlign={{ xs: "center", sm: "start" }} sx={{ fontSize: 14, marginTop: '10px' }} >
                        What would you like to view?
                    </Typography>
                </Grid>
                <Grid xs={12} sm={5} md={5} lg={4} display="flex-end">
                    <CustomSelect disabled={disableUserFunctions} width={"100%"} name="filter" placeholder="Search for..." label="Leads by" StartIcon={<LocalPhoneOutlinedIcon />} onChange={onChangeSelect} value={state.filter}>
                        <MenuItem value="1">All Unresulted Leads</MenuItem>
                        <MenuItem value="2">All Leads between a specific date and status</MenuItem>
                        <MenuItem value="3">All Open Inquiries</MenuItem>
                        <MenuItem value="4">Unresulted Past Appointments</MenuItem>
                        <MenuItem value="5">Open Sales</MenuItem>
                        <MenuItem value="6">All Sales between a specific date</MenuItem>
                        <MenuItem value="7">Search for...</MenuItem>
                    </CustomSelect>
                </Grid>
            </Grid>

            {(state.filter === "7") && <SearchFor id={leadId} page={paginationState.page} pageSize={paginationState.pageSize} applyFilter={(searchFor, searchMode) => fetchLeadBySearch(searchFor, searchMode)} />}
            {(state.filter === "2") && <AllSalesBetweenASpecificDateAndStatus page={paginationState.page} pageSize={paginationState.pageSize} applyFilter={(start, end, status) => fetchLeadsByDateAndStatus(start, end, status)} />}
            {(state.filter === "6") && <AllSalesBetweenASpecificDate page={paginationState.page} pageSize={paginationState.pageSize} applyFilter={(start, end) => fetchLeadsByDateAndStatus(start, end)} />}
            {state.errorMessage && <Typography color="red" fontSize={12}>{state.errorMessage}</Typography>}
            <LeadGrid
                renderToggle
                partialColumns={state.callLeads.length ? Object.keys(state.callLeads[0]) : []}
                callLeads={state.callLeads}
                page={paginationState.page}
                pageSize={paginationState.pageSize}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                loading={paginationState.isLoading}
                total={paginationState.total!}
                sortModel={paginationState.sortModel}
                onSortModelChange={(newSortModel) => onChangeOrder(newSortModel)}
            />
        </>
    )
}