import React, { FunctionComponent } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    SvgIcon,
    Stack,
    MenuItem,
    Typography,
    Backdrop,
    CircularProgress,
    Snackbar,
    Alert
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog"
import { Icon } from "@mdi/react";
import { mdiAccountPlus } from "@mdi/js"
import { Button } from "../Button";
import { CustomInput } from "../CustomInput";
import { CustomSelect } from "../CustomSelect"
import { useAddUserDialog } from "./hook";
import { IProps } from "./IProps";

export const AddUserDialog: FunctionComponent<IProps> = (props): JSX.Element => {
    const { onChange, onChangeSelect, state, onClear, onSubmit, alertType, message, setMessage } = useAddUserDialog();

    return (
        <Dialog
            fullWidth
            open={props.isOpen}
        >
            <DialogTitle>
                <Box display="flex" alignItems="center" gap={1}>
                    <SvgIcon htmlColor="#1BA0E9" fontSize="large" >
                        <Icon path={mdiAccountPlus} />
                    </SvgIcon>
                    <Typography sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 22 }}>
                        Add a user
                    </Typography>
                </Box>
            </DialogTitle>
            <DialogContent>
                <Stack mt={4} direction="column" spacing={2}>
                    <CustomSelect width="100%" label="Type" name="type" value={state.type} onChange={onChangeSelect}>
                        <MenuItem value="1">
                            Dealer
                        </MenuItem>
                        <MenuItem value="8" >
                            Admin
                        </MenuItem>
                    </CustomSelect>
                    <CustomInput
                        name="companyName"
                        value={state.companyName}
                        onChange={onChange}
                        width="100%"
                        label={{ text: "Company Name" }}
                    />
                    <CustomInput
                        name="username"
                        value={state.username}
                        onChange={onChange}
                        width="100%"
                        label={{ text: "Username" }}
                    />
                    <CustomInput
                        name="password"
                        value={state.password}
                        onChange={onChange}
                        width="100%"
                        type="password"
                        label={{ text: "Password" }}
                    />
                    <CustomInput
                        name="email"
                        value={state.email}
                        onChange={onChange}
                        type="email"
                        width="100%"
                        label={{ text: "Email" }}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button width={120} height={40} label="Save" onClick={onSubmit} />
                <Button width={120} height={40} variant="outlined" label="Cancel" onClick={() => {
                    onClear();
                    props.onClose();
                }} />
            </DialogActions>
            <Snackbar
                key={9}
                open={Boolean(message.length)}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                onClose={() => {
                    setMessage("");
                    if (alertType !== "error") {
                        props.onClose();
                    }
                }} autoHideDuration={3000}>
                <Alert severity={alertType as any} >
                    {message}
                </Alert>
            </Snackbar>
        </Dialog>
    )
}