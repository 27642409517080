import React, { FunctionComponent } from "react";
import { Typography, Divider, SvgIcon } from "@mui/material/"
import { mdiPlusThick, mdiTrashCanOutline, mdiStarOutline, mdiFolder, mdiStarOffOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import Grid from '@mui/material/Unstable_Grid2';
import { CustomBreadCrumbs } from "../../components";
import { Button as CustomButton } from "../../components"
import Button from '@mui/material/Button';
import { ReportsMenuFavorite } from "../../components/ReportsMenuFavorite"
import { NewFolderDialog } from "../../components/NewFolderDialog";
import { AlertDialog } from "../../components/AlertDialog";
import { Folder } from "../../components/Folder";
import { User } from "../../model/User";
import { useReports } from "./hook";
import { route, getAxios } from "../../utils/endpoints";
import { ForbiddenAccessMessage } from "../../components/ForbiddenAccessMessage";

export const Reports: FunctionComponent = (): JSX.Element => {
    const user = new User();
    const {
        folders,
        linkFolders,
        favoriteReports,
        openDialog,
        folderId,
        folderName,
        httpAction,
        permissions,
        fetchReportsFolders,
        toggleOpenDialog,
        onDeleteFolder,
        setPOSTDialogForm,
        setPUTDialogForm,
        onDeleteReport,
        onFavorite,
        onUnFavorite,
        onClone,
    } = useReports();

    const [selected, setSelected] = React.useState<number[]>([]);

    const onBulkDelete = async (ids: number[]) => {
        try {
            await getAxios(route.apiReports.reporstCustom + "bulk-delete", "DELETE", {
                ids: ids
            });
            setSelected([])
            await fetchReportsFolders()
        } catch (error) {
            console.error(error)
        }
        console.log(74, ids)
    }

    const onBulkFavorite = async (ids: number[]) => {
        try {
            await getAxios(route.apiReports.reporstCustom + "bulk-favorite", "PUT", {
                ids: ids
            });
            await fetchReportsFolders()
        } catch (error) {
            console.error(error)
        }
    }

    const onBulkUnFavorite = async (ids: number[]) => {
        try {
            await getAxios(route.apiReports.reporstCustom + "bulk-favorite", "DELETE", {
                ids: ids
            });
            await fetchReportsFolders()
        } catch (error) {
            console.error(error)
        }
    }
    return (
        <>
            <Grid xs={12} sx={{ mb: 2 }}>
                <CustomBreadCrumbs path="Home/Reports" />
            </Grid>
            <Grid textAlign={{ xs: "center", sm: "start" }} xs={12} sx={{ mb: 2 }}>
                <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25, mb: 1 }} >
                    Reports 
                </Typography>
                <Typography sx={{ fontSize: 14 }} >
                    Choose among the reports one to start working on it
                </Typography>
            </Grid>

            {permissions.hideReportsHome ? <ForbiddenAccessMessage/> :
                <>
                    <Grid sx={{ mb: 2 }} container columns={{ xs: 7, sm: 7, md: 8, lg: 12, xl: 12 }} display="flex" justifyContent="flex-end" spacing={{ xs: 2 }} alignItems="center">
                        {!permissions.hideEditBulkFavorite &&
                            <Grid sm={2.5} md={2.1} lg={2.1} xl={1.7} justifyContent="flex-end" display="flex">
                                    <Button onClick={() => onBulkFavorite(selected)} startIcon={<SvgIcon> <Icon path={mdiStarOutline} /> </SvgIcon>} sx={{ fontSize: 14, textTransform: "none", fontFamily: "lato", fontWeight: 700, letterSpacing: 0.2 }} variant="text">Add to Favorites</Button>
                            </Grid>
                        }
                        {!permissions.hideDeleteBulkFavorite &&
                            <Grid sm={2.5} md={2.1} lg={2.1} xl={1.6} justifyContent="flex-end" display="flex">
                                <Button onClick={() => onBulkUnFavorite(selected)} startIcon={<SvgIcon> <Icon path={mdiStarOffOutline} /> </SvgIcon>} sx={{ fontSize: 14, textTransform: "none", fontFamily: "lato", fontWeight: 700, letterSpacing: 0.2 }} variant="text">Remove from Favorites</Button>
                            </Grid>
                        }
                        {(user.Roles[0].secLevel !== 1 && !permissions.hideAddFolders) && 
                            <Grid sm={2} md={2.1} lg={2.1} xl={1.49} justifyContent="flex-end" display="flex">
                                    <CustomButton startIcon={<SvgIcon><Icon path={mdiPlusThick} /></SvgIcon>} sx={{ fontSize: 14 }} variant="outlined" width={150} height={35} label='New Folder' onClick={setPOSTDialogForm} />
                            </Grid>
                        }
                        {(user.Roles[0].secLevel !== 1 && !permissions.hideDeleteBulkReports) && 
                            <Grid sm={1.5} md={2.1} lg={1.3} xl={1} justifyContent="flex-end" display="flex">
                                <Button onClick={() => onBulkDelete(selected)} startIcon={<SvgIcon> <Icon path={mdiTrashCanOutline} /> </SvgIcon>} variant="contained" color="error" sx={{ borderRadius: 2, fontFamily: "lato", fontSize: 14, fontWeight: 700, letterSpacing: 0.2, backgroundColor: "#ED3E3E", textTransform: "none", width: 108 }} >Delete</Button>
                            </Grid>
                        }
                        <Divider orientation="vertical" flexItem />
                        {
                            (selected.length >= 0) && (
                                <Grid sm={1.5} md={1.7} lg={1.3} xl={1} display="flex" justifyContent="flex-end" alignItems="center">
                                    <Typography fontWeight={400} fontSize={14} color="rgba(0, 0, 0, 0.6)">{selected.length} items selected</Typography>
                                </Grid>
                            )
                        }
                    </Grid>
                    <Grid container spacing={2} columns={{ xs: 12 }}>
                        <Grid xs={12} sm={6} md={4} >
                            <ReportsMenuFavorite
                                ReportTittle="Favorites"
                                items={favoriteReports}
                                onUnFavorite={(id) => onUnFavorite(id)}
                            />
                        </Grid>
                        {!permissions.hideFolders &&
                            folders.map((item) => {
                                return (
                                    <Grid xs={12} sm={6} md={4}>
                                        <Folder
                                            reportFolderId={item.reportFolderId}
                                            folders={linkFolders.filter((link) => (link.label !== ("ALL") && (link.label !== (item.description))))}
                                            key={item.id}
                                            icon={<Icon path={mdiFolder} />}
                                            description={item.description}
                                            reportsItem={item.reports}
                                            onDelete={() => onDeleteFolder(item.reportFolderId)}
                                            onRename={() => setPUTDialogForm(item.reportFolderId, item.description)}
                                            onDeleteReport={(id) => onDeleteReport(id)}
                                            onFavorite={(id) => onFavorite(id)}
                                            onUnFavorite={(id) => onUnFavorite(id)}
                                            onClone={(id) => onClone(id)}
                                            onSelectedItem={(item) => setSelected([...selected, item])}
                                            unSelectedItem={(item) => setSelected(selected.filter((val) => val !== item))}
                                            onNewFolder={() => setPOSTDialogForm()}
                                            hideEditItem={permissions.hideEditCustomReportSpecs}
                                            hideClone={permissions.hideAddClonedReport}
                                            hideAddFavorites={permissions.hideEditFavorite}
                                            hideDelete={permissions.hideDeleteCustomReportSpecs}
                                            hideMove={permissions.hideEditCurrentFolder}
                                            hideLink={permissions.hideReadCustomReportData}
                                            hideDeleteFolder={permissions.hideDeleteFolders}
                                            hideRenameFolder={permissions.hideEditFolders}
                                        />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>
                    <NewFolderDialog
                        openDialog={openDialog}
                        id={folderId!}
                        name={folderName!}
                        httpAction={httpAction}
                        onClose={toggleOpenDialog}
                        onRefresh={fetchReportsFolders} 
                    />
                </>
            }
        </>
    )
}