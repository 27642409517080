import { useState } from "react";

export const useCustomSelect = () => {
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const onChangeFocus = (): void => {
        setIsFocused(!isFocused);
    }

    return {
        isFocused,
        onChangeFocus
    }
}