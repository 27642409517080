import { Grid, Box, Typography } from "@mui/material";
import { CustomDataGridPro } from "../../components/";
import { useParams } from "react-router-dom"
import { CustomBreadCrumbs } from "../../components/";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { User } from "../../model/User";
import { ForbiddenAccessMessage } from "../../components/ForbiddenAccessMessage";


export const TestDataGrid = (): JSX.Element => {
    const { tableName } = useParams<{ tableName: string }>();

    const formattedTableName = tableName!.replace("-", " ");

    const user = new User();
    const canReadTables = !user.HasForbiddenAccess("Can Read Tables");
    const canEditRows = !user.HasForbiddenAccess("Can Edit Rows");
    const canAddRows = !user.HasForbiddenAccess("Can Add Rows");

    return (
        <>
            <Grid sx={{ mt: 2.8, mb: 2.5 }}>
                <CustomBreadCrumbs path={"Home" + "/" + formattedTableName.charAt(0).toUpperCase() + formattedTableName.slice(1)}/>
            </Grid>
            <Grid xs={12} sm={5} md={5} lg={8} sx={{ mb: 2.5 }}>
                <Typography textAlign={{ xs: "center", sm: "start" }} variant="h4" sx={{ mb: 1.25, color: "#1BA0E9", fontWeight: 600, fontSize: 25 }} >
                    {formattedTableName.charAt(0).toUpperCase() + formattedTableName.slice(1)}
                </Typography>
            </Grid>

            {!canReadTables && <ForbiddenAccessMessage />}
            {canReadTables &&
                <Box
                    sx={{
                        height: 500,

                        width: '100%',
                        '& .actions': {
                            color: 'text.secondary',
                        },
                        '& .textPrimary': {
                            color: 'text.primary',
                        },
                    }}>
                    <CustomDataGridPro tableName={tableName!.replaceAll("-", " ")} canEditRows={canEditRows} canAddRows={canAddRows} />
                </Box>
            }
        </>
    )
}