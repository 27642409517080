import React, { FunctionComponent, useState } from "react";
import { Grid, Box, Drawer, Stack, styled } from "@mui/material"
import { NavBar } from "../Navbar";
import { SideBar } from "../SideBar";
import { usePageLayout } from "./page.layout.hook";
import { IProps } from "./IProps";


export const PageLayout: FunctionComponent<IProps> = ({ ...props }): JSX.Element => {
    const { content, isLoading } = usePageLayout();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
        setOpen(!open);
    };


    return (
        <Box sx={{ width: "100%", display: "flex" }} >
            <SideBar 
                openPersistentSidebar={open} 
                openSidebar={mobileOpen} 
                onToggleSidebar={handleDrawerToggle} 
                content={content} 
                isLoading={isLoading}
            />
            <Box sx={{ width: "100%", display: "flex" }}>
                <NavBar onToggleSidebar={handleDrawerToggle}  />
                <Grid container >
                    <Grid item sx={{ mt: 6, justifyContent: "flex-start", padding: 4, paddingTop:4, mb: 4, width: "100%" }} >
                        {props.children}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}