import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import { IProps } from "./IProps";

const GridImageStyled = styled(Grid)<IProps>(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
        display: "none"
    }
}));

export const GridImage: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <GridImageStyled height="100%" item src={props.src} xs={6} >
            <img style={{width: "100%", height: "100%", objectFit: "cover"}} src={props.src} alt="meeting" />
        </GridImageStyled>
    )
}