import { FunctionComponent } from "react";
import { styled, Switch, FormControlLabel, Typography } from "@mui/material";
import { IProps } from "./IProps";

const StyledSwitch = styled(Switch)(({ theme }) => ({
    width: 60,
    height: 24,
    padding: 0,
    marginLeft: 10,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(30px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: "#1BA0E9",
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 20,
      height: 20,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: "rgba(0, 0, 0, 0.12)",
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      "&: after, &: before": {
        fontSize: "10px",
        fontWeight: 700,
        position: "absolute",
        top: "4px"
      },
      "&: after": {
        content: '"YES"',
        color: "white",
        left: "4px"
      },
      "&: before": {
        content: '"NO"',
        color: "rgba(0, 0, 0, 0.5)",
        right: "8px"
      }
    },
}))

export const CustomSwitch: FunctionComponent<IProps> = ({ label,  value, onChange }): JSX.Element => {
    return (
        <FormControlLabel 
            value="start" 
            labelPlacement="start" 
            label={<Typography sx={{ color: "rgba(0, 0, 0, 0.8)" }} fontSize={14} fontWeight={600}>{ label }</Typography>}
            sx={{ fontSize: 14, fontWeight: 700 }}
            control={<StyledSwitch value={value} onChange={onChange} defaultChecked={value} checked={value} />} />
    )
}