import React, { FunctionComponent, useState } from 'react'
import timezones from "timezones-list";
import { Typography, Divider, Stack, Box, MenuItem, SvgIcon, FormControl, FormLabel, Menu, TextField, FormControlLabel, Radio, RadioGroup, Button as MuiButton } from '@mui/material';
import { StepTitle } from "../StepTitle";
import { CustomSwitch } from '../../CustomSwitch';
import { CustomSelect } from '../../CustomSelect';
import { CustomDatePicker } from '../../CustomDatePicker';
import { CustomTimePicker } from "../../CustomTimePicker";
import { CustomInput } from '../../CustomInput';
import { Button } from '../../Button';
import { mdiWrenchClock, mdiWeb, mdiPound, mdiEmailOutline, mdiCalendarCheckOutline, mdiPlusThick } from "@mdi/js";
import dayjs, { Dayjs } from "dayjs";
import { Icon } from "@mdi/react";
import Grid from '@mui/material/Unstable_Grid2';
import { IStepFourProps } from "../IProps";
import { IStepFourState } from "../IState";

const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const minDate = dayjs("2000-01-01");

const ScheduleReport: FunctionComponent<{ value: boolean, onChange: () => void }> = (props) => {
    return (
        <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid spacing={2}>
                <Grid>
                    <Typography sx={{ fontSize: 16, fontWeight: 900, color: "#1BA0E9" }}>
                        Do you want to schedule this report?
                    </Typography>
                </Grid>
                <Grid>
                    <Typography sx={{ fontSize: 14, fontWeight: 400 }}>
                        Please adjust the parameters below
                    </Typography>
                </Grid>
            </Grid>

            <Divider flexItem variant='middle' orientation="vertical"></Divider>

            <Grid display="flex" alignItems="center">
                <CustomSwitch value={props.value} onChange={props.onChange} />
            </Grid>
        </Grid>

    )
}

export const StepFour: FunctionComponent<IStepFourProps<IStepFourState>> = ({ state, setter, onDisableInput, onDisableReportScheduled, onChange, onChangeDate, onChangeHour, onChangeEmails, onChangeRadio, generateTextfield }): JSX.Element => {
    return (
        <Box>
            <Grid xs={12}>
                <StepTitle title="Schedule Your New Report" />
            </Grid>
            <ScheduleReport value={state.isReportScheduled} onChange={() => onDisableReportScheduled!()} />
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} spacing={2} rowSpacing={4}>
                <Grid container xs={12} sm={12} md={12} spacing={2}>
                    <Grid xs={12} sm={6} md={6} width={"100%"} >  { /* Time Interval Select */}
                        <CustomSelect
                            value={state.timeInterval}
                            onChange={(event) => onChange(event, setter)}
                            disabled={!state.isReportScheduled}
                            width="100%"
                            name="timeInterval"
                            label="Time Interval"
                            StartIcon={(
                                <SvgIcon>
                                    <Icon path={mdiWrenchClock} />
                                </SvgIcon>
                            )}
                        >
                            <MenuItem value="Daily">
                                Daily
                            </MenuItem>
                            <MenuItem value="Weekly">
                                Weekly
                            </MenuItem>
                            <MenuItem value="Monthly">
                                Monthly
                            </MenuItem>
                        </CustomSelect>
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"}>{ /* Time Zone Select */}
                        <CustomSelect
                            disabled={!state.isReportScheduled}
                            width="100%"
                            label="Time Zone"
                            name="timeZone"
                            value={state.timeZone}
                            onChange={(event) => onChange(event, setter)}
                            StartIcon={(
                                <SvgIcon>
                                    <Icon path={mdiWeb} />
                                </SvgIcon>
                            )} >
                            <MenuItem value="UTC-5:00" >
                                Eastern Time (America/New_York)
                            </MenuItem>
                            <MenuItem value="UTC-6:00" >
                                Central Time (America/Chicago)
                            </MenuItem>
                            <MenuItem value="UTC-7:00" >
                                Mountain Time (America/Denver)
                            </MenuItem>
                            <MenuItem value="UTC-7:00" >
                                Mountain Time - Arizona (America/Phoenix)
                            </MenuItem>
                            <MenuItem value="UTC-8:00" >
                                Pacific Time (America/Los_Angeles)
                            </MenuItem>
                            <MenuItem value="UTC-9:00" >
                                Alaska Time (America/Anchorage)
                            </MenuItem>
                        </CustomSelect>
                    </Grid>
                </Grid>
                <Grid container display="flex" alignItems="center" xs={12} sm={12} md={12} spacing={3} >
                    <Grid container display="flex" alignItems="center" xs={12} sm={12} md >  { /* Time Interval Select */}
                        <Grid xs={2} sm={2} md={2} >
                            <Typography sx={{ fontSize: 14 }} >Every</Typography>
                        </Grid>
                        <Grid xs sm md width={"100%"}>
                            <CustomSelect
                                disabled={!state.isReportScheduled}
                                width="100%"
                                name="every"
                                label=""
                                value={state.every}
                                onChange={(event) => onChange(event, setter)}
                                StartIcon={(
                                    <SvgIcon>
                                        <Icon path={mdiPound} />
                                    </SvgIcon>
                                )} >
                                {
                                    [...Array(31).keys()].map((value, index) => {
                                        if (value === 0) {
                                            return;
                                        }

                                        return (
                                            <MenuItem value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </CustomSelect>
                        </Grid>
                    </Grid>
                    {state.timeInterval === "Weekly" && (
                        <Grid container display="flex" alignItems="center" xs={12} sm={12} md={4} >  { /* Time Interval Select */}
                            <Grid xs={2} sm={2} md={2} >
                                <Typography sx={{ fontSize: 14 }} >Week(s) On</Typography>
                            </Grid>
                            <Grid xs sm md width={"100%"}>
                                <CustomSelect
                                    disabled={!state.isReportScheduled}
                                    width="100%"
                                    name="on"
                                    label=""
                                    value={state.on}
                                    onChange={(event) => onChange(event, setter)}
                                    StartIcon={(
                                        <SvgIcon>
                                            <Icon path={mdiCalendarCheckOutline} />
                                        </SvgIcon>
                                    )} >
                                    {
                                        week.map((day, index) => {
                                            return (
                                                <MenuItem value={day}>
                                                    {day}
                                                </MenuItem>
                                            )
                                        })
                                    }
                                </CustomSelect>
                            </Grid>
                        </Grid>
                    )}
                    {
                        state.timeInterval === "Monthly" && (
                            <Grid container display="flex" alignItems="center" xs={12} sm={12} md={4} >  { /* Time Interval Select */}
                                <Grid xs={2} sm={2} md={3} >
                                    <Typography sx={{ fontSize: 14 }} >Month(s) (on)</Typography>
                                </Grid>
                                <Grid xs sm md width={"100%"}>
                                    <CustomSelect
                                        disabled={!state.isReportScheduled}
                                        width="100%"
                                        name="on"
                                        label=""
                                        value={state.on}
                                        onChange={(event) => onChange(event, setter)}
                                        StartIcon={(
                                            <SvgIcon>
                                                <Icon path={mdiCalendarCheckOutline} />
                                            </SvgIcon>
                                        )} >
                                        {
                                            [...Array(31).keys()].map((value, index) => {
                                                if (value === 0) {
                                                    return;
                                                }

                                                return (
                                                    <MenuItem value={value}>
                                                        {value}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </CustomSelect>
                                </Grid>
                            </Grid>
                        )
                    }
                    <Grid container display="flex" alignItems="center" xs={12} sm={12} md >  { /* Time Interval Select */}
                        <Grid xs={2} sm={2} md={2} >
                            <Typography sx={{ fontSize: 14 }} >{state.timeInterval === "Daily" ? "Day(s) at " : "At"}</Typography>
                        </Grid>
                        <Grid xs sm md width={"100%"}>
                            <CustomTimePicker
                                isDisabled={!state.isReportScheduled}
                                width="100%"
                                value={state.hour}
                                onChange={onChangeHour}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sm={6} md={6} width={"100%"}>
                    <CustomDatePicker 
                        disabled={!state.isReportScheduled} 
                        value={state.startDate} 
                        width="100%" 
                        label={{ text: "Starting On" }} 
                        onChange={(newDate) => onChangeDate(newDate, "startDate")} 
                        minDate={minDate}
                    />
                </Grid>
                <Grid sm={6} md={6} width={"100%"}>
                    <CustomDatePicker 
                        disabled={!state.isReportScheduled} 
                        value={state.endDate} 
                        width="100%" 
                        label={{ text: "Ending On" }} 
                        onChange={(newDate) => onChangeDate(newDate, "endDate")} 
                        minDate={minDate}
                    />
                </Grid>

                <Grid sm={12} md={12} container > {/*Email and Label Container*/}
                    <Grid sm={12} md={5} display="flex" sx={{ width: "100%" }} container spacing={0} >  {/*Email and Button Container*/}
                        <Grid sx={{ width: "100%" }} sm={9} md={9}>
                            {
                                state.emailInputs.map((textfield, index) => {
                                    return (
                                        <Box my={2} >
                                            <CustomInput
                                                label={{ text: "Email Notification" }}
                                                startIcon={<Icon path={mdiEmailOutline} />}
                                                name={`email${index}`}
                                                value={state.emails[`email${index}` as keyof typeof state.emails]}
                                                type="email"
                                                placeholder="janedoe@email.com"
                                                disabled={!state.isReportScheduled}
                                                onChange={onChangeEmails}
                                                width="100%"
                                                height={35}
                                            />
                                        </Box>
                                    )
                                })
                            }
                        </Grid>
                    </Grid>
                    <Grid sm={12} md={3} sx={{ mt: 2 }} display="flex" alignItems="end">
                        <Button disabled={!state.isReportScheduled} width={180} height={35} variant="outlined" startIcon={<Icon path={mdiPlusThick} color="#1BA0E9" size="18px" />} label="Add another email" onClick={generateTextfield} />
                    </Grid>
                    <Grid sm={12} md>
                        <FormControl>
                            <FormLabel>Attach this report as:</FormLabel>
                            <RadioGroup row value={state.reportFile} onChange={onChangeRadio} >
                                <FormControlLabel value="PDF" control={<Radio disabled={!state.isReportScheduled} />} label="PDF" />
                                <FormControlLabel value="CSV" control={<Radio disabled={!state.isReportScheduled} />} label="CSV" />
                                <FormControlLabel value="XLSX" control={<Radio disabled={!state.isReportScheduled} />} label="XLSX" />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>


            </Grid>
        </Box>
    )
}