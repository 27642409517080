import React, { FunctionComponent } from "react";
import { 
    Dialog, 
    DialogTitle, 
    DialogContent, 
    DialogContentText, 
    DialogActions, 
    Box, 
    Button, 
    FormControl, 
    Stack, 
    Typography 
} from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { mdiFile } from "@mdi/js"
import { Icon } from "@mdi/react";
import { IProps } from "./IProps";

export const FormFileDialog: FunctionComponent<IProps> = (props) => {
    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={props.isOpen}
        >
            <DialogTitle sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 22, letterSpacing: 0.5 }}>Upload a file</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Select a .csv from your machine.
                </DialogContentText>
                <Box
                    noValidate
                    component="form"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        m: 'auto',
                        width: 'fit-content',
                    }}
                >
                    <FormControl sx={{ mt: 2, minWidth: 120 }}>
                        <Button
                            startIcon={<FileUploadIcon />}
                            variant="outlined"
                            onClick={props.onOpenSelectionFile}
                            color={props.isError ? "error" : "primary"}
                        >
                            Upload a file
                        </Button>
                    </FormControl>
                    {(props.file && !props.isError) && (
                        <Stack direction="row" mt={2} spacing={0.5} >
                            <Icon path={mdiFile} size={0.9} color="#1BA0E9" />
                            <Typography
                                sx={{ fontSize: 12, color: "#1BA0E9" }}
                            >
                                {props.file.name}
                            </Typography>
                        </Stack>
                    )}
                    {(props.fileMessage && props.isError) && (
                        <Stack direction="row" mt={2} spacing={0.5} >
                            <Typography
                                sx={{ fontSize: 12, color: "red" }}
                            >
                                {props.fileMessage}
                            </Typography>
                        </Stack>
                    )}
                    <input accept=".csv" id="btn-file-upload" type="file" style={{ display: "none" }} onChange={props.onHandleSelectionFile} />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" disabled={props.file === null} onClick={props.onSave}>Save</Button>
                <Button onClick={props.onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}