import { useState, useEffect } from "react";
import { getAxios, route } from "../../utils/endpoints";


export const useNewFolderDialog = (httpAction: "POST" | "PUT", id: number, name: string, onClose: VoidFunction, cb?: VoidFunction) => {
    const [newFolder, setNewFolder] = useState<string>("");
    const [error, setError] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);


    const invalidInput = () => {
        if (newFolder === "") {
            setError("This field cannot be empty");

            return true;
        }

        else return false;
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewFolder(event.target.value);

        if (newFolder.length > 0) {
            setError("");
        }
    }

    const onCreateFolder = async (): Promise<void> => {
        try {
            await getAxios(route.apiReports.reportsFolders, "POST", {
                FolderName: newFolder
            });
        }

        catch (error) {
            console.error(error);
        }
    }

    const onRenameFolder = async (folderId: number, newName: string) => {
        try {
            await getAxios(route.apiReports.reportsFolders, "PUT", {
                FolderId: folderId,
                FolderName: newName
            });
        }

        catch (error) {
            console.error(error);
        }
    }

    const onClick = async (): Promise<void> => {
        if (invalidInput()) {
            return;
        }

        setIsLoading(true);

        if (httpAction === "POST") {
            await onCreateFolder();
        }

        else if (httpAction === "PUT") {
            await onRenameFolder(id, newFolder);
        }

        if (cb) {
            cb();
        }

        setNewFolder("");
        setIsLoading(false);
        onClose();
    }

    useEffect(() => {
        if (httpAction === "PUT") {
            setNewFolder(name);
        }
    }, [httpAction, name])

    return {
        newFolder,
        error,
        isLoading,
        onChange,
        onClick
    }
}