import React, { useState } from "react";
import { IState } from "./IState";

const initialState: IState = {
    adress: "",
    city: "",
    competitors: "",
    firstname: "",
    heater: "",
    lastname: "",
    leadOutcome: "",
    light: "",
    notes: "",
    phone: "",
    po: "",
    reassignToDealer: "",
    sentry: "",
    services: "",
    shadeside: "",
    shadespot: "",
    state: "",
    sunboot: "",
    sunbrero: "",
    suncover: "",
    sundrop: "",
    sunesta: "",
    sunlight: "",
    sunplus: "",
    sunroll: "",
    sunstyle: "",
    totalSalePrice: "",
    zip: ""
}

export const useEditLeadForm = () => {
    const [state, setState] = useState<IState>(initialState);

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;

        setState({
            ...state,
            [name]: value
        });
    }

    const onReset = (): void => {
        setState(initialState);
    }

    return { state, onChange, onReset }
}