import { FunctionComponent } from "react";
import { Grid, Typography, SvgIcon } from "@mui/material/"
import { useHint } from "./hint.hook"
import { IProps } from "./IProps"


export const Hint: FunctionComponent<IProps> = (props): JSX.Element => {
    const { Icon, color } = useHint(props.type);

    return (
        <Grid sx={{ mt: 1 }} container direction="row" alignItems="center" wrap="nowrap">
            {
                props.type !== "default" && (
                    <Grid item>
                        <SvgIcon>
                            {Icon}
                        </SvgIcon>
                    </Grid>
                )
            }
            <Grid item>
                <Typography sx={{ ml: 1, fontSize: 12, color: color }} >
                    { props.message }
                </Typography>
            </Grid>
        </Grid>
    )
}