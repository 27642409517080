import React, { useState } from "react";
import { store } from "../../../state";
import { ActionType } from "../../../state/action-types";
import { User } from "../../../model/User";

export const useFolderItem = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [openWarningModal, setOpenWarningModal] = useState<boolean>(false);

    const open = Boolean(anchorEl);

    const onClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    }

    const onClose = () => {
        setAnchorEl(null);
    }

    return {
        anchorEl,
        open,
        openWarningModal,
        setOpenWarningModal,
        onClick,
        onClose
    }
}