import React, { FunctionComponent } from "react";
import { MenuItem, SelectChangeEvent } from "@mui/material";
import { CustomSelect, CustomInput, Button } from "../../../components";
import Grid from '@mui/material/Unstable_Grid2';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PinDropIcon from '@mui/icons-material/PinDrop';

type IProps = {
    mode: string;
    value: string;
    onChangeSelect: (event: SelectChangeEvent<unknown>) => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onApply: () => void;
}

export const SearchFor: FunctionComponent<IProps> = (props) => {
    const [option, setOption] = React.useState<string>("1");
    const [text, setText] = React.useState<string>("");

    const renderSearchInputPropsBySearch = (opt: string) => {
        const props = {
            label: "",
            name: "",
            placeholder: "",
        }

        switch (opt) {
            case "1": {
                props.label = "Warranty ID";
                props.name = "warrantyID";
                props.placeholder = "#00000";
                break;
            }

            case "2": {
                props.label = "Lastname";
                props.name = "lastname";
                props.placeholder = "...";
                break;
            }
                
            case "7": {
                props.label = "Street Number";
                props.name = "streetNumber";
                props.placeholder = "...";
                break;
            }

            case "3": {
                props.label = "Street";
                props.name = "street";
                props.placeholder = "...";
                break;
            }

            case "4": {
                props.label = "City";
                props.name = "city";
                props.placeholder = "...";
                break;
            }

            case "5": {
                props.label = "Zip";
                props.name = "zip";
                props.placeholder = "...";
                break;
            }

            case "6": {
                props.label = "Phonenumber";
                props.name = "phonenumber";
                props.placeholder = "000000";
                break;
            }
        }

        return props;
    }

    const inputProps = renderSearchInputPropsBySearch(props.mode);

    return (
        <Grid container sx={{ mb: 1, ml: 1 }} columns={{ xs: 12, sm: 12, lg: 12 }} columnSpacing={{ lg: 2 }} >
            <Grid container sm={12} xs={12} lg={10} columnSpacing={{ xs: 0, sm: 2 }} rowSpacing={{ xs: 2 }}>
                <Grid sx={{ width: "100%" }} xs={12} sm={6} lg={4}>
                    <CustomSelect 
                        width={"100%"} 
                        placeholder="Leads with Phone #s Like" 
                        label="Search for" 
                        name="search"
                        value={props.mode}
                        onChange={props.onChangeSelect}
                    >
                        <MenuItem value="1">Warranty IDs Like</MenuItem>
                        <MenuItem value="2">Last Name like</MenuItem>
                        <MenuItem value="7">Street Number like</MenuItem>
                        <MenuItem value="3">Street like</MenuItem>
                        <MenuItem value="4">City like</MenuItem>
                        <MenuItem value="5">Zip like</MenuItem>
                        <MenuItem value="6">Phone like</MenuItem>
                    </CustomSelect>
                </Grid>
                <Grid sx={{ width: "100%" }} xs={12} sm={6} lg={4}>
                    <CustomInput 
                        width={"100%"} 
                        height={35} 
                        label={{ text: inputProps.label }} 
                        type="text" 
                        name={inputProps.name} 
                        placeholder={inputProps.placeholder} 
                        value={props.value} 
                        onChange={props.onChange} 
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                props.onApply();
                            }
                        }}
                    />
                </Grid>
                <Grid xs={12} sm={12} lg={2} display="flex" alignItems="end">
                    <Button width={120} height={40} label='Apply' onClick={props.onApply} />
                </Grid>
            </Grid>
        </Grid>
    )
}