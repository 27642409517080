import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DropdownValue } from "../../types/DropdownValue"
import { Label, Hint } from '../component-utils';
import { IProps } from "./IProps";

const icon: React.ReactNode = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon: React.ReactNode = <CheckBoxIcon fontSize="small" />;

const LISTBOX_PADDING: number = 8;

const renderRow: React.FunctionComponent<ListChildComponentProps<DropdownValue>> = (props): JSX.Element => {
    const { data, index, style } = props;
    const dataSet: Array<any> = (data as unknown as Array<any>)[index];
    const inlineStyle = {
        ...style,
        top: (style.top! as number) + LISTBOX_PADDING
    };

    return (
        <Typography component="li" { ...dataSet[0] } noWrap style={inlineStyle}>
            <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                checked={dataSet[2].selected}
            />
            { dataSet[1].description }
        </Typography>
    )
}

const OuterElementContext: React.Context<{}> = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);

    return (
        <div ref={ref} {...props} {...outerProps} />
    );
});

const useResetCache = (data: any): React.RefObject<VariableSizeList<any>> => {
    const ref: React.RefObject<VariableSizeList<any>> = React.useRef<VariableSizeList>(null);

    React.useEffect(() => {
        if (ref.current !== null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);

    return ref
}

const ListBoxComponent = React.forwardRef<
    HTMLDivElement,
    React.HTMLAttributes<HTMLElement>
>(function ListBoxComponent(props, ref) {
    const { children, ...other } = props;
    const itemData: React.ReactNode[] = [];

    (children as React.ReactChild[]).forEach(
        (item: React.ReactNode & { children?: React.ReactNode[] }) => {
            itemData.push(item);
            itemData.push(...(item.children || []));
        }
    );

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child: React.ReactNode) => {
        if ((child as Object).hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    }

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize
        }

        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    }

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    )
});

const StyledPopper = styled(Popper)({
    [`&. ${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        '& ul': {
            padding: 0,
            margin: 0,
           
        }
    }
});



export const CustomMultipleAutocomplete: React.FunctionComponent<IProps> = (props): JSX.Element => {

    return (
        <Autocomplete
            sx={{ width: "100%"}}
            multiple
            limitTags={2}
            disableListWrap
            disableCloseOnSelect
            PopperComponent={StyledPopper}
            ListboxComponent={ListBoxComponent}
            options={props.options}
            getOptionLabel={(option) => option.description}
            onChange={props.onChange}
            value={props.value}
            renderInput={(params) => {
                params.size = "small";
               

                return (
                    <>
                        <Label text={props.label.text} color={props.label.color} />
                        <TextField { ...params } />
                        { props.hint && <Hint type={props.hint?.type} message={props.hint?.message} /> }
                    </>
                )
            }}
            renderOption={(props, option, state) => [props, option, state] as React.ReactNode}
        />
    )
}