import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: boolean = false;

const reducer = (state: boolean = initialState, action: Action): boolean => {
    switch (action.type) {
        case ActionType.ON:
            return action.payload;
        case ActionType.OFF:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;