import React, { FunctionComponent } from "react";
import { Typography, Grid, Divider } from "@mui/material"
import { IProps } from "./IProps";

export const StepTitle: FunctionComponent<IProps> = (props) => {
    return (
        <Grid xs={12} display="flex" alignItems="center" mt={4} >
            <Typography fontWeight={700} fontSize={22} sx={{ color: "#1BA0E9" }}>{props.title}</Typography>
            <Divider sx={{ display: { xs: "none", sm: "none", md: "flex" }, width: "82%" }} variant="inset" />
        </Grid>
    )
}