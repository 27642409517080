import { useLocation, useParams } from "react-router-dom";
import { StepOne } from "./StepOne";
import { StepTwo } from "./StepTwo";
import { StepThree } from "./StepThree";
import { StepFour } from "./StepFour";
import { useExportModule } from "./hooks/useExportModuleForm";
import { FormStepper } from "./FormStepper";
import { Card, CardContent, Backdrop, CircularProgress } from '@mui/material';
import { StepFive } from "./StepFive";
import { ExportModuleFormProvider, useExportModuleFormContext } from "./context";
import { ActionButtons } from "./Components/ActionButtons";
import { AlertDialog } from "../AlertDialog";
import { ForbiddenAccessMessage } from "../ForbiddenAccessMessage";

export const ExportModuleForm = () => {
    const location = useLocation();
    const mode = new URLSearchParams(location.search).get("mode");
    const { id } = useParams();

    const { activeStep, disableAddEditExport, openDiscardDialog, onOpenDiscardDialog, onDiscard, jumpToStep, handleStepper } = useExportModule(Boolean(id));


    return (
        <ExportModuleFormProvider>
            <>
                <Card sx={{ width: "100%", boxShadow: 2, mt: 5, borderRadius: 4 }}>
                    {disableAddEditExport && <ForbiddenAccessMessage/>}
                    {!disableAddEditExport &&
                        <>
                            <CardContent>
                                <FormStepper activeStep={activeStep} onJumpStep={jumpToStep} mode={mode!} />
                                {(activeStep === 0) && (<StepOne mode={mode! as any} id={id} />)}
                                {(activeStep === 1) && (<StepTwo mode={mode! as any} id={id} />)}
                                {(activeStep === 2) && (<StepThree mode={mode! as any} id={id} />)}
                                {(activeStep === 3) && (<StepFour mode={mode! as any} id={id} />)!}
                                {(activeStep === 4) && (<StepFive mode={mode! as any} id={id} />)}
                            </CardContent>
                            <ActionButtons mode={mode!} activeStep={activeStep} handleStepper={handleStepper} handleDiscard={onOpenDiscardDialog} />
                            <AlertDialog
                                onContinue={onOpenDiscardDialog}
                                onCancel={onDiscard}
                                open={openDiscardDialog}
                                type="warning"
                                title="Are you sure?"
                                description="You have not saved the work done yet. If you quit, you will lose the attributes you have added until now."
                                onCancelButtonProps={{
                                    title: "Yes, discard it",
                                    color: "red"
                                }}
                                onSubmitButtonProps={{
                                    title: "Return and save",
                                }}
                            />
                        </>
                    }
                </Card>
            </>
        </ExportModuleFormProvider>
    )
}