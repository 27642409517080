import { FunctionComponent } from "react";
import { MenuItem, Button as MUIButton } from "@mui/material";
import { DataGridPro, GridFooter, GridFooterContainer, GridSearchIcon } from "@mui/x-data-grid-pro";
import AddIcon from "@mui/icons-material/Add"
import { styled } from "@mui/material/styles";
import { IProps } from "./IProps";
import { Button } from "../Button";
import Grid from '@mui/material/Unstable_Grid2';
import { CustomSelect } from "../CustomSelect";
import { CustomInput } from "../CustomInput";
import { useCustomDatagridPro } from "./useCustomDataGridPro";
import SearchIcon from '@mui/icons-material/Search';


const StyledDataGrid = styled(DataGridPro)<IProps>(({ theme }) => ({
    "& .MuiDataGrid-columnHeader": {
        backgroundColor: "#1BA0E9",
        color: "#FFFFFF",
        fontSize: 14,

    },
    "& .MuiDataGrid-columnSeparator": {
        visibility: "",

    },
    "&. MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus": {
        outline: "transparent"
    },
    "& .MuiDataGrid-row": {
        fontSize: 14,
    },
    "& .MuiDataGrid-virtualScrollerRenderZone": {
        "& .MuiDataGrid-row": {
            "&:nth-child(2n)": {
                backgroundColor: "#FAFAFA"
            },
            "&: hover":{
                backgroundColor: "#DDF1FC",
                borderBottomColor: "#8DD0F4",
            },
            "&:nth-last-child(1)": {
                boxShadow: "0px 0px 2px rgba(40, 72, 154, 0.1), 0px 3px 6px rgba(40, 72, 154, 0.1)"
            }

        }
    },

    ".MuiDataGrid-row: hover": {
        backgroundColor: "#DDF1FC",
        borderBottomColor: "#8DD0F4",
    }
    
}));


export const CustomDataGridPro: FunctionComponent<IProps> = (props): JSX.Element => {
    const {
        apiRef,
        rows,
        isLoading,
        rowModesModel,
        search,
        rowIndex,
        pageSize,
        total,
        filter,
        order,
        sortModel,
        gridColumns,
        isFilterEnabled,
        filterDropdownItems,
        isSearchEnabled,
        buttons,
        onClick,
        onChange,
        onChangeFilter,
        onPageChange,
        onPageSizeChange,
        onApplyFilter,
        onChangeOrder,
        handleSortModelChange,
        handleRowEditStart,
        handleAddRow,
        
        handleRowEditStop,
        processRowUpdate
    } = useCustomDatagridPro(props.tableName, props.canEditRows ?? true, props.canAddRows ?? true);

    const CustomFooter = () => {
        return (
            <GridFooterContainer> 
                {props.canAddRows &&
                    <MUIButton sx={{ textTransform: "none", fontWeight: 700, color: "#1BA0E9", marginLeft: 2 }} variant="text" startIcon={<AddIcon />} onClick={handleAddRow}>
                        Add Row
                    </MUIButton>
                }
                <GridFooter sx={{ border: "none" }} />
            </GridFooterContainer>
        )
    }

    return (
        <>
            <Grid columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} >{/*top menu container */}
                <Grid sm={12} md={12} lg={12} container columnSpacing={{ xs: 0, md: 3 }} rowSpacing={{ xs: 3 }} width={"100%"} sx={{mb:2.5}}> {/*select, search and button container */}
                    <Grid xs={12} sm={12} md={6} lg={5} sx={{ width: "100%" }} display={isFilterEnabled ? "flex" : "none"}>
                        <CustomSelect width={150} name="type" label="Filter" value={filter} onChange={onChangeFilter} >
                            {
                                filterDropdownItems?.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.id}>
                                            {item.description}
                                        </MenuItem>
                                    )
                                })
                            }
                        </CustomSelect>
                    </Grid>

                    <Grid xs={12} sm={12} md={4} lg={5} display={isSearchEnabled ? "flex" : "none"} width="100%">
                        <CustomInput startAdornment={<SearchIcon/>} width={"100%"} height={35} onChange={onChange} value={search} label={{ text: "Search", color: "rgba(0, 0, 0, 0.8)" }} />
                    </Grid>
                    <Grid xs={12} sm={3} md={2} lg={2} width={"100%"} display={isSearchEnabled ? "flex" : "none"} alignItems="flex-end">
                        <Button width={"100%"} height={35} onClick={onApplyFilter} label="Apply" />
                    </Grid>
                </Grid>

                <Grid container xs={12} sm={12} lg={12} columnSpacing={{ xs: 0, sm: 3 }} rowSpacing={{ xs: 3 }} width={"100%"} sx={{mb:1.25}}>
                    
                    <Grid width={"100%"} xs={12} sm={4} md={4} lg={3}>
                        {buttons?.map((item, index) => {
                            return (
                                <Button sx={{paddingTop: 1, paddingLeft:2.5, paddingBottom: 1, paddingRight:2.5}} width={"100%"} height={35} key={index} value={item.name} label={item.heading} variant="outlined" onClick={() => onClick(item.id)} />
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <StyledDataGrid
                initialState={{
                    sorting: {
                        sortModel: sortModel
                    },
                    pinnedColumns:{right:["actions"]}
                }}
                tableName={props.tableName}
                TopLeftElement={props.TopLeftElement}
                sx={{ width: "100%", borderRadius: 2.5, boxShadow:"0px 0px 4px rgba(40, 72, 154, 0.04), 0px 8px 16px rgba(40, 72, 154, 0.08);"}}
                disableColumnFilter
                disableColumnMenu
                disableSelectionOnClick
                components={{Footer: CustomFooter}}
                rowHeight={65}
                columns={gridColumns}
                apiRef={apiRef}
                rows={rows}
                sortModel={sortModel}
                sortingOrder={['desc', 'asc']}
                page={rowIndex}
                rowsPerPageOptions={[5, 10, 15, 25]}
                pageSize={pageSize}
                loading={isLoading}
                getRowId={(row) => row.id}
                pagination
                editMode="row"
                paginationMode="server"
                sortingMode="server"
                rowModesModel={rowModesModel}
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
                rowCount={total}
                isCellEditable={() => false}
                onRowEditStart={handleRowEditStart}
                onRowEditStop={handleRowEditStop}
                onSortModelChange={(newModel) => {
                    handleSortModelChange(newModel)
                }}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                
            />
        </>
    )
}