import React, { useEffect, useCallback } from "react";
import {
    Typography,
    Stack,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import { CustomSelect, CustomInput, LeadGrid, CustomBreadCrumbs } from '../../components';
import { BetweenDatesAndDealer } from "./BetweenDatesAndDealer";
import Grid from '@mui/material/Unstable_Grid2';
import { useState } from "react";
import { SearchFor } from "./SearchFor";
import { WarrantyGrid } from "../../components/WarrantyGrid";
import { getAxios } from "../../utils/endpoints";
import { formatDateRange } from "../../utils/time";
import { Warranty } from "../../types/Warranty";
import { useViewWarranties } from "./hook";

type IWarrantyResponse = {
    items: Warranty[] | null;
    totalRecordCount: number;
}

export const ViewWarranties = (): JSX.Element => {
    const { 
        filter,
        mode,
        onChange,
        onChangeSelect,
        onFetchWarranties,
        onFetchWarrantiesByDateAndDealer,
        onFetchWarrantiesBySearch,
        onPageChange,
        onPageSizeChange,
        pageSize,
        rows,
        search,
        startIndex,
        totalRecordCount,
        setFilter
     } = useViewWarranties();

    return (
        <>
            <Grid xs={12} sx={{ mb: 2 }}>
                <CustomBreadCrumbs path="Home/View Warranties" />
            </Grid>
            <Grid container columns={{ xs: 12, sm: 10, md: 10, lg: 12 }} justifyContent="space-between" sx={{ mb: 3 }}>
                <Grid xs={12} sm={5} md={5} lg={8}>
                    <Typography textAlign={{ xs: "center", sm: "start" }} variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25 }} >
                        View Warranties
                    </Typography>
                    <Typography textAlign={{ xs: "center", sm: "start" }} sx={{ fontSize: 14, marginTop: '10px' }} >
                        What would you like to view?
                    </Typography>
                </Grid>
                <Grid xs={12} sm={5} md={5} lg={4} display="flex-end">
                    <CustomSelect width={"100%"} name="filter" placeholder="Search for..." label="Warranties by" value={filter} onChange={(event) => setFilter(event.target.value as string)}>
                    <MenuItem value="0">All</MenuItem>
                        <MenuItem value="1">All Received Between a Date and Dealer</MenuItem>
                        <MenuItem value="2">Search For</MenuItem>
                    </CustomSelect>
                </Grid>
            </Grid>

            {(filter === "1") && <BetweenDatesAndDealer onApply={(start, end, dealer) => onFetchWarrantiesByDateAndDealer(start, end, dealer)} />}
            {(filter === "2") && <SearchFor
                value={search}
                mode={mode}
                onChangeSelect={onChangeSelect}
                onChange={onChange}
                onApply={() => onFetchWarrantiesBySearch(mode, search)} />}
            {/*state.errorMessage && <Typography color="red" fontSize={12}>{state.errorMessage}</Typography>*/}
            <WarrantyGrid rows={rows} total={totalRecordCount} startIndex={startIndex} pageSize={pageSize} onPageChange={onPageChange} onPageSizeChange={onPageSizeChange} />
        </>
    )
}