import { useState, useEffect } from "react";
import { AccessCategory } from "../../types/AccessCategory";
import { AccessTable } from "../../types/AccessTable";
import { getAxios, route } from "../../utils/endpoints"
import { User } from "../../model/User";

export const useDashboardPanel = () => {
    const [categories, setCategories] = useState<AccessCategory[]>([]);
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [items, setItems] = useState<AccessTable[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const user = new User();
    const disableSaveButton = user.HasForbiddenAccess("Can Edit Dashboard Tables Tab");

    const fetchTableAccessCategories = async (): Promise<void> => {
        const response = await getAxios(route.apiAccessDashBoard.tableCategories, "GET");

        const data: AccessCategory[] = await response.data;

        setCategories(data);
    }

    const fetchTableAccessItems = async (): Promise<void> => {
        const response = await getAxios(route.apiAccessDashBoard.tables, "GET");

        const data: AccessTable[] = await response.data;

        const selectedItems = data.filter((item) => item.isSelected);

        const selectedTableItemsId = selectedItems.map((item) => item.dashboardTableID);

        setItems(data);
        setSelectedItems(selectedTableItemsId);
        setIsLoading(false);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val: number = parseInt(event.target.value);

        if (event.target.checked) {
            setSelectedItems([...selectedItems, val]);
        }

        else {
            const filteredArr: number[] = selectedItems.filter((item) => item !== val);

            setSelectedItems(filteredArr);
        }
    }

    const onUpdateSelectedTables = async (): Promise<void> => {
        setIsLoading(true);
        
        try {
            const response = await getAxios(route.apiAccessDashBoard.tables, "PUT", {
                optionsIds: selectedItems
            });
    
            if (response.status === 200) {
                fetchTableAccessItems();            
            }
    
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchTableAccessCategories();
        fetchTableAccessItems();
    }, []);

    return {
        categories,
        items,
        selectedItems,
        isLoading,
        disableSaveButton,
        onChange,
        onUpdateSelectedTables,
        fetchTableAccessItems
    };
}