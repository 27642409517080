import React, { FunctionComponent, useState } from "react";
import {
    Card,
    CardContent,
    Typography,
    Box,
    Stack,
    MenuItem,
    Snackbar,
    Alert,
    Grid,
    Button as MuiButton,
    ButtonGroup,
    IconButton,
    Divider,
    SelectChangeEvent,

} from "@mui/material";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails, { AccordionDetailsProps } from "@mui/material/AccordionDetails";
import { Button, CustomInput, CustomSelect } from "../../components";
import { CustomBreadCrumbs } from "../../components/CustomBreadcrumbs";
import { CustomTextArea } from "../../components/CustomTextArea";
import { Save, Search, PlusOne, Add, Remove, Expand } from "@mui/icons-material";
import { User } from "../../model/User";
import { CustomSnackbar } from "../../components/CustomSnackbar";
import { LeadInfoDialog } from "../../components/LeadInfoDialog";
import { getAxios, route } from "../../utils/endpoints";
import { DropdownValue } from "../../types/DropdownValue";
import { useAddLead } from "./hook";
import { CustomAutocomplete } from "../../components/CustomAutocomplete";

const message: string = `
    Warm Transfer to
    (320)348-4419 (First Number) (Call Twice)
    (763)360-9082 (CALL ONLY SATURDAY OR AFTER HOURS)
    (area code 763 pgone number is Brittany's Cell Phone - NEVER GIVE TO CUSTOMER)\n\n
    IF needing to Tentatively Set: Mon. -fRI.:
    Anytime between 12pm - 7:30pm | Sat/:
    Anytime between 10am - 1pm 
    **Never tentatively set anything earlier than 12pm without dealer approval**
`

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    '&:before': {
        display: "none"
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: "white" }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "#ed3e3e",
    color: "white",
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
    backgroundColor: "#f7d5d7",
}));

const SectionTitle: FunctionComponent<{ title: string }> = (props): JSX.Element => {
    return (
        <Box sx={{ p: 2, backgroundColor: "#F5F5F5", borderRadius: 4, mt: 2, mb: 2 }}>
            <Typography fontSize={18} fontWeight="600" color="#1BA0E9">
                {props.title}
            </Typography>
        </Box>
    )
}

export const AddLead = (): JSX.Element => {
    const {
        leadState,
        addPhoneNumber,
        dealerSources,
        deletePhoneNumber,
        handleClose,
        onHandlePhonenumberChange,
        onChangeDealer,
        handleOpen,
        isOpen,
        dealer,
        onChange,
        onChangeAutocomplete,
        source,
        phoneNumbers,
        products,
        onChangeSelect,
        onAddLead,
        onFetchDealerFormWithDealerInfo
    } = useAddLead();

    const user: User = new User();

    return (
        <>
            <Grid xs={12} sx={{ mb: 2 }}>
                <CustomBreadCrumbs path="Home/Add Lead" />
            </Grid>
            <Grid container columns={{ xs: 12, sm: 10, md: 10, lg: 12 }} justifyContent="space-between" sx={{ mb: 3 }}>
                <Grid xs={12} sm={5} md={5} lg={8}>
                    <Typography textAlign={{ xs: "center", sm: "start" }} variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25 }} >
                        Add Lead
                    </Typography>
                </Grid>
                <Grid xs={12} md={6} sm={4} mt={4}>
                    <Accordion>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Important Notice</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                Warm Transfer to
                                (320)348-4419 (First Number) (Call Twice)
                                (763)360-9082 (CALL ONLY SATURDAY OR AFTER HOURS)
                            </Typography>
                            <br></br>
                            <Typography>
                                (area code 763 pgone number is Brittany's Cell Phone - NEVER GIVE TO CUSTOMER)
                                IF needing to Tentatively Set: Mon. -fRI.:
                            </Typography>
                            <br></br>
                            <Typography>
                                IF needing to Tentatively Set: Mon. -fRI.:
                                Anytime between 12pm - 7:30pm | Sat.:
                                Anytime between 10am - 1pm
                            </Typography>
                            <br></br>
                            <Typography>
                                **Never tentatively set anything earlier than 12pm without dealer approval**
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                <Box width="100%" display="flex" alignItems="center" gap={2} mt={4}>
                    <CustomSelect name="dealer" value={dealer} onChange={onChangeDealer} label="Dealer" >
                        {
                            user.Accounts.map((val) => {
                                return (
                                    <MenuItem value={val.dealerId} >{val.companyName}</MenuItem>
                                )
                            })
                        }
                    </CustomSelect>
                    <ButtonGroup
                        disableElevation
                        variant="contained"
                        aria-label="Disabled elevation buttons"
                        sx={{ marginTop: 3 }}
                    >
                        <MuiButton sx={{ backgroundColor: "#1BA0E9", textTransform: "none", fontFamily: `"Lato", "Roboto", "Helvetica", "Arial"` }} onClick={onFetchDealerFormWithDealerInfo} >Find dealer</MuiButton>
                        <MuiButton
                            size="small"
                            aria-haspopup="menu"
                            sx={{ backgroundColor: "#1BA0E9" }}
                            disabled={!dealer}
                            onClick={handleOpen}
                        >
                            <Search />
                        </MuiButton>
                    </ButtonGroup>
                </Box>
            </Grid>
            <Box display="flex" alignItems="center" justifyContent="center">
                <Card sx={{ borderRadius: 4, width: "100%" }} >
                    <CardContent>
                        <SectionTitle title="Please fill the lead information" />
                        <CustomAutocomplete
                            disabled={false}
                            options={dealerSources}
                            name="dialedTollFree"
                            label={{
                                text: "Lead Source"
                            }}
                            value={source}
                            onChange={(event, val) => onChangeAutocomplete(event, val, "")}
                        />

                        <Box
                            sx={{
                                display: 'grid',
                                gridTemplateColumns: { sm: '1fr 1fr 1fr' },
                                gap: 2,
                                mt: 4
                            }}
                        >
                            <CustomInput
                                name="firstname"
                                value={leadState.firstname}
                                height={35}
                                label={{ text: "Firstname" }}
                                onChange={onChange}
                            />
                            <CustomInput
                                name="lastname"
                                value={leadState.lastname}
                                height={35}
                                onChange={onChange}
                                label={{ text: "Lastname" }}
                            />
                            <CustomInput
                                name="address"
                                value={leadState.address}
                                height={35}
                                onChange={onChange}
                                label={{ text: "Address" }}
                            />
                            <CustomInput
                                name="city"
                                value={leadState.city}
                                height={35}
                                onChange={onChange}
                                label={{ text: "City" }}
                            />
                            <CustomInput
                                name="state"
                                value={leadState.state}
                                onChange={onChange}
                                height={35}
                                label={{ text: "State" }}
                            />
                            <CustomInput
                                name="zip"
                                value={leadState.zip}
                                onChange={onChange}
                                height={35}
                                label={{ text: "Zip" }}
                            />
                            <CustomInput
                                name="email"
                                value={leadState.email}
                                onChange={onChange}
                                height={35}
                                label={{ text: "Email" }}
                            />
                            <CustomInput
                                name="email2"
                                value={leadState.email2}
                                onChange={onChange}
                                height={35}
                                label={{ text: "Email 2" }}
                            />
                            <CustomSelect
                                name="product"
                                value={leadState.product}
                                label="Product"
                                onChange={onChangeSelect}
                            >
                                {
                                    products.map((val) => {
                                        return (
                                            <MenuItem value={val.id}>
                                                {val.description}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </CustomSelect>
                            <CustomInput
                                name="status"
                                value={leadState.status}
                                onChange={onChange}
                                height={35}
                                label={{ text: "Status" }}
                            />
                            <CustomInput
                                name="apptDt"
                                value={leadState.apptDt}
                                onChange={onChange}
                                height={35}
                                label={{ text: "Appt Dt" }}
                            />
                            <CustomInput
                                name="time"
                                value={leadState.time}
                                height={35}
                                onChange={onChange}
                                label={{ text: "Time" }}
                            />
                        </Box>
                        <SectionTitle title={`Add Phone numbers (${phoneNumbers.length} / 3)`} />
                        <Box display="flex" gap={2} my={3}>
                            <Stack direction="column" spacing={2} >
                                {
                                    phoneNumbers.map((_, index) => {
                                        return (
                                            <CustomInput
                                                name={index.toString()}
                                                value={phoneNumbers[index]}
                                                onChange={onHandlePhonenumberChange}
                                                height={35}
                                                label={{ text: "Phone number" }}
                                            />
                                        )
                                    })
                                }
                            </Stack>
                            <Box mt={3} display="flex" alignItems="flex-start" gap={1} >
                                <IconButton onClick={addPhoneNumber} sx={{ borderRadius: 2, bgcolor: "#1BA0E9", color: "white", ":hover": { bgcolor: "#1BA0E9", color: "white" } }} >
                                    <Add />
                                </IconButton>
                                <IconButton onClick={deletePhoneNumber} sx={{ borderRadius: 2, bgcolor: "#ed3e3e", color: "white", ":hover": { bgcolor: "#ed3e3e", color: "white" } }} >
                                    <Remove />
                                </IconButton>
                            </Box>
                        </Box>
                        <SectionTitle title="Notes" />
                        <Box mt={2} >
                            <CustomTextArea name="notes" value={leadState.notes} onChange={onChange} width="100%" maxLength={500} />
                        </Box>
                        <Box sx={{ width: "100%", borderTop: "1px solid rgba(0, 0, 0, 0.12)", mt: 4, p: 2 }}>
                            <Stack direction="row" justifyContent="flex-end">
                                <Stack direction="row" spacing={2}>
                                    <Button variant="outlined" label="Cancel" />
                                    <Button startIcon={<Save />} label="Save" onClick={onAddLead} />
                                </Stack>
                            </Stack>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
            { /* <CustomSnackbar variant="primary" level="success" message="This is a success message!" /> */}
            <LeadInfoDialog id={dealer} open={isOpen} onClose={handleClose} />
        </>
    )
}