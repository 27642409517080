import React, { FunctionComponent, useState } from 'react';
import {
    Typography,
    Stack,
    Chip,
    SvgIcon,
} from '@mui/material';
import { mdiCheck } from "@mdi/js"
import { Icon } from "@mdi/react";
import { StepTitle } from "../StepTitle";
import { DraggableItem } from "../DraggableItem";
import Grid from '@mui/material/Unstable_Grid2';
import { IStepTwoProps, IColumn } from "../IProps";
import { IStepTwoState } from "../IState";
import { SortableContainer as GridSortable } from "../../GridSortable";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const defaultSx = {
    border: "1px solid rgba(0, 0, 0, 0.6) ",
    backgroundColor: "transparent",
    color: "rgba(0, 0, 0, 0.6)",
}

const checkedSx = {
    backgroundColor: "#1BA0E9",
    color: "#FFFFFF",
}

export const StepTwo: FunctionComponent<IStepTwoProps<IStepTwoState>> = ({ onSortEnd, state, onSelect, onChangeChecked }): JSX.Element => {

    return (
        <Grid container sx={{ width: "100%" }} >
            {state.isLoading 
            ? (
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            )
            : (
                <>
                    <Grid xs={12} sm={12} lg={12}>
                        <StepTitle title="Select columns and filters" />
                    </Grid>
                    <Grid width={"100%"} container mt={4} columns={{ xs: 12, sm: 12, lg: 12 }} > {/*Main Container*/}
                        <Grid width={"100%"} xs={12} sm={12} lg={12}>
                            <Typography sx={{ fontWeight: 700, color: "#1BA0E9", fontSize: 18 }} >
                                Select the columns
                            </Typography>
                            {(!state.isValid && state.selectedOptions.length < 1) && (
                                <Typography sx={{ fontWeight: 700, color: "red", fontSize: 12, mt: 1 }} >
                                    *Please select at least one of the columns
                                </Typography>
                            )}
                        </Grid>
                        <Grid width={"100%"} xs={12} sm={12} lg={12}>
                            <Typography mt={1} sx={{ fontSize: 14 }} >
                                Drag and reorder the columns
                            </Typography>
                        </Grid>
                        <GridSortable
                            items={state.columns}
                            onDragEnd={(event) => onSortEnd(event.active.id as number, event.over?.id as number)}
                            onChangeChecked={(event, isChecked) => onChangeChecked(event, isChecked)}
                        /> {/* Drag and Drop Options Component */}
                        <Grid mt={4} container xs={12}> {/*Chip and Typography Container*/}
                            <Grid xs={12}>
                                <Typography sx={{ fontWeight: 700, color: "#1BA0E9", fontSize: 18 }} >
                                    Select the filters
                                </Typography>
                                {(!state.isValid && state.selectedFilters.length < 1) && (
                                    <Typography sx={{ fontWeight: 700, color: "red", fontSize: 12, mt: 1 }} >
                                        *Please select at least one of the filters
                                    </Typography>
                                )}
                            </Grid>
                            <Grid xs={12}>
                                <Typography mt={1} sx={{ fontSize: 14 }}>
                                    Choose the first four (4)* filters you need for this report to use quickly.
                                </Typography>
                            </Grid>
                            <Grid container xs={12} sm={12} md={12} display="flex-wrap" justifyContent="space-between" wrap="wrap" alignItems="center" spacing={2} mt={2} >
                                {
                                    state.filters.map((filter, index) => {

                                        const currentSx = filter.checked ? checkedSx : defaultSx;
                                        const checkMark: React.ReactElement | undefined = filter.checked ? (
                                            <SvgIcon sx={{ fontSize: 18 }} key={index}>
                                                <Icon path={mdiCheck} color="#FFFFFF" />
                                            </SvgIcon>
                                        ) : undefined;

                                        return (
                                            <Grid xs={6} sm={3} md={2} lg={2} xl={1.2}>
                                                <Chip
                                                    icon={checkMark}
                                                    variant="filled"
                                                    key={index}
                                                    label={filter.description}
                                                    sx={{ maxWidth: 200, width: "100%", height: 35, fontSize: 14, cursor: "pointer", ...currentSx }}
                                                    onClick={() => onSelect(filter)}
                                                />
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            
        </Grid>
    )
}