import React, { useState, useEffect, useCallback } from "react";
import { getAxios } from "../../utils/endpoints";
import { FunctionLevelSecurity } from "../../types/FunctionLevelSecurity"
import { PageLevelSecurity } from "../../types/PageLevelSecurity";

export const useAssignDialog = (username: string, onCancel: () => void) => {
    const [pageLevelSecurityItems, setPageLevelSecurityItems] = useState<PageLevelSecurity[]>([]);
    const [functionLevelSecurityItems, setFunctionLevelSecurityItems] = useState<FunctionLevelSecurity[]>([]);
    const [pageLevelIds, setPageLevelIds] = useState<number[]>([]);
    const [functionLevelIds, setFunctionLevelIds] = useState<number[]>([]);
    
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarType, setSnackbarType] = useState<"info" | "error">("info");

    const onFetchItemsByEndpointName = useCallback(async (itemName: "menu" | "access") => {
        const response = await getAxios(`/access/user-${itemName}`, "GET", undefined, {
            selectedUsername: username
        });

        let data: any;

        if (itemName === "menu") {
            data = (await response.data) as PageLevelSecurity[];
            
            const checkedItems = (data as PageLevelSecurity[]).filter((val) => val.hasAccess !== false).map((item) => item.menuId);

            setPageLevelIds(checkedItems);

            setPageLevelSecurityItems(data);

        } else {
            data = (await response.data) as FunctionLevelSecurity[];

            const checkedItems = (data as FunctionLevelSecurity[]).filter((val) => val.hasAccess !== false).map((item) => item.accessId);

            setFunctionLevelIds(checkedItems);

            setFunctionLevelSecurityItems(data);
        }
    }, [username])

    const onSelectAllItemsByFilter = (to: "pageLevel" | "functionLevel", filterName: string, event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (to === "pageLevel") {
                const mappedItems = pageLevelSecurityItems.map((val) => {
                    if (val.sectionDescription === filterName) {
                        val.hasAccess = true;
                    }

                    return val;
                });

                const ids = mappedItems.filter((val) => val.sectionDescription === filterName).map((val) => val.menuId)

                setPageLevelSecurityItems(mappedItems);
                setPageLevelIds(ids);
            } 
            
            else {
                const mappedItems = functionLevelSecurityItems.map((val) => {
                    if (val.pageName === filterName) {
                        val.hasAccess = true;
                    }

                    return val;
                });

                const ids = mappedItems.filter((val) => val.pageName === filterName).map((val) => val.accessId)

                setFunctionLevelSecurityItems(mappedItems);
                setFunctionLevelIds(ids);
            }
        } 
        
        else {
            if (to === "pageLevel") {
                const mappedItems = pageLevelSecurityItems.map((val) => {
                    if (val.sectionDescription === filterName) {
                        val.hasAccess = false;
                    }

                    return val;
                });

                setPageLevelSecurityItems(mappedItems);
                setPageLevelIds([]);
            } 
            
            else {
                const mappedItems = functionLevelSecurityItems.map((val) => {
                    if (val.pageName === filterName) {
                        val.hasAccess = false;
                    }

                    return val;
                });

                setFunctionLevelSecurityItems(mappedItems);
                setFunctionLevelIds([]);
            }
        }
    }

    const onChangeParent = (to: "pageLevel" | "functionLevel", event: React.ChangeEvent<HTMLInputElement>) => {
        const val: number = parseInt(event.target.value);

        if (event.target.checked) {
            if (to === "pageLevel") {
                const allTrue = pageLevelSecurityItems.map((val) => {
                    val.hasAccess = true;

                    return val;
                });

                setPageLevelSecurityItems(allTrue);
                setPageLevelIds(pageLevelSecurityItems.map((val) => val.menuId));
            }

            else {
                const allTrue = functionLevelSecurityItems.map((val) => {
                    val.hasAccess = true;

                    return val;
                });

                setFunctionLevelSecurityItems(allTrue);
                setFunctionLevelIds(functionLevelSecurityItems.map((val) => val.accessId));
            }
        }

        else {
            if (to === "pageLevel") {
                const allFalse = pageLevelSecurityItems.map((val) => {
                    val.hasAccess = false;
                    
                    return val;
                });

                setPageLevelSecurityItems(allFalse);
                setPageLevelIds([])
            }

            else {
                const allFalse = functionLevelSecurityItems.map((val) => {
                    val.hasAccess = false;

                    return val;
                })

                setFunctionLevelSecurityItems(allFalse);
                setFunctionLevelIds([]);
            }
        }
    }

    const onChange = (to: "pageLevel" | "functionLevel", event: React.ChangeEvent<HTMLInputElement>) => {
        const val: number = parseInt(event.target.value);
    
        if (event.target.checked) {
            if (to === "pageLevel") {
                const items = pageLevelSecurityItems;
                const foundIndex = items.findIndex((i) => i.menuId === val);

                items[foundIndex].hasAccess = true;

                setPageLevelIds((prevState) => [...prevState, val]);
                setPageLevelSecurityItems(items);
            } 
            
            else {
                const items = functionLevelSecurityItems;
                const foundIndex = items.findIndex((i) => i.accessId === val);

                items[foundIndex].hasAccess = true;

                setFunctionLevelIds((prevState) => [...prevState, val]);
                setFunctionLevelSecurityItems(items);
            }
        }

        else {
            if (to === "pageLevel") {
                const filtered = pageLevelIds.filter((id) => id !== val);

                const items = pageLevelSecurityItems;
                const foundIndex = items.findIndex((i) => i.menuId === val);

                items[foundIndex].hasAccess = false;

                setPageLevelIds(filtered);
                setPageLevelSecurityItems(items);
            } 
            
            else {
                const filtered = functionLevelIds.filter((id) => id !== val);

                const items = functionLevelSecurityItems;
                const foundIndex = items.findIndex((i) => i.accessId === val);

                items[foundIndex].hasAccess = false;

                setFunctionLevelIds(filtered);
                setFunctionLevelSecurityItems(items)
            }
        }
    }

    const onSave = async (): Promise<void> => {
        try {
            const response = await getAxios("/access/joint-access", "PUT", {
                selectedUsername: username,
                menuIds: pageLevelIds,
                accessIds: functionLevelIds
            });
    
            const data = await response.data;
            
            onCancel();
        
        } catch (error) {
            setSnackbarMessage("Something went wrong");
            setSnackbarType("error");
        }
    }

    useEffect(() => {
        setPageLevelSecurityItems([]);
        setFunctionLevelSecurityItems([]);
        setPageLevelIds([]);
        setFunctionLevelIds([])
        onFetchItemsByEndpointName("access");
    }, [onFetchItemsByEndpointName]);

    useEffect(() => {
        setPageLevelSecurityItems([]);
        setFunctionLevelSecurityItems([]);
        setPageLevelIds([]);
        setFunctionLevelIds([])
        onFetchItemsByEndpointName("menu");
    }, [onFetchItemsByEndpointName]);
    
    return {
        pageLevelSecurityItems,
        functionLevelSecurityItems,
        pageLevelIds,
        functionLevelIds,
        snackbarMessage,
        snackbarType,
        setSnackbarMessage,
        setSnackbarType,
        onSave,
        onChangeParent,
        onSelectAllItemsByFilter,
        onChange
    }
}
