import React, { FunctionComponent, useState } from "react";
import { Box, Tab, Tabs, Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { IProps, IStyledTabsProps, ITabPanel, ITabLabelProps } from "./IProps";

const a11yProps = (index: number) => {
    return {
        id: `custom-tabpabel-${index}`,
        'aria-controls': `custom-tabpabel-${index}`
    }
}

const TabPanel: FunctionComponent<ITabPanel> = (props): JSX.Element => {
    const { index, value, children, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`custom-tabpanel-${index}`}
            {...other}
        >
            {
                value === index && (
                    <Box sx={{ p: 1 }}>
                        {children}
                    </Box>
                )
            }
        </div>
    )
}

const StyledTabs = styled((props: IStyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" />, sx: { '& .MuiTabs-indicatorSpan': { height: props.orientation ? 40 : "inherit" } } }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        backgroundColor: 'transparent',
        width: 4
    },
    '& .MuiTabs-indicatorSpan': {
        width: "100%",
        backgroundColor: '#1BA0E9',
    },
})

const StyledTab = styled((props: ITabLabelProps) => (
    <Tab disableRipple iconPosition="start" icon={props.icon} label={props.title} {...props} />
))(({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: '#000000CC',
    '&.Mui-selected': {
        color: '#1BA0E9',
    },
}));

export const CustomTabs: FunctionComponent<IProps> = (props) => {
    const initialValue: number = props.initialValue ? props.initialValue : 0;

    const [value, setValue] = useState<number>(initialValue);

    const onChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    }

    return (
        <Box sx={{ width: '100%', flexGrow: props.orientation ? 1 : "inherit", display: props.orientation ? "flex" : "block", }}>
            <Box sx={{ borderBottom: props.orientation ? 0 : 1, borderRight: props.orientation ? 1 : 0, borderColor: 'divider', display: "flex", alignItems: "flex-start" }}>
                <StyledTabs sx={{ width: props.orientation ? "100%" : "inherit" }} orientation={props.orientation} value={value} onChange={onChange} aria-label="custom-panel-tab">
                    {
                        props.tabLabels.map((label, index) => {
                            return (
                                <StyledTab
                                    disabled={label.disabled}
                                    sx={{ marginRight: (theme) => props.orientation ? theme.spacing(5) : theme.spacing(1) }}
                                    icon={label.icon}
                                    title={label.title}
                                    {...a11yProps(index)}
                                />
                            )
                        })
                    }
                </StyledTabs>
                {
                    (props.children) && (
                        <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end" >
                            <Stack direction="row" spacing={2} >
                                {props.children}
                            </Stack>
                        </Box>
                    )
                }
            </Box>
            <Box>
                {
                    props.tabComponents.map((component, index) => {
                        return (
                            <TabPanel value={value} index={index}>
                                <React.Fragment>
                                    {component}
                                </React.Fragment>
                            </TabPanel>
                        )
                    })
                }
            </Box>
        </Box>
    )
}