import React, { FunctionComponent, useState } from "react";
import { Stack, Badge, Avatar, Typography, Box, IconButton, SvgIcon, Popper, Fade, Paper } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { UserNavMenu } from "../UserNavMenu";
import { User } from "../../model/User";
import { useUserProfileNav } from "./hook";
import { mdiBellOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { NotificationPopOver } from "../NotificationPopOver";


export const UserProfileNav: FunctionComponent = (): JSX.Element => {
    const { anchorEl, open, notifications, onClick, onClose, onReset, onGetNotificationsHistory, onAcknowledgeNotification } = useUserProfileNav();
    const user = new User();

    return (
        <Box>

            <Stack direction="row" alignItems="center" >
                <NotificationPopOver notifications={notifications} onReset={onReset} onAcknowledgeNotification={onAcknowledgeNotification} />
                <Stack direction="row" alignItems="center" sx={{ mx: 2, "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" }, borderRadius: 1, cursor: "pointer" }} spacing={2} onClick={onClick} >
                    <Avatar sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} alt="profile">{user.DisplayUserName.split(' ')[0][0]}</Avatar>
                    <Typography
                        display={{ xs: 'none', sm: "none", md: "flex", lg: "flex" }}
                        variant="subtitle1"
                        noWrap
                        component="div"
                        sx={{ color: "#000000CC", fontWeight: "600" }}
                    >
                        {user.DisplayUserName}
                    </Typography>
                    <Box display={{ sm: "none", md: "flex", lg: "flex" }} >
                        <ArrowDropDownIcon sx={{ color: "#000000CC" }} />
                    </Box>
                </Stack>
            </Stack>
            <UserNavMenu open={open} anchorEl={anchorEl} onClose={onClose} />
        </Box>
    )
}