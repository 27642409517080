import React, { FunctionComponent } from "react";
import { useIsAuthenticated } from "@azure/msal-react";
import { Navigate  } from "react-router-dom";
import { User } from "../../model/User";

export const ProtectedRoute: FunctionComponent<{ children: React.ReactNode }> = ({ children }): any => {
    const user = new User();
    const token = sessionStorage.getItem("token");
    const hasToken = token ? true : false;
    const isAuthenticated = useIsAuthenticated();

    if ((!isAuthenticated && !user.Exists && !hasToken) || !user.Exists || !hasToken) {
        return <Navigate to="/" replace />
    }

    return children;
}