import React, { FunctionComponent } from "react";
import {
    Grid,
    Box,
    Typography,
    Snackbar,
    Alert,
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    SvgIcon,
    Stack,
    MenuItem,
    SelectChangeEvent
} from "@mui/material";
import { Icon } from "@mdi/react";
import { mdiAccount, mdiAccountPlus } from "@mdi/js";
import { CustomBreadCrumbs, CustomInput, CustomSelect, Button } from "../../components/";
import { AccountTabPanel } from "../../components/AccountTabPanel";
import { useAccounts } from "./hook";
import { FormFileDialog } from "../../components/FormFileDialog";
import { AddUserDialog } from "../../components/AddUserDialog";

export const Accounts = (): JSX.Element => {
    const {
        fileMessage,
        isError,
        isAddUserDialogOpen,
        isUploadFileDialogOpen,
        onCloseUploadFileDialog,
        onHandleSelectionFile,
        openSelectionFile,
        selectedFile,
        onOpenFileUploadDialog,
        onOpenAddUserUploadDialog,
        onSaveFile,
        isSnackbarOpen,
        onCloseSnackbar,
        onOpenSnackbar,
        onCloseAddUser,
        snackbarMessage
    } = useAccounts();

    return (
        <>
            <Grid sx={{ mt: 2.8, mb: 2.5 }}>
                <CustomBreadCrumbs path="Home/Accounts" />
            </Grid>
            <Grid xs={12} sm={5} md={5} lg={8} sx={{ mb: 2.5 }}>
                <Typography textAlign={{ xs: "center", sm: "start" }} variant="h4" sx={{ mb: 1.25, color: "#1BA0E9", fontWeight: 600, fontSize: 25 }} >
                    Accounts
                </Typography>
            </Grid>
            <Box mt={4}>
                <AccountTabPanel 
                    onClickAddUser={onOpenAddUserUploadDialog}
                    onClickTerritory={onOpenFileUploadDialog}
                />
            </Box>
            <FormFileDialog
                file={selectedFile}
                fileMessage={fileMessage}
                isOpen={isUploadFileDialogOpen}
                isError={isError}
                onClose={onCloseUploadFileDialog}
                onHandleSelectionFile={onHandleSelectionFile}
                onOpenSelectionFile={openSelectionFile}
                onSave={onSaveFile}
            />
            <AddUserDialog 
                isOpen={isAddUserDialogOpen} 
                onClose={onCloseAddUser}
            />
            <Snackbar key={9} open={isSnackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={onCloseSnackbar} autoHideDuration={2000}>
                <Alert severity="info" >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </>
    )
}