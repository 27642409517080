import { AutocompleteInputChangeReason } from "@mui/material/Autocomplete";
import { useMatch, useLocation, matchRoutes, matchPath } from "react-router-dom"
import React, { useState, useEffect, useCallback } from "react";
import { getAxios, route } from "../../utils/endpoints";
import { SearchLead } from "../../types/SearchLead";

export const useSearchBar = () => {
    const location = useLocation();

    const [value, setValue] = useState<SearchLead | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [opts, setOpts] = useState<SearchLead[]>([]);

    const onSearch = useCallback(async () => {
        try {
            setTimeout(async () => {
                const response = await getAxios(`${route.apiLeads.search}?property=${search}`, "GET");

                const leads: SearchLead[] = await response.data;

                console.log(leads);

                if (!leads) {
                    return;
                }

                setOpts(leads);
            }, 200);
        } 
        
        catch (error) {
            console.error(error);
        }
    }, [search])

    const onChange = (event: React.SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        setSearch(value);

        if (!value) {
            setOpen(false);
        }
    }

    const onOpen = () => {
        if (search.length >= 3) {
            setOpen(true);
        }
    }

    const onClose = () => {
        setOpen(false);
    }

    const onHandleReset = () => {
        setValue(null);
        setSearch("");
    }

    useEffect(() => {
        if (search.length >= 3) {
            onSearch();
        }
    }, [onSearch]);

    useEffect(() => {
        const regex = /\/view-leads\/[0-9]/i

        if (!location.pathname.match(regex)) {
            onHandleReset();
        }
    }, [location])

    return { value, open, opts, search, onChange, onOpen, onClose, onHandleReset };
}