import React, { FunctionComponent } from "react";
import {
    Typography,
    MenuItem,
    Divider,
    SvgIcon,
    Box,
    Dialog,
    DialogContent,
    FormControl,
    FormLabel,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    Grid
} from "@mui/material/";
import { mdiCalendarCheckOutline, mdiClose, mdiEmailOutline, mdiPlusThick, mdiWrenchClock, mdiWeb, mdiPound } from "@mdi/js";
import { Icon } from "@mdi/react";
import { CustomSelect, Button, CustomInput, CustomSwitch } from "../../components";
import { CustomDatePicker } from "../../components/CustomDatePicker"
import { IProps } from "./IProps";
import { CustomTimePicker } from "../CustomTimePicker";

export const ScheduleDialog: FunctionComponent<IProps> = (props) => {
    const week = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    return (
        <Dialog
            fullWidth
            maxWidth="lg"
            open={props.open}
        >
            <DialogContent>
                <Box width="100%" display="flex" alignItems="center" justifyContent="flex-end">
                    <SvgIcon sx={{ color: "#00000080" }} onClick={props.onClose}>
                        <Icon path={mdiClose} />
                    </SvgIcon>
                </Box>
                <Box display="flex" alignItems="center">
                    <Typography fontSize={22} fontWeight="md" sx={{ color: "#1BA0E9" }} mr={1}>
                        Who and How?
                    </Typography>
                    <Divider sx={{ width: "83%" }} orientation="horizontal" />
                </Box>
                <Box sx={{ borderRadius: 4 }}>
                    <Box sx={{ borderRadius: 2, borderBottom: 1 }}>
                        <Grid columns={{ md: 12 }}>
                            <Grid mt={3.5} container md={12}>
                                <Grid md={3}>
                                    {
                                        props.emailInputs.map((email, index) => {
                                            return (
                                                <CustomInput
                                                    label={{ text: "Email Notification" }}
                                                    startIcon={<Icon path={mdiEmailOutline} />}
                                                    name={email.name}
                                                    value={props.emails[`email${index}` as keyof typeof props.emails]}
                                                    type="email"
                                                    placeholder="janedoe@email.com"
                                                    height={35}
                                                    width={"100%"}
                                                    disabled={!props.isScheduled}
                                                    onChange={props.onChangeEmail}
                                                />
                                            )
                                        })
                                    }
                                </Grid>
                                <Grid ml={10} alignItems="flex-start" >
                                    <FormGroup sx={{ marginBottom: 4 }}>
                                        <FormControlLabel
                                            label="Do you want to schedule this report?"
                                            control={<CustomSwitch value={props.isScheduled} onChange={() => props.onDisableReportScheduled()} />}
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                    <Button
                                        width={180}
                                        height={35}
                                        variant="outlined"
                                        startIcon={<Icon path={mdiPlusThick} color="#1BA0E9" size="18px" />}
                                        label="Add another email"
                                        disabled={!props.isScheduled}
                                        onClick={props.onGenerateEmailTextfield}
                                    />
                                </Grid>
                                <Grid ml={12}>
                                    <FormControl disabled={!props.isScheduled} >
                                        <FormLabel>Attach this report as:</FormLabel>
                                        <RadioGroup row value={props.fileFormat} onChange={props.onChangeRadio}>
                                            <FormControlLabel value="PDF" control={<Radio disabled={!props.isScheduled} />} label="PDF" />
                                            <FormControlLabel value="CSV" control={<Radio disabled={!props.isScheduled} />} label="CSV" />
                                            <FormControlLabel value="XLSX" control={<Radio disabled={!props.isScheduled} />} label="XLSX" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box mt={4.5} display="flex" alignItems="center">
                            <Typography fontSize={22} fontWeight="md" sx={{ color: "#1BA0E9" }} mr={1}>
                                When?
                            </Typography>
                            <Divider sx={{ width: "90%" }} orientation="horizontal" />
                        </Box>
                        <Grid columns={{ md: 12 }} mb={10}>
                            <Grid mt={3.5} container md={12} spacing={2}>
                                <Grid md={6}>
                                    <CustomSelect
                                        width="100%"
                                        name="timeInterval"
                                        label="Time Interval"
                                        value={props.timeInterval}
                                        onChange={props.onChange}
                                        disabled={!props.isScheduled}
                                        StartIcon={(
                                            <SvgIcon>
                                                <Icon path={mdiWrenchClock} />
                                            </SvgIcon>
                                        )}
                                    >
                                        <MenuItem value="Daily">
                                            Daily
                                        </MenuItem>
                                        <MenuItem value="Weekly">
                                            Weekly
                                        </MenuItem>
                                        <MenuItem value="Monthly">
                                            Monthly
                                        </MenuItem>
                                    </CustomSelect>
                                </Grid>
                                <Grid md={6}>
                                    <CustomSelect
                                        width="100%"
                                        label="Time Zone"
                                        name="timeZone"
                                        value={props.timeZone}
                                        onChange={props.onChange}
                                        disabled={!props.isScheduled}
                                        StartIcon={(
                                            <SvgIcon>
                                                <Icon path={mdiWeb} />
                                            </SvgIcon>
                                        )} >
                                        <MenuItem value="UTC-5:00" >
                                            Eastern Time (America/New_York)
                                        </MenuItem>
                                        <MenuItem value="UTC-6:00" >
                                            Central Time (America/Chicago)
                                        </MenuItem>
                                        <MenuItem value="UTC-7:00" >
                                            Mountain Time (America/Denver)
                                        </MenuItem>
                                        <MenuItem value="UTC-7:00" >
                                            Mountain Time - Arizona (America/Phoenix)
                                        </MenuItem>
                                        <MenuItem value="UTC-8:00" >
                                            Pacific Time (America/Los_Angeles)
                                        </MenuItem>
                                        <MenuItem value="UTC-9:00" >
                                            Alaska Time (America/Anchorage)
                                        </MenuItem>
                                    </CustomSelect>
                                </Grid>
                            </Grid>
                            <Grid container mt={2.5} md={12} spacing={2}>
                                <Grid md={1} display="flex" alignItems={"center"}>
                                    <Typography sx={{ fontSize: 14 }} >Every</Typography>
                                </Grid>
                                <Grid md={3} width={"100%"}>
                                    <CustomSelect
                                        width="100%"
                                        name="every"
                                        label=""
                                        value={props.every}
                                        onChange={props.onChange}
                                        disabled={!props.isScheduled}
                                        StartIcon={(
                                            <SvgIcon>
                                                <Icon path={mdiPound} />
                                            </SvgIcon>
                                        )} >
                                        {
                                            [...Array(31).keys()].map((value, index) => {
                                                if (value === 0) {
                                                    return;
                                                }

                                                return (
                                                    <MenuItem value={value}>
                                                        {value}
                                                    </MenuItem>
                                                )
                                            })
                                        }
                                    </CustomSelect>
                                </Grid>
                                {props.timeInterval === "Weekly" && (
                                    <Grid container display="flex" alignItems="center" xs={12} sm={12} md={4} >  { /* Time Interval Select */}
                                        <Grid xs={2} sm={2} md={2} >
                                            <Typography sx={{ fontSize: 14 }} >Week(s) On</Typography>
                                        </Grid>
                                        <Grid xs sm md width={"100%"}>
                                            <CustomSelect
                                                disabled={!props.isScheduled}
                                                width="100%"
                                                name="on"
                                                label=""
                                                value={props.on}
                                                onChange={props.onChange}
                                                StartIcon={(
                                                    <SvgIcon>
                                                        <Icon path={mdiCalendarCheckOutline} />
                                                    </SvgIcon>
                                                )} >
                                                {
                                                    week.map((day, index) => {
                                                        return (
                                                            <MenuItem value={day}>
                                                                {day}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </CustomSelect>
                                        </Grid>
                                    </Grid>
                                )}
                                {
                                    props.timeInterval === "Monthly" && (
                                        <Grid container display="flex" alignItems="center" xs={12} sm={12} md={4} >  { /* Time Interval Select */}
                                            <Grid xs={2} sm={2} md={3} >
                                                <Typography sx={{ fontSize: 14 }} >Month(s) (on)</Typography>
                                            </Grid>
                                            <Grid xs sm md width={"100%"}>
                                                <CustomSelect
                                                    disabled={!props.isScheduled}
                                                    width="100%"
                                                    name="on"
                                                    label=""
                                                    value={props.on}
                                                    onChange={props.onChange}
                                                    MenuProps={{
                                                        sx: {
                                                            maxHeight: 200
                                                        }
                                                    }}
                                                    StartIcon={(
                                                        <SvgIcon>
                                                            <Icon path={mdiCalendarCheckOutline} />
                                                        </SvgIcon>
                                                    )} >
                                                    {
                                                        [...Array(31).keys()].map((value, index) => {
                                                            if (value === 0) {
                                                                return;
                                                            }

                                                            return (
                                                                <MenuItem value={value}>
                                                                    {value}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </CustomSelect>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid md={0.8} display={"flex"} alignItems={"center"}>
                                    <Typography sx={{ fontSize: 14 }} >{props.timeInterval === "Daily" ? "Day(s) at " : "At"}</Typography>
                                </Grid>
                                <Grid md={3} width={"100%"}>
                                    <CustomTimePicker isDisabled={!props.isScheduled} value={props.at} onChange={(newValue) => props.onChangeDate(newValue, "at")} />
                                </Grid>
                            </Grid>
                            <Grid container md={12} mt={2.5} spacing={2}>
                                <Grid md={6} width={"100%"}>
                                    <CustomDatePicker disabled={!props.isScheduled} width="100%" label={{ text: "Starting On" }} value={props.startDate} onChange={(newDate) => props.onChangeDate(newDate, "startDate")} />
                                </Grid>
                                <Grid md={6} width={"100%"}>
                                    <CustomDatePicker disabled={!props.isScheduled} width="100%" label={{ text: "Ending On" }} value={props.endDate} onChange={(newDate) => props.onChangeDate(newDate, "endDate")} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ backgroundColor: " #FAFAFA" }}>
                        <Grid columns={{ md: 12 }}>
                            <Grid container display="flex" justifyContent="right" spacing={2}>
                                <Grid mr={2}>
                                    <Button variant="outlined" width={120} height={40} label={"Cancel"} onClick={props.onClose} />
                                </Grid>
                                <Grid>
                                    <Button width={120} height={40} label="Save" onClick={props.onSchedule} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    )
}