import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SvgIcon, Typography, Grid, IconButton, Stack, Box,  } from "@mui/material";
import { MoreVert, SwapVert } from "@mui/icons-material";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { CustomDataGrid } from "../CustomDataGrid";
import { mdiSquareEditOutline, mdiTrashCan, mdiPencil } from "@mdi/js";
import { Icon } from "@mdi/react"
import { formatDate } from "../../utils/time";
import { IProps } from "./IProps";
import DeleteIcon from '@mui/icons-material/Delete';
import { BranchAccessModal } from "../BranchAccessDialog";
import { AssignDialog } from "../AssignDialog";
import { CustomInput } from "../CustomInput";
import { Button } from "../Button"
import { useProfileAccessDatagrid } from "./hook";

const defaultColumnOptions: Partial<GridColDef> = {
    resizable: false,
    flex: 1,
    hideSortIcons: false,
    disableColumnMenu: false
}

let selectedRow: string = "lokkj"

interface DealerData {
    username: string;
    ids: string[];
}

export const ProfileAccessDataGrid: FunctionComponent<IProps> = (props) => {
    const {
        isAssignOpen,
        onChange,
        onFetchProfileAccess, 
        onPageChange,
        onPageSizeChange,
        pageSize,
        rows,
        search,
        setIsAssignOpen,
        setUsername,
        startIndex,
        totalRecordCount,
        username,
     } = useProfileAccessDatagrid();

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={22}
                    >
                        {params.value}
                    </Typography>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "companyName",
            headerName: "Company Name",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={22}
                        sx={{ color: "#00000099" }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "primaryContact",
            headerName: "Contact",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={22}
                        sx={{ color: "#00000099" }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "username",
            headerName: "Username",
            align: "center",
            headerAlign: "center",
            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Typography
                        fontWeight={400}
                        fontSize={14}
                        lineHeight={22}
                        sx={{ color: "#00000099" }}
                    >
                        {params.value}
                    </Typography>
                )
            },
            ...defaultColumnOptions
        },
        {
            field: "assign",
            headerName: "Assign",
            align: "center",
            headerAlign: "center",

            renderHeader: (params: GridColumnHeaderParams) => {
                return (
                    <strong>
                        {params.colDef.headerName}
                    </strong>
                )
            },
            renderCell: (params: GridRenderCellParams) => {

                const onGetRow = () => {
                    setUsername(params.row.username)
                    setIsAssignOpen(true);
                }

                return (

                    <IconButton color="primary" sx={{ border: "2px solid", borderRadius: 1 }} onClick={() => onGetRow()}>
                        <SvgIcon>
                            <Icon path={mdiSquareEditOutline} />
                        </SvgIcon>
                    </IconButton>
                )
            },
            ...defaultColumnOptions
        }
    ]

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
                <CustomInput name="search" value={search} onChange={onChange} height={35} placeholder="Search for a record" onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        onFetchProfileAccess(event.target.value)
                    }
                }} />
                <Button label="Search" height={35} onClick={() => onFetchProfileAccess(search)} />
            </Stack>
            <CustomDataGrid
                sortingOrder={["asc", "desc"]}
                columns={columns}
                rows={rows}
                page={startIndex}
                rowsPerPageOptions={[5, 10, 15, 25]}
                rowCount={totalRecordCount}
                pagination
                initialState={{
                    pagination: {
                        page: startIndex,
                        pageSize: pageSize
                    }
                }}
                paginationMode="server"
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
            />
            <AssignDialog
                ids={[]}
                open={isAssignOpen}
                title={username}
                onCancel={() => {
                    setUsername("")
                    setIsAssignOpen(false);
                }}
            />
        </>
    )
}