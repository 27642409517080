import {
    useState,
    useEffect,
    useCallback,
    useContext,
    createContext,
    FunctionComponent
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { SourceColumn } from "../../../types/SourceColumn";
import { useExportModuleFormContext as useExportModuleFormContextHook } from "../hooks/useExportModuleFormContext"
import { Criteria } from "../interfaces/ICriteria";
import { IMode } from "../interfaces/IMode";
import { SourceOrder } from "../interfaces/ISourceOrder";

interface IAlertResponse {
    isOpen: boolean;
    success: boolean | null;
    message: string | null;
}

interface IContext {
    title: string;
    isPublic: boolean;
    SQL: string;
    source: string;
    leftColumns: SourceColumn[];
    rightColumns: SourceColumn[];
    criteriaOne: Criteria;
    criteriaTwo: Criteria;
    criteriaThree: Criteria;
    criteriaFour: Criteria;
    criteriaFive: Criteria;
    sourceOrderOne: SourceOrder;
    sourceOrderTwo: SourceOrder;
    sourceOrderThree: SourceOrder;
    sourceOrderFour: SourceOrder;
    sourceOrderFive: SourceOrder;
    alertResponse: IAlertResponse;
    scriptValue: string;
    notificationOpen: boolean;
    setNotificationOpen: any;
    downloadMessage: string;
    setDownloadMessage: any;
    setScriptValue: any;
    setTitle: any;
    setIsPublic: any;
    isDisposable: boolean;
    isLoading: boolean;
    setIsLoading: any;
    setIsDisposable: any;
    setSQL: any;
    setSource: any;
    setLeftColumns: any;
    setRightColumns: any;
    setCriteriaOne: any;
    setCriteriaTwo: any;
    setCriteriaThree: any;
    setCriteriaFour: any;
    setCriteriaFive: any;
    setSourceOrderOne: any;
    setSourceOrderTwo: any;
    setSourceOrderThree: any;
    setSourceOrderFour: any;
    setSourceOrderFive: any;
    setAlertResponse: any;
    onHandleProcess: (processType: "save" | "download", fileFormat?: string) => void;
}

const Context = createContext<IContext>({} as IContext);

export const ExportModuleFormProvider: FunctionComponent<{ children: React.ReactNode }> = ({ children }): JSX.Element => {
    const location = useLocation();
    const mode = new URLSearchParams(location.search).get("mode");
    const { id } = useParams()

    const value = useExportModuleFormContextHook(mode! as IMode, id!);

    return (
        <Context.Provider
            value={value}
        >
            {children}
        </Context.Provider>
    )
}

export const useExportModuleFormContext = () => {
    return useContext(Context)
}

