import React, { FunctionComponent, useState } from "react";
import { Button } from "../Button";
import { CustomMenu } from "../CustomMenu";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { IProps } from "./IProps";
import { useDownloadButton } from "./hook";
import Fade from "@mui/material/Fade";

export const DownloadButton: FunctionComponent<IProps> = (props) => {
    const { downloadMenuAnchorEl, downloadOpen, setDownloadMenuAnchorEl, onClickDownloadButton } = useDownloadButton();

    return (
        <>
            <Button
                id="download-button"
                aria-controls={downloadOpen ? 'download-button' : undefined}
                aria-haspopup="true"
                aria-expanded={downloadOpen ? 'true' : undefined}
                variant="contained"
                disableElevation
                disableRipple
                sx={{ backgroundColor: "#7EDE5D", "&:hover": { backgroundColor: "#6c9147" } }}
                width={120}
                height={35}
                label="Download"
                endIcon={<KeyboardArrowDownIcon />}
                onClick={onClickDownloadButton}
            />
            <CustomMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'download-button',
                }}
                open={downloadOpen}
                anchorEl={downloadMenuAnchorEl}
                funcLinks={props.fileFormats}
                TransitionComponent={Fade}
                onClose={() => setDownloadMenuAnchorEl(null)}
            />
        </>
    )
}