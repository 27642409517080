import { FunctionComponent } from "react";
import { Box, Stack, SvgIcon, IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { mdiResizeBottomRight } from "@mdi/js";
import { Icon } from "@mdi/react";
import TextareaAutosize, { TextareaAutosizeProps } from "@mui/material/TextareaAutosize";
import { styled } from "@mui/material/styles";
import { Label, Hint } from "../component-utils";
import { BoxProps } from "@mui/material/Box";
import { IProps } from "./IProps";

const StyledBox = styled(Box)<IProps>((props) => ({
    width: props.width ? props.width : 370,
    height: props.height ? props.height: "inherit",
    borderRadius: 5,
    border: "1px solid",
    borderColor: "#00000026",
    padding: "8px 8px 8px 15px"
}));

const StyledTextArea = styled(TextareaAutosize)<IProps>((props) => ({
    width: props.width ? props.width : 370,
    height: props.height ? props.height : 100,
    borderRadius: 5,
    border: "1px solid",
    borderColor: "#00000026",
    padding: "8px 8px 8px 15px",
    fontSize: 14,
    lineHeight: 1.5,
    fontFamily: "Lato",
    fontWeight: "400",
    color: "#000000CC",
    outline: "none",
    resize: props.disableResize ? "none" : "inherit"
}));

export const CustomTextArea: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <>
            { props.label && <Label text={props.label.text} color={props.label.color} /> }
            <StyledTextArea 
                minRows={props.minRows ? props.minRows : 3} 
                width={props.width} height={props.height} 
                name={props.name} value={props.value} 
                onChange={props.onChange} 
                readOnly={props.readOnly} 
                maxLength={props.maxLength}
                { ...props } 
            />
            { !props.hint && <Hint type="default" message={`${props.value?.toString().length}/${props.maxLength} Characters`} /> }
            { props.hint && <Hint type="error" message={props.hint.message} /> }
        </>
    )
}