import { SideBarMenu } from "../../types/SidebarMenu";
import { ActionType } from "../action-types";
import { Action } from "../actions";

const initialState: SideBarMenu[] = [];

const reducer = (state: SideBarMenu[] = initialState, action: Action): SideBarMenu[] => {
    switch (action.type) {
        case ActionType.GET_MENU:
            return action.payload;
        default:
            return state;
    }
}

export default reducer;