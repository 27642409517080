import React, { FunctionComponent, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Stack, styled, Theme, CircularProgress } from "@mui/material";
import { SidebarContent } from "../SidebarContent";
import { IProps } from "./IProps";
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import { CSSObject } from "@emotion/react";

const drawerWidth = 270;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: "#FFFFFF",
  padding: theme.spacing(1.25, 3.75, 1.25, 1.25),
  width: 270,
  minHeight: 70,
}));

const openMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overFlowX: 'hidden',
  width: `calc(${theme.spacing(7)}) + 1px`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)}) + 1px`
  }
});

const OpenClosedDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'openPersistentSidebar',
})(({ theme, open }) => ({
  width: 270,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openMixin(theme),
    '& .MuiDrawer-paper': openMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export const SideBar: FunctionComponent<IProps> = ({ onToggleSidebar, openSidebar, openPersistentSidebar, ...props }): JSX.Element => {
  const { window } = props;
  const container = window !== undefined ? () => window().document.body : undefined;

  const DrawerContent = () => {
    return (
      props.isLoading ? (
        <Grid item sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }} position="sticky">
          <CircularProgress />
        </Grid>
      ) : (
        <Stack spacing={2} >
          {
            props.content.map((con, index): JSX.Element => {
              return (
                <SidebarContent key={index} title={con.title} links={con.links} />
              )
            })
          }
        </Stack>
      )
    )
  }

  return (
    <Box>
      {/*Medium and larger screen drawer*/}
      <Box component="nav"
        sx={{ width: { md: 270 }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders">
        <OpenClosedDrawer
          variant="persistent"
          sx={{
            display: { xs: 'none', sm: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              borderWidth: 0,
              backgroundColor: "#12253A",
              width: 270,
            },
          }}
          open={openPersistentSidebar}
          anchor="left"
        >
          <DrawerHeader>
            <Link to="/dealer-home" >
              <Box display={{ xs: 'none', sm: "none", md: "flex", lg: "flex" }} >
                <img src={process.env.PUBLIC_URL + "/lp_central_cyangray.svg"} />
              </Box>
            </Link>
          </DrawerHeader>
          <DrawerContent />
        </OpenClosedDrawer>
      </Box>
      {/* Mobile Drawer */}
      <Box >
        <Drawer
          container={container}
          variant="temporary"
          open={openSidebar}
          onClose={onToggleSidebar}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none', lg: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: "#12253A",
            },

          }}
        >
          <DrawerHeader>
            <Link to="/dealer-home" >
              <Box display={{ xs: 'flex', sm: "flex", md: "none", lg: "none" }} >
                <img src={process.env.PUBLIC_URL + "/lp_lite.svg"} />
              </Box>
            </Link>
          </DrawerHeader>
          <DrawerContent />
        </Drawer>
      </Box>
    </Box>
  );
}