import { FunctionComponent } from "react";
import { Typography, Divider, Stack } from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { IProps } from "../interfaces/IStepThree"
import { useStepThree } from "../hooks/useStepThree"
import { CriteriaForm } from "../Components/CriteriaForm"
import { useExportModuleFormContext } from "../context";
import { Criteria } from "../interfaces/ICriteria";

export const StepThree: FunctionComponent<IProps> = (props) => {
  const {
    criteriaOne,
    criteriaTwo,
    criteriaThree,
    criteriaFour,
    criteriaFive,
    setCriteriaOne,
    setCriteriaTwo,
    setCriteriaThree,
    setCriteriaFour,
    setCriteriaFive,
    source,
    rightColumns
  } = useExportModuleFormContext();


  const { sourceItems } = useStepThree(props.mode!, source);

  return (
    <>
      <Grid container columns={{ xs: 12, sm: 12, md: 12 }} ml={4}>
        <Grid xs={12} display="flex" alignItems="center" mt={4} >
          <Typography fontWeight={700} fontSize={22} sx={{ color: "#1BA0E9" }} >Set the Criteria</Typography>
          <Divider sx={{ display: { xs: "none", sm: "none", md: "flex" }, width: "82%" }} variant="inset" />
        </Grid>
        <Grid sx={{ width: '100%', maxWidth: 360, mt: 2.5 }}>
          <Stack direction="column" spacing={2}>
            <CriteriaForm
              isReadOnly
              sourceItems={sourceItems}
              statement={criteriaOne.statement}
              leftParenthesis={criteriaOne.leftParenthesis}
              source={criteriaOne.source}
              operator={criteriaOne.operator}
              note={criteriaOne.note}
              rightParenthesis={criteriaOne.rightParenthesis}
              onChangeSelect={(event) => setCriteriaOne((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value as string
              }))}
              onChange={(event) => setCriteriaOne((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value
              }))}
              onResetNote={() => setCriteriaOne((prevState: Criteria) => ({ ...prevState, note: "" }))}
            />

            <CriteriaForm
              sourceItems={sourceItems}
              statement={criteriaTwo.statement}
              leftParenthesis={criteriaTwo.leftParenthesis}
              source={criteriaTwo.source}
              operator={criteriaTwo.operator}
              note={criteriaTwo.note}
              rightParenthesis={criteriaTwo.rightParenthesis}
              onChangeSelect={(event) => setCriteriaTwo((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value as string
              }))}
              onChange={(event) => setCriteriaTwo((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value
              }))}
              onResetNote={() => setCriteriaTwo((prevState: Criteria) => ({ ...prevState, note: "" }))}
            />

            <CriteriaForm
              sourceItems={sourceItems}
              statement={criteriaThree.statement}
              leftParenthesis={criteriaThree.leftParenthesis}
              source={criteriaThree.source}
              operator={criteriaThree.operator}
              note={criteriaThree.note}
              rightParenthesis={criteriaThree.rightParenthesis}
              onChangeSelect={(event) => setCriteriaThree((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value as string
              }))}
              onChange={(event) => setCriteriaThree((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value
              }))}
              onResetNote={() => setCriteriaThree((prevState: Criteria) => ({ ...prevState, note: "" }))}
            />

            <CriteriaForm
              sourceItems={sourceItems}
              statement={criteriaFour.statement}
              leftParenthesis={criteriaFour.leftParenthesis}
              source={criteriaFour.source}
              operator={criteriaFour.operator}
              note={criteriaFour.note}
              rightParenthesis={criteriaFour.rightParenthesis}
              onChangeSelect={(event) => setCriteriaFour((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value as string
              }))}
              onChange={(event) => setCriteriaFour((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value
              }))}
              onResetNote={() => setCriteriaFour((prevState: Criteria) => ({ ...prevState, note: "" }))}
            />

            <CriteriaForm
              sourceItems={sourceItems}
              statement={criteriaFive.statement}
              leftParenthesis={criteriaFive.leftParenthesis}
              source={criteriaFive.source}
              operator={criteriaFive.operator}
              note={criteriaFive.note}
              rightParenthesis={criteriaFive.rightParenthesis}
              onChangeSelect={(event) => setCriteriaFive((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value as string,

              }))}
              onChange={(event) => setCriteriaFive((prevState: Criteria) => ({
                ...prevState,
                [event.target.name]: event.target.value
              }))}
              onResetNote={() => setCriteriaFive((prevState: Criteria) => ({ ...prevState, note: "" }))}
            />

          </Stack>
        </Grid>
      </Grid>
    </>
  )
}