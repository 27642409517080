import { useState } from "react";
import { IState } from "./IState";

export const useCustomInput = () => {
    const [state, setState] = useState<IState>({
        color: "rgba(0, 0, 0, 0.4)"
    });

    const onFocus = (): void => {
        setState({
            ...state,
            color: "#1BA0E9"
        });
    }

    const onBlur = (): void => {
        setState({
            ...state,
            color: "rgba(0, 0, 0, 0.4)"
        });
    }

    return {
        state,
        onFocus,
        onBlur
    }
}