import { useState, useEffect, useCallback } from "react";
import { SourceColumn } from "../../../types/SourceColumn";
import { getAxios, route } from "../../../utils/endpoints";
import { Criteria } from "../interfaces/ICriteria";
import { IMode } from "../interfaces/IMode";
import { SourceOrder } from "../interfaces/ISourceOrder";

const initialCriteria: Criteria = {
    leftParenthesis: "",
    note: "",
    operator: "",
    rightParenthesis: "",
    source: "",
    statement: ""
}

const initialSourceOrder: SourceOrder = {
    order: "",
    source: ""
}

export const useExportModuleFormContext = (mode: IMode, id?: string) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [source, setSource] = useState<string>("");

    const [leftColumns, setLeftColumns] = useState<SourceColumn[]>([]);
    const [rightColumns, setRightColumns] = useState<SourceColumn[]>([]);

    const [criteriaOne, setCriteriaOne] = useState<Criteria>(initialCriteria);
    const [criteriaTwo, setCriteriaTwo] = useState<Criteria>(initialCriteria);
    const [criteriaThree, setCriteriaThree] = useState<Criteria>(initialCriteria);
    const [criteriaFour, setCriteriaFour] = useState<Criteria>(initialCriteria);
    const [criteriaFive, setCriteriaFive] = useState<Criteria>(initialCriteria);

    const [sourceOrderOne, setSourceOrderOne] = useState<SourceOrder>(initialSourceOrder);
    const [sourceOrderTwo, setSourceOrderTwo] = useState<SourceOrder>(initialSourceOrder);
    const [sourceOrderThree, setSourceOrderThree] = useState<SourceOrder>(initialSourceOrder);
    const [sourceOrderFour, setSourceOrderFour] = useState<SourceOrder>(initialSourceOrder);
    const [sourceOrderFive, setSourceOrderFive] = useState<SourceOrder>(initialSourceOrder);

    const [scriptValue, setScriptValue] = useState<string>("");

    const [alertResponse, setAlertResponse] = useState({
        isOpen: false,
        success: null,
        message: null
    });

    const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
    const [downloadMessage, setDownloadMessage] = useState<string>("")

    const [title, setTitle] = useState<string>("");
    const [isPublic, setIsPublic] = useState<boolean>(true);
    const [SQL, setSQL] = useState<string>("");

    const [isDisposable, setIsDisposable] = useState<boolean>(false);

    const fetchExportModuleData = async (): Promise<void> => {
        const response = await getAxios(`${route.apiExport.exports}/${id}`, "GET", undefined);

        const data = await response.data;

        const rColumns: SourceColumn[] = data.columnList.map((val: string) => {
            return {
                columnId: 0,
                name: val,
                needsQuote: false,
                xType: 0
            }
        })

        setTitle(data.title);
        setSQL(data.sql);
        setSource(data.source);
        setRightColumns(rColumns)


        setCriteriaOne({
            leftParenthesis: data.leftParentheses[0],
            statement: "",
            note: data.inputs[0],
            operator: data.operators[0],
            rightParenthesis: data.rightParentheses[0],
            source: data.fields[0]
        });
        setCriteriaTwo({
            leftParenthesis: data.leftParentheses[0],
            statement: data.andOrInputs[1],
            note: data.inputs[1],
            operator: data.operators[1],
            rightParenthesis: data.rightParentheses[1],
            source: data.fields[1]
        });
        setCriteriaThree({
            leftParenthesis: data.leftParentheses[2],
            statement: data.andOrInputs[2],
            note: data.inputs[2],
            operator: data.operators[2],
            rightParenthesis: data.rightParentheses[2],
            source: data.fields[2]
        });
        setCriteriaFour({
            leftParenthesis: data.leftParentheses[2],
            statement: data.andOrInputs[2],
            note: data.inputs[2],
            operator: data.operators[2],
            rightParenthesis: data.rightParentheses[2],
            source: data.fields[2]
        });
        setCriteriaFive({
            leftParenthesis: data.leftParentheses[3],
            statement: data.andOrInputs[3],
            note: data.inputs[3],
            operator: data.operators[3],
            rightParenthesis: data.rightParentheses[3],
            source: data.fields[3]
        });

        setSourceOrderOne({
            order: data.directions[0].toUpperCase(),
            source: data.sortFields[0]
        });

        setSourceOrderTwo({
            order: data.directions[1].toUpperCase(),
            source: data.sortFields[1]
        });

        setSourceOrderThree({
            order: data.directions[2].toUpperCase(),
            source: data.sortFields[2]
        });

        setSourceOrderFour({
            order: data.directions[3].toUpperCase(),
            source: data.sortFields[3]
        });

        setSourceOrderFive({
            order: data.directions[4].toUpperCase(),
            source: data.sortFields[4]
        });

        console.log(data);
    }

    const getColumns = useCallback(async () => {
        const response = await getAxios(route.apiExport.columns, "GET", undefined, {
            mode: mode,
            source: source,
            showmode: true
        });

        const data: SourceColumn[] | string = await response.data;

        if (typeof data === "string") {
            return;
        }

        setLeftColumns(data)
    }, [source]);

    const onHandleProcess = async (processType: "save" | "download", fileFormat?: string): Promise<void> => {
        setIsLoading(true);

        const endpoint: string = processType === "save" ? route.apiExport.exports : route.apiExport.generate;
        const mode = new URLSearchParams(location.search).get("mode");

        const exportId = 0;

        let mainBody = {
            id: processType === "save" ? exportId : null,
            title: title,
            sourceType: mode,
            source: source,
            sql: scriptValue.length ? scriptValue : null,
            columnList: rightColumns.map((item) => item.name),
            andOrInputs: [criteriaOne.statement, criteriaTwo.statement, criteriaThree.statement, criteriaFour.statement, criteriaFive.statement],
            leftParentheses: [criteriaOne.leftParenthesis, criteriaTwo.leftParenthesis, criteriaThree.leftParenthesis, criteriaFour.leftParenthesis, criteriaFive.leftParenthesis],
            fields: [criteriaOne.source, criteriaTwo.source, criteriaThree.source, criteriaFour.source, criteriaFive.source],
            operators: [criteriaOne.operator, criteriaTwo.operator, criteriaThree.operator, criteriaFour.operator, criteriaFive.operator],
            inputs: [criteriaOne.note, criteriaTwo.note, criteriaThree.note, criteriaFour.note, criteriaFive.note],
            rightParentheses: [criteriaOne.rightParenthesis, criteriaTwo.rightParenthesis, criteriaThree.rightParenthesis, criteriaFour.rightParenthesis, criteriaFive.rightParenthesis],
            sortFields: [sourceOrderOne.source, sourceOrderTwo.source, sourceOrderThree.source, sourceOrderFour.source, sourceOrderFive.source],
            directions: [sourceOrderOne.order, sourceOrderTwo.order, sourceOrderThree.order, sourceOrderFour.order, sourceOrderFive.order]
        }

        // delete empty elements when a field is not selected 
        // run from last to first to keep the indexes in places 
        for (let i = 4; i >= 0; i--) {
            if (mainBody.fields[i].trim().length === 0) {
                mainBody.andOrInputs.splice(i, 1);
                mainBody.leftParentheses.splice(i, 1);
                mainBody.fields.splice(i, 1);
                mainBody.operators.splice(i, 1);
                mainBody.inputs.splice(i, 1);
                mainBody.rightParentheses.splice(i, 1);
            }

            if (mainBody.sortFields[i].trim().length === 0) {
                mainBody.sortFields.splice(i, 1);
                mainBody.directions.splice(i, 1);
            }
        }

        // console.log(mainBody);


        let wrapper = {};

        if (processType === "save") {
            wrapper = mainBody
        } else {
            wrapper = {
                FileFormat: fileFormat,
                Export: mainBody
            }
        }

        try {
            const response = await getAxios(
                endpoint,
                "PUT",
                wrapper
            );

            const data = await response.data;

            if (processType === "save") {
                setAlertResponse(() => ({
                    success: data.success,
                    message: data.message,
                    isOpen: true
                }));
            } else {
                setNotificationOpen(true);
                setDownloadMessage(data.messageDetails);
            }
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if (isDisposable) {
            setIsDisposable(false);
        }
    }, [source,
        rightColumns,
        criteriaOne,
        criteriaTwo,
        criteriaThree,
        criteriaFour,
        criteriaFive,
        sourceOrderOne,
        sourceOrderTwo,
        sourceOrderThree,
        sourceOrderFour,
        sourceOrderFive
    ]);

    useEffect(() => {
        if (source) {
            getColumns();
        }

        if (id) {
            fetchExportModuleData();
        }
    }, [getColumns]);

    return {
        title,
        isPublic,
        SQL,
        source,
        leftColumns,
        rightColumns,
        criteriaOne,
        criteriaTwo,
        criteriaThree,
        criteriaFour,
        criteriaFive,
        sourceOrderOne,
        sourceOrderTwo,
        sourceOrderThree,
        sourceOrderFour,
        sourceOrderFive,
        alertResponse,
        isDisposable,
        scriptValue,
        downloadMessage,
        notificationOpen,
        isLoading,
        setIsLoading,
        setNotificationOpen,
        setDownloadMessage,
        setScriptValue,
        setIsDisposable,
        setTitle,
        setIsPublic,
        setSQL,
        setSource,
        setLeftColumns,
        setRightColumns,
        setCriteriaOne,
        setCriteriaTwo,
        setCriteriaThree,
        setCriteriaFour,
        setCriteriaFive,
        setSourceOrderOne,
        setSourceOrderTwo,
        setSourceOrderThree,
        setSourceOrderFour,
        setSourceOrderFive,
        setAlertResponse,
        onHandleProcess,
    }
}