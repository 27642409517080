import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import SearchIcon from '@mui/icons-material/Search';
import { InputBase, Autocomplete, Box, Typography, Divider, Stack } from "@mui/material"
import { IProps } from "./IProps";
import { useSearchBar } from "./hook"
import { Link } from "react-router-dom"
import { formatDate } from "../../utils/time";
import { BackgroundIcon } from "../BackgroundIcon";
import { getLeadTypeColors } from "../../utils/get-lead-type-colors";
import { User } from "../../model/User";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#EEF3F7",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "100%"
    },
    [theme.breakpoints.down("md")]: {
        width: 900
    },
    [theme.breakpoints.down("lg")]: {
        width: 1200
    }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#1BA0E9"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#818181",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        fontFamily: "Lato",
        fontWeight: 400,
        color: "#717E84"
    }
}));

export const SearchBar: FunctionComponent<IProps> = (props): JSX.Element => {
    const { open, opts, search, value, onChange, onClose, onOpen, onHandleReset } = useSearchBar();
    const user = new User();
    const disableSearchBar = user.HasForbiddenAccess("Can Read Search Bar");

    const onRenderType = (params: string) => {
        const { bg, icon, lead_type } = getLeadTypeColors(parseInt(params));
        return (
            <BackgroundIcon color="rgba(0, 0, 0, 0.4)" iconHeight={17} iconWidth={17} width={26} height={26} bgColor={bg} icon={icon} label={lead_type} />
        )
    }

    return (
        <Search hidden={disableSearchBar}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <Autocomplete
                sx={{ width: "100%" }}
                open={open}
                onOpen={onOpen}
                onClose={onClose}
                value={value}
                inputValue={search}
                onInputChange={onChange}
                options={opts}
                clearOnBlur={false}
                getOptionLabel={(options) => (options.id) && `${options.lastName} | ${options.id} | ${options.phone} | ${formatDate(String(options.inquiryDate))}` || "No Results"}
                noOptionsText="No results"
                popupIcon={null}
                renderOption={(props, opts) => (
                    <Box component="li" { ...props } >
                        <Link style={{ textDecoration: "none", color: "#000000CC" }} to={`/view-leads/${opts.id}`} >
                            <Stack direction="row">
                                <Typography lineHeight={14} color="#000000CC" fontWeight={400} fontSize={14} display="flex" justifyContent="space-between" alignItems="center" sx={{ mr: 2 }}>
                                    {onRenderType(opts.type.toString())}
                                </Typography>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Typography fontWeight={400} fontSize={14} display="flex" justifyContent="space-between" alignItems="center" sx={{ ml: 1, mr: 1 }}>
                                    {opts.lastName}
                                </Typography>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Typography fontWeight={800} color="#1BA0E9" display="flex" justifyContent="space-between" alignItems="center" sx={{ ml: 1, mr: 1 }}>
                                    {opts.id}
                                </Typography>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Typography fontWeight={400} fontSize={14} display="flex" justifyContent="space-between" alignItems="center" sx={{ ml: 1, mr: 1 }}>
                                    {opts.phone}
                                </Typography>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Typography fontWeight={400} fontSize={13} color="#00000099" display="flex" justifyContent="space-between" alignItems="center" sx={{ ml: 1, mr: 1 }}>
                                    {formatDate(opts.inquiryDate.toString())}
                                </Typography>
                            </Stack>
                        </Link>
                    </Box>
                )}

                renderInput={(params) => {
                    const { InputLabelProps, InputProps, ...rest } = params;
                    return <StyledInputBase placeholder="Search" {...params.InputProps} {...rest} />
                }}
            />
        </Search>
    )
}