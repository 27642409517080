import React, { FunctionComponent } from "react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextField, Box, SvgIcon } from '@mui/material/';
import { Label, Hint } from "../component-utils"
import { IProps } from "./IProps";
import { useCustomDatePicker } from "./hook";

export const CustomDatePicker: FunctionComponent<IProps> = (props): JSX.Element => {
    const { color, onChangeLabelColor } = useCustomDatePicker();

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} >
            <DesktopDatePicker
                value={props.value}
                disabled={props.disabled}
                onChange={props.onChange}
                minDate={props.minDate}
                InputProps={{
                    onFocus: () => {
                        onChangeLabelColor("#1BA0E9");
                    },
                    onBlur: () => {
                        onChangeLabelColor("rgba(0, 0, 0, 0.8)");
                    },
                    sx: {
                        width: props.width ? props.width : 227,
                        height: 35,
                        fontSize: 14,
                        flexDirection: "row-reverse",
                        minWidth: 0
                    },
                }}
                components={{
                    OpenPickerIcon: () => {
                        return (
                            <SvgIcon sx={{ fontSize: 20 }} >
                                <CalendarMonthOutlinedIcon />
                            </SvgIcon>
                        )
                    }
                }}
                renderInput={(params) => {
                    return (
                        <Box width={"100%"} minWidth={0}>
                            <Label text={props.label.text} color={color} />
                            <TextField sx={{ width: "100%", minWidth: 0 }} onFocus={props.onFocus} name={props.name} {...params} onKeyDown={(e) => e.preventDefault()} />
                            {props.hint && <Hint message={props.hint.message} type={props.hint.type} />}
                        </Box>
                    )
                }}
            />
        </LocalizationProvider>
    )
}