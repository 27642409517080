import React, { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SvgIcon, Typography, Grid } from "@mui/material";
import { MoreVert, SwapVert } from "@mui/icons-material";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { CustomDataGrid } from "../CustomDataGrid";
import { mdiArrowRight } from "@mdi/js";
import { Icon } from "@mdi/react"
import { IProps } from "./IProps";
import { formatDate } from "../../utils/time";

const defaultColumnOptions: Partial<GridColDef> = {
    resizable: false,
    flex: 1,
    hideSortIcons: false,
    disableColumnMenu: false
}

const columns: GridColDef[] = [
    {
        field: "title",
        headerName: "Title",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams)  => {
            return (
                <strong>
                    { params.colDef.headerName }
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    { params.value }
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "dateChanged",
        headerName: "Last Modified",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams)  => {
            return (
                <strong>
                    { params.colDef.headerName }
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    { formatDate(params.value) }
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "employeeName",
        headerName: "Owner",
        headerAlign: "center",
        align: "center",
        renderHeader: (params: GridColumnHeaderParams)  => {
            return (
                <strong>
                    { params.colDef.headerName }
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Typography
                    fontWeight={400}
                    fontSize={14}
                    lineHeight={22}
                >
                    { params.value ? params.value : "n/a" }
                </Typography>
            )
        },
        ...defaultColumnOptions
    },
    {
        field: "",
        align: "center",
        sortable: false,
        filterable: false,
        hideSortIcons: true,
        disableColumnMenu: true,
        renderHeader: (params: GridColumnHeaderParams)  => {
            return (
                <strong>
                    { params.colDef.headerName }
                </strong>
            )
        },
        renderCell: (params: GridRenderCellParams) => {
            const router = useNavigate();

            return (
                <Grid sx={{ cursor: "pointer" }} container direction="row" alignItems="center" justifyContent="center" onClick={() => router(`/export-module/${params.row.id}?mode=${params.row.sourceType}`)} >
                    <Grid item>
                        <Typography
                            fontWeight={700}
                            fontSize={14}
                            lineHeight={18}
                            sx={{ color: "#1BA0E9" }}   
                        >
                            Select
                        </Typography>
                    </Grid>
                    <Grid item marginLeft={1}>
                        <SvgIcon>
                            <Icon path={mdiArrowRight} color="#1BA0E9" />
                        </SvgIcon>
                    </Grid>
                </Grid>
            )
        },
        ...defaultColumnOptions
    }
]

export const ExportDataGrid: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <>
            <CustomDataGrid 
                sortingOrder={["asc", "desc"]}
                columns={columns}
                rows={props.rows}
            />
        </>
    )
}