import React from "react";
import Typography from "@mui/material/Typography";
import { CustomBreadCrumbs } from "../../components";
import { NewReportForm } from "../../components/NewReportForm";
import Grid from '@mui/material/Unstable_Grid2';
import { useParams } from "react-router-dom"
import { getAxios } from "../../utils/endpoints";
import dayjs from "dayjs"

export interface ItemDetail {
    value: string;
    description: string;
}


export interface FilterDetail {
    description: string;
    filterId: string;
    selectedItems: ItemDetail[];
    shortCode: string;
    type: string;
}

export interface ReportDetail {
    columnDetails: ItemDetail[];
    dayPreference: string;
    description: string;
    emailsToNotify: string[];
    endingDate: Date;
    favorite: boolean;
    fileFormat: string;
    filterDetails: FilterDetail[];
    folderId: number;
    frequency: number;
    groupBy: FilterDetail[];
    id: number;
    isScheduled: boolean;
    reportTitle: string;
    reportType: string;
    sendEmailNotification: boolean;
    shortDescription: string;
    startingDate: Date;
    subGroupBy: FilterDetail[];
    time: string;
    timeInterval: string;
    timeZone: string;
}

export const NewReport = (): JSX.Element => {
    const { id } = useParams();

    const [stepOneData, setStepOneData] = React.useState({
        type: "",
        folder: 0,
        title: "",
        description: "",
        shortDescription: "",
    });

    const [stepTwoData, setStepTwoData] = React.useState<any>({
      selectedFilters: [],
      selectedOptions: [],
      isPreloadedData: false,
    });

    const [stepThreeData, setStepThreeData] = React.useState<any>({
        selectedFilters: [],
        groupBy: {},
        subGroup: {},
        isPreloadedData: false,
    });

    const [stepFourData, setStepFourData] = React.useState<any>({
        isReportScheduled: true,
        timeInterval: "",
        timeZone: "",
        every: "",
        on: "",
        hour: null,
        startDate: new Date(),
        endDate: new Date(),
        reportFile: "",
        emails: []
    });

    const [dataNotFoundMsg, setDataNotFoundMsg] = React.useState<string>("")

    const onFetch = async (): Promise<void> => {
        try {
            const response = await getAxios(`/reports/custom/${id}`, "GET");

            const data: ReportDetail = await response.data;
    
            if (!data) {
                setDataNotFoundMsg("This report doesn't exist or it was deleted");
                return;
            }

            const selectedColumns = data.columnDetails.map((val) => {
                return parseInt(val.value);
            });
            
            const selectedFilters = data.filterDetails.map((val) => {
                return parseInt(val.filterId);
            });
            
            const stepThreeFilters = data.filterDetails.map((filter) => {
                return {
                    filterId: parseInt(filter.filterId),
                    selectedItems: filter.selectedItems === null ? [] : filter.selectedItems 
                }
            });

            console.warn("Precargado", stepThreeFilters);
    
            const time = {
                hour: data.time.split(":")[0],
                minute: data.time.split(":")[1]
            };
        
            setStepOneData({
                type: data.reportType,
                title: data.reportTitle,
                description: data.description,
                shortDescription: data.shortDescription,
                folder: data.folderId
            });
    
            setStepTwoData({
                selectedOptions: selectedColumns,
                selectedFilters: selectedFilters,
                isPreloadedData: true,
            });
    
            setStepThreeData({
                selectedFilters: stepThreeFilters,
                groupBy: data.groupBy[0] ? data.groupBy[0] : "" ,
                subGroup: data.subGroupBy[0] ? data.subGroupBy[0] : "",
                isPreloadedData: true,
            })
    
            setStepFourData({
                isReportScheduled: data.isScheduled,
                timeInterval: data.timeInterval,
                timeZone: data.timeZone,
                every: data.frequency,
                on: data.dayPreference,
                startDate: data.startingDate,
                endDate: data.endingDate,
                reportFile: data.fileFormat,
                emails: {
                    email0: data.emailsToNotify[0],
                    email1: data.emailsToNotify[1],
                    email2: data.emailsToNotify[2],
                    email3: data.emailsToNotify[3]
                },
                hour: dayjs('2018-01-01').set("hour", parseInt(time.hour)).set("minute", parseInt(time.minute))
            });
        } catch (error) {
            console.log(error);
            setDataNotFoundMsg("Data Not found");
        }
    }

    React.useEffect(() => {
     if (id) {
        onFetch();
     }   
    }, [])

    console.log("paso 2: ", stepTwoData)

    return (
        <>
            <Grid xs={12} sx={{ mb: 2 }}>
                <CustomBreadCrumbs path="Home/New Reports" />
            </Grid>
            <Grid textAlign={{ xs: "center", sm: "start" }} xs={12} sx={{ mb: 2 }}>
                <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25, mb: 1 }} >
                    {id ? stepOneData.title : "New Report"}
                </Typography>
                <Typography variant="subtitle1" sx={{ color: "red", fontWeight: 600, fontSize: 13, mb: 1 }} >
                    { dataNotFoundMsg }
                </Typography>
                <Typography sx={{ fontSize: 14 }} >
                    Choose the parameters you need to create your report from scratch.
                </Typography>
            </Grid>
            {
                (stepTwoData.isPreloadedData && stepThreeData.isPreloadedData) || !id
                ?
                (
                    <NewReportForm id={id} httpAction={id ? "PUT" : "POST"} stepOne={stepOneData} stepTwo={stepTwoData} stepThree={stepThreeData} stepFour={stepFourData} />
                )
                :
                (null)
            }
        </>
    )
}