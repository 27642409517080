import React, { FunctionComponent, useEffect, useState } from "react";
import {
    Grid
} from "@mui/material"
import { SourceColumn } from "../../../types/SourceColumn";
import { IProps } from "../interfaces/IStepTwo"
import { StepTitle } from "../Components/StepTitle";
import { TransferList } from "../Components/TransferList"
import { useExportModuleFormContext } from "../context";
import { useStepTwo } from "../hooks/useStepTwo";

export const StepTwo: FunctionComponent<IProps> = (props): JSX.Element => {
    const context = useExportModuleFormContext();

    let lColumns: string[] = context.rightColumns.map((val) => {
        return val.name
    });

    

    return (
        <>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} ml={4}>
                <StepTitle title="Select the columns" />
                <p>columns length: {context.rightColumns.length}</p>
                <Grid sx={{ width: '100%', bgcolor: 'background.paper', mt: 2.5 }}>
                    <TransferList 
                        leftColumns={context.leftColumns.filter((item) => !lColumns.includes(item.name))}
                        rightColumns={context.rightColumns}
                        setterLeft={context.setLeftColumns}
                        setterRight={context.setRightColumns}
                    />
                </Grid>
            </Grid>
        </>
    )
}