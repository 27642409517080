import React, { FunctionComponent } from "react";
import { Typography, Divider, SvgIcon, Grid, Box } from "@mui/material/";
import { DropdownButton } from "../../components/DropdownButton";
import { ExportDataGrid } from "../../components/ExportDatagrid";
import { useExport } from "./hook";
import { mdiEye, mdiTable, mdiFileDocumentOutline, mdiDatabaseOutline, mdiAlert } from "@mdi/js";
import { Icon } from "@mdi/react"
import { ForbiddenAccessMessage } from "../../components/ForbiddenAccessMessage";


export const Export = () => {
    const { rows, onRedirectExportModuleMode, disableExport, disableAddEditExport } = useExport();

    return (
        <>
            <Grid xs={12} sm={5} md={5} lg={15}>
                <Box width="100%" display="flex" alignItems="center" justifyContent="space-between" >
                    <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 25, mt: 2 }} >
                        Export Module
                    </Typography>
                    {!disableAddEditExport &&
                        <DropdownButton
                            title="Select data source"
                            links={[
                                {
                                    icon: <Icon path={mdiEye} />,
                                    title: "View",
                                    onClick: () => onRedirectExportModuleMode("V")
                                },
                                {
                                    icon: <Icon path={mdiTable} />,
                                    title: "Table",
                                    onClick: () => onRedirectExportModuleMode("T")
                                },
                                {
                                    icon: <Icon path={mdiFileDocumentOutline} />,
                                    title: "Report Data",
                                    onClick: () => onRedirectExportModuleMode("P")
                                },
                                {
                                    icon: <Icon path={mdiDatabaseOutline} />,
                                    title: "SQL Statement",
                                    onClick: () => onRedirectExportModuleMode("M")
                                }
                            ]}
                            color="#12253A"
                        />
                    }
                </Box>
                <Typography sx={{ fontSize: 14, mt: 2 }} >
                    You can start doing your export with the button "Select Data Source" or loading a saved spec from the table below
                </Typography>
                <Box mt={6}>
                    {disableExport && <ForbiddenAccessMessage/>}
                    <ExportDataGrid rows={rows} />
                </Box>
            </Grid>
        </>
    )
}