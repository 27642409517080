import React, { useState } from "react";
import { LPLNotification } from "../../types/LPLNotification";

export const useNotificationPopOver = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const onClick = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(anchorEl ? null : event.currentTarget);   
    }

    const onClose = () => {
        setAnchorEl(null);
    }

    return {
        open,
        anchorEl,
        onClick,
        onClose,
    }
}