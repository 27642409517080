import React, { FunctionComponent } from "react";
import { 
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    SvgIcon,
    Stack,
    Typography
} from "@mui/material";
import { mdiCheckCircle, mdiAlert, mdiAlertCircle, mdiCloseCircle } from "@mdi/js";
import { Icon } from "@mdi/react"
import { IProps } from "./IProps";

export const AlertDialog: FunctionComponent<IProps> = (props): JSX.Element => {
    let icon: string = ""
    let color: string = "";

    switch (props.type) {
        case "success": {
            icon = mdiCheckCircle;
            color = "#71DB4D";
            break;
        }
        
        case "warning": {
            icon = mdiAlert;
            color = "#F3D745";
            break
        }

        case "error": {
            icon = mdiCloseCircle;
            color = "#ED3E3E"
            break;
        }

        case "info": {
            icon = mdiAlertCircle;
            color = "#1BA0E9";
        }

        default: {
            icon = ""
            break;
        }
    }

    const cancelButtonColor: string = props.onCancelButtonProps?.color ? props.onCancelButtonProps.color : "#1BA0E9";
    const cancelButtonLabel: string = props.onCancelButtonProps?.title ? props.onCancelButtonProps.title : "Cancel";
    
    const SubmitButtonColor: string = props.onSubmitButtonProps?.color ? props.onSubmitButtonProps?.title : "#1BA0E9"; 
    const SubmitButtonLabel: string = props.onSubmitButtonProps?.title ? props.onSubmitButtonProps.title : "Ok"

    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                style: {
                    width: 450,
                    height: 207
                }
            }}
        >
            <DialogTitle id="alert-dialog-title">
                <Stack direction="row" alignItems="center" spacing={2} >
                    <SvgIcon>
                        <Icon color={color} path={icon} style={{ fontSize: 23}} />
                    </SvgIcon>
                    <Typography fontSize={18} fontWeight={700} sx={{ color: "#000000CC" }} >
                        { props.title }
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <DialogContentText mt={2} fontSize={16} fontWeight={600} lineHeight={1.2} id="alert-dialog-description">
                    { props.description }
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                { props.onCancel && (
                    <Button sx={{ fontSize: 14, fontWeight: 700, color: cancelButtonColor, textTransform: "none" }} onClick={props.onCancel}>{cancelButtonLabel}</Button>
                ) }
                <Button sx={{ fontSize: 14, fontWeight: 700, color: SubmitButtonColor, textTransform: "none" }} onClick={props.onContinue} autoFocus>
                    { SubmitButtonLabel }
                </Button>
            </DialogActions>
        </Dialog>
    )
}