import { useState, useEffect } from "react";
import { AccessCategory } from "../../types/AccessCategory";
import { AccessQuery } from "../../types/AccessQuery";
import { getAxios, route } from "../../utils/endpoints"
import { User } from "../../model/User";

export const useQueriesPanel = () => {
    const [selectedItems, setSelectedItems] = useState<number[]>([]);
    const [categories, setCategories] = useState<AccessCategory[]>([]);
    const [items, setItems] = useState<AccessQuery[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const user = new User();
    const disableSaveButton = user.HasForbiddenAccess("Can Edit Queries Tab");

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const val: number = parseInt(event.target.value);

        if (event.target.checked) {
            setSelectedItems([...selectedItems, val]);
        }

        else {
            const filteredArr: number[] = selectedItems.filter((item) => item !== val);

            setSelectedItems(filteredArr);
        }
    }

    const fetchQueriesAccessCategories = async (): Promise<void> => {
        const response = await getAxios(route.apiAccessDashBoard.queryCategories, "GET");

        const data: AccessCategory[] = await response.data;

        setCategories(data);
    }


    const fetchQueriesAccessItems = async (): Promise<void> => {
        const response = await getAxios(route.apiAccessDashBoard.queries, "GET");

        const queries: AccessQuery[] = await response.data;

        const selectedQueries = queries.filter((item) => item.isSelected);

        const selectedQueriesId = selectedQueries.map((item) => item.queryId);

        setItems(queries);
        setSelectedItems(selectedQueriesId);
        setIsLoading(false);
    }

    const onUpdateSelectedQueries = async (): Promise<void> => {
        setIsLoading(true);

        try {
            const response = await getAxios(route.apiAccessDashBoard.queries, "PUT", {
                optionsIds: selectedItems
            });

            if (response.status === 200) {
                fetchQueriesAccessItems();
            }
        } catch (error) {
            console.error(error);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        fetchQueriesAccessCategories();
        fetchQueriesAccessItems();
    }, []);

    return {
        categories,
        items,
        selectedItems,
        isLoading,
        disableSaveButton,
        onChange,
        onUpdateSelectedQueries,
        fetchQueriesAccessItems
    }
}