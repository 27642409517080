//function to render a color and a lead type depending of the response in the api

import { mdiPhoneOutline, mdiWeb, mdiMailbox } from "@mdi/js";

export const getLeadTypeColors = (type: number) => {
    let bg: string = "";
    let icon: string = "";
    let lead_type = ""

    switch(type) {
        case 1: {
            bg = "#FFE072";
            icon = mdiPhoneOutline;
            lead_type = "Call";
            break;
        }
        case 2: {
            bg = "#BAE75C";
            icon = mdiWeb;
            lead_type = "Web";
            break;
        }
        case 3: {
            bg = "#7AE0E7";
            icon = mdiMailbox;
            lead_type = "Mail";
            break;
        }
    }

    return { bg, icon, lead_type };
}