import { useDispatch } from "react-redux";
import { getAxios, route } from "../utils/endpoints";
import { bindActionCreators } from "redux";
import { actionCreators } from "../state";
import { SideBarMenu } from "../types/SidebarMenu";

export class Menu {
    public onRefreshPageMenu = async (): Promise<SideBarMenu[]> => {
        const response = await getAxios(route.apiSidebar.sideMenu, "GET");

        const data: SideBarMenu[] = await response.data;

        return data;
    }
}