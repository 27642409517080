import { Configuration, RedirectRequest } from "@azure/msal-browser"

type UserFlow = {
    SignIn: string;
    PasswordReset: string;
}

export type IdTokenClaims = {
    aud: string;
    auth_time: number;
    emails: string[];
    exp: number;
    iat: number;
    isForgotPassword: boolean;
    iss: string;
    nbf: string;
    nonce: string;
    sub: string;
    tfp: string;
    ver: string;
}

export const B2C_TENANT_NAME: string = localStorage.getItem("tenantName") as string;
export const B2C_WEB_APP_ID: string = localStorage.getItem("webAppID") as string;

export const msalConfig: Configuration = {
    auth: {
        clientId: B2C_WEB_APP_ID,
        authority: `https://${B2C_TENANT_NAME}.b2clogin.com/${B2C_TENANT_NAME}.onmicrosoft.com/B2C_1_LPLSignIn`,
        knownAuthorities: [`${B2C_TENANT_NAME}.b2clogin.com/user.read`],
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    }
};

export const userFlow: UserFlow = {
    SignIn: "B2C_1_LPLSignIn",
    PasswordReset: "B2C_1_LPLPasswordReset"
}

export const loginRequest: RedirectRequest = {
    scopes: ["User.read"],
};