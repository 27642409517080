import React, { FunctionComponent, useState } from "react";
import { Dayjs } from "dayjs"
import {
    TextField,
    Box,
    Stack
} from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro/DateRangePicker';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Label } from "../component-utils";
import { IProps } from "./IProps";


export const CustomDateRangePicker: FunctionComponent<IProps> = (props) => {
    return (
        <LocalizationProvider
            dateAdapter={AdapterDayjs}
            localeText={{ start: "start date", end: "end date" }}
        >
            <DateRangePicker
                value={props.value}
                minDate={props.value[0]}
                maxDate={props.value[1]}
                onChange={(newValue) => {
                    props.onChange(newValue)
                }}
                InputProps={{
                    name: props.name,
                }}
                renderInput={(startProps, endProps) => {
                    return (
                        <React.Fragment >
                            <Box>
                                <Label text={props.label.text} color={props.label.color} />
                                <Stack direction="row" spacing={1} mt={2} >
                                    <TextField
                                        size="small" {...startProps}
                                    />
                                    <TextField size="small" {...endProps} />
                                </Stack>
                            </Box>
                        </React.Fragment>
                    )
                }}
            />
        </LocalizationProvider>
    )
}