import { FunctionComponent } from "react";
import { Stack, Typography, Divider } from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';


export const StepTitle: FunctionComponent<{ title: string }> = (props): JSX.Element => {
    return (
        <Grid display="flex" alignItems="center" mt={4} >
            <Typography variant="h4" sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 22 }} >
                {props.title}
            </Typography>
            <Divider sx={{ display: { xs: "none", sm:"none", md:"flex" }, width: "70%" }} variant="inset" />
        </Grid>
    )
}