import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { Typography } from "@mui/material";
import { IProps } from "./IProps";
import { useFormStepper } from "./hook";

interface IStyledCustomStepIcon {
    ownerState: {
        completed?: boolean;
        active?: boolean;
    }
}

const StyledConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 24,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#1BA0E9',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#1BA0E9',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const StyledStepIcon = styled("div")<IStyledCustomStepIcon>(({ theme, ownerState }) => ({
    backgroundColor: "transparent",
    zIndex: 1,
    color: "white",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        border: "2px solid",
        borderColor: "rgba(0, 0, 0, 0.0)",
        backgroundColor: "#1BA0E9",
        color: "white",
    }),
    ...(!ownerState.active && {
        backgroundColor: "white",
        border: "2px solid",
        borderColor: "rgba(0, 0, 0, 0.2)",
        color: "rgba(0, 0, 0, 0.5)"
    }),
    ...(ownerState.completed && {
        backgroundColor: "white",
        border: "2px solid",
        borderColor: "#1BA0E9",
        color: "#1BA0E9"
    })
}));

const CustomStepIcon: FunctionComponent<StepIconProps> = (props): JSX.Element => {
    const { active, completed, className } = props;

    const numberOfSteps: { [index: string]: string } = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5"
    };

    return (
        <StyledStepIcon
            ownerState={{ completed, active }}
            className={className}
        >
            <Typography sx={{ fontWeight: 700, fontSize: 16 }} >
                {numberOfSteps[String(props.icon)]}
            </Typography>
        </StyledStepIcon>
    )
}

const labels: string[] = ["Source", "Columns", "Criteria", "Sort", "Finish"];

export const FormStepper: FunctionComponent<IProps> = (props): JSX.Element => {
    const { isDisabled } = useFormStepper(props.mode);

    return (
        <Stepper
            alternativeLabel
            activeStep={props.activeStep}
            nonLinear
            connector={<StyledConnector />}
        >
            {labels.map((label, index) => (
                <Step key={label}>
                    <StepButton onClick={() => isDisabled ? null : props.onJumpStep(index)}>
                        <StepLabel sx={{ ".MuiStepLabel-alternativeLabel": "red" }} StepIconComponent={CustomStepIcon}>
                            <Typography sx={{ fontWeight: 700, fontSize: 16, color: props.activeStep === index ? "#1BA0E9" : "rgba(0, 0, 0, 0.5)" }}>
                                {label}
                            </Typography>
                        </StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    )
}