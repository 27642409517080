import React, { useState, useEffect } from "react";
import { getAxios, route } from "../../utils/endpoints";
import { DropdownValue } from "../../types/DropdownValue";
import { SelectChangeEvent } from "@mui/material";

type IState = {
    firstname: string;
    lastname: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    email: string;
    email2: string;
    product: string;
    status: string;
    apptDt: string;
    time: string;
    notes: string;
}

export const useAddLead = () => {
    const [leadState, setLeadState] = useState<IState>({
        address: "",
        apptDt: "",
        city: "",
        email: "",
        email2: "",
        firstname: "",
        lastname: "",
        product: "",
        state: "",
        status: "",
        time: "",
        zip: "",
        notes: ""
    })

    const [dealer, setDealer] = useState<string>("");
    const [phoneNumbers, setPhoneNumbers] = useState<string[]>([""]);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [dealerSources, setDealerSources] = useState<DropdownValue[]>([]);
    const [source, setSource] = useState<DropdownValue>({
        description: "",
        id: 0,
        sortOrder: 0
    });

    const [products, setProducts] = useState<DropdownValue[]>([]);

    const addPhoneNumber = (): void => {
        if (phoneNumbers.length >= 3) {
            return;
        }

        setPhoneNumbers((prevState) => [...prevState, ""]);
    }

    const deletePhoneNumber = (): void => {
        if (phoneNumbers.length <= 1) {
            return;
        }

        const arr = phoneNumbers.slice(0, -1);

        setPhoneNumbers(arr);
    }

    const onHandlePhonenumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const values = [...phoneNumbers];

        values[parseInt(event.target.name)] = event.target.value;

        setPhoneNumbers(values);
    }

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const onChangeDealer = (event: SelectChangeEvent<unknown>) => {
        setDealer(event.target.value as string);
    }

    const onChangeSelect = (event: SelectChangeEvent<unknown>) => {
        setLeadState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value as string
        }))
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setLeadState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    }

    const onChangeAutocomplete = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownValue | null,
        name: string,
    ) => {
        setSource(value!);
    }

    const onFetchDealerSource = async () => {
        const numberResponse = await getAxios(route.apiOptions.subcampaign, "GET");

        const data: DropdownValue[] = await numberResponse.data;

        setDealerSources(data);
    }

    const onFetchProducts = async (): Promise<void> => {
        const response = await getAxios("/options/product", "GET");

        const data: DropdownValue[] = await response.data;

        setProducts(data);
    }

    const onFetchDealerFormWithDealerInfo = (): void => {
        const fakeData: IState = {
            address: "test address",
            apptDt: "10/08/2020",
            city: "Test City",
            email: "test@test.com",
            email2: "test2@gmail.com",
            firstname: "Test",
            lastname: "Lead",
            product: products[1].id.toString(),
            state: "Test State",
            status: "Test Status",
            time: "8/24/2023",
            zip: "0987",
            notes: "this is a test note"
        };

        setPhoneNumbers([" (800) 555‑0100", " (800) 555‑0100", " (800) 555‑0100"]);
        setSource(dealerSources[4]);

        setLeadState(fakeData);
    }

    const onAddLead = async (): Promise<void> => {
        const response = await getAxios("/call-detail", "POST", {
            FirstName: leadState.firstname,
            LastName: leadState.lastname,
            Address: leadState.address,
            City: leadState.city,
            State: leadState.state,
            Zip: leadState.zip,
            Phone: phoneNumbers[0],
            Phone2: phoneNumbers[1],
            Phone3: phoneNumbers[2],
            Email: leadState.email,
            Emai2: leadState.email2,
            ProductId: leadState.product,
            Source: source.description,
            DealerId: dealer,
            ApptDate: leadState.apptDt,
            ApptTime: leadState.time,
            Notes: leadState.notes,
            UnassignedDealer: "",
            FirstName2: "",
            LastName2: ""
        });

        console.log(await response.data);
    }

    React.useEffect(() => {
        onFetchProducts();
        onFetchDealerSource();
    }, []);

    return {
        dealer,
        products,
        phoneNumbers,
        source,
        isOpen,
        dealerSources,
        addPhoneNumber,
        deletePhoneNumber,
        onHandlePhonenumberChange,
        handleOpen,
        handleClose,
        onChangeDealer,
        onChangeSelect,
        onChangeAutocomplete,
        onChange,
        leadState,
        onAddLead,
        onFetchDealerFormWithDealerInfo
    }
}