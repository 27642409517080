import React, { FunctionComponent } from "react";
import {
    Grid,
    Divider,
    Typography,
    Card,
    CardHeader,
    List,
    Button,
    Checkbox,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    CardContent,
    Box,
    Input,
    InputBase,
    SvgIcon,
    styled
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { SourceColumn } from "../../../../types/SourceColumn";
import { useTransferList, intersection, not, union } from "./hook";
import Icon from "@mdi/react";
import { mdiArrowLeft, mdiArrowRight } from "@mdi/js";
import { IProps } from "./IProps";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid rgba(0, 0, 0, 0.15)",
    backgroundColor: "#FFFFFF",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    marginBottom: 8,
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: 428
    },
    [theme.breakpoints.down("md")]: {
        width: 428
    },
    [theme.breakpoints.down("lg")]: {
        width: 428
    }
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 1),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(0, 0, 0, 0.4)"
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "#818181",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(3)})`,
        transition: theme.transitions.create("width"),
        fontFamily: "Lato",
        fontWeight: 400,
        color: "#717E84"
    }
}));

const SearchInput: FunctionComponent<{ name: string, value: string, onChange: (event: React.ChangeEvent<HTMLInputElement>) => void; }> = (props) => {
    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase name={props.name} value={props.value} onChange={props.onChange} placeholder="Search..." />
        </Search>
    )
}

export const TransferList: FunctionComponent<IProps> = (props) => {
    const {
        checked,
        handleCheckedLeft,
        handleCheckedRight,
        handleToggle,
        handleToggleAll,
        numberOfChecked,
        onChangeSearch,
        search,
        itemLength,
        leftChecked,
        rightChecked
    } = useTransferList(props.leftColumns, props.rightColumns, props.setterLeft, props.setterRight);

    const CustomList = (title: React.ReactNode, items: readonly SourceColumn[], searchName: string, searchValue: string) => {
        return (
            <Card sx={{ width: 470, height: 410, border: "2.5px solid rgba(0, 0, 0, 0.15)", borderRadius: "10px" }}>
                <CardHeader
                    sx={{ px: 2, py: 1 }}
                    avatar={
                        <Checkbox
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={
                                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{
                                'aria-label': 'all items selected',
                            }}

                        />
                    }
                    title={(
                        <Box display="flex" alignItems="center">
                            <Typography
                                fontSize={18}
                                fontWeight="600"
                                sx={{ color: "#1BA0E9", mr: 2 }}
                            >
                                {title}
                            </Typography>
                            <Typography
                                fontSize={14}
                                fontWeight="400"
                                sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                            >
                                {`${numberOfChecked(items)}/${items.length} Selected`}
                            </Typography>
                        </Box>
                    )}
                />
                <List
                    sx={{
                        width: "100%",
                        height: "90%",
                        bgcolor: 'background.paper',
                        overflowY: "auto",
                        overflowX: "hidden"
                    }}
                    dense
                    component="div"
                    role="list"
                >
                    <SearchInput name={searchName} value={searchValue} onChange={onChangeSearch} />
                    {items.filter((column) => {
                        if (!searchValue) {
                            return column;
                        }

                        else if (column.name.includes(searchValue)) {
                            return column;
                        }
                    }).map((value: SourceColumn) => {
                        const labelId = `transfer-list-all-item-${value.columnId}-label`;

                        return (
                            <ListItem
                                key={value.columnId}
                                role="listitem"
                                button
                                onClick={handleToggle(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{
                                            'aria-labelledby': labelId,
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={value.name} />
                            </ListItem>
                        );
                    })}
                </List>
            </Card>
        )
    }

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item>{CustomList('Choices', props.leftColumns, "left", search.left)}</Grid>
            <Grid item>
                <Grid container direction="column" alignItems="center">
                    <button
                        style={{
                            width: 50,
                            height: 45,
                            borderRadius: 8,
                            backgroundColor: "#1BA0E9",
                            color: "white",
                            outline: "none",
                            border: 0,
                            marginBottom: 7,
                            opacity: (leftChecked.length === 0) ? 0.5 : 1,
                            cursor: "pointer"
                        }}
                        onClick={handleCheckedRight}
                        disabled={leftChecked.length === 0}
                    >
                        <SvgIcon>
                            <Icon path={mdiArrowRight} size={11} />
                        </SvgIcon>
                    </button>
                    <button
                        style={{
                            width: 50,
                            height: 45,
                            borderRadius: 8,
                            border: 0,
                            backgroundColor: "#1BA0E9",
                            color: "white",
                            outline: "none",
                            marginBottom: 4,
                            opacity: (rightChecked.length === 0) ? 0.5 : 1,
                            cursor: "pointer"
                        }}
                        onClick={handleCheckedLeft}
                        disabled={rightChecked.length === 0}
                    >
                        <SvgIcon>
                            <Icon path={mdiArrowLeft} size={11} />
                        </SvgIcon>
                    </button>
                </Grid>
            </Grid>
            <Grid item>{CustomList('Chosen', props.rightColumns, "right", search.right)}</Grid>
        </Grid>
    )
}