import React, { FunctionComponent, useState, useEffect } from "react";
import { Button, CustomDatePicker, CustomSelect } from "../../../components";
import Grid from '@mui/material/Unstable_Grid2';
import { getAxios } from "../../../utils/endpoints";
import { MenuItem } from "@mui/material";
import { User } from "../../../model/User";

type IProps = {
    onApply: (start: Date, end: Date, dealer: string) => void;
}

export const BetweenDatesAndDealer: FunctionComponent<IProps> = (props): JSX.Element => {
    const user: User = new User();
    
    const dealers = user.Accounts.map((account) => account.companyName);

    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [dealer, setDealer] = useState<string>("");
    
    return (
        <Grid container columns={{ xs: 12, sm: 12, md: 12 }} sx={{ width: "100%", mb: 2 }} columnSpacing={{ xs: 0, md: 2 }} rowSpacing={{ xs: 2, md: 0 }}>
            <Grid xs={12} md={4}>
                <CustomDatePicker
                    label={{ text: "Start Date" }}
                    value={startDate}
                    onChange={(newValue, keyInputValue) => setStartDate(newValue)}
                    width={"100%"}
                />
            </Grid>
            <Grid xs={12} md={4}>
                <CustomDatePicker
                    label={{ text: "End Date" }}
                    value={endDate}
                    onChange={(newValue, keyInputValue) => setEndDate(newValue)}
                    width={"100%"}
                />
            </Grid>
            <Grid xs={12} md={4}>
                <CustomSelect value={dealer} label="Dealer" onChange={(event) => setDealer(event.target.value as string)} >
                    {
                        dealers.map((val) => {
                            return (
                                <MenuItem value={val} >{val}</MenuItem>
                            )
                        })
                    }
                </CustomSelect>
            </Grid>
            <Grid mt={2} xs={12} md={4} display="flex" alignItems="end">
                <Button width={120} height={40} label='Apply' onClick={() => props.onApply(startDate, endDate, dealer)} />
            </Grid>
            {/*state.errorMessage && <Typography color="red" fontSize={12}>{state.errorMessage}</Typography>*/}
        </Grid>
    )
}
