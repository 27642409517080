import { FunctionComponent } from "react";
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { CustomMenu } from "../CustomMenu";
import { IProps } from "./IProps"
import { User } from "../../model/User";

export const ItemMenu: FunctionComponent<IProps> = (props): JSX.Element => {
    let links = [
        { Icon: <CreateOutlinedIcon />, href: `/edit-lead/${props.itemId}`, label: "Edit Lead" },
        { Icon: <ManageSearchOutlinedIcon />, href: "#", label: "Find" },
        { Icon: <LaunchOutlinedIcon />, href: "#", label: "Go to LP" }
    ];

    const user = new User();
    const disableUserFunctions = user.HasForbiddenAccess("Can Read Lead");
    if(disableUserFunctions)
    {
        // Remove the first item from the array
        links.splice(0, 1);
    }


    return (
        <CustomMenu links={links}  {...props} />
    )
}