import { useState, useEffect, useCallback } from "react";
import { IProfileRow } from "../../types/UserAccess";
import { getAxios } from "../../utils/endpoints/getAxios";

interface UserAccessResponse { 
    items: IProfileRow[];
    totalRecordCount: number;
}

export const useProfileAccessDatagrid = () => {
    const [rows, setRows] = useState<IProfileRow[]>([]);
    const [isAssignOpen, setIsAssignOpen] = useState<boolean>(false);
    const [username, setUsername] = useState<string>("");
    const [search, setSearch] = useState<string>("");

    const [startIndex, setStartIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [totalRecordCount, setTotalRecordCount] = useState<number>(0);
    
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }

    const onPageChange = (newPage: number) => {
        setStartIndex(newPage);
    }

    const onPageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    }

    const onFetchProfileAccess = useCallback(async (profileSearch?: string) => {
        const response = await getAxios("/profiles", "GET", undefined, {
            ProfileMode: true,
            search: profileSearch,
            startindex: (pageSize * startIndex),
            pagesize: pageSize,
        });

        const data: UserAccessResponse = await response.data;

        if (typeof data === "string") {
            setRows([]);
            return;
        }

        setRows(data.items);
        setTotalRecordCount(data.totalRecordCount);
    }, [startIndex, pageSize]);


    const onSave = async (selectedUsername: string, profileUsername: string): Promise<void> => {
        const response = await getAxios("/access/assign-profile", "PUT", {
            selectedUsername: selectedUsername,
            profileUsername: profileUsername
        });

        const data: string = await response.data;
    }

    useEffect(() => {
        onFetchProfileAccess();
    }, [onFetchProfileAccess])

    return {
        rows,
        isAssignOpen,
        username,
        search,
        startIndex,
        pageSize,
        totalRecordCount,
        setIsAssignOpen,
        setUsername,
        onPageChange,
        onPageSizeChange,
        onChange,
        onSave,
        onFetchProfileAccess
    }
}