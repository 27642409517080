import { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";
import { IProps } from "./IProps";

const ButtonStyled = styled(MuiButton)<IProps>((props) => ({
    color: props.variant === "outlined" ? "#1BA0E9" : "#ffffff",
    backgroundColor: props.variant === "outlined" ? "transparent" : "#1ba0e9",
    fontFamily: `"Lato", "Roboto", "Helvetica", "Arial"`,
    fontWeight: "600",
    fontStyle: "normal",
    borderRadius: 8,
    fontSize: 14,
    textTransform: "none",
    width: props.width ? props.width : 123,
    height: props.height ? props.height : 46,
    "&error":{
        backgroundcolor: props.theme.palette.error,
        color:props.theme.palette.error
    },
    '&:hover': {
        backgroundColor: props.variant === "outlined" ? "#1BA0E9" : "#0a86c9",
        color: props.variant === "outlined" && "#ffffff"
    }
}));

export const Button: FunctionComponent<IProps> = (props): JSX.Element => {
    return (
        <ButtonStyled startIcon={props.startIcon} endIcon={props.endIcon} { ...props }>
            { props.label }
        </ButtonStyled>
    )
}