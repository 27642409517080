import React from "react";
import { RouteProps as RouterProps} from "react-router-dom";
import { 
    Login, 
    Home, 
    NewReport, 
    Reports, 
    ViewLeads, 
    EditProfile, 
    EditSunestaLead, 
    EditLead, 
    TestDataGrid,  
    NotFoundPage,
    ReportDetail,
    Export,
    ExportModule,
    UserAccess,
    Accounts,
    ViewWarranties,
    AddLead
} from "../pages"
import { EditWarranty } from "../pages/EditWarranty";

interface IRouteProps extends RouterProps {
    renderWithLayout?: boolean;
    isProtected: boolean;
}

export const routes: IRouteProps[] = [
    {
        path: "/",
        element: <Login />,
        isProtected: false,
        renderWithLayout: false,
    },
    {
        path: "/dealer-home",
        element: <Home />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/view-leads/",
        element: <ViewLeads />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/view-leads/:leadId",
        element: <ViewLeads />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/reports",
        element: <Reports />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/report-detail/:id",
        element: <ReportDetail />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/new-report",
        element: <NewReport />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/new-report/:id",
        element: <NewReport />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/edit-profile/:username",
        element: <EditProfile />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/edit-profile",
        element: <EditProfile />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/edit-lead/:leadId",
        element: <EditLead />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/edit-sunesta-lead",
        element: <EditSunestaLead />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/edit-warranty/:warrantyId",
        element: <EditWarranty />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/datagrid/:tableName",
        element: <TestDataGrid />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/export",
        element: <Export />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/export-module",
        element: <ExportModule />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/export-module/:id",
        element: <ExportModule />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/user-access",
        element: <UserAccess />,
        isProtected: true,
        renderWithLayout: true,
    },
    {
        path: "/accounts",
        element: <Accounts />,
        isProtected: true,
        renderWithLayout: true
    },
    {
        path: "/view-warranties",
        element: <ViewWarranties />,
        isProtected: true,
        renderWithLayout: true
    },
    {
        path: "/add-lead",
        element: <AddLead />,
        isProtected: true,
        renderWithLayout: true
    },
    {
        path: "*",
        element: <NotFoundPage />,
        isProtected: true,
        renderWithLayout: true,
    },
]