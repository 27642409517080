import React, { FunctionComponent } from "react";
import { Grid, Box, Stack, Snackbar, Alert, Backdrop, CircularProgress } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { StepTitle } from "../Components/StepTitle";
import { CustomTextArea } from "../../../components/CustomTextArea";
import { DownloadButton } from "../../../components/DownloadButton";
import { Button as CustomButton, CustomInput, CustomSwitch } from "../../../components/"
import { mdiFileDelimitedOutline, mdiFilePdfBox, mdiMicrosoftExcel, mdiTableCog } from "@mdi/js";
import { Icon } from "@mdi/react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SaveIcon from '@mui/icons-material/Save';
import { useExportModuleFormContext } from "../context";
import { useStepFive } from "../hooks/useStepFive";
import { AlertDialog } from "../../AlertDialog";
import { validateParenthesis } from "../../../utils/text";
import { IStep } from "../interfaces/IStep";

export const StepFive: FunctionComponent<IStep> = (props) => {
    const context = useExportModuleFormContext();

    const criteriaOne: string = context.criteriaOne.source.length ? `${context.criteriaOne.statement} ${context.criteriaOne.leftParenthesis} ${context.criteriaOne.source} ${context.criteriaOne.operator} '${context.criteriaOne.note}' ${context.criteriaOne.rightParenthesis}` : "";
    const criteriaTwo: string = context.criteriaTwo.source.length ? `${context.criteriaTwo.statement} ${context.criteriaTwo.leftParenthesis} ${context.criteriaTwo.source} ${context.criteriaTwo.operator} '${context.criteriaTwo.note}' ${context.criteriaTwo.rightParenthesis}` : "";
    const criteriaThree: string = context.criteriaThree.source.length ? `${context.criteriaThree.statement} ${context.criteriaThree.leftParenthesis} ${context.criteriaThree.source} ${context.criteriaThree.operator} '${context.criteriaThree.note}' ${context.criteriaThree.rightParenthesis}` : "";
    const criteriaFour: string = context.criteriaFour.source.length ? `${context.criteriaFour.statement} ${context.criteriaFour.leftParenthesis} ${context.criteriaFour.source} ${context.criteriaFour.operator} '${context.criteriaFour.note}' ${context.criteriaFour.rightParenthesis}` : "";
    const criteriaFive: string = context.criteriaFive.source.length ? `${context.criteriaFive.statement} ${context.criteriaFive.leftParenthesis} ${context.criteriaFive.source} ${context.criteriaFive.operator} '${context.criteriaFive.note}' ${context.criteriaFive.rightParenthesis}` : "";

    const sourceOrderOne: string = `${context.sourceOrderOne.source} ${context.sourceOrderOne.order}`;
    const sourceOrderTwo: string = `${context.sourceOrderTwo.source} ${context.sourceOrderTwo.order}`;
    const sourceOrderThree: string = `${context.sourceOrderThree.source} ${context.sourceOrderThree.order}`;
    const sourceOrderFour: string = `${context.sourceOrderFour.source} ${context.sourceOrderFour.order}`;
    const sourceOrderFive: string = `${context.sourceOrderFive.source} ${context.sourceOrderFive.order}`;

    let columns: string[] = context.rightColumns.map((column, index) => {
        return column.name;
    });

    let criterias: string[] = [criteriaOne, criteriaTwo, criteriaThree, criteriaFour, criteriaFive].filter((val) => val.trim() !== "")
    let sources: string[] = [sourceOrderOne, sourceOrderTwo, sourceOrderThree, sourceOrderFour, sourceOrderFive].filter((val) => val.trim() !== "")

    const columnsStatement: string = `SELECT ${columns.join(", ")}`;
    const sourceStatement: string = `\nFROM ${context.source}`;
    const criteriaStatement: string = `\nWHERE ${criterias.length === 0 ? "" : criterias.filter((criteria) => criteria.trim() !== "").join("\n")}`;
    const orderByStatement: string = (sources.length !== 0) ? `\nORDER BY ${sources.join(", ")}` : ""

    const str: string = `${columnsStatement} ${sourceStatement} ${criteriaStatement} ${orderByStatement}`;

    context.setSQL((prevState: string) => {
        if ((context.isDisposable) && (prevState !== str)) {
            context.setIsDisposable(false);
            return str;
        }

        return str;
    });

    const { displayActions, setDisplayActions, sqlError, setSqlError, openNotificationQueue, setOpenNotificationQueue, disableAddEditExport, disableGenerateExport } = useStepFive();



    const test = () => {

        if (props.mode === "M") {
            if (context.scriptValue.length === 0) {
                setSqlError("SQL script cannot be empty");
                setDisplayActions(false);
                return;
            }
        } else {
            if (context.source === "") {
                setSqlError("Source in step one is required");
                setDisplayActions(false);
                return;
            }

            if (context.source && columns.length === 0) {
                setSqlError("Select at least one column on step two");
                setDisplayActions(false)
                return;
            }

            if (criterias.length === 0) {
                setSqlError("Set at least one criteria in step three");
                setDisplayActions(false);
                return;
            }

            if (!validateParenthesis(context.SQL)) {
                setSqlError("There is an error in the SQL syntax");
                setDisplayActions(false);
                return;
            }
        }

        if (context.title.trim().length === 0) {
            setSqlError("Title is required");
            setDisplayActions(false);
            return;
        }

        context.setIsDisposable(true);
        setDisplayActions(true);
    }


    const SaveAndDownload: FunctionComponent<{ onSaveClick: () => void }> = (props) => {
        const fileDownloadLinks = [
            {
                Icon: <Icon path={mdiFileDelimitedOutline} />,
                label: ".CSV",
                onClick: async () => context.onHandleProcess("download", "CSV")
            },
            {
                Icon: <Icon path={mdiMicrosoftExcel} />,
                label: ".XLSX",
                onClick: async () => context.onHandleProcess("download", "XLSX")
            }
        ]

        return (
            <Stack direction="row" spacing={2}>
                {!disableGenerateExport && <DownloadButton fileFormats={fileDownloadLinks} />}
                {!disableAddEditExport && <CustomButton width={94} height={35} label="Save" startIcon={<SaveIcon />} onClick={props.onSaveClick} />}
            </Stack>
        )
    }

    const navigate = useNavigate();

    return (
        <>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} ml={4}>
                <StepTitle title="Result..." />
                <Box mt={3} width={"90%"} >
                    <CustomTextArea
                        label={{ text: "Description" }}
                        minRows={10}
                        width={"100%"}
                        value={(props.mode === "M") ? context.scriptValue : context.SQL}
                        maxLength={1000}
                        disableResize
                        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => context.setScriptValue(event.target.value)}
                        hint={sqlError ? { message: sqlError, type: "default" } : undefined}
                    />
                    <Box display="flex" alignItems="center" justifyContent="flex-end" >
                        {!displayActions && <CustomButton width={85} height={35} label="Go" endIcon={<ArrowForwardIcon />} onClick={() => {
                            test();
                        }} />}
                        {displayActions && <SaveAndDownload onSaveClick={() => context.onHandleProcess("save")} />}
                    </Box>
                </Box>
                <Stack direction="row" alignItems="center" spacing={2} >
                    <CustomInput name="title" value={context.title} label={{ text: "Title" }} startIcon={<Icon path={mdiTableCog} />} height={35} onChange={(event) => context.setTitle(event.target.value)} />
                    <Box mt={5}>
                        <CustomSwitch value={context.isPublic} onChange={(event) => context.setIsPublic(event.target.checked)} label="Public" />
                    </Box>
                    <AlertDialog
                        open={context.alertResponse.isOpen}
                        title={context.alertResponse.success ? "Well Done!" : "Error!"}
                        description={context.alertResponse.message!}
                        type={context.alertResponse.success ? "success" : "error"}
                        onContinue={() => {
                            context.setAlertResponse({ ...context.alertResponse, message: null, isOpen: false });

                            if (context.alertResponse.success) {
                                navigate("/export")
                            }

                        }} />
                </Stack>
                <Snackbar key={9} open={context.notificationOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={() => {
                    context.setNotificationOpen(false);
                    context.setDownloadMessage("");
                }} autoHideDuration={2000}>
                    <Alert severity="info" >
                        {context.downloadMessage}
                    </Alert>
                </Snackbar>
            </Grid>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={context.isLoading}
            >
                <CircularProgress color="primary" />
            </Backdrop>
        </>
    )
}