//Function to get sale status

interface ISaleStatus {
    P: string;
    F: string;
    H: string;
    N: string;
    R: string;
    O: string;
    S: string;
    I: string;
    "1": string;
    "2": string;
    "3": string;
}

const saleStatus: ISaleStatus = {
    P: "Pending",
    F: "Awaiting Financing",
    H: "On-Hold",
    N: "Finance Hold",
    R: "RTP-Released to Prod",
    O: "Ordered",
    S: "Shipped",
    I: "Installed",
    "1": "Cancelled/Homeowner",
    "2": "Cancelled/Company",
    "3": "Finance Decline"
}

export const getSaleStatusDescription = (property: string): string => {
    type ObjectKey = keyof typeof saleStatus;

    const key = property as ObjectKey;

    return saleStatus[key];
}
