import React, { FunctionComponent, useState, useEffect } from "react";
import { SourceColumn } from "../../../../types/SourceColumn";

export const not = (a: readonly SourceColumn[], b: readonly SourceColumn[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
}

export const intersection = (a: readonly SourceColumn[], b: readonly SourceColumn[]) => {
    return a.filter((value) => b.indexOf(value) !== -1);
}

export const union = (a: readonly SourceColumn[], b: readonly SourceColumn[]) => {
    return [...a, ...not(b, a)];
}

export const useTransferList = (leftColumns: SourceColumn[], rightColumns: SourceColumn[], setterLeft: any, setterRight: any) => {
    let itemLength: number = leftColumns.length

    const [checked, setChecked] = useState<readonly SourceColumn[]>([]);
    const [search, setSearch] = useState<{ left: string, right: string }>({
        left: "",
        right: ""
    })

    const leftChecked = intersection(checked, leftColumns);
    const rightChecked = intersection(checked, rightColumns);

    const handleToggle = (value: SourceColumn) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly SourceColumn[]) =>
        intersection(checked, items).length;

    const handleToggleAll = (items: readonly SourceColumn[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setterRight(rightColumns.concat(leftChecked));
        setterLeft(not(leftColumns, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setterLeft(leftColumns.concat(rightChecked));
        setterRight(not(rightColumns, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const onChangeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        setSearch((prevState) => ({
            ...prevState,
            [name]: value
        }));
    }

    return {
        checked,
        leftChecked,
        rightChecked,
        search,
        itemLength,
        handleCheckedLeft,
        numberOfChecked,
        handleToggle,
        handleToggleAll,
        handleCheckedRight,
        onChangeSearch
    }

}