import { useNavigate } from "react-router-dom"
import { EditSunestaLeadForm } from "../../components"

export const EditSunestaLead = (): JSX.Element => {
    const navigate  = useNavigate();

    return (
        <>
                <button onClick={() => navigate("/edit-lead")} >go to standard edit lead</button>
                <EditSunestaLeadForm />
        </>
    )
}