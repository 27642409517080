import React, { FunctionComponent } from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import { Typography, Divider, SelectChangeEvent } from '@mui/material';
import { SourceOrder } from "../Components/SourceOrder"
import { useStepFour } from "../hooks/useStepFour"
import { IProps } from "../interfaces/IStepFour"
import { StepTitle } from '../Components/StepTitle';
import { useExportModuleFormContext } from "../context"
import { SourceOrder as ISourceOrder } from '../interfaces/ISourceOrder';

export const StepFour: FunctionComponent<IProps> = (props) => {
    const {
        source,
        sourceOrderOne,
        sourceOrderTwo,
        sourceOrderThree,
        sourceOrderFour,
        sourceOrderFive,
        setSourceOrderOne,
        setSourceOrderTwo,
        setSourceOrderThree,
        setSourceOrderFour,
        setSourceOrderFive
    } = useExportModuleFormContext();

    const { sourceItems } = useStepFour(props.mode!, source)

    return (
        <Grid container columns={{ xs: 12, sm: 12, md: 12, lg: 12 }} ml={4} mb={10}>
            <StepTitle title="Order the sources" />
            <SourceOrder
                sourceItems={sourceItems}
                order={1}
                sourceValue={sourceOrderOne.source}
                orderValue={sourceOrderOne.order}
                onChangeOrder={(event) => setSourceOrderOne((prevState: ISourceOrder) => ({
                    ...prevState,
                    order: event.target.value as string
                }))}
                onChangeSelect={(event) => setSourceOrderOne((prevState: ISourceOrder) => ({
                    ...prevState,
                    source: event.target.value as string
                }))}
            />
            <SourceOrder
                sourceItems={sourceItems}
                order={2}
                sourceValue={sourceOrderTwo.source}
                orderValue={sourceOrderTwo.order}
                onChangeOrder={(event) => setSourceOrderTwo((prevState: ISourceOrder) => ({
                    ...prevState,
                    order: event.target.value as string
                }))}
                onChangeSelect={(event) => setSourceOrderTwo((prevState: ISourceOrder) => ({
                    ...prevState,
                    source: event.target.value as string
                }))}
            />
            <SourceOrder
                sourceItems={sourceItems}
                order={3}
                sourceValue={sourceOrderThree.source}
                orderValue={sourceOrderThree.order}
                onChangeOrder={(event) => setSourceOrderThree((prevState: ISourceOrder) => ({
                    ...prevState,
                    order: event.target.value as string
                }))}
                onChangeSelect={(event) => setSourceOrderThree((prevState: ISourceOrder) => ({
                    ...prevState,
                    source: event.target.value as string
                }))}
            />
            <SourceOrder
                sourceItems={sourceItems}
                order={4}
                sourceValue={sourceOrderFour.source}
                orderValue={sourceOrderFour.order}
                onChangeOrder={(event) => setSourceOrderFour((prevState: ISourceOrder) => ({
                    ...prevState,
                    order: event.target.value as string
                }))}
                onChangeSelect={(event) => setSourceOrderFour((prevState: ISourceOrder) => ({
                    ...prevState,
                    source: event.target.value as string
                }))}
            />
            <SourceOrder
                sourceItems={sourceItems}
                order={5}
                sourceValue={sourceOrderFive.source}
                orderValue={sourceOrderFive.order}
                onChangeOrder={(event) => setSourceOrderFive((prevState: ISourceOrder) => ({
                    ...prevState,
                    order: event.target.value as string
                }))}
                onChangeSelect={(event) => setSourceOrderFive((prevState: ISourceOrder) => ({
                    ...prevState,
                    source: event.target.value as string
                }))}
            />
        </Grid>
    )
}