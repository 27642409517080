import React, { useState, useEffect, SyntheticEvent } from "react";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Typography, Box } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGridPro,
    GridColumns,
    GridRowParams,
    MuiEvent,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRenderCellParams,
    GridEnrichedColDef,
    useGridApiContext,
    GridColDef,
    GridColumnHeaderParams,
    GridSortModel,
    gridVisibleRowCountSelector,
    gridVisibleColumnDefinitionsSelector,
    gridVisibleSortedRowIdsSelector,
    useGridApiRef,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import { IColumn, ISetupDetails, IDropdownItems, IButton } from "./interfaces";
import { toLowerCaseObject } from "../../utils/to-lowercase-object";
import { formatDate, getHour } from "../../utils/time";
import { truncate } from "../../utils/text";
import { BASE_URL, OCP_APIM_SUBSCRIPTION_KEY, route } from "../../utils/endpoints/"

export const useCustomDatagridPro = (tableName: string, isEditable: boolean, canAddRows: boolean) => {
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [gridColumns, setGridColumns] = useState<GridColumns>([]);
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [search, setSearch] = useState<string>("");
    const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false);
    const [isFilterEnabled, setIsFilterEnabled] = useState<boolean>(false);
    const [filterDropdownItems, setFilterDropdownItems] = useState<IDropdownItems[] | null>(null);
    const [filter, setFilter] = useState<string | undefined>("");
    const [order, setOrder] = useState<string>("");
    const [rowIndex, setRowIndex] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(25);
    const [total, setTotal] = useState<number>(0);
    const [buttons, setButtons] = useState<IButton[]>([]);
    const [sortModel, setSortModel] = useState<GridSortModel>([]);
    const [pkeys, setPKeys] = useState<Record<string, any>>({});
    const [pkeyStr, setPKeyStr] = useState<string>(""); //this string will contain all the column fields that are primary key
    const [processMode, setProcessMode] = useState<"edit" | "add" | null>(null);
    const [coordinates, setCoordinates] = useState({
        rowIndex: 0,
        colIndex: 0,
    });

    const apiRef = useGridApiRef();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSearch(value);
    }

    const onChangeFilter = (event: SelectChangeEvent<unknown>): void => {
        const { value } = event.target;

        setFilter(value as string);
        setRowIndex(0);
        setTotal
    }

    const onPageChange = (newPage: number): void => {
        setRowIndex(newPage);
    }

    const onPageSizeChange = (newPageSize: number): void => {
        setPageSize(newPageSize);
    }

    const handleAddRow = (): void => {
        let keys = "";
        let newRecord: Record<string, any> = {};
        const sortedColumns = columns.sort((a, b) => a.order > b.order ? 1 : -1);

        sortedColumns.forEach((column, index) => {
            if (column.isPrimaryKey) {
                keys += column.name + "," + " "
            }

            newRecord[column.name.toLowerCase()] = "";
        });

        newRecord.id = randomId();

        setRows((oldRows) => [...oldRows, { ...newRecord, isNew: true }]);

        setProcessMode("add");

        setRowModesModel((oldModel) => ({
            ...oldModel,
            [newRecord.id]: { mode: GridRowModes.Edit },
        }));

        setPKeyStr(keys.substring(0, keys.length - 2));

        const maxRowIndex = gridVisibleRowCountSelector(apiRef) -1;
        const maxColIndex = gridVisibleColumnDefinitionsSelector(apiRef).length - 1;
        

        setCoordinates((cords) => {
            return { ...cords, rowIndex: maxRowIndex, colIndex: 0}
        });
        console.log(1414, maxRowIndex, maxColIndex)
    }

    const onChangeOrder = (field: string, sort: string) => {
        if (field === null || sort === null) {
            setOrder("");
        }

        setOrder(`${field} ${sort}`);
    }

    const handleRowEditStart: GridEventListener<"rowEditStart"> = (params: GridRowParams, event: MuiEvent<SyntheticEvent>): void => {
        event.defaultMuiPrevented = true;
    }

    const handleSortModelChange = (newSortModel: GridSortModel): void => {
        setSortModel(newSortModel);
        setOrder(`${newSortModel[0].field} ${newSortModel[0].sort}`);
    }

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event): void => {
        event.defaultMuiPrevented = true;
    }

    const handleEditClick = (id: GridRowId, row: any): VoidFunction => (): void => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });

        setProcessMode("edit");

        const sortedColumns = columns.sort((a, b) => a.order > b.order ? 1 : -1);

        let primaryKeys: Record<string, any> = {};

        type ObjectKey = keyof typeof row;

        let counter = 0;

        sortedColumns.forEach((column, index) => {
            if (column.isPrimaryKey) {
                counter++;
                primaryKeys[`_pkfield${counter}`] = row[column.name.toLocaleLowerCase() as ObjectKey];
            }
        });

        setPKeys(primaryKeys);
    }

    const handleSaveClick = (id: GridRowId): VoidFunction => (): void => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }

    const handleCancelClick = (id: GridRowId): VoidFunction => (): void => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View, ignoreModifications: true } });

        const editedRow = rows.find((row) => row.id === id);

        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    }

    const onAddRow = async (newRow: GridRowModel): Promise<void> => {
        try {
            const Values: Record<string, any> = {
                ...newRow,
            }

            const bodyJSON = JSON.stringify({
                Filter: filter,
                Values,
                primaryKeys: pkeyStr
            });

            const response = await fetch(`${BASE_URL}/${route.apiTable}/${tableName}/values`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY
                },
                body: bodyJSON
            });

        } catch (error) {
            console.error(error)
        }
    }

    const onUpdateRow = async (updatedRow: GridRowModel): Promise<void> => {
        try {
            const Values: Record<string, any> = {
                ...updatedRow,
                ...pkeys
            };

            const bodyJSON = JSON.stringify({
                TrailerPrimaryKey: null,
                Filter: null,
                Values
            })

            const response = await fetch(`${BASE_URL}/${route.apiTable}/${tableName}/values`, {
                method: "PUT",
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY
                },
                body: bodyJSON,
            });

        } catch (error) {
            console.error(error);
        }
    }

    const processRowUpdate = async (newRow: GridRowModel): Promise<GridRowModel> => {
        const updatedRow = { ...newRow, isNew: false };

        try {

            if (processMode === "edit") {
                await onUpdateRow(updatedRow);
            }

            else if (processMode === "add") {
                await onAddRow(newRow);
            }

            await onFetchColumns();
            await onFetchRows();
        }

        catch (error) {
            console.error(error);
        }

        return updatedRow;
    }

    const getDatagridType = (type: string) => {
        switch (type) {
            case "varchar": {
                return "string";
                break;
            }

            case "int": {
                return "number";
                break;
            }

            case "datetime": {
                return "dateTime";
                break;
            }
        }
    }

    const mappedColumns = (columns: IColumn[]) => {
        const newColumns: GridColumns = columns.map((column, index) => {
            const mappedDropdownItems = column.dropdownItems?.map((dropdownItem) => {
                return {
                    value: dropdownItem.id,
                    label: dropdownItem.description
                }
            });

            return {
                field: column.name.toLocaleLowerCase(),
                headerName: column.heading,
                type: column.isDropdown ? "singleSelect" : getDatagridType(column.type),
                valueOptions: mappedDropdownItems,
                align: "center",
                headerAlign: "center",
                minWidth: 75,
                editable: true,
                sortable: true,
                disableReorder: true,
                flex: 1,
                renderHeader: (params: GridColumnHeaderParams) => {
                    return (
                        <p>
                            {params.colDef.headerName}
                        </p>
                    )
                },
                renderCell(params) {
                    let date;

                    if (column.type === "datetime") {
                        date = new Date(params.value);

                        return (
                            <Box sx={{ mt: 1, mb: 1 }} >
                                <Typography sx={{ fontWeight: 400, fontSize: 14 }} >
                                    {formatDate(params.value)}
                                </Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: 14 }} >
                                    {getHour(new Date(params.value))}
                                </Typography>
                            </Box>
                        )
                    }

                    if (column.isDropdown && column.dropdownItems) {
                        const item: IDropdownItems | undefined = column.dropdownItems.find((value, index) => {


                            return String(params.value).toLowerCase() === String(value.id).toLowerCase();
                        });

                        return (
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 14, fontWeight: 600 }}>
                                {truncate(String(item?.description), 11, true)}
                            </Typography>
                        )
                    }

                    return (
                        <Typography sx={{ color: "rgba(0, 0, 0, 0.7)", fontSize: 14, fontWeight: 600 }}>
                            {truncate(String(params.value), 15, true)}
                        </Typography>
                    )
                },
            } as GridEnrichedColDef
        });

        if (columns.length && isEditable) {
            newColumns.push({
                field: "actions",
                headerName: "Actions",
                type: "actions",
                cellClassName: "actions",
                flex: 1,
                renderHeader: (params: GridColumnHeaderParams) => {
                    return (
                        <p>
                            {params.colDef.headerName}
                        </p>
                    )
                },
                getActions: (params) => {
                    const isInEditMode = rowModesModel[params.id]?.mode === GridRowModes.Edit;

                    if (isInEditMode) {
                        return [
                            <GridActionsCellItem
                                icon={<SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(params.id)}
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                onClick={handleCancelClick(params.id)}
                            />,
                        ]
                    }

                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            onClick={handleEditClick(params.id, params.row)}
                            color="inherit"

                        />,
                    ]
                }
            });
        }

        setGridColumns(newColumns);
    }

    const rowsToLowerCase = (rows: object[]) => {
        const lowerRows = rows.map((row, index) => {
            return toLowerCaseObject(row);
        });

        return lowerRows;
    }

    const onFetchColumns = async (): Promise<void> => {
        setIsLoading(true)

        try {
            const response = await fetch(`${BASE_URL}/${route.apiTable}/${tableName}/setup-details?filter=&search=${search}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY
                }
            });

            const setupDetails: ISetupDetails = await response.json();

            setIsSearchEnabled(setupDetails.searchEnabled);
            setIsFilterEnabled(setupDetails.dropdownEnabled);
            setFilterDropdownItems(setupDetails.dropdownItems);
            setButtons(setupDetails.buttons!);
            setColumns(setupDetails.columns);

            if (setupDetails.dropdownEnabled) {
                setFilter(setupDetails.dropdownItems![0].id as string);
            } else setFilter(undefined);

            handleSortModelChange([{ field: setupDetails.columns[0].name.toLowerCase(), sort: "asc" }]);
        }

        catch (error) {
            console.error(error)
        }

        setIsLoading(false);
    }

    const onFetchRows = async (): Promise<void> => {
        setIsLoading(true);

        console.log("Table Name: ", tableName);
        console.log("FILTER: ", filter);
        console.log("Order: ", order)

        if (filter === "") {
            console.log("filter is empty");
            return;
        }

        try {
            const response = await fetch(`${BASE_URL}/${route.apiTable}/${tableName}/values?filter=${filter}&search=${search}&orderBy=${order}&startIndex=${pageSize * rowIndex}&pageSize=${pageSize}`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-Key": OCP_APIM_SUBSCRIPTION_KEY
                }
            });

            const rows = await response.json();

            if (!rows) {
                setRows([]);
            }

            setTotal(rows[0]._overall_count_);
            setRows(rowsToLowerCase(rows));
        }

        catch (e) {
            setRows([]);
        }

        setIsLoading(false);
    }

    const onClick = async (id: number): Promise<void> => {
        const bodyJSON = JSON.stringify({
            ButtonID: id,
            Filter: filter
        });

        try {
            await fetch(`${BASE_URL}/${route.apiTable}/${tableName}/button`, {
                method: "POST",
                body: bodyJSON,
                headers: {
                    Authorization: `Bearer ${sessionStorage.getItem("token")}`,
                    "Ocp-Apim-Subscription-key": OCP_APIM_SUBSCRIPTION_KEY
                }
            });

            await onFetchRows();

        } catch (error) {
            console.error(error);
        }
    }

    const onApplyFilter = async () => {
        await onFetchRows();
    }

    const keyDownHandler = async (event: KeyboardEvent) => {
        if ((event.key === "Enter")) {
            await onApplyFilter();
        }
    }

  

    useEffect(() => {
        setSearch("");
        setRowIndex(0);
        setPageSize(25);
        setOrder("");
        setFilter("");

        onFetchColumns();
    }, [tableName]);

    useEffect(() => {
        mappedColumns(columns)
    }, [columns, rowModesModel, pkeys]);

    useEffect(() => {
        onFetchRows();
    }, [rowIndex, pageSize, order, tableName, filter])

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    })

    useEffect(() => {
        if (rows.length && columns.length) {
            const { rowIndex } = coordinates;
            apiRef.current.scrollToIndexes(coordinates);
            const id = gridVisibleSortedRowIdsSelector(apiRef)[coordinates.rowIndex];
            const column = gridVisibleColumnDefinitionsSelector(apiRef)[rowIndex];
            // apiRef.current.setCellFocus(id, column.field);
            // console.log(id, column)
        }
    }, [apiRef, coordinates, rows]);

    return {
        columns,
        rows,
        isLoading,
        rowModesModel,
        search,
        filter,
        rowIndex,
        pageSize,
        order,
        total,
        sortModel,
        isSearchEnabled,
        isFilterEnabled,
        filterDropdownItems,
        buttons,
        apiRef,
        
        onClick,
        gridColumns,
        onChange,
        onChangeFilter,
        onApplyFilter,
        onPageChange,
        onPageSizeChange,
        onChangeOrder,
        handleCancelClick,
        handleEditClick,
        handleRowEditStart,
        handleRowEditStop,
        handleSaveClick,
        handleAddRow,
        handleSortModelChange,
        setRows,
        setRowModesModel,
        processRowUpdate
    }
}