import { FunctionComponent } from "react";
import { Box, Stack, Checkbox, FormControlLabel, Typography, CircularProgress } from "@mui/material";
import { IProps } from "./IProps";
import { AccessQuery } from "../../types/AccessQuery";

export const AccessDashboard: FunctionComponent<IProps> = (props) => {

    let loading: boolean = props.isLoading!;

    const instanceOfType = (object: any): object is AccessQuery => {
        return 'description' in object;
    }

    const StackControls = () => {
        return (
            <>
                {loading ? <CircularProgress /> : (
                    <Stack direction="column">
                        {
                            props.items.map((item) => {
                                if (instanceOfType(item)) {
                                    return (
                                        <FormControlLabel
                                            disabled={(props.disabled && !item.isSelected)}
                                            control={<Checkbox
                                                defaultChecked={item.isSelected}
                                                value={item.queryId.toString()}
                                                name="query-checkbox"
                                                onChange={(event) => {
                                                    props.onChange!(event);
                                                    item.isSelected = event.target.checked;
                                                }}
                                            />}
                                            label={item.description}
                                        />
                                    )
                                } else {
                                    return (
                                        <FormControlLabel
                                            disabled={(props.disabled && !item.isSelected)}
                                            control={<Checkbox
                                                defaultChecked={item.isSelected}
                                                value={item.dashboardTableID}
                                                name="table-checkbox"
                                                onChange={(event) => {
                                                    props.onChange!(event);
                                                    item.isSelected = event.target.checked;
                                                }} />}
                                            label={item.dashboardTableDescription}
                                        />
                                    )
                                }
                            })
                        }
                    </Stack>
                )}
            </>
        )
    }

    return (
        <Box sx={{ pl: 4 }}>
            {(props.items.length) ? <StackControls /> : <Typography>No items available</Typography>}
        </Box>
    )
}