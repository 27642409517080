import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { GridColDef, GridColumnHeaderParams, GridRenderCellParams } from "@mui/x-data-grid";
import { IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import { CustomDataGrid } from "../CustomDataGrid";
import { mdiSquareEditOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { Button } from "../Button";
import { CustomInput } from "../CustomInput";
import { useAccountDatagrid } from "./hook";

const defaultColumnOptions = {
    resizable: false,
    flex: 1,
    hideSortIcons: false,
    disableColumnMenu: false,
}

const columns: GridColDef[] = [
    {
        field: "id",
        headerName: "ID",
        headerAlign: "left",
        align: "left",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        ...defaultColumnOptions
    },
    {
        field: "companyName",
        headerName: "Company Name",
        headerAlign: "center",
        align: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        ...defaultColumnOptions
    },
    {
        field: "primaryContact",
        headerName: "Contact",
        headerAlign: "center",
        align: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        ...defaultColumnOptions
    },
    {
        field: "username",
        headerName: "Username",
        align: "center",
        headerAlign: "center",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        ...defaultColumnOptions
    },
    {
        field: "",
        headerName: "edit",
        headerAlign: "right",
        align: "right",
        renderHeader: (params: GridColumnHeaderParams) => {
            return (
                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {params.colDef.headerName!}
                </Typography>
            );
        },
        renderCell: (params: GridRenderCellParams) => {
            const navigate = useNavigate();

            return (
                <IconButton color="primary" sx={{ border: "2px solid", borderRadius: 1 }} onClick={() => navigate(`/edit-profile/${params.row.username}`)} >
                    <SvgIcon>
                        <Icon path={mdiSquareEditOutline} />
                    </SvgIcon>
                </IconButton>
            );
        },
        ...defaultColumnOptions
    },
]

export const AccountsDataGrid: FunctionComponent<{ adminMode: boolean }> = (props): JSX.Element => {
    const {
        onKeyEnterEvent,
        onPageChange,
        onPageSizeChange,
        pageSize,
        rowCount,
        rows,
        startIndex,
        onFetchAccounts
    } = useAccountDatagrid(props.adminMode);

    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1} mt={2}>
                <CustomInput name="search" height={35} placeholder="Search for a record" onKeyDown={onKeyEnterEvent} />
                <Button label="Search" height={35} onClick={() => onFetchAccounts()} />
            </Stack>
            <CustomDataGrid
                sortingOrder={["asc", "desc"]}
                columns={columns}
                rows={rows}
                page={startIndex}
                rowsPerPageOptions={[5, 10, 15, 25]}
                rowCount={rowCount}
                pagination
                initialState={{
                    pagination: {
                        page: startIndex,
                        pageSize: pageSize
                    }
                }}
                paginationMode="server"
                onPageChange={onPageChange}
                onPageSizeChange={onPageSizeChange}
            />
        </>
    )
}