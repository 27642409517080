import React, { FunctionComponent } from "react";
import { CustomTabs } from "../CustomTabs"
import { Button } from "../Button";
import { IProps } from "./IProps";
import { AccountsDataGrid } from "../AccountDatagrid";


export const AccountTabPanel: FunctionComponent<IProps> = (props) => {
    return (
        <CustomTabs
            children={
                [
                    <Button height={35} onClick={props.onClickAddUser} title="Add User" label="Add User" />,
                    <Button height={35} onClick={props.onClickTerritory} title="Import territory" label="Import Territory" />
                ]
            }
            tabLabels={[{ title: "Admin" }, { title: "Dealer" }]}
            tabComponents={[
                <AccountsDataGrid adminMode />,
                <AccountsDataGrid adminMode={false} />,
            ]}
        />
    )
}