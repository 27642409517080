import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, Typography, Stack, SvgIcon } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import { mdiHome, mdiHomeVariantOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import { IProps } from "./IProps";


const mappedRoutes = {
    Home: "/dealer-home",
    Reports: "/reports",
    UserAccess: "/user-access",
    Account: "/account"
}

export const CustomBreadCrumbs: FunctionComponent<IProps> = (props): JSX.Element => {
    const splittedPath = props.path.split("/");

    const breadcrumbs = splittedPath.map((path, i, arr,) => {
        const isLastOne = (arr.length - 1) === i;
        const isFirstone = (arr.length - (arr.length - 1))

        return isLastOne ? (
            <Typography display="flex" alignItems="end" fontSize={14} fontWeight="400" sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
                {path}
            </Typography>
        ) : (
            <Link style={{ textDecoration: "none" }} to={mappedRoutes[path as keyof typeof mappedRoutes]}>
                <Typography display="flex" alignItems="end" fontSize={14} fontWeight="400" sx={{ color: "#1BA0E9" }}>
                    {path}
                </Typography>
            </Link>
        )
    })

    const CustomSeparator = () => {
        return (
            <SvgIcon fontSize="small" htmlColor="#1BA0E9" >
                <NavigateNext />
            </SvgIcon>
        )
    }

    return (
        <Stack display="flex" alignItems="center" direction="row" spacing={2} height={'40px'}>
            <Link to={mappedRoutes.Home}>
                <Icon size={0.8} path={props.icon ? props.icon : mdiHomeVariantOutline} color="#1BA0E9" />
            </Link>
            <Breadcrumbs separator={<CustomSeparator />} >
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    )
}