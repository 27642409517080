import React, { FunctionComponent } from 'react';
import { Typography, Card, CardContent, Stack, Box, FormControlLabel, Checkbox, Snackbar, Alert } from '@mui/material';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Icon } from "@mdi/react";
import { mdiPound, mdiKeyOutline, mdiEyeOffOutline } from "@mdi/js";
import { CustomInput } from "../../components/CustomInput";
import { Button } from "../../components/Button";
import { useEditProfileForm } from "./Hook"
import { ICompanyInfoState, IHatchState, ILeadPerfectionDataState, IMarketSharpState, Ii360State } from "./IState";
import { User } from "../../model/User";
import Grid from '@mui/material/Unstable_Grid2';
import { IProps } from "./IProps"

interface IEditprofileForm<T> {
    data: T;
    onChange: (event: React.ChangeEvent<HTMLInputElement>, sectionName: string) => void;
}

interface IcompanyInfoProps<T> extends IEditprofileForm<T> {
    disableReadForm: boolean;
    disableFieldsForDealers: () => boolean;
    onChangeCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface ILeadPerfectionProps<T> extends IEditprofileForm<T> {
    disableReadForm: boolean;
}

const CompanyInfo: React.FunctionComponent<IcompanyInfoProps<ICompanyInfoState>> = (props) => {
    return (
        <Box>
            <Typography sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 18, mb: 1 }} >
                Company Info
            </Typography>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} spacing={4}>
                <Grid container xs={12} sm={12} md={6} columnSpacing={2} rowSpacing={2}>
                    <Grid xs={12} sm={12} md={12} width={"100%"}>
                        <CustomInput disabled={props.disableReadForm || props.disableFieldsForDealers()} width={"100%"} height={35} label={{ text: "Company Name", color: "rgba(0, 0, 0, 0.8)" }} name="companyName" value={props.data.companyName} placeholder="company name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} readOnly={props.disableFieldsForDealers()} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} width={"100%"}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "Contact Phone", color: "rgba(0, 0, 0, 0.8)" }} name="contactPhone" value={props.data.contactPhone} placeholder="contact phone" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} width={"100%"}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "Phone Lead CC Email", color: "rgba(0, 0, 0, 0.8)" }} name="phoneLeadCCEmail" value={props.data.phoneLeadCCEmail} placeholder="phone lead cc email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} width={"100%"}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "Internet Lead CC Email", color: "rgba(0, 0, 0, 0.8)" }} name="internetLeadCCEmail" value={props.data.internetLeadCCEmail} placeholder="internet lead cc email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} />
                    </Grid>

                </Grid>
                <Grid container xs={12} sm={12} md={6} columnSpacing={2} rowSpacing={0}>
                    <Grid xs={12} sm={12} md={12} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "Primary Contact", color: "rgba(0, 0, 0, 0.8)" }} name="primaryContact" value={props.data.primaryContact} placeholder="primay contact" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "Phone Lead Email Address", color: "rgba(0, 0, 0, 0.8)" }} name="phoneLeadEmailAddress" value={props.data.phoneLeadEmailAddress} placeholder="phone lead email address" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} />
                    </Grid>
                    <Grid xs={12} sm={12} md={12} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "Internet Lead Email", color: "rgba(0, 0, 0, 0.8)" }} name="internetLeadEmail" value={props.data.internetLeadEmail} placeholder="internet lead email" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "companyInfo")} />
                    </Grid>

                </Grid>
                <Grid xs={12}>
                    <FormControlLabel
                        disabled={props.disableReadForm}
                        sx={{ my: 1 }}
                        control={<Checkbox checked={props.data.sendEmail} value={props.data.sendEmail} sx={{ color: "#1BA0E9" }} onChange={props.onChangeCheckbox} icon={<RadioButtonUncheckedIcon />}
                            checkedIcon={<RadioButtonCheckedIcon sx={{ color: "#1BA0E9" }} />} />}
                        label={<Typography sx={{ fontSize: 14, fontWeight: 600 }}>Send Email For Each Call</Typography>}
                    />
                </Grid>
            </Grid>

        </Box>
    )
}



const LeadPerfectionData: React.FunctionComponent<ILeadPerfectionProps<ILeadPerfectionDataState>> = (props) => {
    return (
        <Box>
            <Typography sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 18, mb: 1 }} >
                LeadPerfection
            </Typography>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} rowSpacing={2}>
                <Grid container xs={12} sm={12} md={12} columnSpacing={2} rowSpacing={2}>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "LP URL", color: "rgba(0, 0, 0, 0.8)" }} name="lpurl" value={props.data.lpurl} placeholder="lpl url" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "leadPerfectionData")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "LP Product", color: "rgba(0, 0, 0, 0.8)" }} name="lpProduct" value={props.data.lpProduct} placeholder="lp product" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "leadPerfectionData")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} width={"100%"} height={35} label={{ text: "LP Source", color: "rgba(0, 0, 0, 0.8)" }} name="lpSource" value={props.data.lpSource} placeholder="lp source" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "leadPerfectionData")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput disabled={props.disableReadForm} type='number' width={"100%"} height={35} label={{ text: "LP SubSource ID", color: "rgba(0, 0, 0, 0.8)" }} name="lpSubsourceID" value={props.data.lpSubsourceID} placeholder="lp subsource id" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "leadPerfectionData")} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const MarketSharpSection: FunctionComponent<IEditprofileForm<IMarketSharpState>> = (props) => {
    return (
        <Box sx={{ mt: 4 }} >
            <Typography sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 18, mb: 1 }} >
                MarketSharp
            </Typography>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} rowSpacing={2}>
                <Grid container xs={12} sm={12} md={12} columnSpacing={2} rowSpacing={2}>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "COY", color: "rgba(0, 0, 0, 0.8)" }} name="coy" value={props.data.coy} placeholder="coy" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "marketSharp")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Form Key", color: "rgba(0, 0, 0, 0.8)" }} name="formKey" value={props.data.formKey} placeholder="form key" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "marketSharp")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Source Key", color: "rgba(0, 0, 0, 0.8)" }} name="sourceKey" value={props.data.sourceKey} placeholder="source key" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "marketSharp")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput type='text' width={"100%"} height={35} label={{ text: "Capture Name", color: "rgba(0, 0, 0, 0.8)" }} name="captureName" value={props.data.captureName} placeholder="capture name" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "marketSharp")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput type='text' width={"100%"} height={35} label={{ text: "Default Source", color: "rgba(0, 0, 0, 0.8)" }} name="defaultSource" value={props.data.defaultSource} placeholder="default source" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "marketSharp")} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const I360Section: FunctionComponent<IEditprofileForm<Ii360State>> = (props) => {
    return (
        <Box sx={{ mt: 4 }} >
            <Typography sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 18, mb: 1 }} >
                i360
            </Typography>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} rowSpacing={2}>
                <Grid container xs={12} sm={12} md={12} columnSpacing={2} rowSpacing={2}>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Webhook Address", color: "rgba(0, 0, 0, 0.8)" }} name="webhookAddress" value={props.data.webhookAddress} placeholder="webhook address" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "i360")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Default Source", color: "rgba(0, 0, 0, 0.8)" }} name="defaultSource" value={props.data.defaultSource} placeholder="default source" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "i360")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Default Source Type", color: "rgba(0, 0, 0, 0.8)" }} name="defaultSourceType" value={props.data.defaultSourceType} placeholder="default source type" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "i360")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput type="text" width={"100%"} height={35} label={{ text: "Default Interest", color: "rgba(0, 0, 0, 0.8)" }} name="defaultInterest" value={props.data.defaultInterest} placeholder="default interest" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "i360")} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const HacthSection: FunctionComponent<IEditprofileForm<IHatchState>> = (props): JSX.Element => {
    return (
        <Box sx={{ mt: 2 }} >
            <Typography sx={{ color: "#1BA0E9", fontWeight: 600, fontSize: 18, mb: 1 }} >
                Hatch
            </Typography>
            <Grid container columns={{ xs: 12, sm: 12, md: 12 }} rowSpacing={2}>
                <Grid container xs={12} sm={12} md={12} columnSpacing={2} rowSpacing={2}>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Webhook Address", color: "rgba(0, 0, 0, 0.8)" }} name="webhookAddress" value={props.data.webhookAddress} placeholder="webhook address" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "hatch")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Client ID", color: "rgba(0, 0, 0, 0.8)" }} name="clientId" value={props.data.clientId} placeholder="client id" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "hatch")} />
                    </Grid>
                    <Grid xs={12} sm={6} md={6} width={"100%"} mt={2}>
                        <CustomInput width={"100%"} height={35} label={{ text: "Client Token", color: "rgba(0, 0, 0, 0.8)" }} name="clientToken" value={props.data.clientToken} placeholder="client token" onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.onChange(event, "hatch")} />
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

export const EditProfileForm: FunctionComponent<IProps> = (props): JSX.Element => {
    const user = new User();
    const disableReadForm = user.HasForbiddenAccess("Can Read User Info");
    const disableEditForm = user.HasForbiddenAccess("Can Edit User Info");
    const {
        state,
        snackbarOpen,
        fetchUserData,
        onUpdate,
        onClose,
        onChange,
        onChangeCheckBox
    } = useEditProfileForm(props.username);

    const disableFieldsForDealers = (): boolean => {
        return user.Roles[0].secLevel === 1
    }


    return (
        <>
            <Card sx={{ boxShadow: 2, mt: 2, borderRadius: 4 }}>
                <CardContent>
                    <>
                        {   
                            !props.isIntegrationsEnabled 
                            ? (
                                <CompanyInfo
                                    data={state.companyInfo}
                                    disableFieldsForDealers={disableFieldsForDealers}
                                    disableReadForm={disableReadForm}
                                    onChange={(event) => onChange(event, "companyInfo")}
                                    onChangeCheckbox={onChangeCheckBox}
                                />
                                )
                            : (
                                <>      
                                    <LeadPerfectionData
                                        data={state.leadPerfectionData}
                                        disableReadForm={disableReadForm}
                                        onChange={onChange}
                                    />
                                    <MarketSharpSection
                                        data={state.marketSharp}
                                        onChange={onChange}
                                    />
                                    <I360Section
                                        data={state.i360}
                                        onChange={onChange}
                                    />
                                    <HacthSection data={state.hatch} onChange={onChange} />
                                </>
                            )
                        }
                    </>
                </CardContent>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "100%", borderTop: 1, backgroundColor: "#FAFAFA", borderColor: "#00000026", p: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" width={120} height={40} label="Cancel" onClick={fetchUserData} />
                        <Button disabled={disableReadForm || disableEditForm} width={120} height={40} label='Save' onClick={onUpdate} />
                    </Stack>
                </Box>
                <Snackbar open={snackbarOpen} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} onClose={onClose} autoHideDuration={2000} >
                    <Alert sx={{ width: '100%', backgroundColor: "#1BA0E9", color: "white", fontSize: 18, fontWeight: 600 }}>
                        Profile has been updated
                    </Alert>
                </Snackbar>
            </Card>
        </>
    )
}