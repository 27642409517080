import { FunctionComponent } from "react";
import { Card, CardContent, Typography, Box, SvgIcon, Stack, FormControlLabel, Checkbox, MenuItem } from "@mui/material";
import { CloseOutlined, PersonOutlined, Person, LanguageOutlined, LocationOnOutlined, Save } from "@mui/icons-material";
import { mdiMapMarkerRadiusOutline, mdiMapMarkerPath } from "@mdi/js";
import { Icon } from "@mdi/react";
import { CustomInput, CustomSelect, Button, InputGroup } from "..";
import { CustomTextArea } from "../CustomTextArea";
import { useEditLeadForm } from "./hook";

export const EditSunestaLeadForm = (): JSX.Element => {
    const { onChange, onReset, state } = useEditLeadForm();

    const awnings = [
        {
            label: { text: "Sunesta" },
            name: "sunesta",
            value: state.sunesta,
            onChange
        },
        {
            label: { text: "Sunstyle" },
            name: "sunstyle",
            value: state.sunstyle,
            onChange
        },
        {
            label: { text: "Sunlight" },
            name: "sunlight",
            value: state.sunlight,
            onChange
        },
        {
            label: { text: "Sunbrero" },
            name: "sunbrero",
            value: state.sunbrero,
            onChange
        },
        {
            label: { text: "Sundrop" },
            name: "sundrop",
            value: state.sundrop,
            onChange
        }
    ];

    const sentry = [
        {
            label: { text: "Sunroll" },
            name: "sunroll",
            value: state.sunroll,
            onChange
        }
    ]

    const accesories = [
        {
            label: { text: "Shadeside" },
            name: "shadeside",
            value: state.shadeside,
            onChange
        },
        {
            label: { text: "Sentry" },
            name: "sentry",
            value: state.sentry,
            onChange
        },
        {
            label: { text: "Sunboot" },
            name: "sunboot",
            value: state.sunboot,
            onChange
        },
        {
            label: { text: "Light" },
            name: "light",
            value: state.light,
            onChange
        },
        {
            label: { text: "Heater" },
            name: "heater",
            value: state.heater,
            onChange
        }
    ];

    const shelters = [
        {
            label: { text: "Suncover" },
            name: "suncover",
            value: state.suncover,
            onChange
        },
        {
            label: { text: "Sunplus" },
            name: "sunplus",
            value: state.sunplus,
            onChange
        },
        {
            label: { text: "Shadespot" },
            name: "shadespot",
            value: state.shadespot,
            onChange
        }
    ]

    const services = [
        {
            label: { text: "Services" },
            name: "services",
            value: state.services,
            onChange
        }
    ]

    const SectionTitle: FunctionComponent<{ title: string }> = (props): JSX.Element => {
        return (
            <Box sx={{ p: 2, backgroundColor: "#F5F5F5", borderRadius: 4, mt: 6 }}>
                <Typography fontSize={18} fontWeight="600" color="#1BA0E9">
                    {props.title}
                </Typography>
            </Box>
        )
    }

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Card sx={{ borderRadius: 4, width: "100%" }} >
                <CardContent>
                    <Box display="flex" alignItems="center" justifyContent="center" position="relative">
                        <Typography fontSize={22} fontWeight="600" color="#1BA0E9" textAlign="center">
                            Edit Lead
                        </Typography>
                        <SvgIcon sx={{ position: "absolute", color: "rgba(0, 0, 0, 0.5)", top: 5, right: 20 }}>
                            <CloseOutlined />
                        </SvgIcon>
                    </Box>
                    <SectionTitle title="Personal Info" />
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="lastname" startIcon={<PersonOutlined />} label={{ text: "Last Name" }} width={360} height={35} value={state.lastname} onChange={onChange} />
                        <CustomInput name="firstname" startIcon={<Person />} label={{ text: "First Name" }} width={360} height={35} value={state.firstname} onChange={onChange} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="state" startIcon={<LanguageOutlined />} label={{ text: "State Name" }} width={360} height={35} value={state.state} onChange={onChange} />
                        <CustomInput name="city" startIcon={<Icon path={mdiMapMarkerRadiusOutline} />} label={{ text: "City Name" }} width={360} height={35} value={state.city} onChange={onChange} />
                    </Stack>
                    <Stack direction="row" justifyContent="space-between" spacing={4} mt={2} >
                        <CustomInput name="address" startIcon={<LocationOnOutlined />} label={{ text: "Address" }} width={360} height={35} value={state.adress} onChange={onChange} />
                        <CustomInput name="zip" startIcon={<Icon path={mdiMapMarkerPath} />} label={{ text: "Zip Code" }} width={360} height={35} value={state.zip} onChange={onChange} />
                    </Stack>
                    <Box mt={2} >
                        <CustomInput name="phone" startIcon={<PersonOutlined />} label={{ text: "Phone" }} width={360} height={35} value={state.phone} onChange={onChange} />
                    </Box>
                    <Box mt={2}>
                        <CustomTextArea label={{text: "Notes"}} name="notes" width="100%" maxLength={50} value={state.notes} onChange={onChange} />
                    </Box>
                    <SectionTitle title="Sold Products" />
                    <Stack direction="row" spacing={2} mt={4}>
                        <InputGroup title="Awnings" textboxes={awnings} />
                        <InputGroup title="Sentry" textboxes={sentry} />
                        <InputGroup title="Shelters" textboxes={shelters} />
                        <InputGroup title="Accesories" textboxes={accesories} />
                        <InputGroup title="Services" textboxes={services} />
                    </Stack>
                    <SectionTitle title="Details" />
                    <Stack direction="row" justifyContent="space-between" spacing={2} mt={2} >
                        <CustomSelect value="Unresulted" width={280} label="LeadOutcome">
                            <MenuItem value="Unresulted" >Unresulted</MenuItem>
                            <MenuItem>Dead</MenuItem>
                            <MenuItem>Database</MenuItem>
                            <MenuItem>Working: callback to set</MenuItem>
                            <MenuItem>Inquiry: Appt. cancelled, try reset</MenuItem>
                            <MenuItem>Inquiry: Appt. cancelled, Dead</MenuItem>
                            <MenuItem>Inquiry: Issue, No Demo</MenuItem>
                            <MenuItem>Inquiry: Issue, No sale</MenuItem>
                            <MenuItem>Inquiry: Sale</MenuItem>
                        </CustomSelect>
                        <CustomInput width={280} name="po" value={state.po} onChange={onChange} label={{ text: "Enter PO#s (Separate multilple PO's with a comma)", color: "rgba(0, 0, 0, 0.8)" }} height={35} placeholder="..." />
                        <CustomSelect value="Please Choose" width={280} label="How many competitors were in the home before you?">
                            <MenuItem value="Please Choose">Please Choose</MenuItem>
                            <MenuItem>None</MenuItem>
                            <MenuItem>1, 2, 3</MenuItem>
                            <MenuItem>4 or more</MenuItem>
                        </CustomSelect>
                    </Stack>
                    <Stack direction="row" spacing={2} mt={2} >
                        <CustomInput width={280} name="totalSalePrice" value={state.totalSalePrice} onChange={onChange} placeholder="0.0000" label={{ text: "Total sale price", color: "rgba(0, 0, 0, 0.8)" }} height={35} />
                        <CustomSelect value="Select One..." width={280} label="Reassign to dealer">
                            <MenuItem value="Select One...">Select One...</MenuItem>
                        </CustomSelect>
                    </Stack>
                    <Box sx={{ width: "100%", borderTop: "1px solid rgba(0, 0, 0, 0.12)", mt: 4, p: 2 }}>
                        <Stack direction="row" justifyContent="space-between">
                            <FormControlLabel control={<Checkbox defaultChecked />} label="Send Email" />
                            <Stack direction="row" spacing={2}>
                                <Button variant="outlined" label="Cancel" onClick={onReset}  />
                                <Button startIcon={<Save />} label="Save" />
                            </Stack>
                        </Stack>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    )
}